import { FieldInputProps, FormikProps } from "formik";
import Select, { SelectProps } from "./select";

type FormSelectProps<T, I> = {
  field: FieldInputProps<I>;
  props: FormikProps<T>;
} & SelectProps;

const FormSelect = <T, I>({ field, props, ...rest }: FormSelectProps<T, I>) => {
  const { value: fieldValue, onChange: fieldOnChange, ...restField } = field;

  return (
    <Select
      value={rest.options?.find((option) => option.value === fieldValue)}
      onChange={(option) => {
        props.setFieldValue(field.name, option?.value);
      }}
      {...rest}
      {...restField}
    />
  );
};

export default FormSelect;
