import { Image, LinkBox, LinkOverlay } from "@chakra-ui/react";

const AppStoreButton = ({ icon, url }: { icon: string; url: string }) => {
  return (
    <LinkBox
      borderColor="neutral.300"
      borderWidth={1}
      boxShadow="0 3px 18px 0 #4a3aff1c"
      bg="neutral.100"
      px="25px"
      py="14px"
      borderRadius={14}
    >
      <LinkOverlay href={url} isExternal>
        <Image src={icon} />
      </LinkOverlay>
    </LinkBox>
  );
};

export default AppStoreButton;
