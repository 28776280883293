import { useState, useEffect } from "react";
import { queryList } from "../utils";
import { userOrganizationsByOrganizationId } from "../graphql/queries";
import {
  UserOrganization,
  UserOrganizationRole,
  UserOrganizationsByOrganizationIdQuery,
  UserOrganizationsByOrganizationIdQueryVariables,
} from "../API";
import { Organization, OrganizationFull } from "../models";

// Custom hook for fetching user organizations without accountants
type UseUserOrganizationProps = {
  selectedOrganization: Organization | OrganizationFull | undefined;
  withAccountants?: boolean;
  includeDeleted?: boolean;
};

export function useUserOrganizations({
  selectedOrganization,
  withAccountants = false,
  includeDeleted = false,
}: UseUserOrganizationProps) {
  const [userOrganizations, setUserOrganizations] =
    useState<UserOrganization[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (selectedOrganization) {
      const fetchUserOrganizations = async () => {
        try {
          const userOrganizations = await queryList<
            UserOrganization,
            UserOrganizationsByOrganizationIdQueryVariables,
            UserOrganizationsByOrganizationIdQuery
          >(
            userOrganizationsByOrganizationId,
            {
              organizationId: selectedOrganization.id,
              filter: withAccountants
                ? undefined
                : {
                    role: {
                      ne: UserOrganizationRole.accountant,
                    },
                  },
            },
            includeDeleted
          );
          setUserOrganizations(userOrganizations);
          setIsLoading(false);
        } catch (err) {
          setError(err as Error);
          setIsLoading(false);
        }
      };
      fetchUserOrganizations();
    }
  }, [includeDeleted, selectedOrganization, withAccountants]);

  return {
    userOrganizations,
    setUserOrganizations,
    isLoading,
    error,
  };
}
