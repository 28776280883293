import {
  ChakraProps,
  FocusLock,
  FormControl,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import {
  DateRange,
  DayPickerRangeProps,
  DayPicker as ReactDayPicker,
} from "react-day-picker";
import "react-day-picker/dist/style.css";
import { State } from "../types";
import { firstOfYear, formatDate } from "../utils";

const DayPicker = ({ ...props }: DayPickerRangeProps) => {
  return <ReactDayPicker {...props} />;
};

const DateRangeSelector = ({
  state,
  range,
  setRange,
  ...props
}: {
  state: State;
  range: DateRange | undefined;
  setRange: Dispatch<SetStateAction<DateRange | undefined>>;
} & ChakraProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      onOpen={state !== "initial" && state !== "loading" ? onOpen : undefined}
      onClose={onClose}
      placement="bottom"
      {...props}
    >
      <PopoverTrigger>
        <FormControl maxWidth={{ base: "auto", md: 240 }}>
          <Input
            isReadOnly={true}
            isDisabled={state === "initial" || state === "loading"}
            value={
              range && range.from && range.to
                ? `${formatDate(range.from)} - ${formatDate(range.to)}`
                : "Datum wählen"
            }
          />
        </FormControl>
      </PopoverTrigger>
      <PopoverContent p={5}>
        <FocusLock persistentFocus={false}>
          <PopoverArrow />
          <DayPicker
            mode="range"
            defaultMonth={firstOfYear}
            selected={range}
            onSelect={setRange}
            initialFocus={isOpen}
          />
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

export default DateRangeSelector;
