import { useState, useEffect } from "react";
import { queryList } from "../utils";
import { listTransactionReferences } from "../graphql/queries";
import {
  ListTransactionReferencesQuery,
  ListTransactionReferencesQueryVariables,
} from "../API";
import { TransactionReference, User } from "../models";

// Custom hook for fetching transaction categores
export function useTransactionReferencesByUsers(users?: User[]) {
  const [transactionReferences, setTransactionReferences] =
    useState<TransactionReference[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (users) {
      const fetchTransactionReferences = async () => {
        try {
          const TransactionReferences = await queryList<
            TransactionReference,
            ListTransactionReferencesQueryVariables,
            ListTransactionReferencesQuery
          >(listTransactionReferences, {
            filter: {
              or: users.map((user) => ({
                authUserId: {
                  eq: user.id,
                },
              })),
            },
          });

          setTransactionReferences(TransactionReferences);
          setIsLoading(false);
        } catch (err) {
          setError(err as Error);
          setIsLoading(false);
        }
      };
      fetchTransactionReferences();
    }
  }, [users]);

  return { transactionReferences, isLoading, error };
}
