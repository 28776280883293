/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    deletedAt
    name
    userOrganizations {
      nextToken
      __typename
    }
    bookkeepings {
      nextToken
      __typename
    }
    type
    logo
    billing {
      id
      deletedAt
      userId
      customerId
      subscriptionId
      subscriptionItemId
      quantity
      status
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    organizationBillingId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    deletedAt
    name
    userOrganizations {
      nextToken
      __typename
    }
    bookkeepings {
      nextToken
      __typename
    }
    type
    logo
    billing {
      id
      deletedAt
      userId
      customerId
      subscriptionId
      subscriptionItemId
      quantity
      status
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    organizationBillingId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const createBilling = /* GraphQL */ `mutation CreateBilling(
  $input: CreateBillingInput!
  $condition: ModelBillingConditionInput
) {
  createBilling(input: $input, condition: $condition) {
    id
    deletedAt
    userId
    customerId
    subscriptionId
    subscriptionItemId
    quantity
    status
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBillingMutationVariables,
  APITypes.CreateBillingMutation
>;
export const updateBilling = /* GraphQL */ `mutation UpdateBilling(
  $input: UpdateBillingInput!
  $condition: ModelBillingConditionInput
) {
  updateBilling(input: $input, condition: $condition) {
    id
    deletedAt
    userId
    customerId
    subscriptionId
    subscriptionItemId
    quantity
    status
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBillingMutationVariables,
  APITypes.UpdateBillingMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    deletedAt
    firstName
    lastName
    email
    userOrganizations {
      nextToken
      __typename
    }
    userBookkeepings {
      nextToken
      __typename
    }
    role
    transactions {
      nextToken
      __typename
    }
    isSSO
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    deletedAt
    firstName
    lastName
    email
    userOrganizations {
      nextToken
      __typename
    }
    userBookkeepings {
      nextToken
      __typename
    }
    role
    transactions {
      nextToken
      __typename
    }
    isSSO
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const createUserOrganization = /* GraphQL */ `mutation CreateUserOrganization(
  $input: CreateUserOrganizationInput!
  $condition: ModelUserOrganizationConditionInput
) {
  createUserOrganization(input: $input, condition: $condition) {
    id
    deletedAt
    userId
    organizationId
    role
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserOrganizationMutationVariables,
  APITypes.CreateUserOrganizationMutation
>;
export const updateUserOrganization = /* GraphQL */ `mutation UpdateUserOrganization(
  $input: UpdateUserOrganizationInput!
  $condition: ModelUserOrganizationConditionInput
) {
  updateUserOrganization(input: $input, condition: $condition) {
    id
    deletedAt
    userId
    organizationId
    role
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserOrganizationMutationVariables,
  APITypes.UpdateUserOrganizationMutation
>;
export const createBookkeeping = /* GraphQL */ `mutation CreateBookkeeping(
  $input: CreateBookkeepingInput!
  $condition: ModelBookkeepingConditionInput
) {
  createBookkeeping(input: $input, condition: $condition) {
    id
    deletedAt
    name
    organizationId
    userBookkeepings {
      nextToken
      __typename
    }
    accountingSystem
    accountingSystemConfig {
      id
      deletedAt
      baseCurrency
      billingPeriod
      senderBankAccountId
      reimbursement
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    bookingAccounts {
      nextToken
      __typename
    }
    taxRates {
      nextToken
      __typename
    }
    bookkeepingYears {
      nextToken
      __typename
    }
    color
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    bookkeepingAccountingSystemConfigId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookkeepingMutationVariables,
  APITypes.CreateBookkeepingMutation
>;
export const updateBookkeeping = /* GraphQL */ `mutation UpdateBookkeeping(
  $input: UpdateBookkeepingInput!
  $condition: ModelBookkeepingConditionInput
) {
  updateBookkeeping(input: $input, condition: $condition) {
    id
    deletedAt
    name
    organizationId
    userBookkeepings {
      nextToken
      __typename
    }
    accountingSystem
    accountingSystemConfig {
      id
      deletedAt
      baseCurrency
      billingPeriod
      senderBankAccountId
      reimbursement
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    bookingAccounts {
      nextToken
      __typename
    }
    taxRates {
      nextToken
      __typename
    }
    bookkeepingYears {
      nextToken
      __typename
    }
    color
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    bookkeepingAccountingSystemConfigId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookkeepingMutationVariables,
  APITypes.UpdateBookkeepingMutation
>;
export const createBookkeepingYear = /* GraphQL */ `mutation CreateBookkeepingYear(
  $input: CreateBookkeepingYearInput!
  $condition: ModelBookkeepingYearConditionInput
) {
  createBookkeepingYear(input: $input, condition: $condition) {
    id
    deletedAt
    bookkeepingId
    transactions {
      nextToken
      __typename
    }
    hasNetTaxRate
    year
    balanceStart
    balance
    closedAt
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookkeepingYearMutationVariables,
  APITypes.CreateBookkeepingYearMutation
>;
export const updateBookkeepingYear = /* GraphQL */ `mutation UpdateBookkeepingYear(
  $input: UpdateBookkeepingYearInput!
  $condition: ModelBookkeepingYearConditionInput
) {
  updateBookkeepingYear(input: $input, condition: $condition) {
    id
    deletedAt
    bookkeepingId
    transactions {
      nextToken
      __typename
    }
    hasNetTaxRate
    year
    balanceStart
    balance
    closedAt
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookkeepingYearMutationVariables,
  APITypes.UpdateBookkeepingYearMutation
>;
export const createUserBookkeeping = /* GraphQL */ `mutation CreateUserBookkeeping(
  $input: CreateUserBookkeepingInput!
  $condition: ModelUserBookkeepingConditionInput
) {
  createUserBookkeeping(input: $input, condition: $condition) {
    id
    deletedAt
    userId
    bookkeepingId
    accountingSystemBill {
      id
      deletedAt
      supplierId
      contactPartnerId
      senderBankAccountId
      billingPeriod
      baseCurrency
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      accountingSystemBillAccountingSystemReimbursementId
      __typename
    }
    bills {
      nextToken
      __typename
    }
    transactionReferences {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    userBookkeepingAccountingSystemBillId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserBookkeepingMutationVariables,
  APITypes.CreateUserBookkeepingMutation
>;
export const updateUserBookkeeping = /* GraphQL */ `mutation UpdateUserBookkeeping(
  $input: UpdateUserBookkeepingInput!
  $condition: ModelUserBookkeepingConditionInput
) {
  updateUserBookkeeping(input: $input, condition: $condition) {
    id
    deletedAt
    userId
    bookkeepingId
    accountingSystemBill {
      id
      deletedAt
      supplierId
      contactPartnerId
      senderBankAccountId
      billingPeriod
      baseCurrency
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      accountingSystemBillAccountingSystemReimbursementId
      __typename
    }
    bills {
      nextToken
      __typename
    }
    transactionReferences {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    userBookkeepingAccountingSystemBillId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserBookkeepingMutationVariables,
  APITypes.UpdateUserBookkeepingMutation
>;
export const createTransactionReference = /* GraphQL */ `mutation CreateTransactionReference(
  $input: CreateTransactionReferenceInput!
  $condition: ModelTransactionReferenceConditionInput
) {
  createTransactionReference(input: $input, condition: $condition) {
    id
    deletedAt
    name
    userBookkeeping {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    userBookkeepingId
    transactions {
      nextToken
      __typename
    }
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionReferenceMutationVariables,
  APITypes.CreateTransactionReferenceMutation
>;
export const updateTransactionReference = /* GraphQL */ `mutation UpdateTransactionReference(
  $input: UpdateTransactionReferenceInput!
  $condition: ModelTransactionReferenceConditionInput
) {
  updateTransactionReference(input: $input, condition: $condition) {
    id
    deletedAt
    name
    userBookkeeping {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    userBookkeepingId
    transactions {
      nextToken
      __typename
    }
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionReferenceMutationVariables,
  APITypes.UpdateTransactionReferenceMutation
>;
export const createAccountingSystemConfig = /* GraphQL */ `mutation CreateAccountingSystemConfig(
  $input: CreateAccountingSystemConfigInput!
  $condition: ModelAccountingSystemConfigConditionInput
) {
  createAccountingSystemConfig(input: $input, condition: $condition) {
    id
    deletedAt
    baseCurrency
    billingPeriod
    senderBankAccountId
    reimbursement
    bookkeepingId
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountingSystemConfigMutationVariables,
  APITypes.CreateAccountingSystemConfigMutation
>;
export const updateAccountingSystemConfig = /* GraphQL */ `mutation UpdateAccountingSystemConfig(
  $input: UpdateAccountingSystemConfigInput!
  $condition: ModelAccountingSystemConfigConditionInput
) {
  updateAccountingSystemConfig(input: $input, condition: $condition) {
    id
    deletedAt
    baseCurrency
    billingPeriod
    senderBankAccountId
    reimbursement
    bookkeepingId
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountingSystemConfigMutationVariables,
  APITypes.UpdateAccountingSystemConfigMutation
>;
export const createAccountingSystemBill = /* GraphQL */ `mutation CreateAccountingSystemBill(
  $input: CreateAccountingSystemBillInput!
  $condition: ModelAccountingSystemBillConditionInput
) {
  createAccountingSystemBill(input: $input, condition: $condition) {
    id
    deletedAt
    supplierId
    contactPartnerId
    senderBankAccountId
    billingPeriod
    baseCurrency
    accountingSystemReimbursement {
      id
      deletedAt
      receiverIban
      receiverStreet
      receiverHouseNumber
      receiverCity
      receiverPostalCode
      receiverCountry
      accountingSystemBillId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    userBookkeepingId
    authUserId
    authGroups
    createdAt
    updatedAt
    accountingSystemBillAccountingSystemReimbursementId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountingSystemBillMutationVariables,
  APITypes.CreateAccountingSystemBillMutation
>;
export const updateAccountingSystemBill = /* GraphQL */ `mutation UpdateAccountingSystemBill(
  $input: UpdateAccountingSystemBillInput!
  $condition: ModelAccountingSystemBillConditionInput
) {
  updateAccountingSystemBill(input: $input, condition: $condition) {
    id
    deletedAt
    supplierId
    contactPartnerId
    senderBankAccountId
    billingPeriod
    baseCurrency
    accountingSystemReimbursement {
      id
      deletedAt
      receiverIban
      receiverStreet
      receiverHouseNumber
      receiverCity
      receiverPostalCode
      receiverCountry
      accountingSystemBillId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    userBookkeepingId
    authUserId
    authGroups
    createdAt
    updatedAt
    accountingSystemBillAccountingSystemReimbursementId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountingSystemBillMutationVariables,
  APITypes.UpdateAccountingSystemBillMutation
>;
export const createAccountingSystemReimbursement = /* GraphQL */ `mutation CreateAccountingSystemReimbursement(
  $input: CreateAccountingSystemReimbursementInput!
  $condition: ModelAccountingSystemReimbursementConditionInput
) {
  createAccountingSystemReimbursement(input: $input, condition: $condition) {
    id
    deletedAt
    receiverIban
    receiverStreet
    receiverHouseNumber
    receiverCity
    receiverPostalCode
    receiverCountry
    accountingSystemBillId
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountingSystemReimbursementMutationVariables,
  APITypes.CreateAccountingSystemReimbursementMutation
>;
export const updateAccountingSystemReimbursement = /* GraphQL */ `mutation UpdateAccountingSystemReimbursement(
  $input: UpdateAccountingSystemReimbursementInput!
  $condition: ModelAccountingSystemReimbursementConditionInput
) {
  updateAccountingSystemReimbursement(input: $input, condition: $condition) {
    id
    deletedAt
    receiverIban
    receiverStreet
    receiverHouseNumber
    receiverCity
    receiverPostalCode
    receiverCountry
    accountingSystemBillId
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountingSystemReimbursementMutationVariables,
  APITypes.UpdateAccountingSystemReimbursementMutation
>;
export const createBookingAccount = /* GraphQL */ `mutation CreateBookingAccount(
  $input: CreateBookingAccountInput!
  $condition: ModelBookingAccountConditionInput
) {
  createBookingAccount(input: $input, condition: $condition) {
    id
    deletedAt
    name
    accountNumber
    sortOrder
    taxType
    transactionCategory {
      id
      deletedAt
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    transactionCategoryId
    accountingSystemId
    bookkeepingId
    transactions {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingAccountMutationVariables,
  APITypes.CreateBookingAccountMutation
>;
export const updateBookingAccount = /* GraphQL */ `mutation UpdateBookingAccount(
  $input: UpdateBookingAccountInput!
  $condition: ModelBookingAccountConditionInput
) {
  updateBookingAccount(input: $input, condition: $condition) {
    id
    deletedAt
    name
    accountNumber
    sortOrder
    taxType
    transactionCategory {
      id
      deletedAt
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    transactionCategoryId
    accountingSystemId
    bookkeepingId
    transactions {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookingAccountMutationVariables,
  APITypes.UpdateBookingAccountMutation
>;
export const createTaxRate = /* GraphQL */ `mutation CreateTaxRate(
  $input: CreateTaxRateInput!
  $condition: ModelTaxRateConditionInput
) {
  createTaxRate(input: $input, condition: $condition) {
    id
    deletedAt
    code
    rate
    taxType
    sortOrder
    accountingSystemId
    bookkeepingId
    dateFrom
    dateTo
    taxes {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaxRateMutationVariables,
  APITypes.CreateTaxRateMutation
>;
export const updateTaxRate = /* GraphQL */ `mutation UpdateTaxRate(
  $input: UpdateTaxRateInput!
  $condition: ModelTaxRateConditionInput
) {
  updateTaxRate(input: $input, condition: $condition) {
    id
    deletedAt
    code
    rate
    taxType
    sortOrder
    accountingSystemId
    bookkeepingId
    dateFrom
    dateTo
    taxes {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaxRateMutationVariables,
  APITypes.UpdateTaxRateMutation
>;
export const createTransactionCategory = /* GraphQL */ `mutation CreateTransactionCategory(
  $input: CreateTransactionCategoryInput!
  $condition: ModelTransactionCategoryConditionInput
) {
  createTransactionCategory(input: $input, condition: $condition) {
    id
    deletedAt
    name
    icon
    bookingAccounts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionCategoryMutationVariables,
  APITypes.CreateTransactionCategoryMutation
>;
export const updateTransactionCategory = /* GraphQL */ `mutation UpdateTransactionCategory(
  $input: UpdateTransactionCategoryInput!
  $condition: ModelTransactionCategoryConditionInput
) {
  updateTransactionCategory(input: $input, condition: $condition) {
    id
    deletedAt
    name
    icon
    bookingAccounts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionCategoryMutationVariables,
  APITypes.UpdateTransactionCategoryMutation
>;
export const deleteTransactionCategory = /* GraphQL */ `mutation DeleteTransactionCategory(
  $input: DeleteTransactionCategoryInput!
  $condition: ModelTransactionCategoryConditionInput
) {
  deleteTransactionCategory(input: $input, condition: $condition) {
    id
    deletedAt
    name
    icon
    bookingAccounts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionCategoryMutationVariables,
  APITypes.DeleteTransactionCategoryMutation
>;
export const createBill = /* GraphQL */ `mutation CreateBill(
  $input: CreateBillInput!
  $condition: ModelBillConditionInput
) {
  createBill(input: $input, condition: $condition) {
    id
    deletedAt
    name
    accountingSystemId
    dateFrom
    dateTo
    status
    amount
    currency
    baseCurrencyAmount
    transactions {
      nextToken
      __typename
    }
    userBookkeeping {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    userBookkeepingId
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBillMutationVariables,
  APITypes.CreateBillMutation
>;
export const updateBill = /* GraphQL */ `mutation UpdateBill(
  $input: UpdateBillInput!
  $condition: ModelBillConditionInput
) {
  updateBill(input: $input, condition: $condition) {
    id
    deletedAt
    name
    accountingSystemId
    dateFrom
    dateTo
    status
    amount
    currency
    baseCurrencyAmount
    transactions {
      nextToken
      __typename
    }
    userBookkeeping {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    userBookkeepingId
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBillMutationVariables,
  APITypes.UpdateBillMutation
>;
export const createTransaction = /* GraphQL */ `mutation CreateTransaction(
  $input: CreateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  createTransaction(input: $input, condition: $condition) {
    id
    deletedAt
    paidAt
    currencyCode
    taxes {
      nextToken
      __typename
    }
    amount
    attachments {
      nextToken
      __typename
    }
    title
    exchangeRate
    baseCurrencyAmount
    status
    accountingSystemId
    bookkeepingYear {
      id
      deletedAt
      bookkeepingId
      hasNetTaxRate
      year
      balanceStart
      balance
      closedAt
      authGroups
      createdAt
      updatedAt
      __typename
    }
    bookkeepingYearId
    bill {
      id
      deletedAt
      name
      accountingSystemId
      dateFrom
      dateTo
      status
      amount
      currency
      baseCurrencyAmount
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    billId
    bookingAccount {
      id
      deletedAt
      name
      accountNumber
      sortOrder
      taxType
      transactionCategoryId
      accountingSystemId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    bookingAccountId
    transactionReference {
      id
      deletedAt
      name
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    transactionReferenceId
    user {
      id
      deletedAt
      firstName
      lastName
      email
      role
      isSSO
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    userId
    replacedById
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionMutationVariables,
  APITypes.CreateTransactionMutation
>;
export const updateTransaction = /* GraphQL */ `mutation UpdateTransaction(
  $input: UpdateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  updateTransaction(input: $input, condition: $condition) {
    id
    deletedAt
    paidAt
    currencyCode
    taxes {
      nextToken
      __typename
    }
    amount
    attachments {
      nextToken
      __typename
    }
    title
    exchangeRate
    baseCurrencyAmount
    status
    accountingSystemId
    bookkeepingYear {
      id
      deletedAt
      bookkeepingId
      hasNetTaxRate
      year
      balanceStart
      balance
      closedAt
      authGroups
      createdAt
      updatedAt
      __typename
    }
    bookkeepingYearId
    bill {
      id
      deletedAt
      name
      accountingSystemId
      dateFrom
      dateTo
      status
      amount
      currency
      baseCurrencyAmount
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    billId
    bookingAccount {
      id
      deletedAt
      name
      accountNumber
      sortOrder
      taxType
      transactionCategoryId
      accountingSystemId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    bookingAccountId
    transactionReference {
      id
      deletedAt
      name
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    transactionReferenceId
    user {
      id
      deletedAt
      firstName
      lastName
      email
      role
      isSSO
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    userId
    replacedById
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionMutationVariables,
  APITypes.UpdateTransactionMutation
>;
export const createAttachment = /* GraphQL */ `mutation CreateAttachment(
  $input: CreateAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  createAttachment(input: $input, condition: $condition) {
    id
    deletedAt
    key
    accountingSystemId
    accountingSystemName
    transactionId
    authUserId
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttachmentMutationVariables,
  APITypes.CreateAttachmentMutation
>;
export const updateAttachment = /* GraphQL */ `mutation UpdateAttachment(
  $input: UpdateAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  updateAttachment(input: $input, condition: $condition) {
    id
    deletedAt
    key
    accountingSystemId
    accountingSystemName
    transactionId
    authUserId
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttachmentMutationVariables,
  APITypes.UpdateAttachmentMutation
>;
export const createTax = /* GraphQL */ `mutation CreateTax(
  $input: CreateTaxInput!
  $condition: ModelTaxConditionInput
) {
  createTax(input: $input, condition: $condition) {
    id
    deletedAt
    amount
    totalAmount
    transactionId
    taxRate {
      id
      deletedAt
      code
      rate
      taxType
      sortOrder
      accountingSystemId
      bookkeepingId
      dateFrom
      dateTo
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    taxRateId
    accountingSystemId
    authUserId
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaxMutationVariables,
  APITypes.CreateTaxMutation
>;
export const updateTax = /* GraphQL */ `mutation UpdateTax(
  $input: UpdateTaxInput!
  $condition: ModelTaxConditionInput
) {
  updateTax(input: $input, condition: $condition) {
    id
    deletedAt
    amount
    totalAmount
    transactionId
    taxRate {
      id
      deletedAt
      code
      rate
      taxType
      sortOrder
      accountingSystemId
      bookkeepingId
      dateFrom
      dateTo
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    taxRateId
    accountingSystemId
    authUserId
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaxMutationVariables,
  APITypes.UpdateTaxMutation
>;
