import {
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { stringBookingAccounts } from "../../../strings";
import BookingAccountsTable from "../../../components/booking-accounts-table";
import { useTransactionCategories } from "../../../hooks/use-transaction-categories";
import { useAccountingSystemBookingAccounts } from "../../../hooks/use-accounting-system-booking-accounts";
import { useContextTypeSettingsBookkeeping } from "../dashboard-layout";
import {
  AccountingSystem,
  CreateBookingAccountInput,
  UpdateBookingAccountInput,
} from "../../../API";
import { FieldArray, Form, Formik } from "formik";
import { BookingAccount } from "../../../models";
import { useNavigate } from "react-router-dom";
import {
  createBookingAccount,
  updateBookingAccount,
} from "../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { BookingAccountValue } from "../../create-organization/booking-accounts";
import { deleteItem } from "../../../utils";
import equal from "fast-deep-equal";

type BookingAccountsValues = {
  bookingAccounts?: BookingAccountValue[];
};

const typeNamePlural = stringBookingAccounts;

const BookingAccounts = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { selectedOrganization, selectedBookkeepingFull } =
    useContextTypeSettingsBookkeeping();
  const { transactionCategories } = useTransactionCategories();

  const hasAccountingSystemBookingAccounts =
    selectedBookkeepingFull?.accountingSystem === AccountingSystem.bexio;

  const { accountingSystemBookingAccounts } =
    useAccountingSystemBookingAccounts(
      selectedOrganization?.id,
      hasAccountingSystemBookingAccounts
    );

  const initialValues: BookingAccountsValues = {
    bookingAccounts: selectedBookkeepingFull?.bookingAccounts,
  };

  const isValid = (values: BookingAccountsValues) =>
    !hasAccountingSystemBookingAccounts ||
    (accountingSystemBookingAccounts &&
      values.bookingAccounts?.every((ba) =>
        accountingSystemBookingAccounts.find(
          (aba) => aba.account_no === ba.accountNumber.toString()
        )
      ));

  return (
    <VStack width="full" height="full" alignItems="start" spacing="20px">
      <VStack alignItems="start" spacing="4px">
        <Text fontSize={18} fontWeight="semibold" color="gray.900">
          {typeNamePlural}
        </Text>
        <Text fontSize={14} color="gray.600">
          {`Hier kannst du deine ${typeNamePlural} verwalten`}
        </Text>
      </VStack>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (isValid(values)) {
            const bookingAccountValues = values.bookingAccounts?.map((ba) => ({
              ...ba,
              accountingSystemId: accountingSystemBookingAccounts
                ?.find((aba) => aba.account_no === ba.accountNumber.toString())
                ?.id.toString(),
            }));

            const toAdd: CreateBookingAccountInput[] | undefined =
              bookingAccountValues
                ?.filter(
                  (ba) =>
                    !selectedBookkeepingFull?.bookingAccounts?.find(
                      (i) => i.id === ba.id
                    )
                )
                .map((ba) => {
                  const index = bookingAccountValues?.findIndex(
                    (i) => i.id === ba.id
                  );
                  const firstItem =
                    selectedBookkeepingFull?.bookingAccounts?.[0]!;
                  return {
                    ...ba,
                    bookkeepingId: selectedBookkeepingFull!.id,
                    sortOrder: index! + 1,
                    authGroupsRead: firstItem.authGroupsRead,
                    authGroupsUpdate: firstItem.authGroupsUpdate,
                  };
                });

            const toRemove: BookingAccount[] | undefined =
              selectedBookkeepingFull?.bookingAccounts?.filter(
                (ba) => !bookingAccountValues?.find((i) => i.id === ba.id)
              );

            // Check if any field has changed
            // and only add this field
            // also check if sortOrder has changed
            const toUpdate: UpdateBookingAccountInput[] | undefined =
              bookingAccountValues
                ?.filter((ba, index) =>
                  selectedBookkeepingFull?.bookingAccounts?.find(
                    (i) =>
                      i.id === ba.id &&
                      (!equal(i, ba) || i.sortOrder !== index + 1)
                  )
                )
                .map((ba) => {
                  const initial =
                    selectedBookkeepingFull?.bookingAccounts?.find(
                      (i) => i.id === ba.id
                    );
                  const index = bookingAccountValues?.findIndex(
                    (i) => i.id === ba.id
                  );
                  const sortOrder = index! + 1;
                  return {
                    id: ba.id,
                    ...(ba.accountNumber !== initial?.accountNumber
                      ? { accountNumber: ba.accountNumber }
                      : {}),
                    ...(ba.accountingSystemId !== initial?.accountingSystemId
                      ? { accountingSystemId: ba.accountingSystemId }
                      : {}),
                    ...(ba.name !== initial?.name ? { name: ba.name } : {}),
                    ...(ba.transactionCategoryId !==
                    initial?.transactionCategoryId
                      ? { transactionCategoryId: ba.transactionCategoryId }
                      : {}),
                    ...(ba.taxType !== initial?.taxType
                      ? { taxType: ba.taxType }
                      : {}),
                    ...(initial?.sortOrder !== sortOrder ? { sortOrder } : {}),
                  };
                });

            // Execute the mutations in parallel
            const toAddPromises = toAdd?.map((ba) =>
              API.graphql<GraphQLQuery<CreateBookingAccountInput>>(
                graphqlOperation(createBookingAccount, {
                  input: ba,
                })
              )
            );
            const toRemovePromises = toRemove?.map((ba) =>
              deleteItem(updateBookingAccount, ba.id)
            );
            const toUpdatePromises = toUpdate?.map((ba) =>
              API.graphql(
                graphqlOperation(updateBookingAccount, {
                  input: ba,
                })
              )
            );

            await Promise.all([
              ...(toAddPromises ?? []),
              ...(toRemovePromises ?? []),
              ...(toUpdatePromises ?? []),
            ]);

            toast({
              title: "Buchungskonten gespeichert",
              status: "success",
            });
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <Form
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <VStack width="full" height="full" justify="space-between">
              <FieldArray name="bookingAccounts">
                {({ remove, replace, push, move }) => (
                  <BookingAccountsTable
                    typeNamePlural={typeNamePlural}
                    transactionCategories={transactionCategories}
                    bookingAccounts={values.bookingAccounts}
                    hasAccountingSystemBookingAccounts={
                      hasAccountingSystemBookingAccounts
                    }
                    accountingSystemBookingAccounts={
                      accountingSystemBookingAccounts
                    }
                    remove={remove}
                    replace={replace}
                    push={push}
                    move={move}
                  />
                )}
              </FieldArray>
              <Divider />
              <HStack width="full" justify="end">
                <Button
                  variant="outline"
                  onClick={() => {
                    navigate("/");
                  }}
                  isDisabled={isSubmitting}
                >
                  Abbrechen
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Speichern
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default BookingAccounts;
