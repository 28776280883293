import { useNavigate } from "react-router";
import { Button, Text, VStack } from "@chakra-ui/react";
import { routeOnboardingAuth } from "../routes";
import { maxWidth } from "../../utils";

const Onboarding = () => {
  const navigate = useNavigate();
  return (
    <VStack maxWidth={maxWidth} width="full" alignItems="start" spacing="32px">
      <Text fontSize={36} fontWeight="medium">
        Mehr Zeit
        <br />
        fürs Wesentliche
      </Text>
      <Button
        width="full"
        colorScheme="primary"
        onClick={() => {
          navigate(routeOnboardingAuth);
        }}
      >
        Loslegen
      </Button>
    </VStack>
  );
};

export default Onboarding;
