import { useState, useEffect } from "react";
import { queryList } from "../utils";
import { listTransactionCategories } from "../graphql/queries";
import {
  ListTransactionCategoriesQuery,
  ListTransactionCategoriesQueryVariables,
} from "../API";
import { TransactionCategory } from "../models";

// Custom hook for fetching transaction categories
export function useTransactionCategories() {
  const [transactionCategories, setTransactionCategories] =
    useState<TransactionCategory[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchTransactionCategories = async () => {
      try {
        const transactionCategories = await queryList<
          TransactionCategory,
          ListTransactionCategoriesQueryVariables,
          ListTransactionCategoriesQuery
        >(listTransactionCategories);

        setTransactionCategories(transactionCategories);
        setIsLoading(false);
      } catch (err) {
        setError(err as Error);
        setIsLoading(false);
      }
    };
    fetchTransactionCategories();
  }, []);

  return { transactionCategories, isLoading, error };
}
