import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  useRadioGroup,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  routeCreateOrganizationAccountingSystemConfig,
  routeCreateOrganizationAccountingSystemLogin,
  routeCreateOrganizationBookingAccounts,
} from "../routes";
import { useSetBookkeepingValues } from "./create-organization-layout";
import { AccountingSystem } from "../../API";
import {
  aerzteTreuhandLogo,
  capitalize,
  currentYear,
  maxWidth,
} from "../../utils";
import RadioCard from "../../components/radio-card";
import ColorPicker from "../../components/color-picker";
import { useRef } from "react";
import { Option } from "../../components/select";
import FormSelect from "../../components/form-select";

const optionsAccountingSystem: Option[] = Object.values(AccountingSystem).map(
  (accountingSystem) => ({
    value: accountingSystem,
    label:
      accountingSystem === AccountingSystem.none
        ? "Keine"
        : capitalize(accountingSystem),
  })
);

const years = [currentYear - 1, currentYear];
const optionsYear: Option[] = years.map((year) => ({
  value: year,
  label: year.toString(),
}));

export type BookkeepingValues = {
  name: string;
  accountingSystem: AccountingSystem;
  color: string;
  year: number;
  balance: number;
  hasNetTaxRate: boolean;
};

const Bookkeeping = () => {
  const navigate = useNavigate();

  const {
    user,
    mainOrganization,
    organizationValues,
    bookkeepingValues,
    setBookkeepingValues,
  } = useSetBookkeepingValues();

  const initialValues: BookkeepingValues =
    bookkeepingValues ??
    (mainOrganization.logo === aerzteTreuhandLogo
      ? {
          name: "Kassenbuch Praxis",
          accountingSystem: AccountingSystem.topal,
          color: "#99ccff",
          year: currentYear,
          balance: 0,
          hasNetTaxRate: true,
        }
      : {
          name: organizationValues.name,
          accountingSystem: AccountingSystem.bexio,
          color: "#c0bff5",
          year: currentYear,
          balance: 0,
          hasNetTaxRate: true,
        });

  const refColor = useRef<HTMLInputElement>(null);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "hasNetTaxRate",
    defaultValue: initialValues.hasNetTaxRate.toString(),
  });

  const group = getRootProps();

  return (
    <VStack maxWidth={maxWidth} width="full" alignItems="start" spacing="12px">
      <Text fontSize={36} fontWeight="medium" color="gray.900">
        {mainOrganization.logo === aerzteTreuhandLogo
          ? "Neues Kassenbuch anlegen"
          : "Neue Buchhaltung anlegen"}
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, helpers) => {
          setBookkeepingValues({
            name: values.name,
            accountingSystem: values.accountingSystem,
            color: refColor.current?.value ?? values.color,
            year: values.year,
            balance: values.balance,
            hasNetTaxRate: JSON.parse(values.hasNetTaxRate.toString()),
          });
          if (values.accountingSystem === AccountingSystem.bexio) {
            navigate(routeCreateOrganizationAccountingSystemLogin);
          } else if (values.accountingSystem !== AccountingSystem.none) {
            navigate(routeCreateOrganizationAccountingSystemConfig);
          } else {
            navigate(routeCreateOrganizationBookingAccounts);
          }
        }}
      >
        {(props) => (
          <Form style={{ width: "100%" }}>
            <VStack spacing="20px">
              {!user.isSSO && (
                <Field
                  name="name"
                  validate={(value: string) => {
                    let error;
                    if (!value) {
                      error = "Name ist erforderlich";
                    }
                    return error;
                  }}
                >
                  {({ field, form }: FieldProps<string, BookkeepingValues>) => (
                    <FormControl
                      isInvalid={
                        (form.errors.name && form.touched.name) as boolean
                      }
                    >
                      <FormLabel fontSize={14} color="gray.700">
                        Name
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder={
                          mainOrganization.logo === aerzteTreuhandLogo
                            ? "Name des Kassenbuchs"
                            : "Name der Buchhaltung"
                        }
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
              {mainOrganization.logo !== aerzteTreuhandLogo && !user.isSSO && (
                <Field name="accountingSystem">
                  {({ field }: FieldProps<number, BookkeepingValues>) => (
                    <FormControl>
                      <FormLabel fontSize={14} color="gray.700">
                        Buchhaltungssoftware
                      </FormLabel>
                      <FormSelect
                        field={field}
                        props={props}
                        options={optionsAccountingSystem}
                      />
                    </FormControl>
                  )}
                </Field>
              )}
              {!user.isSSO && (
                <Field name="color">
                  {({ field }: FieldProps<string, BookkeepingValues>) => {
                    return (
                      <FormControl>
                        <FormLabel fontSize={14} color="gray.700">
                          Farbe
                        </FormLabel>
                        <ColorPicker ref={refColor} {...field} />
                      </FormControl>
                    );
                  }}
                </Field>
              )}
              {mainOrganization.logo === aerzteTreuhandLogo && (
                <Field name="year">
                  {({ field }: FieldProps<number, BookkeepingValues>) => (
                    <FormControl>
                      <FormLabel fontSize={14} color="gray.700">
                        Buchhaltungsjahr
                      </FormLabel>
                      <FormSelect
                        field={field}
                        props={props}
                        options={optionsYear}
                      />
                    </FormControl>
                  )}
                </Field>
              )}
              {mainOrganization.logo === aerzteTreuhandLogo && (
                <Field
                  name="balance"
                  validate={(value: number) => {
                    let error;
                    if (!value && value !== 0) {
                      error = "Saldovortrag ist erforderlich";
                    }
                    return error;
                  }}
                >
                  {({ field, form }: FieldProps<number, BookkeepingValues>) => (
                    <FormControl
                      isInvalid={
                        (form.errors.balance && form.touched.balance) as boolean
                      }
                    >
                      <FormLabel fontSize={14} color="gray.700">
                        Saldovortrag
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder="Saldovortrag"
                        type="number"
                      />
                      <FormErrorMessage>{form.errors.balance}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
              <Field name="hasNetTaxRate">
                {({ field, form }: FieldProps<boolean, BookkeepingValues>) => {
                  return (
                    <FormControl
                      isInvalid={
                        (form.errors.hasNetTaxRate &&
                          form.touched.hasNetTaxRate) as boolean
                      }
                    >
                      <FormLabel fontSize={14} color="gray.700">
                        MwSt. Abrechnung
                      </FormLabel>
                      <VStack width="full" {...group} {...field}>
                        <RadioCard
                          key={1}
                          {...getRadioProps({ value: true.toString() })}
                        >
                          <Text
                            fontSize={14}
                            color="primary.800"
                            fontWeight="medium"
                          >
                            Saldo/Pauschal/Keine
                          </Text>
                          <Text fontSize={14} color="primary.700">
                            Wenn das Unternehmen die MwSt. Saldo/Pauschal
                            abrechnet oder von der Pflicht befreit ist.
                          </Text>
                        </RadioCard>
                        <RadioCard
                          key={0}
                          {...getRadioProps({ value: false.toString() })}
                        >
                          <Text
                            fontSize={14}
                            color="primary.800"
                            fontWeight="medium"
                          >
                            Effektiv
                          </Text>
                          <Text fontSize={14} color="primary.700">
                            Wenn das Unternehmen die MwSt. effektiv abrechnet.
                          </Text>
                        </RadioCard>
                      </VStack>
                      <FormErrorMessage>
                        {form.errors.hasNetTaxRate}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
              <HStack width="full">
                <Button
                  width="full"
                  variant="outline"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Zurück
                </Button>
                <Button width="full" colorScheme="primary" type="submit">
                  Weiter
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default Bookkeeping;
