import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonCircle,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";

import { useEffect, useMemo, useState } from "react";
import {
  BookingAccountsByBookkeepingIdQuery,
  BookingAccountsByBookkeepingIdQueryVariables,
  BookkeepingYearsByBookkeepingIdQuery,
  BookkeepingYearsByBookkeepingIdQueryVariables,
  BookkeepingsByOrganizationIdQuery,
  BookkeepingsByOrganizationIdQueryVariables,
  GetBillingQuery,
  GetUserQuery,
  ListOrganizationsQuery,
  ListOrganizationsQueryVariables,
  OrganizationType,
  UserAppRole,
  UserBookkeepingsByBookkeepingIdQuery,
  UserBookkeepingsByBookkeepingIdQueryVariables,
  UserOrganizationRole,
  UserOrganizationsByUserIdQuery,
  UserOrganizationsByUserIdQueryVariables,
} from "../../API";
import {
  Billing,
  BookingAccount,
  Bookkeeping,
  BookkeepingFull,
  BookkeepingYear,
  Organization,
  OrganizationAccountantFull,
  OrganizationFull,
  User,
  UserBookkeeping,
  UserFull,
  UserOrganization,
} from "../../models";
import {
  bookingAccountsByBookkeepingId,
  bookkeepingYearsByBookkeepingId,
  bookkeepingsByOrganizationId,
  getBilling,
  getUser,
  listOrganizations,
  userBookkeepingsByBookkeepingId,
  userOrganizationsByUserId,
} from "../../graphql/queries";
import {
  compareStrings,
  createOrganizationText,
  getAuthUserName,
  queryList,
  showSettings,
} from "../../utils";
import {
  routeCreateOrganizationOrganization,
  routeSettingsOrganization,
} from "../routes";
import Tooltip from "../../components/tooltip";
import useSetDatadogAndGleapUser from "../../hooks/use-set-datadog-and-gleap-user";
import Gleap from "gleap";
import Select, { Option } from "../../components/select";

const dashboardRoutes = [
  "transactions",
  "settings-bookkeeping",
  "settings-organization",
] as const;

export type DashboardRoutes = (typeof dashboardRoutes)[number];

type ContextTypeTransactions = {
  user: UserFull | undefined;
  organizations: OrganizationFull[] | undefined;
  selectedOrganization: OrganizationFull | undefined;
  selectedBookkeepingFull: BookkeepingFull | undefined;
  setSelectedBookkeepingFull: React.Dispatch<
    React.SetStateAction<BookkeepingFull | undefined>
  >;
};

type ContextTypeSettingsBookkeeping = {
  user: UserFull | undefined;
  selectedOrganization: OrganizationFull | undefined;
  selectedBookkeepingFull: BookkeepingFull | undefined;
};

type ContextTypeSettingsOrganization = {
  user: UserFull | undefined;
  selectedOrganization: OrganizationFull | undefined;
  bookkeepings: Bookkeeping[] | undefined;
};

const DashboardLayout = () => {
  const { signOut, user: authUser } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const [organizations, setOrganizations] = useState<OrganizationFull[]>();
  const [bookkeepings, setBookkeepings] = useState<Bookkeeping[]>();
  const [mainOrganizationAccountant, setMainOrganizationAccountant] =
    useState<OrganizationAccountantFull>();
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationFull>();
  const [selectedBookkeeping, setSelectedBookkeeping] = useState<Bookkeeping>();
  const [selectedBookkeepingFull, setSelectedBookkeepingFull] =
    useState<BookkeepingFull>();
  const [logo, setLogo] = useState<string>();
  const [user, setUser] = useState<UserFull>();

  useSetDatadogAndGleapUser(user, "Dashboard");
  Gleap.showFeedbackButton(false);

  const step = location.pathname.split("/")[1] as DashboardRoutes;

  useEffect(() => {
    if (!user && authUser) {
      const fetchUser = async () => {
        const userResponses = await Promise.all([
          API.graphql<GraphQLQuery<GetUserQuery>>(
            graphqlOperation(getUser, { id: authUser.attributes?.sub })
          ),
          API.graphql<GraphQLQuery<UserOrganizationsByUserIdQuery>>(
            graphqlOperation(userOrganizationsByUserId, {
              userId: authUser.attributes?.sub,
            })
          ),
        ]);

        const userResponse = userResponses[0];
        const userOrganizationsResponse = userResponses[1];

        const user = userResponse.data?.getUser as User;
        const userOrganizations = userOrganizationsResponse.data
          ?.userOrganizationsByUserId?.items as unknown as UserOrganization[];

        setUser({
          ...user,
          userOrganizations,
        });
      };
      fetchUser();
    }
  }, [authUser, user]);

  useEffect(() => {
    if (user) {
      const fetchOrganizations = async () => {
        const organizations = await queryList<
          Organization,
          ListOrganizationsQueryVariables,
          ListOrganizationsQuery
        >(listOrganizations);

        const organizationsFull: OrganizationFull[] = [];

        const billingPromises = organizations.map((organization) => {
          if (organization.organizationBillingId) {
            return API.graphql<GraphQLQuery<GetBillingQuery>>(
              graphqlOperation(getBilling, {
                id: organization.organizationBillingId,
              })
            );
          } else {
            return Promise.resolve(undefined);
          }
        });

        const billingResponses = await Promise.all(billingPromises);

        organizations.forEach((organization) => {
          const billingResponse = billingResponses.find(
            (br) =>
              br?.data?.getBilling?.id === organization.organizationBillingId
          );

          organizationsFull.push({
            ...organization,
            billing: billingResponse?.data?.getBilling as Billing,
          });
        });

        // Get main organization if user is accountant
        const mainOrganizationAccountant = organizationsFull.find(
          (organization) => organization.type === OrganizationType.accountant
        );

        if (mainOrganizationAccountant) {
          const userOrganizationsLoggedInUser = await queryList<
            UserOrganization,
            UserOrganizationsByUserIdQueryVariables,
            UserOrganizationsByUserIdQuery
          >(userOrganizationsByUserId, {
            userId: user.id,
            filter: { organizationId: { eq: mainOrganizationAccountant.id } },
          });
          setMainOrganizationAccountant({
            ...mainOrganizationAccountant,
            userOrganization: userOrganizationsLoggedInUser[0],
          });
        }

        // Sort organizations by name and accountants first
        setOrganizations(
          organizationsFull
            .filter(
              (o) =>
                organizationsFull.length === 1 ||
                o.type !== OrganizationType.accountant
            )
            .sort((a, b) => {
              if (
                a.type === OrganizationType.accountant &&
                b.type !== OrganizationType.accountant
              ) {
                return -1;
              } else if (
                a.type !== OrganizationType.accountant &&
                b.type === OrganizationType.accountant
              ) {
                return 1;
              } else {
                return compareStrings(a.name, b.name);
              }
            })
        );
      };
      fetchOrganizations();
    }
  }, [user]);

  useEffect(() => {
    if (organizations && user && bookkeepings) {
      // If the user is the organizationAdmin and there are no bookkeepings
      // navigate to create organization
      if (
        user.role === UserAppRole.organizationAdmin &&
        bookkeepings.length === 0
      ) {
        navigate(routeCreateOrganizationOrganization);
      }
    }
  }, [bookkeepings, navigate, organizations, user]);

  useEffect(() => {
    if (organizations && organizations.length > 0 && organizations[0]) {
      setSelectedOrganization(organizations[0]);
    }
  }, [organizations]);

  useEffect(() => {
    if (selectedOrganization) {
      const fetchBookkeepings = async () => {
        const bookkeepings = await queryList<
          Bookkeeping,
          BookkeepingsByOrganizationIdQueryVariables,
          BookkeepingsByOrganizationIdQuery
        >(bookkeepingsByOrganizationId, {
          organizationId: selectedOrganization.id,
        });

        setBookkeepings(
          bookkeepings.sort((a, b) => compareStrings(a.name, b.name))
        );
      };
      fetchBookkeepings();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (bookkeepings) {
      if (bookkeepings.length > 0 && bookkeepings[0]) {
        setSelectedBookkeeping(bookkeepings[0]);
      } else {
        setSelectedBookkeeping(undefined);
      }
    }
  }, [bookkeepings]);

  useEffect(() => {
    if (
      selectedBookkeeping &&
      (!selectedBookkeepingFull ||
        selectedBookkeeping.id !== selectedBookkeepingFull.id)
    ) {
      const fetchBookkeepingFull = async () => {
        const [bookkeepingYears, bookingAccounts, userBookkeepings] =
          await Promise.all([
            queryList<
              BookkeepingYear,
              BookkeepingYearsByBookkeepingIdQueryVariables,
              BookkeepingYearsByBookkeepingIdQuery
            >(bookkeepingYearsByBookkeepingId, {
              bookkeepingId: selectedBookkeeping.id,
            }),
            queryList<
              BookingAccount,
              BookingAccountsByBookkeepingIdQueryVariables,
              BookingAccountsByBookkeepingIdQuery
            >(
              bookingAccountsByBookkeepingId,
              {
                bookkeepingId: selectedBookkeeping.id,
              },
              // Also include deleted booking accounts
              // so transactions can be displayed correctly
              true
            ),
            queryList<
              UserBookkeeping,
              UserBookkeepingsByBookkeepingIdQueryVariables,
              UserBookkeepingsByBookkeepingIdQuery
            >(userBookkeepingsByBookkeepingId, {
              bookkeepingId: selectedBookkeeping.id,
            }),
          ]);

        const bookkeepingYearsSorted = bookkeepingYears.sort((a, b) =>
          a.year > b.year ? -1 : 1
        );

        const bookingAccountsSorted = bookingAccounts.sort((a, b) =>
          a.sortOrder < b.sortOrder ? -1 : 1
        );

        const userBookkeepingsSorted = userBookkeepings.sort((a, b) =>
          compareStrings(a.id, b.id)
        );

        setSelectedBookkeepingFull({
          ...selectedBookkeeping,
          bookkeepingYears: bookkeepingYearsSorted,
          bookingAccounts: bookingAccountsSorted,
          userBookkeepings: userBookkeepingsSorted,
        });
      };
      fetchBookkeepingFull();
    } else if (!selectedBookkeeping && selectedBookkeepingFull) {
      setSelectedBookkeepingFull(undefined);
    }
  }, [selectedBookkeeping, selectedBookkeepingFull]);

  useEffect(() => {
    if (selectedOrganization) {
      const fetchLogo = async () => {
        const component = await import(
          `../../assets/logos/${
            selectedOrganization?.logo
              ? selectedOrganization.logo
              : "expensly.svg"
          }`
        );
        setLogo(component.default);
      };
      fetchLogo();
    }
  }, [selectedOrganization]);

  const [openBilling, setOpenBilling] = useState(false);

  useEffect(() => {
    if (selectedOrganization && openBilling) {
      const openBillingAsync = async () => {
        const response = await API.post(
          "expenslyREST",
          "/billing/create-portal-session",
          {
            body: {
              customerId: selectedOrganization.billing?.customerId,
              returnUrl: window.location.origin,
            },
          }
        );
        if (response) {
          setOpenBilling(false);
          window.location.href = response;
        }
      };
      openBillingAsync();
    }
  }, [openBilling, selectedOrganization]);

  const menuBookkeepings = bookkeepings && bookkeepings.length > 1 && (
    <MenuGroup title="Kassenbücher">
      {bookkeepings.map((bookkeeping) => (
        <MenuItem
          key={bookkeeping.id}
          onClick={() => {
            setSelectedBookkeeping(bookkeeping);
          }}
        >
          {bookkeeping.name}
        </MenuItem>
      ))}
    </MenuGroup>
  );

  const menuAccountantCreateOrganization = user?.role ===
    UserAppRole.accountant && (
    <MenuItem
      onClick={() => {
        navigate(routeCreateOrganizationOrganization);
      }}
    >
      {`Neue ${createOrganizationText}`}
    </MenuItem>
  );
  const menuAccountantSettings = mainOrganizationAccountant?.userOrganization
    .role === UserOrganizationRole.admin && (
    <MenuItem
      onClick={() => {
        setSelectedOrganization(mainOrganizationAccountant);
        navigate(routeSettingsOrganization);
      }}
    >
      Einstellungen Treuhand
    </MenuItem>
  );
  const menuAccountantDivider = menuBookkeepings &&
    menuAccountantCreateOrganization && <MenuDivider />;

  const menuOrganizationSettings = user &&
    selectedOrganization &&
    showSettings(user, selectedOrganization) &&
    selectedOrganization.id !== mainOrganizationAccountant?.id && (
      <MenuItem
        onClick={() => {
          navigate(routeSettingsOrganization);
        }}
      >
        Einstellungen Organisation
      </MenuItem>
    );
  const menuOrganizationBilling = user &&
    user.id === selectedOrganization?.billing?.userId && (
      <MenuItem closeOnSelect={false}>
        <Button
          variant="link"
          fontWeight="400"
          color="gray.800"
          onClick={() => {
            setOpenBilling(true);
          }}
          isLoading={openBilling}
        >
          Abonnement verwalten
        </Button>
      </MenuItem>
    );
  const menuOrganizationDivider = (menuBookkeepings ||
    menuAccountantCreateOrganization) &&
    (menuOrganizationSettings || menuOrganizationBilling) && <MenuDivider />;

  const optionsOrganization: Option[] | undefined = useMemo(
    () =>
      organizations?.map((organization) => ({
        value: organization.id,
        label: organization.name,
      })),
    [organizations]
  );

  return (
    <VStack width="full" p="4" alignItems="start" height="full">
      <Flex mb="4" alignItems="center" columnGap="4" width="full">
        {logo ? (
          <Tooltip label="Zurück zur Startseite">
            <IconButton
              variant="unstyled"
              icon={<Image maxHeight="50px" src={logo} />}
              aria-label={"Logo"}
              onClick={() => navigate("/")}
            />
          </Tooltip>
        ) : (
          <Skeleton height="50px" width="200px" />
        )}
        <Spacer />
        {organizations &&
          organizations.length > 1 &&
          selectedOrganization?.id !== mainOrganizationAccountant?.id && (
            <Select
              value={optionsOrganization?.find((o) =>
                selectedOrganization
                  ? o.value === selectedOrganization.id
                  : null
              )}
              options={optionsOrganization}
              onChange={(value) => {
                setSelectedOrganization(
                  organizations.find(
                    (organization) => organization.id === value?.value
                  )
                );
              }}
              width={{ base: "160px", md: "240px" }}
            />
          )}
        <Menu>
          <MenuButton>
            {selectedOrganization ? (
              <Avatar
                name={selectedBookkeepingFull?.name ?? " "}
                backgroundColor={selectedBookkeepingFull?.color ?? "#c0bff5"}
              />
            ) : (
              <SkeletonCircle height="48px" width="48px" />
            )}
          </MenuButton>
          <MenuList zIndex={3}>
            {menuBookkeepings}
            {menuAccountantDivider}
            {menuAccountantCreateOrganization}
            {menuAccountantSettings}
            {menuOrganizationDivider}
            {menuOrganizationSettings}
            {menuOrganizationBilling}
            {menuBookkeepings ||
            menuAccountantCreateOrganization ||
            menuOrganizationSettings ||
            menuOrganizationBilling ? (
              <MenuDivider />
            ) : null}
            <MenuItem onClick={Gleap.open}>Support</MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={async () => {
                signOut();
              }}
            >
              {`${getAuthUserName(authUser)} abmelden`}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Outlet
        context={
          step === "transactions"
            ? ({
                user,
                organizations,
                selectedOrganization,
                selectedBookkeepingFull,
                setSelectedBookkeepingFull,
              } satisfies ContextTypeTransactions)
            : step === "settings-bookkeeping"
            ? ({
                user,
                selectedOrganization,
                selectedBookkeepingFull,
              } satisfies ContextTypeSettingsBookkeeping)
            : ({
                user,
                selectedOrganization,
                bookkeepings,
              } satisfies ContextTypeSettingsOrganization)
        }
      />
    </VStack>
  );
};

export default DashboardLayout;

export const useContextTypeTransactions = () => {
  return useOutletContext<ContextTypeTransactions>();
};

export const useContextTypeSettingsBookkeeping = () => {
  return useOutletContext<ContextTypeSettingsBookkeeping>();
};

export const useContextTypeSettingsOrganization = () => {
  return useOutletContext<ContextTypeSettingsOrganization>();
};
