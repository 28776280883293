import { BrowserRouter, Routes as ReactRoutes, Route } from "react-router-dom";
import DashboardLayout, { DashboardRoutes } from "./dashboard/dashboard-layout";
import RequireAuth from "../components/require-auth";
import Transactions from "./dashboard/transactions";
import Home from "./home";
import OnboardingLayout, {
  OnboardingStep,
} from "./onboarding/onboarding-layout";
import Onboarding from "./onboarding/onboarding";
import Auth from "./onboarding/auth";
import CreateOrganizationLayout, {
  CreateOrganizationStep,
} from "./create-organization/create-organization-layout";
import Organization from "./create-organization/organization";
import OrganizationMembers from "./create-organization/organization-members";
import { default as CreateOrganizationBookingAccounts } from "./create-organization/booking-accounts";
import Billing from "./onboarding/billing";
import Confirmation from "./onboarding/confirmation";
import Bookkeeping from "./create-organization/bookkeeping";
import AccountingSystemLogin from "./create-organization/accounting-system-login";
import TaxRates from "./create-organization/tax-rates";
import AccountingSystemConfig from "./create-organization/accounting-system-config";
import SettingsBookkeepingLayout, {
  SettingsBookkeepingTab,
} from "./dashboard/settings-bookkeeping/settings-bookkeeping-layout";
import { default as SettingsBookingAccounts } from "./dashboard/settings-bookkeeping/booking-accounts";
import SettingsOrganizationLayout, {
  SettingsOrganizationTab,
} from "./dashboard/settings-organization/settings-organization-layout";
import Team from "./dashboard/settings-organization/team";

export const routeOnboarding = "/onboarding";
const routeOnboardingAuthSuffix: OnboardingStep = "auth";
export const routeOnboardingAuth = `${routeOnboarding}/${routeOnboardingAuthSuffix}`;
const routeOnboardingBillingSuffix: OnboardingStep = "billing";
export const routeOnboardingBilling = `${routeOnboarding}/${routeOnboardingBillingSuffix}`;
const routeOnboardingConfirmationSuffix: OnboardingStep = "confirmation";
export const routeOnboardingConfirmation = `${routeOnboarding}/${routeOnboardingConfirmationSuffix}`;

export const routeCreateOrganization = "/create-organization";
const routeCreateOrganizationOrganizationSuffix: CreateOrganizationStep =
  "organization";
export const routeCreateOrganizationOrganization = `${routeCreateOrganization}/${routeCreateOrganizationOrganizationSuffix}`;
const routeCreateOrganizationMembersSuffix: CreateOrganizationStep =
  "organization-members";
export const routeCreateOrganizationMembers = `${routeCreateOrganization}/${routeCreateOrganizationMembersSuffix}`;
const routeCreateOrganizationBookkeepingSuffix: CreateOrganizationStep =
  "bookkeeping";
export const routeCreateOrganizationBookkeeping = `${routeCreateOrganization}/${routeCreateOrganizationBookkeepingSuffix}`;
const routeCreateOrganizationAccountingSystemLoginSuffix: CreateOrganizationStep =
  "accounting-system-login";
export const routeCreateOrganizationAccountingSystemLogin = `${routeCreateOrganization}/${routeCreateOrganizationAccountingSystemLoginSuffix}`;
export const routeCreateOrganizationAccountingSystemConfigSuffix: CreateOrganizationStep =
  "accounting-system-config";
export const routeCreateOrganizationAccountingSystemConfig = `${routeCreateOrganization}/${routeCreateOrganizationAccountingSystemConfigSuffix}`;
const routeCreateOrganizationBookingAccountsSuffix: CreateOrganizationStep =
  "booking-accounts";
export const routeCreateOrganizationBookingAccounts = `${routeCreateOrganization}/${routeCreateOrganizationBookingAccountsSuffix}`;
const routeCreateOrganizationTaxRatesSuffix: CreateOrganizationStep =
  "tax-rates";
export const routeCreateOrganizationTaxRates = `${routeCreateOrganization}/${routeCreateOrganizationTaxRatesSuffix}`;
const routeTransactionsSuffix: DashboardRoutes = "transactions";
export const routeTransactions = `/${routeTransactionsSuffix}`;
const routeSettingsBookkeepingSuffix: DashboardRoutes = "settings-bookkeeping";
export const routeSettingsBookkeeping = `/${routeSettingsBookkeepingSuffix}`;
const routeSettingsBookkeepingBookingAccountsSuffix: SettingsBookkeepingTab =
  "booking-accounts";
export const routeSettingsBookkeepingBookingAccounts = `${routeSettingsBookkeeping}/${routeSettingsBookkeepingBookingAccountsSuffix}`;
export const routeSettingsOrganizationSuffix: DashboardRoutes =
  "settings-organization";
export const routeSettingsOrganization = `/${routeSettingsOrganizationSuffix}`;
const routeSettingsOrganizationTeamSuffix: SettingsOrganizationTab = "team";
export const routeSettingsOrganizationTeam = `${routeSettingsOrganization}/${routeSettingsOrganizationTeamSuffix}`;

const Routes = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/" element={<Home />} />
        <Route path={routeOnboarding} element={<OnboardingLayout />}>
          <Route path={routeOnboarding} element={<Onboarding />} />
          <Route path={routeOnboardingAuth} element={<Auth />} />
          <Route
            path={routeOnboardingBilling}
            element={
              <RequireAuth>
                <Billing />
              </RequireAuth>
            }
          />
          <Route
            path={routeOnboardingConfirmation}
            element={
              <RequireAuth>
                <Confirmation />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path={routeCreateOrganization}
          element={
            <RequireAuth>
              <CreateOrganizationLayout />
            </RequireAuth>
          }
        >
          <Route
            path={routeCreateOrganizationOrganization}
            element={<Organization />}
          />
          <Route
            path={routeCreateOrganizationMembers}
            element={<OrganizationMembers />}
          />
          <Route
            path={routeCreateOrganizationBookkeeping}
            element={<Bookkeeping />}
          />
          <Route
            path={routeCreateOrganizationAccountingSystemLogin}
            element={<AccountingSystemLogin />}
          />
          <Route
            path={routeCreateOrganizationAccountingSystemConfig}
            element={<AccountingSystemConfig />}
          />
          <Route
            path={routeCreateOrganizationBookingAccounts}
            element={<CreateOrganizationBookingAccounts />}
          />
          <Route
            path={routeCreateOrganizationTaxRates}
            element={<TaxRates />}
          />
        </Route>
        <Route
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route path={routeTransactions} element={<Transactions />} />
          <Route
            path={routeSettingsBookkeeping}
            element={<SettingsBookkeepingLayout />}
          >
            <Route
              path={routeSettingsBookkeepingBookingAccounts}
              element={<SettingsBookingAccounts />}
            />
          </Route>
          <Route
            path={routeSettingsOrganization}
            element={<SettingsOrganizationLayout />}
          >
            <Route path={routeSettingsOrganizationTeam} element={<Team />} />
          </Route>
        </Route>
      </ReactRoutes>
    </BrowserRouter>
  );
};

export default Routes;
