import { UserAppRole, UserOrganizationRole } from "./API";
import { SettingsBookkeepingTab } from "./routes/dashboard/settings-bookkeeping/settings-bookkeeping-layout";
import { SettingsOrganizationTab } from "./routes/dashboard/settings-organization/settings-organization-layout";

export const stringBookingAccounts = "Buchungskonten";

export const stringMapSettingsBookkeepingTab: Map<
  SettingsBookkeepingTab,
  string
> = new Map([["booking-accounts", stringBookingAccounts]]);

export const stringMembers = "Teammitglieder";

export const stringMapSettingsOrganizationTab: Map<
  SettingsOrganizationTab,
  string
> = new Map([["team", stringMembers]]);

export const stringMapUserOrganizationRole: Map<UserOrganizationRole, string> =
  new Map([
    [UserOrganizationRole.accountant, "Buchhalter"],
    [UserOrganizationRole.admin, "Administrator"],
    [UserOrganizationRole.employee, "Mitarbeiter"],
    [UserOrganizationRole.head, "Teamleiter"],
  ]);

export const stringMapUserAppRole: Map<UserAppRole, string> = new Map([
  [UserAppRole.accountant, "Buchhalter"],
  [UserAppRole.organizationAdmin, "Organisationsleiter"],
  [UserAppRole.user, "Benutzer"],
]);

export const stringIsDisabled = "Kann nicht bearbeitet werden";
