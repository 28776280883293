import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";

import IconTrash from "../assets/icons/trash01.svg";
import { useRef } from "react";
import Tooltip from "./tooltip";

type DeleteButtonProps = {
  typeName?: string;
  onDelete: () => Promise<void>;
  isLoading?: boolean;
};

const DeleteButton = ({ typeName, onDelete, isLoading }: DeleteButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const label = typeName ? `${typeName} löschen` : "Löschen";

  return (
    <>
      <Tooltip label={label}>
        <IconButton
          aria-label="Delete button"
          variant="outline"
          icon={<Image boxSize="20px" src={IconTrash} />}
          onClick={
            typeName
              ? onOpen
              : async () => {
                  await onDelete();
                }
          }
          isLoading={typeName ? undefined : isLoading}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="semibold">
              {label}
            </AlertDialogHeader>
            <AlertDialogBody>{`${typeName} wirklich löschen?`}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
                Abbrechen
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  await onDelete();
                  onClose();
                }}
                isLoading={isLoading}
                ml={3}
              >
                Löschen
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
export default DeleteButton;
