import { Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routeCreateOrganizationAccountingSystemConfig } from "../routes";
import { useSetAccountingSystemLoginValues } from "./create-organization-layout";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import LogoBexio from "../../assets/logos/bexio.svg";
import { maxWidth } from "../../utils";

const bexioClientId = "46c01f33-46db-4f0d-a003-c5977d06bce5";
const bexioRedirectUriDev =
  "https://cqtpwosabc.execute-api.eu-central-1.amazonaws.com/dev/auth/bexio/callback";
const bexioRedirectUriStaging =
  "https://bbx692x960.execute-api.eu-central-1.amazonaws.com/staging/auth/bexio/callback";
const bexioRedirectUriProd =
  "https://g2zypbu8b6.execute-api.eu-central-1.amazonaws.com/prod/auth/bexio/callback";

export type AccountingSystemLoginValues = {
  success?: boolean;
};

const AccountingSystemLogin = () => {
  const navigate = useNavigate();

  const {
    organizationValues,
    accountingSystemLoginValues,
    setAccountingSystemLoginValues,
  } = useSetAccountingSystemLoginValues();

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const secret = searchParams.get("secret");
  const error = searchParams.get("error");

  useEffect(() => {
    if (secret) {
      setIsLoading(true);
      setSearchParams();
      const finalizeAuthFlow = async () => {
        try {
          const response = await API.post("expenslyREST", "/auth/bexio/token", {
            body: {
              secret,
              organizationId: organizationValues.id,
            },
          });

          setAccountingSystemLoginValues({
            success: response.success,
          });
        } catch (error) {
          setAccountingSystemLoginValues({
            success: false,
          });
        }
      };
      finalizeAuthFlow();
    } else if (error) {
      setSearchParams();
    } else if (accountingSystemLoginValues?.success) {
      navigate(routeCreateOrganizationAccountingSystemConfig);
    }
  }, [
    accountingSystemLoginValues?.success,
    error,
    organizationValues,
    navigate,
    secret,
    setAccountingSystemLoginValues,
    setSearchParams,
  ]);

  return (
    <VStack maxWidth={maxWidth} width="full" alignItems="start" spacing="12px">
      <Text fontSize={36} fontWeight="medium" color="gray.900">
        bexio Anmeldung
      </Text>
      <Text fontSize={16} color="gray.600">
        Du musst dich bei bexio anmelden, um Expensly mit bexio nutzen zu
        können.
      </Text>
      <VStack width="full" spacing="20px">
        <Button
          width="full"
          variant="outline"
          leftIcon={<Image src={LogoBexio} width="24px" />}
          onClick={async () => {
            setIsLoading(true);
            window.location.replace(
              `https://idp.bexio.com/authorize?client_id=${bexioClientId}&response_type=code&redirect_uri=${
                process.env.REACT_APP_ENV === "prod"
                  ? bexioRedirectUriProd
                  : process.env.REACT_APP_ENV === "staging"
                  ? bexioRedirectUriStaging
                  : bexioRedirectUriDev
              }&scope=${encodeURIComponent(
                [
                  "openid",
                  "offline_access",
                  "file",
                  "contact_edit",
                  "kb_bill_show",
                ].join(" ")
              )}`
            );
          }}
          isLoading={isLoading}
        >
          Mit bexio anmelden
        </Button>
        <HStack width="full">
          <Button
            width="full"
            variant="outline"
            onClick={() => {
              navigate(-1);
            }}
          >
            Zurück
          </Button>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default AccountingSystemLogin;
