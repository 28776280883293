import {
  HStack,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useContextTypeSettingsBookkeeping } from "../dashboard-layout";
import { routeSettingsBookkeeping } from "../../routes";
import { stringMapSettingsBookkeepingTab } from "../../../strings";
import BookingAccounts from "./booking-accounts";
import { useEffect } from "react";
import { showSettings } from "../../../utils";

const settingsBookkeepingTab = ["booking-accounts"] as const;

export type SettingsBookkeepingTab = (typeof settingsBookkeepingTab)[number];

const SettingsBookkeepingLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the index of the active tab based on the current route
  const activeTabIndex = settingsBookkeepingTab.findIndex((t) =>
    location.pathname.includes(t)
  );

  const handleTabsChange = (index: number) => {
    navigate(`${routeSettingsBookkeeping}/${settingsBookkeepingTab[index]}`);
  };

  const { user, selectedOrganization, selectedBookkeepingFull } =
    useContextTypeSettingsBookkeeping();

  useEffect(() => {
    // Redirect to the first tab if the current path doesn't match any tab or is the root route
    if (
      activeTabIndex === -1 ||
      location.pathname === routeSettingsBookkeeping
    ) {
      navigate(`${routeSettingsBookkeeping}/${settingsBookkeepingTab[0]}`);
    }
  }, [activeTabIndex, location.pathname, navigate]);

  if (
    user &&
    selectedOrganization &&
    !showSettings(user, selectedOrganization)
  ) {
    return <Navigate to="/" />;
  }

  return (
    <VStack
      width="full"
      height="full"
      alignItems="start"
      spacing="24px"
      py="24px"
    >
      <HStack>
        <Text fontSize={24} fontWeight="semibold" color="gray.900">
          Einstellungen
        </Text>
        {selectedBookkeepingFull ? (
          <Text fontSize={24} fontWeight="semibold" color="gray.900">
            {selectedBookkeepingFull.name}
          </Text>
        ) : (
          <Skeleton height="36px" width="120px" />
        )}
      </HStack>
      <Tabs
        width="full"
        height="full"
        variant="soft-rounded"
        colorScheme="primary"
        isLazy
        index={activeTabIndex}
        onChange={handleTabsChange}
      >
        <TabList>
          {settingsBookkeepingTab.map((t: SettingsBookkeepingTab) => (
            <Tab key={t} borderRadius={6}>
              {stringMapSettingsBookkeepingTab.get(t)}{" "}
            </Tab>
          ))}
        </TabList>
        <TabPanels height="full">
          {settingsBookkeepingTab.map((t: SettingsBookkeepingTab) => (
            <TabPanel key={t} height="full">
              {t === "booking-accounts" ? <BookingAccounts /> : <></>}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default SettingsBookkeepingLayout;
