import { Tooltip as TooltipChakra, TooltipProps } from "@chakra-ui/react";

const Tooltip = (props: TooltipProps) => {
  const { children, ...rest } = props;
  return (
    <TooltipChakra {...rest} bg="white" p="8px" color="gray.700" fontSize={14}>
      {children}
    </TooltipChakra>
  );
};

export default Tooltip;
