import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
  useRadioGroup,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSetAccountingSystemConfigValues } from "./create-organization-layout";
import { Field, FieldProps, Form, Formik } from "formik";
import { AccountingSystem, BillingPeriod, CurrencyCode } from "../../API";
import {
  routeCreateOrganizationAccountingSystemLogin,
  routeCreateOrganizationBookingAccounts,
} from "../routes";
import RadioCard from "../../components/radio-card";
import { useEffect, useMemo, useState } from "react";
import { API, Auth } from "aws-amplify";
import { maxWidth } from "../../utils";
import FormSelect from "../../components/form-select";
import { Option } from "../../components/select";

const optionsCurrencies: Option[] = Object.values(CurrencyCode).map(
  (value) => ({
    label: value.toUpperCase(),
    value,
  })
);

type BankAccount = {
  id: number;
  name: string;
};

type Currency = {
  id: number;
  name: CurrencyCode;
};

type KbItemClass = "KbBill";

type DocumentSettings = {
  kb_item_class: KbItemClass;
  default_currency_id: number;
};

export type AccountingSystemConfigValues = {
  baseCurrency: CurrencyCode;
  billingPeriod: BillingPeriod;
  senderBankAccountId?: number;
  reimbursement: boolean;
};

const AccountingSystemConfig = () => {
  const navigate = useNavigate();
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>();
  const [currencyDefault, setCurrencyDefault] = useState<CurrencyCode>();

  const optionsBankAccounts: Option[] | undefined = useMemo(
    () =>
      bankAccounts?.map((bankAccount) => ({
        label: bankAccount.name,
        value: bankAccount.id.toString(),
      })),
    [bankAccounts]
  );

  const {
    user,
    organizationValues,
    bookkeepingValues,
    accountingSystemLoginValues,
    accountingSystemConfigValues,
    setAccountingSystemConfigValues,
  } = useSetAccountingSystemConfigValues();

  const isBexio =
    bookkeepingValues?.accountingSystem === AccountingSystem.bexio;

  const initialValues: AccountingSystemConfigValues =
    accountingSystemConfigValues ?? {
      baseCurrency: CurrencyCode.chf,
      billingPeriod: BillingPeriod.monthly,
      reimbursement: false,
      senderBankAccountId: isBexio ? undefined : 1000,
    };

  const {
    getRootProps: getRootPropsBillingPeriod,
    getRadioProps: getRadioPropsBillingPeriod,
  } = useRadioGroup({
    name: "billingPeriod",
    defaultValue: initialValues.billingPeriod,
  });

  const groupBillingPeriod = getRootPropsBillingPeriod();

  const {
    getRootProps: getRootPropsReimbursement,
    getRadioProps: getRadioPropsReimbursement,
  } = useRadioGroup({
    name: "reimbursement",
    defaultValue: initialValues.reimbursement.toString(),
  });

  const groupReimbursement = getRootPropsReimbursement();

  useEffect(() => {
    if ((accountingSystemLoginValues?.success || user.isSSO) && !bankAccounts) {
      const fetchAccountingSystemConfig = async () => {
        const headers = {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };

        const [
          bankAccountsResponse,
          currenciesResponse,
          documentSettingsResponse,
        ]: [
          BankAccount[] | undefined,
          Currency[] | undefined,
          DocumentSettings[] | undefined
        ] = await Promise.all([
          API.post("expenslyREST", "/auth/bexio/proxy", {
            headers,
            body: {
              organizationId: organizationValues.id,
              path: "/3.0/banking/accounts",
              method: "GET",
            },
          }),
          API.post("expenslyREST", "/auth/bexio/proxy", {
            headers,
            body: {
              organizationId: organizationValues.id,
              path: "/3.0/currencies",
              method: "GET",
            },
          }),
          API.post("expenslyREST", "/auth/bexio/proxy", {
            headers,
            body: {
              organizationId: organizationValues.id,
              path: "/2.0/kb_item_setting",
              method: "GET",
            },
          }),
        ]);

        setCurrencyDefault(
          (currenciesResponse
            ?.find(
              (currency) =>
                currency.id ===
                documentSettingsResponse?.filter(
                  (ds) => ds.kb_item_class === "KbBill"
                )[0].default_currency_id
            )
            ?.name.toLowerCase() as CurrencyCode) ?? CurrencyCode.chf
        );

        setBankAccounts(bankAccountsResponse);
      };
      fetchAccountingSystemConfig();
    } else if (
      !accountingSystemLoginValues?.success &&
      !user.isSSO &&
      isBexio
    ) {
      navigate(routeCreateOrganizationAccountingSystemLogin);
    }
  }, [
    accountingSystemLoginValues?.success,
    bankAccounts,
    organizationValues.id,
    navigate,
    user.isSSO,
    isBexio,
  ]);

  const senderBankAccountIdPlaceholder =
    "Konto, von dem die Spesen abgebucht werden";

  return (
    <VStack maxWidth={maxWidth} width="full" alignItems="start" spacing="12px">
      <Text fontSize={36} fontWeight="medium" color="gray.900">
        Abrechnung
      </Text>
      {bankAccounts || !isBexio ? (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, helpers) => {
            setAccountingSystemConfigValues({
              baseCurrency: currencyDefault ?? values.baseCurrency,
              billingPeriod: values.billingPeriod,
              senderBankAccountId: values.senderBankAccountId,
              reimbursement: JSON.parse(values.reimbursement.toString()),
            });
            navigate(routeCreateOrganizationBookingAccounts);
          }}
        >
          {(props) => (
            <Form style={{ width: "100%" }}>
              <VStack spacing="20px">
                {!currencyDefault && isBexio && (
                  <Field name="baseCurrency">
                    {({
                      field,
                    }: FieldProps<string, AccountingSystemConfigValues>) => (
                      <FormControl>
                        <FormLabel fontSize={14} color="gray.700">
                          Standardwährung
                        </FormLabel>
                        <FormSelect
                          field={field}
                          props={props}
                          options={optionsCurrencies}
                        />
                      </FormControl>
                    )}
                  </Field>
                )}
                {isBexio && (
                  <Field name="billingPeriod">
                    {({
                      field,
                    }: FieldProps<string, AccountingSystemConfigValues>) => {
                      return (
                        <FormControl>
                          <FormLabel fontSize={14} color="gray.700">
                            Spesenabrechnung Rhythmus
                          </FormLabel>
                          <VStack
                            width="full"
                            {...groupBillingPeriod}
                            {...field}
                          >
                            <RadioCard
                              key={BillingPeriod.monthly}
                              {...getRadioPropsBillingPeriod({
                                value: BillingPeriod.monthly,
                              })}
                            >
                              <Text
                                fontSize={14}
                                color="primary.800"
                                fontWeight="medium"
                              >
                                Monatlich
                              </Text>
                              <Text fontSize={14} color="primary.700">
                                Die Spesenabrechnung wird jeden Monat
                                geschlossen.
                              </Text>
                            </RadioCard>
                            <RadioCard
                              key={BillingPeriod.biWeekly}
                              {...getRadioPropsBillingPeriod({
                                value: BillingPeriod.biWeekly,
                              })}
                            >
                              <Text
                                fontSize={14}
                                color="primary.800"
                                fontWeight="medium"
                              >
                                Alle zwei Wochen
                              </Text>
                              <Text fontSize={14} color="primary.700">
                                Die Spesenabrechnung wird alle zwei Wochen
                                geschlossen.
                              </Text>
                            </RadioCard>
                          </VStack>
                        </FormControl>
                      );
                    }}
                  </Field>
                )}
                <Field
                  name="senderBankAccountId"
                  validate={(value: string) => {
                    let error;
                    if (!value) {
                      error = "Belastungskonto ist erforderlich";
                    }
                    return error;
                  }}
                >
                  {({
                    field,
                    form,
                  }: FieldProps<string, AccountingSystemConfigValues>) => (
                    <FormControl isInvalid={!!form.errors.senderBankAccountId}>
                      <FormLabel fontSize={14} color="gray.700">
                        Belastungskonto
                      </FormLabel>
                      {isBexio ? (
                        <FormSelect
                          field={field}
                          props={props}
                          options={optionsBankAccounts}
                          placeholder={senderBankAccountIdPlaceholder}
                        />
                      ) : (
                        <Input
                          {...field}
                          placeholder={senderBankAccountIdPlaceholder}
                        />
                      )}
                      <FormErrorMessage>
                        {form.errors.senderBankAccountId}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {isBexio && (
                  <Field name="reimbursement">
                    {({
                      field,
                      form,
                    }: FieldProps<boolean, AccountingSystemConfigValues>) => {
                      return (
                        <FormControl
                          isInvalid={
                            (form.errors.reimbursement &&
                              form.touched.reimbursement) as boolean
                          }
                        >
                          <FormLabel fontSize={14} color="gray.700">
                            Spesen Rückvergütung
                          </FormLabel>
                          <VStack
                            width="full"
                            {...groupReimbursement}
                            {...field}
                          >
                            <RadioCard
                              key={0}
                              {...getRadioPropsReimbursement({
                                value: false.toString(),
                              })}
                            >
                              <Text
                                fontSize={14}
                                color="primary.800"
                                fontWeight="medium"
                              >
                                Aus
                              </Text>
                              <Text fontSize={14} color="primary.700">
                                Die Mitarbeiter zahlen ihre Spesen mit einer
                                Firmenkarte.
                              </Text>
                            </RadioCard>
                            <RadioCard
                              key={1}
                              {...getRadioPropsReimbursement({
                                value: true.toString(),
                              })}
                            >
                              <Text
                                fontSize={14}
                                color="primary.800"
                                fontWeight="medium"
                              >
                                Ein
                              </Text>
                              <Text fontSize={14} color="primary.700">
                                Die Mitarbeiter zahlen privat und das
                                Unternehmen erstattet die Kosten.
                              </Text>
                            </RadioCard>
                          </VStack>
                          <FormErrorMessage>
                            {form.errors.reimbursement}
                          </FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>
                )}
                <HStack width="full">
                  <Button
                    width="full"
                    variant="outline"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Zurück
                  </Button>
                  <Button width="full" colorScheme="primary" type="submit">
                    Weiter
                  </Button>
                </HStack>
              </VStack>
            </Form>
          )}
        </Formik>
      ) : (
        <Center width="full">
          <Spinner />
        </Center>
      )}
    </VStack>
  );
};

export default AccountingSystemConfig;
