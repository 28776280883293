/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateOrganizationInput = {
  id?: string | null,
  deletedAt?: string | null,
  name: string,
  type: OrganizationType,
  logo?: string | null,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  organizationBillingId?: string | null,
};

export enum OrganizationType {
  accountant = "accountant",
  company = "company",
}


export type ModelOrganizationConditionInput = {
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  type?: ModelOrganizationTypeInput | null,
  logo?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationBillingId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelOrganizationTypeInput = {
  eq?: OrganizationType | null,
  ne?: OrganizationType | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  deletedAt?: string | null,
  name: string,
  userOrganizations?: ModelUserOrganizationConnection | null,
  bookkeepings?: ModelBookkeepingConnection | null,
  type: OrganizationType,
  logo?: string | null,
  billing?: Billing | null,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  createdAt: string,
  updatedAt: string,
  organizationBillingId?: string | null,
};

export type CommonFields = {
  __typename: "CommonFields",
  id: string,
  deletedAt?: string | null,
};

export type Billing = {
  __typename: "Billing",
  id: string,
  deletedAt?: string | null,
  userId: string,
  customerId: string,
  subscriptionId: string,
  subscriptionItemId: string,
  quantity: number,
  status: BillingStatus,
  authGroupsRead: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export enum BillingStatus {
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  trialing = "trialing",
  active = "active",
  past_due = "past_due",
  canceled = "canceled",
  unpaid = "unpaid",
}


export type User = {
  __typename: "User",
  id: string,
  deletedAt?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  userOrganizations?: ModelUserOrganizationConnection | null,
  userBookkeepings?: ModelUserBookkeepingConnection | null,
  role: UserAppRole,
  transactions?: ModelTransactionConnection | null,
  isSSO?: boolean | null,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserOrganizationConnection = {
  __typename: "ModelUserOrganizationConnection",
  items:  Array<UserOrganization | null >,
  nextToken?: string | null,
};

export type UserOrganization = {
  __typename: "UserOrganization",
  id: string,
  deletedAt?: string | null,
  userId: string,
  organizationId: string,
  role: UserOrganizationRole,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export enum UserOrganizationRole {
  accountant = "accountant",
  admin = "admin",
  head = "head",
  employee = "employee",
}


export type ModelUserBookkeepingConnection = {
  __typename: "ModelUserBookkeepingConnection",
  items:  Array<UserBookkeeping | null >,
  nextToken?: string | null,
};

export type UserBookkeeping = {
  __typename: "UserBookkeeping",
  id: string,
  deletedAt?: string | null,
  userId: string,
  bookkeepingId: string,
  accountingSystemBill?: AccountingSystemBill | null,
  bills?: ModelBillConnection | null,
  transactionReferences?: ModelTransactionReferenceConnection | null,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  createdAt: string,
  updatedAt: string,
  userBookkeepingAccountingSystemBillId?: string | null,
};

export type AccountingSystemBill = {
  __typename: "AccountingSystemBill",
  id: string,
  deletedAt?: string | null,
  supplierId?: number | null,
  contactPartnerId: number,
  senderBankAccountId: number,
  billingPeriod: BillingPeriod,
  baseCurrency: CurrencyCode,
  accountingSystemReimbursement?: AccountingSystemReimbursement | null,
  userBookkeepingId: string,
  authUserId: string,
  authGroups: Array< string >,
  createdAt: string,
  updatedAt: string,
  accountingSystemBillAccountingSystemReimbursementId?: string | null,
};

export enum BillingPeriod {
  monthly = "monthly",
  biWeekly = "biWeekly",
}


export enum CurrencyCode {
  aed = "aed",
  afn = "afn",
  all = "all",
  amd = "amd",
  ang = "ang",
  aoa = "aoa",
  ars = "ars",
  aud = "aud",
  awg = "awg",
  azn = "azn",
  bam = "bam",
  bbd = "bbd",
  bdt = "bdt",
  bgn = "bgn",
  bhd = "bhd",
  bif = "bif",
  bmd = "bmd",
  bnd = "bnd",
  bob = "bob",
  brl = "brl",
  bsd = "bsd",
  btn = "btn",
  bwp = "bwp",
  byn = "byn",
  bzd = "bzd",
  cad = "cad",
  cdf = "cdf",
  chf = "chf",
  clp = "clp",
  cny = "cny",
  cop = "cop",
  crc = "crc",
  cve = "cve",
  czk = "czk",
  djf = "djf",
  dkk = "dkk",
  dop = "dop",
  dzd = "dzd",
  egp = "egp",
  ern = "ern",
  etb = "etb",
  eur = "eur",
  fjd = "fjd",
  fkp = "fkp",
  gbp = "gbp",
  gel = "gel",
  ghs = "ghs",
  gip = "gip",
  gmd = "gmd",
  gnf = "gnf",
  gtq = "gtq",
  gyd = "gyd",
  hkd = "hkd",
  hnl = "hnl",
  hrk = "hrk",
  htg = "htg",
  huf = "huf",
  idr = "idr",
  ils = "ils",
  inr = "inr",
  iqd = "iqd",
  irr = "irr",
  isk = "isk",
  jep = "jep",
  jmd = "jmd",
  jod = "jod",
  jpy = "jpy",
  kes = "kes",
  kgs = "kgs",
  khr = "khr",
  kid = "kid",
  kmf = "kmf",
  krw = "krw",
  kwd = "kwd",
  kyd = "kyd",
  kzt = "kzt",
  lak = "lak",
  lbp = "lbp",
  lkr = "lkr",
  lrd = "lrd",
  lsl = "lsl",
  ltl = "ltl",
  lvl = "lvl",
  lyd = "lyd",
  mad = "mad",
  mdl = "mdl",
  mga = "mga",
  mkd = "mkd",
  mmk = "mmk",
  mnt = "mnt",
  mop = "mop",
  mru = "mru",
  mur = "mur",
  mvr = "mvr",
  mwk = "mwk",
  mxn = "mxn",
  myr = "myr",
  mzn = "mzn",
  nad = "nad",
  ngn = "ngn",
  nio = "nio",
  nok = "nok",
  npr = "npr",
  nzd = "nzd",
  omr = "omr",
  pab = "pab",
  pen = "pen",
  pgk = "pgk",
  php = "php",
  pkr = "pkr",
  pln = "pln",
  pyg = "pyg",
  qar = "qar",
  ron = "ron",
  rsd = "rsd",
  rub = "rub",
  rwf = "rwf",
  sar = "sar",
  sbd = "sbd",
  scr = "scr",
  sdg = "sdg",
  sek = "sek",
  sgd = "sgd",
  shp = "shp",
  sll = "sll",
  sos = "sos",
  srd = "srd",
  ssp = "ssp",
  stn = "stn",
  syp = "syp",
  szl = "szl",
  thb = "thb",
  tjs = "tjs",
  tmt = "tmt",
  tnd = "tnd",
  top = "top",
  ttd = "ttd",
  twd = "twd",
  tzs = "tzs",
  uah = "uah",
  ugx = "ugx",
  usd = "usd",
  uyu = "uyu",
  uzs = "uzs",
  ved = "ved",
  ves = "ves",
  vnd = "vnd",
  vuv = "vuv",
  wst = "wst",
  xaf = "xaf",
  xcd = "xcd",
  xof = "xof",
  xpf = "xpf",
  xxx = "xxx",
  yer = "yer",
  zar = "zar",
  zmw = "zmw",
  byr = "byr",
  std = "std",
  vef = "vef",
}


export type AccountingSystemReimbursement = {
  __typename: "AccountingSystemReimbursement",
  id: string,
  deletedAt?: string | null,
  receiverIban: string,
  receiverStreet: string,
  receiverHouseNumber: string,
  receiverCity: string,
  receiverPostalCode: string,
  receiverCountry: CountryCode,
  accountingSystemBillId: string,
  authUserId: string,
  authGroups: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export enum CountryCode {
  ac = "ac",
  ad = "ad",
  ae = "ae",
  af = "af",
  ag = "ag",
  ai = "ai",
  al = "al",
  am = "am",
  an = "an",
  ao = "ao",
  ar = "ar",
  at = "at",
  au = "au",
  aw = "aw",
  ax = "ax",
  az = "az",
  ba = "ba",
  bb = "bb",
  bd = "bd",
  be = "be",
  bf = "bf",
  bg = "bg",
  bh = "bh",
  bi = "bi",
  bj = "bj",
  bl = "bl",
  bm = "bm",
  bn = "bn",
  bo = "bo",
  bq = "bq",
  br = "br",
  bs = "bs",
  bt = "bt",
  bv = "bv",
  bw = "bw",
  by = "by",
  bz = "bz",
  ca = "ca",
  cc = "cc",
  cd = "cd",
  cf = "cf",
  cg = "cg",
  ch = "ch",
  ci = "ci",
  ck = "ck",
  cl = "cl",
  cm = "cm",
  cn = "cn",
  co = "co",
  cr = "cr",
  cu = "cu",
  cv = "cv",
  cw = "cw",
  cx = "cx",
  cy = "cy",
  cz = "cz",
  de = "de",
  dj = "dj",
  dk = "dk",
  dm = "dm",
  dz = "dz",
  ec = "ec",
  ee = "ee",
  eg = "eg",
  eh = "eh",
  er = "er",
  es = "es",
  et = "et",
  fi = "fi",
  fj = "fj",
  fk = "fk",
  fo = "fo",
  fr = "fr",
  ga = "ga",
  gb = "gb",
  gd = "gd",
  ge = "ge",
  gf = "gf",
  gg = "gg",
  gh = "gh",
  gi = "gi",
  gl = "gl",
  gm = "gm",
  gn = "gn",
  gp = "gp",
  gq = "gq",
  gr = "gr",
  gs = "gs",
  gt = "gt",
  gw = "gw",
  gy = "gy",
  hk = "hk",
  hm = "hm",
  hn = "hn",
  hr = "hr",
  ht = "ht",
  hu = "hu",
  id = "id",
  ie = "ie",
  il = "il",
  im = "im",
  io = "io",
  iq = "iq",
  ir = "ir",
  it = "it",
  je = "je",
  jm = "jm",
  jo = "jo",
  jp = "jp",
  ke = "ke",
  kg = "kg",
  kh = "kh",
  ki = "ki",
  km = "km",
  kn = "kn",
  kp = "kp",
  kr = "kr",
  kw = "kw",
  ky = "ky",
  kz = "kz",
  la = "la",
  lb = "lb",
  lc = "lc",
  li = "li",
  lk = "lk",
  lr = "lr",
  ls = "ls",
  lt = "lt",
  lu = "lu",
  lv = "lv",
  ly = "ly",
  ma = "ma",
  mc = "mc",
  md = "md",
  me = "me",
  mf = "mf",
  mg = "mg",
  mk = "mk",
  ml = "ml",
  mm = "mm",
  mn = "mn",
  mo = "mo",
  mq = "mq",
  mr = "mr",
  ms = "ms",
  mt = "mt",
  mu = "mu",
  mv = "mv",
  mw = "mw",
  mx = "mx",
  my = "my",
  mz = "mz",
  na = "na",
  nc = "nc",
  ne = "ne",
  nf = "nf",
  ng = "ng",
  ni = "ni",
  nl = "nl",
  no = "no",
  np = "np",
  nr = "nr",
  nu = "nu",
  nz = "nz",
  om = "om",
  pa = "pa",
  pe = "pe",
  pf = "pf",
  pg = "pg",
  ph = "ph",
  pk = "pk",
  pl = "pl",
  pm = "pm",
  pn = "pn",
  ps = "ps",
  pt = "pt",
  py = "py",
  qa = "qa",
  re = "re",
  ro = "ro",
  rs = "rs",
  ru = "ru",
  rw = "rw",
  sa = "sa",
  sb = "sb",
  sc = "sc",
  sd = "sd",
  se = "se",
  sg = "sg",
  sh = "sh",
  si = "si",
  sj = "sj",
  sk = "sk",
  sl = "sl",
  sm = "sm",
  sn = "sn",
  so = "so",
  sr = "sr",
  ss = "ss",
  st = "st",
  sv = "sv",
  sx = "sx",
  sy = "sy",
  sz = "sz",
  ta = "ta",
  tc = "tc",
  td = "td",
  tf = "tf",
  tg = "tg",
  th = "th",
  tj = "tj",
  tk = "tk",
  tl = "tl",
  tm = "tm",
  tn = "tn",
  to = "to",
  tr = "tr",
  tt = "tt",
  tv = "tv",
  tw = "tw",
  tz = "tz",
  ua = "ua",
  ug = "ug",
  um = "um",
  us = "us",
  uy = "uy",
  uz = "uz",
  va = "va",
  vc = "vc",
  ve = "ve",
  vg = "vg",
  vn = "vn",
  vu = "vu",
  wf = "wf",
  ws = "ws",
  xk = "xk",
  ye = "ye",
  yt = "yt",
  za = "za",
  zm = "zm",
  zw = "zw",
  zz = "zz",
}


export type ModelBillConnection = {
  __typename: "ModelBillConnection",
  items:  Array<Bill | null >,
  nextToken?: string | null,
};

export type Bill = {
  __typename: "Bill",
  id: string,
  deletedAt?: string | null,
  name: string,
  accountingSystemId?: string | null,
  dateFrom: string,
  dateTo?: string | null,
  status: BillStatus,
  amount: number,
  currency: CurrencyCode,
  baseCurrencyAmount?: number | null,
  transactions?: ModelTransactionConnection | null,
  userBookkeeping: UserBookkeeping,
  userBookkeepingId: string,
  authUserId: string,
  authGroups: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export enum BillStatus {
  draft = "draft",
  booked = "booked",
  paid = "paid",
  failed = "failed",
}


export type ModelTransactionConnection = {
  __typename: "ModelTransactionConnection",
  items:  Array<Transaction | null >,
  nextToken?: string | null,
};

export type Transaction = {
  __typename: "Transaction",
  id: string,
  deletedAt?: string | null,
  paidAt: string,
  currencyCode: CurrencyCode,
  taxes?: ModelTaxConnection | null,
  amount: number,
  attachments?: ModelAttachmentConnection | null,
  title: string,
  exchangeRate?: number | null,
  baseCurrencyAmount?: number | null,
  status: TransactionStatus,
  accountingSystemId?: string | null,
  bookkeepingYear: BookkeepingYear,
  bookkeepingYearId: string,
  bill?: Bill | null,
  billId?: string | null,
  bookingAccount: BookingAccount,
  bookingAccountId: string,
  transactionReference?: TransactionReference | null,
  transactionReferenceId?: string | null,
  user: User,
  userId: string,
  replacedById?: string | null,
  authGroups: Array< string >,
  authGroupsRead: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type ModelTaxConnection = {
  __typename: "ModelTaxConnection",
  items:  Array<Tax | null >,
  nextToken?: string | null,
};

export type Tax = {
  __typename: "Tax",
  id: string,
  deletedAt?: string | null,
  amount?: number | null,
  totalAmount?: number | null,
  transactionId: string,
  taxRate: TaxRate,
  taxRateId: string,
  accountingSystemId?: string | null,
  authUserId: string,
  authGroups: Array< string >,
  authGroupsRead: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type TaxRate = {
  __typename: "TaxRate",
  id: string,
  deletedAt?: string | null,
  code: string,
  rate: number,
  taxType: TaxType,
  sortOrder: number,
  accountingSystemId?: string | null,
  bookkeepingId: string,
  dateFrom: string,
  dateTo?: string | null,
  taxes?: ModelTaxConnection | null,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export enum TaxType {
  earnings = "earnings",
  investments = "investments",
  materials = "materials",
}


export type ModelAttachmentConnection = {
  __typename: "ModelAttachmentConnection",
  items:  Array<Attachment | null >,
  nextToken?: string | null,
};

export type Attachment = {
  __typename: "Attachment",
  id: string,
  deletedAt?: string | null,
  key: string,
  accountingSystemId?: string | null,
  accountingSystemName?: string | null,
  transactionId: string,
  authUserId: string,
  authGroups: Array< string >,
  authGroupsRead: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export enum TransactionStatus {
  draft = "draft",
  booked = "booked",
  failed = "failed",
  extracted = "extracted",
}


export type BookkeepingYear = {
  __typename: "BookkeepingYear",
  id: string,
  deletedAt?: string | null,
  bookkeepingId: string,
  transactions?: ModelTransactionConnection | null,
  hasNetTaxRate: boolean,
  year: number,
  balanceStart?: number | null,
  balance?: number | null,
  closedAt?: string | null,
  authGroups: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type BookingAccount = {
  __typename: "BookingAccount",
  id: string,
  deletedAt?: string | null,
  name: string,
  accountNumber: string,
  sortOrder: number,
  taxType: TaxType,
  transactionCategory: TransactionCategory,
  transactionCategoryId: string,
  accountingSystemId?: string | null,
  bookkeepingId: string,
  transactions?: ModelTransactionConnection | null,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type TransactionCategory = {
  __typename: "TransactionCategory",
  id: string,
  deletedAt?: string | null,
  name: string,
  icon: string,
  bookingAccounts?: ModelBookingAccountConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBookingAccountConnection = {
  __typename: "ModelBookingAccountConnection",
  items:  Array<BookingAccount | null >,
  nextToken?: string | null,
};

export type TransactionReference = {
  __typename: "TransactionReference",
  id: string,
  deletedAt?: string | null,
  name: string,
  userBookkeeping: UserBookkeeping,
  userBookkeepingId: string,
  transactions?: ModelTransactionConnection | null,
  authUserId: string,
  authGroups: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type ModelTransactionReferenceConnection = {
  __typename: "ModelTransactionReferenceConnection",
  items:  Array<TransactionReference | null >,
  nextToken?: string | null,
};

export enum UserAppRole {
  accountant = "accountant",
  organizationAdmin = "organizationAdmin",
  user = "user",
}


export type Bookkeeping = {
  __typename: "Bookkeeping",
  id: string,
  deletedAt?: string | null,
  name: string,
  organizationId: string,
  userBookkeepings?: ModelUserBookkeepingConnection | null,
  accountingSystem: AccountingSystem,
  accountingSystemConfig?: AccountingSystemConfig | null,
  bookingAccounts?: ModelBookingAccountConnection | null,
  taxRates?: ModelTaxRateConnection | null,
  bookkeepingYears?: ModelBookkeepingYearConnection | null,
  color: string,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  createdAt: string,
  updatedAt: string,
  bookkeepingAccountingSystemConfigId?: string | null,
};

export enum AccountingSystem {
  none = "none",
  bexio = "bexio",
  topal = "topal",
  banana = "banana",
}


export type AccountingSystemConfig = {
  __typename: "AccountingSystemConfig",
  id: string,
  deletedAt?: string | null,
  baseCurrency: CurrencyCode,
  billingPeriod: BillingPeriod,
  senderBankAccountId: number,
  reimbursement: boolean,
  bookkeepingId: string,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type ModelTaxRateConnection = {
  __typename: "ModelTaxRateConnection",
  items:  Array<TaxRate | null >,
  nextToken?: string | null,
};

export type ModelBookkeepingYearConnection = {
  __typename: "ModelBookkeepingYearConnection",
  items:  Array<BookkeepingYear | null >,
  nextToken?: string | null,
};

export type ModelBookkeepingConnection = {
  __typename: "ModelBookkeepingConnection",
  items:  Array<Bookkeeping | null >,
  nextToken?: string | null,
};

export type UpdateOrganizationInput = {
  id: string,
  deletedAt?: string | null,
  name?: string | null,
  type?: OrganizationType | null,
  logo?: string | null,
  authGroupsRead?: Array< string > | null,
  authGroupsUpdate?: Array< string > | null,
  organizationBillingId?: string | null,
};

export type CreateBillingInput = {
  id?: string | null,
  deletedAt?: string | null,
  userId: string,
  customerId: string,
  subscriptionId: string,
  subscriptionItemId: string,
  quantity: number,
  status: BillingStatus,
  authGroupsRead: Array< string >,
};

export type ModelBillingConditionInput = {
  deletedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  customerId?: ModelStringInput | null,
  subscriptionId?: ModelStringInput | null,
  subscriptionItemId?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  status?: ModelBillingStatusInput | null,
  authGroupsRead?: ModelStringInput | null,
  and?: Array< ModelBillingConditionInput | null > | null,
  or?: Array< ModelBillingConditionInput | null > | null,
  not?: ModelBillingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBillingStatusInput = {
  eq?: BillingStatus | null,
  ne?: BillingStatus | null,
};

export type UpdateBillingInput = {
  id: string,
  deletedAt?: string | null,
  userId?: string | null,
  customerId?: string | null,
  subscriptionId?: string | null,
  subscriptionItemId?: string | null,
  quantity?: number | null,
  status?: BillingStatus | null,
  authGroupsRead?: Array< string > | null,
};

export type CreateUserInput = {
  id?: string | null,
  deletedAt?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  role: UserAppRole,
  isSSO?: boolean | null,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
};

export type ModelUserConditionInput = {
  deletedAt?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  role?: ModelUserAppRoleInput | null,
  isSSO?: ModelBooleanInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelStringInput | null,
};

export type ModelUserAppRoleInput = {
  eq?: UserAppRole | null,
  ne?: UserAppRole | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateUserInput = {
  id: string,
  deletedAt?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  role?: UserAppRole | null,
  isSSO?: boolean | null,
  authGroupsRead?: Array< string > | null,
  authGroupsUpdate?: Array< string > | null,
};

export type CreateUserOrganizationInput = {
  id?: string | null,
  deletedAt?: string | null,
  userId: string,
  organizationId: string,
  role: UserOrganizationRole,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
};

export type ModelUserOrganizationConditionInput = {
  deletedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  role?: ModelUserOrganizationRoleInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  and?: Array< ModelUserOrganizationConditionInput | null > | null,
  or?: Array< ModelUserOrganizationConditionInput | null > | null,
  not?: ModelUserOrganizationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelUserOrganizationRoleInput = {
  eq?: UserOrganizationRole | null,
  ne?: UserOrganizationRole | null,
};

export type UpdateUserOrganizationInput = {
  id: string,
  deletedAt?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  role?: UserOrganizationRole | null,
  authGroupsRead?: Array< string > | null,
  authGroupsUpdate?: Array< string > | null,
};

export type CreateBookkeepingInput = {
  id?: string | null,
  deletedAt?: string | null,
  name: string,
  organizationId: string,
  accountingSystem: AccountingSystem,
  color: string,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  bookkeepingAccountingSystemConfigId?: string | null,
};

export type ModelBookkeepingConditionInput = {
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  accountingSystem?: ModelAccountingSystemInput | null,
  color?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  and?: Array< ModelBookkeepingConditionInput | null > | null,
  or?: Array< ModelBookkeepingConditionInput | null > | null,
  not?: ModelBookkeepingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  bookkeepingAccountingSystemConfigId?: ModelIDInput | null,
};

export type ModelAccountingSystemInput = {
  eq?: AccountingSystem | null,
  ne?: AccountingSystem | null,
};

export type UpdateBookkeepingInput = {
  id: string,
  deletedAt?: string | null,
  name?: string | null,
  organizationId?: string | null,
  accountingSystem?: AccountingSystem | null,
  color?: string | null,
  authGroupsRead?: Array< string > | null,
  authGroupsUpdate?: Array< string > | null,
  bookkeepingAccountingSystemConfigId?: string | null,
};

export type CreateBookkeepingYearInput = {
  id?: string | null,
  deletedAt?: string | null,
  bookkeepingId: string,
  hasNetTaxRate: boolean,
  year: number,
  balanceStart?: number | null,
  balance?: number | null,
  closedAt?: string | null,
  authGroups: Array< string >,
};

export type ModelBookkeepingYearConditionInput = {
  deletedAt?: ModelStringInput | null,
  bookkeepingId?: ModelIDInput | null,
  hasNetTaxRate?: ModelBooleanInput | null,
  year?: ModelIntInput | null,
  balanceStart?: ModelFloatInput | null,
  balance?: ModelFloatInput | null,
  closedAt?: ModelStringInput | null,
  authGroups?: ModelStringInput | null,
  and?: Array< ModelBookkeepingYearConditionInput | null > | null,
  or?: Array< ModelBookkeepingYearConditionInput | null > | null,
  not?: ModelBookkeepingYearConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateBookkeepingYearInput = {
  id: string,
  deletedAt?: string | null,
  bookkeepingId?: string | null,
  hasNetTaxRate?: boolean | null,
  year?: number | null,
  balanceStart?: number | null,
  balance?: number | null,
  closedAt?: string | null,
  authGroups?: Array< string > | null,
};

export type CreateUserBookkeepingInput = {
  id?: string | null,
  deletedAt?: string | null,
  userId: string,
  bookkeepingId: string,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
  userBookkeepingAccountingSystemBillId?: string | null,
};

export type ModelUserBookkeepingConditionInput = {
  deletedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  bookkeepingId?: ModelIDInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  and?: Array< ModelUserBookkeepingConditionInput | null > | null,
  or?: Array< ModelUserBookkeepingConditionInput | null > | null,
  not?: ModelUserBookkeepingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userBookkeepingAccountingSystemBillId?: ModelIDInput | null,
};

export type UpdateUserBookkeepingInput = {
  id: string,
  deletedAt?: string | null,
  userId?: string | null,
  bookkeepingId?: string | null,
  authGroupsRead?: Array< string > | null,
  authGroupsUpdate?: Array< string > | null,
  userBookkeepingAccountingSystemBillId?: string | null,
};

export type CreateTransactionReferenceInput = {
  id?: string | null,
  deletedAt?: string | null,
  name: string,
  userBookkeepingId: string,
  authUserId: string,
  authGroups: Array< string >,
};

export type ModelTransactionReferenceConditionInput = {
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  userBookkeepingId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  and?: Array< ModelTransactionReferenceConditionInput | null > | null,
  or?: Array< ModelTransactionReferenceConditionInput | null > | null,
  not?: ModelTransactionReferenceConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTransactionReferenceInput = {
  id: string,
  deletedAt?: string | null,
  name?: string | null,
  userBookkeepingId?: string | null,
  authUserId?: string | null,
  authGroups?: Array< string > | null,
};

export type CreateAccountingSystemConfigInput = {
  id?: string | null,
  deletedAt?: string | null,
  baseCurrency: CurrencyCode,
  billingPeriod: BillingPeriod,
  senderBankAccountId: number,
  reimbursement: boolean,
  bookkeepingId: string,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
};

export type ModelAccountingSystemConfigConditionInput = {
  deletedAt?: ModelStringInput | null,
  baseCurrency?: ModelCurrencyCodeInput | null,
  billingPeriod?: ModelBillingPeriodInput | null,
  senderBankAccountId?: ModelIntInput | null,
  reimbursement?: ModelBooleanInput | null,
  bookkeepingId?: ModelIDInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  and?: Array< ModelAccountingSystemConfigConditionInput | null > | null,
  or?: Array< ModelAccountingSystemConfigConditionInput | null > | null,
  not?: ModelAccountingSystemConfigConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelCurrencyCodeInput = {
  eq?: CurrencyCode | null,
  ne?: CurrencyCode | null,
};

export type ModelBillingPeriodInput = {
  eq?: BillingPeriod | null,
  ne?: BillingPeriod | null,
};

export type UpdateAccountingSystemConfigInput = {
  id: string,
  deletedAt?: string | null,
  baseCurrency?: CurrencyCode | null,
  billingPeriod?: BillingPeriod | null,
  senderBankAccountId?: number | null,
  reimbursement?: boolean | null,
  bookkeepingId?: string | null,
  authGroupsRead?: Array< string > | null,
  authGroupsUpdate?: Array< string > | null,
};

export type CreateAccountingSystemBillInput = {
  id?: string | null,
  deletedAt?: string | null,
  supplierId?: number | null,
  contactPartnerId: number,
  senderBankAccountId: number,
  billingPeriod: BillingPeriod,
  baseCurrency: CurrencyCode,
  userBookkeepingId: string,
  authUserId: string,
  authGroups: Array< string >,
  accountingSystemBillAccountingSystemReimbursementId?: string | null,
};

export type ModelAccountingSystemBillConditionInput = {
  deletedAt?: ModelStringInput | null,
  supplierId?: ModelIntInput | null,
  contactPartnerId?: ModelIntInput | null,
  senderBankAccountId?: ModelIntInput | null,
  billingPeriod?: ModelBillingPeriodInput | null,
  baseCurrency?: ModelCurrencyCodeInput | null,
  userBookkeepingId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  and?: Array< ModelAccountingSystemBillConditionInput | null > | null,
  or?: Array< ModelAccountingSystemBillConditionInput | null > | null,
  not?: ModelAccountingSystemBillConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountingSystemBillAccountingSystemReimbursementId?: ModelIDInput | null,
};

export type UpdateAccountingSystemBillInput = {
  id: string,
  deletedAt?: string | null,
  supplierId?: number | null,
  contactPartnerId?: number | null,
  senderBankAccountId?: number | null,
  billingPeriod?: BillingPeriod | null,
  baseCurrency?: CurrencyCode | null,
  userBookkeepingId?: string | null,
  authUserId?: string | null,
  authGroups?: Array< string > | null,
  accountingSystemBillAccountingSystemReimbursementId?: string | null,
};

export type CreateAccountingSystemReimbursementInput = {
  id?: string | null,
  deletedAt?: string | null,
  receiverIban: string,
  receiverStreet: string,
  receiverHouseNumber: string,
  receiverCity: string,
  receiverPostalCode: string,
  receiverCountry: CountryCode,
  accountingSystemBillId: string,
  authUserId: string,
  authGroups: Array< string >,
};

export type ModelAccountingSystemReimbursementConditionInput = {
  deletedAt?: ModelStringInput | null,
  receiverIban?: ModelStringInput | null,
  receiverStreet?: ModelStringInput | null,
  receiverHouseNumber?: ModelStringInput | null,
  receiverCity?: ModelStringInput | null,
  receiverPostalCode?: ModelStringInput | null,
  receiverCountry?: ModelCountryCodeInput | null,
  accountingSystemBillId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  and?: Array< ModelAccountingSystemReimbursementConditionInput | null > | null,
  or?: Array< ModelAccountingSystemReimbursementConditionInput | null > | null,
  not?: ModelAccountingSystemReimbursementConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelCountryCodeInput = {
  eq?: CountryCode | null,
  ne?: CountryCode | null,
};

export type UpdateAccountingSystemReimbursementInput = {
  id: string,
  deletedAt?: string | null,
  receiverIban?: string | null,
  receiverStreet?: string | null,
  receiverHouseNumber?: string | null,
  receiverCity?: string | null,
  receiverPostalCode?: string | null,
  receiverCountry?: CountryCode | null,
  accountingSystemBillId?: string | null,
  authUserId?: string | null,
  authGroups?: Array< string > | null,
};

export type CreateBookingAccountInput = {
  id?: string | null,
  deletedAt?: string | null,
  name: string,
  accountNumber: string,
  sortOrder: number,
  taxType: TaxType,
  transactionCategoryId: string,
  accountingSystemId?: string | null,
  bookkeepingId: string,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
};

export type ModelBookingAccountConditionInput = {
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  accountNumber?: ModelStringInput | null,
  sortOrder?: ModelIntInput | null,
  taxType?: ModelTaxTypeInput | null,
  transactionCategoryId?: ModelIDInput | null,
  accountingSystemId?: ModelStringInput | null,
  bookkeepingId?: ModelIDInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  and?: Array< ModelBookingAccountConditionInput | null > | null,
  or?: Array< ModelBookingAccountConditionInput | null > | null,
  not?: ModelBookingAccountConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelTaxTypeInput = {
  eq?: TaxType | null,
  ne?: TaxType | null,
};

export type UpdateBookingAccountInput = {
  id: string,
  deletedAt?: string | null,
  name?: string | null,
  accountNumber?: string | null,
  sortOrder?: number | null,
  taxType?: TaxType | null,
  transactionCategoryId?: string | null,
  accountingSystemId?: string | null,
  bookkeepingId?: string | null,
  authGroupsRead?: Array< string > | null,
  authGroupsUpdate?: Array< string > | null,
};

export type CreateTaxRateInput = {
  id?: string | null,
  deletedAt?: string | null,
  code: string,
  rate: number,
  taxType: TaxType,
  sortOrder: number,
  accountingSystemId?: string | null,
  bookkeepingId: string,
  dateFrom: string,
  dateTo?: string | null,
  authGroupsRead: Array< string >,
  authGroupsUpdate: Array< string >,
};

export type ModelTaxRateConditionInput = {
  deletedAt?: ModelStringInput | null,
  code?: ModelStringInput | null,
  rate?: ModelFloatInput | null,
  taxType?: ModelTaxTypeInput | null,
  sortOrder?: ModelIntInput | null,
  accountingSystemId?: ModelStringInput | null,
  bookkeepingId?: ModelIDInput | null,
  dateFrom?: ModelStringInput | null,
  dateTo?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  and?: Array< ModelTaxRateConditionInput | null > | null,
  or?: Array< ModelTaxRateConditionInput | null > | null,
  not?: ModelTaxRateConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTaxRateInput = {
  id: string,
  deletedAt?: string | null,
  code?: string | null,
  rate?: number | null,
  taxType?: TaxType | null,
  sortOrder?: number | null,
  accountingSystemId?: string | null,
  bookkeepingId?: string | null,
  dateFrom?: string | null,
  dateTo?: string | null,
  authGroupsRead?: Array< string > | null,
  authGroupsUpdate?: Array< string > | null,
};

export type CreateTransactionCategoryInput = {
  id?: string | null,
  deletedAt?: string | null,
  name: string,
  icon: string,
};

export type ModelTransactionCategoryConditionInput = {
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  and?: Array< ModelTransactionCategoryConditionInput | null > | null,
  or?: Array< ModelTransactionCategoryConditionInput | null > | null,
  not?: ModelTransactionCategoryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTransactionCategoryInput = {
  id: string,
  deletedAt?: string | null,
  name?: string | null,
  icon?: string | null,
};

export type DeleteTransactionCategoryInput = {
  id: string,
};

export type CreateBillInput = {
  id?: string | null,
  deletedAt?: string | null,
  name: string,
  accountingSystemId?: string | null,
  dateFrom: string,
  dateTo?: string | null,
  status: BillStatus,
  amount: number,
  currency: CurrencyCode,
  baseCurrencyAmount?: number | null,
  userBookkeepingId: string,
  authUserId: string,
  authGroups: Array< string >,
};

export type ModelBillConditionInput = {
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  accountingSystemId?: ModelStringInput | null,
  dateFrom?: ModelStringInput | null,
  dateTo?: ModelStringInput | null,
  status?: ModelBillStatusInput | null,
  amount?: ModelFloatInput | null,
  currency?: ModelCurrencyCodeInput | null,
  baseCurrencyAmount?: ModelFloatInput | null,
  userBookkeepingId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  and?: Array< ModelBillConditionInput | null > | null,
  or?: Array< ModelBillConditionInput | null > | null,
  not?: ModelBillConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBillStatusInput = {
  eq?: BillStatus | null,
  ne?: BillStatus | null,
};

export type UpdateBillInput = {
  id: string,
  deletedAt?: string | null,
  name?: string | null,
  accountingSystemId?: string | null,
  dateFrom?: string | null,
  dateTo?: string | null,
  status?: BillStatus | null,
  amount?: number | null,
  currency?: CurrencyCode | null,
  baseCurrencyAmount?: number | null,
  userBookkeepingId?: string | null,
  authUserId?: string | null,
  authGroups?: Array< string > | null,
};

export type CreateTransactionInput = {
  id?: string | null,
  deletedAt?: string | null,
  paidAt: string,
  currencyCode: CurrencyCode,
  amount: number,
  title: string,
  exchangeRate?: number | null,
  baseCurrencyAmount?: number | null,
  status: TransactionStatus,
  accountingSystemId?: string | null,
  bookkeepingYearId: string,
  billId?: string | null,
  bookingAccountId: string,
  transactionReferenceId?: string | null,
  userId: string,
  replacedById?: string | null,
  authGroups: Array< string >,
  authGroupsRead: Array< string >,
};

export type ModelTransactionConditionInput = {
  deletedAt?: ModelStringInput | null,
  paidAt?: ModelStringInput | null,
  currencyCode?: ModelCurrencyCodeInput | null,
  amount?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  exchangeRate?: ModelFloatInput | null,
  baseCurrencyAmount?: ModelFloatInput | null,
  status?: ModelTransactionStatusInput | null,
  accountingSystemId?: ModelStringInput | null,
  bookkeepingYearId?: ModelIDInput | null,
  billId?: ModelIDInput | null,
  bookingAccountId?: ModelIDInput | null,
  transactionReferenceId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  replacedById?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  and?: Array< ModelTransactionConditionInput | null > | null,
  or?: Array< ModelTransactionConditionInput | null > | null,
  not?: ModelTransactionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelTransactionStatusInput = {
  eq?: TransactionStatus | null,
  ne?: TransactionStatus | null,
};

export type UpdateTransactionInput = {
  id: string,
  deletedAt?: string | null,
  paidAt?: string | null,
  currencyCode?: CurrencyCode | null,
  amount?: number | null,
  title?: string | null,
  exchangeRate?: number | null,
  baseCurrencyAmount?: number | null,
  status?: TransactionStatus | null,
  accountingSystemId?: string | null,
  bookkeepingYearId?: string | null,
  billId?: string | null,
  bookingAccountId?: string | null,
  transactionReferenceId?: string | null,
  userId?: string | null,
  replacedById?: string | null,
  authGroups?: Array< string > | null,
  authGroupsRead?: Array< string > | null,
};

export type CreateAttachmentInput = {
  id?: string | null,
  deletedAt?: string | null,
  key: string,
  accountingSystemId?: string | null,
  accountingSystemName?: string | null,
  transactionId: string,
  authUserId: string,
  authGroups: Array< string >,
  authGroupsRead: Array< string >,
};

export type ModelAttachmentConditionInput = {
  deletedAt?: ModelStringInput | null,
  key?: ModelStringInput | null,
  accountingSystemId?: ModelStringInput | null,
  accountingSystemName?: ModelStringInput | null,
  transactionId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  and?: Array< ModelAttachmentConditionInput | null > | null,
  or?: Array< ModelAttachmentConditionInput | null > | null,
  not?: ModelAttachmentConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAttachmentInput = {
  id: string,
  deletedAt?: string | null,
  key?: string | null,
  accountingSystemId?: string | null,
  accountingSystemName?: string | null,
  transactionId?: string | null,
  authUserId?: string | null,
  authGroups?: Array< string > | null,
  authGroupsRead?: Array< string > | null,
};

export type CreateTaxInput = {
  id?: string | null,
  deletedAt?: string | null,
  amount?: number | null,
  totalAmount?: number | null,
  transactionId: string,
  taxRateId: string,
  accountingSystemId?: string | null,
  authUserId: string,
  authGroups: Array< string >,
  authGroupsRead: Array< string >,
};

export type ModelTaxConditionInput = {
  deletedAt?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  totalAmount?: ModelFloatInput | null,
  transactionId?: ModelIDInput | null,
  taxRateId?: ModelIDInput | null,
  accountingSystemId?: ModelStringInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  and?: Array< ModelTaxConditionInput | null > | null,
  or?: Array< ModelTaxConditionInput | null > | null,
  not?: ModelTaxConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTaxInput = {
  id: string,
  deletedAt?: string | null,
  amount?: number | null,
  totalAmount?: number | null,
  transactionId?: string | null,
  taxRateId?: string | null,
  accountingSystemId?: string | null,
  authUserId?: string | null,
  authGroups?: Array< string > | null,
  authGroupsRead?: Array< string > | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  type?: ModelOrganizationTypeInput | null,
  logo?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
  organizationBillingId?: ModelIDInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelBillingFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  customerId?: ModelStringInput | null,
  subscriptionId?: ModelStringInput | null,
  subscriptionItemId?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  status?: ModelBillingStatusInput | null,
  authGroupsRead?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBillingFilterInput | null > | null,
  or?: Array< ModelBillingFilterInput | null > | null,
  not?: ModelBillingFilterInput | null,
};

export type ModelBillingConnection = {
  __typename: "ModelBillingConnection",
  items:  Array<Billing | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  role?: ModelUserAppRoleInput | null,
  isSSO?: ModelBooleanInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelUserOrganizationFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  role?: ModelUserOrganizationRoleInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserOrganizationFilterInput | null > | null,
  or?: Array< ModelUserOrganizationFilterInput | null > | null,
  not?: ModelUserOrganizationFilterInput | null,
};

export type ModelBookkeepingFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  accountingSystem?: ModelAccountingSystemInput | null,
  color?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBookkeepingFilterInput | null > | null,
  or?: Array< ModelBookkeepingFilterInput | null > | null,
  not?: ModelBookkeepingFilterInput | null,
  bookkeepingAccountingSystemConfigId?: ModelIDInput | null,
};

export type ModelBookkeepingYearFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  bookkeepingId?: ModelIDInput | null,
  hasNetTaxRate?: ModelBooleanInput | null,
  year?: ModelIntInput | null,
  balanceStart?: ModelFloatInput | null,
  balance?: ModelFloatInput | null,
  closedAt?: ModelStringInput | null,
  authGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBookkeepingYearFilterInput | null > | null,
  or?: Array< ModelBookkeepingYearFilterInput | null > | null,
  not?: ModelBookkeepingYearFilterInput | null,
};

export type ModelUserBookkeepingFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  bookkeepingId?: ModelIDInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserBookkeepingFilterInput | null > | null,
  or?: Array< ModelUserBookkeepingFilterInput | null > | null,
  not?: ModelUserBookkeepingFilterInput | null,
  userBookkeepingAccountingSystemBillId?: ModelIDInput | null,
};

export type ModelTransactionReferenceFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  userBookkeepingId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTransactionReferenceFilterInput | null > | null,
  or?: Array< ModelTransactionReferenceFilterInput | null > | null,
  not?: ModelTransactionReferenceFilterInput | null,
};

export type ModelAccountingSystemConfigFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  baseCurrency?: ModelCurrencyCodeInput | null,
  billingPeriod?: ModelBillingPeriodInput | null,
  senderBankAccountId?: ModelIntInput | null,
  reimbursement?: ModelBooleanInput | null,
  bookkeepingId?: ModelIDInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountingSystemConfigFilterInput | null > | null,
  or?: Array< ModelAccountingSystemConfigFilterInput | null > | null,
  not?: ModelAccountingSystemConfigFilterInput | null,
};

export type ModelAccountingSystemConfigConnection = {
  __typename: "ModelAccountingSystemConfigConnection",
  items:  Array<AccountingSystemConfig | null >,
  nextToken?: string | null,
};

export type ModelAccountingSystemBillFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  supplierId?: ModelIntInput | null,
  contactPartnerId?: ModelIntInput | null,
  senderBankAccountId?: ModelIntInput | null,
  billingPeriod?: ModelBillingPeriodInput | null,
  baseCurrency?: ModelCurrencyCodeInput | null,
  userBookkeepingId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountingSystemBillFilterInput | null > | null,
  or?: Array< ModelAccountingSystemBillFilterInput | null > | null,
  not?: ModelAccountingSystemBillFilterInput | null,
  accountingSystemBillAccountingSystemReimbursementId?: ModelIDInput | null,
};

export type ModelAccountingSystemBillConnection = {
  __typename: "ModelAccountingSystemBillConnection",
  items:  Array<AccountingSystemBill | null >,
  nextToken?: string | null,
};

export type ModelAccountingSystemReimbursementFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  receiverIban?: ModelStringInput | null,
  receiverStreet?: ModelStringInput | null,
  receiverHouseNumber?: ModelStringInput | null,
  receiverCity?: ModelStringInput | null,
  receiverPostalCode?: ModelStringInput | null,
  receiverCountry?: ModelCountryCodeInput | null,
  accountingSystemBillId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountingSystemReimbursementFilterInput | null > | null,
  or?: Array< ModelAccountingSystemReimbursementFilterInput | null > | null,
  not?: ModelAccountingSystemReimbursementFilterInput | null,
};

export type ModelAccountingSystemReimbursementConnection = {
  __typename: "ModelAccountingSystemReimbursementConnection",
  items:  Array<AccountingSystemReimbursement | null >,
  nextToken?: string | null,
};

export type ModelBookingAccountFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  accountNumber?: ModelStringInput | null,
  sortOrder?: ModelIntInput | null,
  taxType?: ModelTaxTypeInput | null,
  transactionCategoryId?: ModelIDInput | null,
  accountingSystemId?: ModelStringInput | null,
  bookkeepingId?: ModelIDInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBookingAccountFilterInput | null > | null,
  or?: Array< ModelBookingAccountFilterInput | null > | null,
  not?: ModelBookingAccountFilterInput | null,
};

export type ModelTaxRateFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  code?: ModelStringInput | null,
  rate?: ModelFloatInput | null,
  taxType?: ModelTaxTypeInput | null,
  sortOrder?: ModelIntInput | null,
  accountingSystemId?: ModelStringInput | null,
  bookkeepingId?: ModelIDInput | null,
  dateFrom?: ModelStringInput | null,
  dateTo?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  authGroupsUpdate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTaxRateFilterInput | null > | null,
  or?: Array< ModelTaxRateFilterInput | null > | null,
  not?: ModelTaxRateFilterInput | null,
};

export type ModelTransactionCategoryFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTransactionCategoryFilterInput | null > | null,
  or?: Array< ModelTransactionCategoryFilterInput | null > | null,
  not?: ModelTransactionCategoryFilterInput | null,
};

export type ModelTransactionCategoryConnection = {
  __typename: "ModelTransactionCategoryConnection",
  items:  Array<TransactionCategory | null >,
  nextToken?: string | null,
};

export type ModelBillFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  accountingSystemId?: ModelStringInput | null,
  dateFrom?: ModelStringInput | null,
  dateTo?: ModelStringInput | null,
  status?: ModelBillStatusInput | null,
  amount?: ModelFloatInput | null,
  currency?: ModelCurrencyCodeInput | null,
  baseCurrencyAmount?: ModelFloatInput | null,
  userBookkeepingId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBillFilterInput | null > | null,
  or?: Array< ModelBillFilterInput | null > | null,
  not?: ModelBillFilterInput | null,
};

export type ModelTransactionFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  paidAt?: ModelStringInput | null,
  currencyCode?: ModelCurrencyCodeInput | null,
  amount?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  exchangeRate?: ModelFloatInput | null,
  baseCurrencyAmount?: ModelFloatInput | null,
  status?: ModelTransactionStatusInput | null,
  accountingSystemId?: ModelStringInput | null,
  bookkeepingYearId?: ModelIDInput | null,
  billId?: ModelIDInput | null,
  bookingAccountId?: ModelIDInput | null,
  transactionReferenceId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  replacedById?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTransactionFilterInput | null > | null,
  or?: Array< ModelTransactionFilterInput | null > | null,
  not?: ModelTransactionFilterInput | null,
};

export type ModelAttachmentFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  key?: ModelStringInput | null,
  accountingSystemId?: ModelStringInput | null,
  accountingSystemName?: ModelStringInput | null,
  transactionId?: ModelIDInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAttachmentFilterInput | null > | null,
  or?: Array< ModelAttachmentFilterInput | null > | null,
  not?: ModelAttachmentFilterInput | null,
};

export type ModelTaxFilterInput = {
  id?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  totalAmount?: ModelFloatInput | null,
  transactionId?: ModelIDInput | null,
  taxRateId?: ModelIDInput | null,
  accountingSystemId?: ModelStringInput | null,
  authUserId?: ModelIDInput | null,
  authGroups?: ModelStringInput | null,
  authGroupsRead?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTaxFilterInput | null > | null,
  or?: Array< ModelTaxFilterInput | null > | null,
  not?: ModelTaxFilterInput | null,
};

export type ModelSubscriptionOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  organizationBillingId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBillingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  subscriptionId?: ModelSubscriptionStringInput | null,
  subscriptionItemId?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBillingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBillingFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  deletedAt?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  isSSO?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  id?: ModelStringInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionUserOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  role?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserOrganizationFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionBookkeepingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  accountingSystem?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookkeepingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookkeepingFilterInput | null > | null,
  bookkeepingAccountingSystemConfigId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionBookkeepingYearFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  bookkeepingId?: ModelSubscriptionIDInput | null,
  hasNetTaxRate?: ModelSubscriptionBooleanInput | null,
  year?: ModelSubscriptionIntInput | null,
  balanceStart?: ModelSubscriptionFloatInput | null,
  balance?: ModelSubscriptionFloatInput | null,
  closedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookkeepingYearFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookkeepingYearFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUserBookkeepingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  bookkeepingId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserBookkeepingFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserBookkeepingFilterInput | null > | null,
  userBookkeepingAccountingSystemBillId?: ModelSubscriptionIDInput | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionTransactionReferenceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  userBookkeepingId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionReferenceFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionReferenceFilterInput | null > | null,
  authUserId?: ModelStringInput | null,
};

export type ModelSubscriptionAccountingSystemConfigFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  baseCurrency?: ModelSubscriptionStringInput | null,
  billingPeriod?: ModelSubscriptionStringInput | null,
  senderBankAccountId?: ModelSubscriptionIntInput | null,
  reimbursement?: ModelSubscriptionBooleanInput | null,
  bookkeepingId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountingSystemConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountingSystemConfigFilterInput | null > | null,
};

export type ModelSubscriptionAccountingSystemBillFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  supplierId?: ModelSubscriptionIntInput | null,
  contactPartnerId?: ModelSubscriptionIntInput | null,
  senderBankAccountId?: ModelSubscriptionIntInput | null,
  billingPeriod?: ModelSubscriptionStringInput | null,
  baseCurrency?: ModelSubscriptionStringInput | null,
  userBookkeepingId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountingSystemBillFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountingSystemBillFilterInput | null > | null,
  accountingSystemBillAccountingSystemReimbursementId?: ModelSubscriptionIDInput | null,
  authUserId?: ModelStringInput | null,
};

export type ModelSubscriptionAccountingSystemReimbursementFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  receiverIban?: ModelSubscriptionStringInput | null,
  receiverStreet?: ModelSubscriptionStringInput | null,
  receiverHouseNumber?: ModelSubscriptionStringInput | null,
  receiverCity?: ModelSubscriptionStringInput | null,
  receiverPostalCode?: ModelSubscriptionStringInput | null,
  receiverCountry?: ModelSubscriptionStringInput | null,
  accountingSystemBillId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountingSystemReimbursementFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountingSystemReimbursementFilterInput | null > | null,
  authUserId?: ModelStringInput | null,
};

export type ModelSubscriptionBookingAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  accountNumber?: ModelSubscriptionStringInput | null,
  sortOrder?: ModelSubscriptionIntInput | null,
  taxType?: ModelSubscriptionStringInput | null,
  transactionCategoryId?: ModelSubscriptionIDInput | null,
  accountingSystemId?: ModelSubscriptionStringInput | null,
  bookkeepingId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookingAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingAccountFilterInput | null > | null,
};

export type ModelSubscriptionTaxRateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  rate?: ModelSubscriptionFloatInput | null,
  taxType?: ModelSubscriptionStringInput | null,
  sortOrder?: ModelSubscriptionIntInput | null,
  accountingSystemId?: ModelSubscriptionStringInput | null,
  bookkeepingId?: ModelSubscriptionIDInput | null,
  dateFrom?: ModelSubscriptionStringInput | null,
  dateTo?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTaxRateFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaxRateFilterInput | null > | null,
};

export type ModelSubscriptionTransactionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionCategoryFilterInput | null > | null,
};

export type ModelSubscriptionBillFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  accountingSystemId?: ModelSubscriptionStringInput | null,
  dateFrom?: ModelSubscriptionStringInput | null,
  dateTo?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  currency?: ModelSubscriptionStringInput | null,
  baseCurrencyAmount?: ModelSubscriptionFloatInput | null,
  userBookkeepingId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBillFilterInput | null > | null,
  or?: Array< ModelSubscriptionBillFilterInput | null > | null,
  authUserId?: ModelStringInput | null,
};

export type ModelSubscriptionTransactionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  paidAt?: ModelSubscriptionStringInput | null,
  currencyCode?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  title?: ModelSubscriptionStringInput | null,
  exchangeRate?: ModelSubscriptionFloatInput | null,
  baseCurrencyAmount?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  accountingSystemId?: ModelSubscriptionStringInput | null,
  bookkeepingYearId?: ModelSubscriptionIDInput | null,
  billId?: ModelSubscriptionIDInput | null,
  bookingAccountId?: ModelSubscriptionIDInput | null,
  transactionReferenceId?: ModelSubscriptionIDInput | null,
  replacedById?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionAttachmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  key?: ModelSubscriptionStringInput | null,
  accountingSystemId?: ModelSubscriptionStringInput | null,
  accountingSystemName?: ModelSubscriptionStringInput | null,
  transactionId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  authUserId?: ModelStringInput | null,
};

export type ModelSubscriptionTaxFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  transactionId?: ModelSubscriptionIDInput | null,
  taxRateId?: ModelSubscriptionIDInput | null,
  accountingSystemId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTaxFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaxFilterInput | null > | null,
  authUserId?: ModelStringInput | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    deletedAt?: string | null,
    name: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    bookkeepings?:  {
      __typename: "ModelBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    type: OrganizationType,
    logo?: string | null,
    billing?:  {
      __typename: "Billing",
      id: string,
      deletedAt?: string | null,
      userId: string,
      customerId: string,
      subscriptionId: string,
      subscriptionItemId: string,
      quantity: number,
      status: BillingStatus,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    organizationBillingId?: string | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    deletedAt?: string | null,
    name: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    bookkeepings?:  {
      __typename: "ModelBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    type: OrganizationType,
    logo?: string | null,
    billing?:  {
      __typename: "Billing",
      id: string,
      deletedAt?: string | null,
      userId: string,
      customerId: string,
      subscriptionId: string,
      subscriptionItemId: string,
      quantity: number,
      status: BillingStatus,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    organizationBillingId?: string | null,
  } | null,
};

export type CreateBillingMutationVariables = {
  input: CreateBillingInput,
  condition?: ModelBillingConditionInput | null,
};

export type CreateBillingMutation = {
  createBilling?:  {
    __typename: "Billing",
    id: string,
    deletedAt?: string | null,
    userId: string,
    customerId: string,
    subscriptionId: string,
    subscriptionItemId: string,
    quantity: number,
    status: BillingStatus,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBillingMutationVariables = {
  input: UpdateBillingInput,
  condition?: ModelBillingConditionInput | null,
};

export type UpdateBillingMutation = {
  updateBilling?:  {
    __typename: "Billing",
    id: string,
    deletedAt?: string | null,
    userId: string,
    customerId: string,
    subscriptionId: string,
    subscriptionItemId: string,
    quantity: number,
    status: BillingStatus,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    deletedAt?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    role: UserAppRole,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    isSSO?: boolean | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    deletedAt?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    role: UserAppRole,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    isSSO?: boolean | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserOrganizationMutationVariables = {
  input: CreateUserOrganizationInput,
  condition?: ModelUserOrganizationConditionInput | null,
};

export type CreateUserOrganizationMutation = {
  createUserOrganization?:  {
    __typename: "UserOrganization",
    id: string,
    deletedAt?: string | null,
    userId: string,
    organizationId: string,
    role: UserOrganizationRole,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserOrganizationMutationVariables = {
  input: UpdateUserOrganizationInput,
  condition?: ModelUserOrganizationConditionInput | null,
};

export type UpdateUserOrganizationMutation = {
  updateUserOrganization?:  {
    __typename: "UserOrganization",
    id: string,
    deletedAt?: string | null,
    userId: string,
    organizationId: string,
    role: UserOrganizationRole,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBookkeepingMutationVariables = {
  input: CreateBookkeepingInput,
  condition?: ModelBookkeepingConditionInput | null,
};

export type CreateBookkeepingMutation = {
  createBookkeeping?:  {
    __typename: "Bookkeeping",
    id: string,
    deletedAt?: string | null,
    name: string,
    organizationId: string,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    accountingSystem: AccountingSystem,
    accountingSystemConfig?:  {
      __typename: "AccountingSystemConfig",
      id: string,
      deletedAt?: string | null,
      baseCurrency: CurrencyCode,
      billingPeriod: BillingPeriod,
      senderBankAccountId: number,
      reimbursement: boolean,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    taxRates?:  {
      __typename: "ModelTaxRateConnection",
      nextToken?: string | null,
    } | null,
    bookkeepingYears?:  {
      __typename: "ModelBookkeepingYearConnection",
      nextToken?: string | null,
    } | null,
    color: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    bookkeepingAccountingSystemConfigId?: string | null,
  } | null,
};

export type UpdateBookkeepingMutationVariables = {
  input: UpdateBookkeepingInput,
  condition?: ModelBookkeepingConditionInput | null,
};

export type UpdateBookkeepingMutation = {
  updateBookkeeping?:  {
    __typename: "Bookkeeping",
    id: string,
    deletedAt?: string | null,
    name: string,
    organizationId: string,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    accountingSystem: AccountingSystem,
    accountingSystemConfig?:  {
      __typename: "AccountingSystemConfig",
      id: string,
      deletedAt?: string | null,
      baseCurrency: CurrencyCode,
      billingPeriod: BillingPeriod,
      senderBankAccountId: number,
      reimbursement: boolean,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    taxRates?:  {
      __typename: "ModelTaxRateConnection",
      nextToken?: string | null,
    } | null,
    bookkeepingYears?:  {
      __typename: "ModelBookkeepingYearConnection",
      nextToken?: string | null,
    } | null,
    color: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    bookkeepingAccountingSystemConfigId?: string | null,
  } | null,
};

export type CreateBookkeepingYearMutationVariables = {
  input: CreateBookkeepingYearInput,
  condition?: ModelBookkeepingYearConditionInput | null,
};

export type CreateBookkeepingYearMutation = {
  createBookkeepingYear?:  {
    __typename: "BookkeepingYear",
    id: string,
    deletedAt?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    hasNetTaxRate: boolean,
    year: number,
    balanceStart?: number | null,
    balance?: number | null,
    closedAt?: string | null,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBookkeepingYearMutationVariables = {
  input: UpdateBookkeepingYearInput,
  condition?: ModelBookkeepingYearConditionInput | null,
};

export type UpdateBookkeepingYearMutation = {
  updateBookkeepingYear?:  {
    __typename: "BookkeepingYear",
    id: string,
    deletedAt?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    hasNetTaxRate: boolean,
    year: number,
    balanceStart?: number | null,
    balance?: number | null,
    closedAt?: string | null,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserBookkeepingMutationVariables = {
  input: CreateUserBookkeepingInput,
  condition?: ModelUserBookkeepingConditionInput | null,
};

export type CreateUserBookkeepingMutation = {
  createUserBookkeeping?:  {
    __typename: "UserBookkeeping",
    id: string,
    deletedAt?: string | null,
    userId: string,
    bookkeepingId: string,
    accountingSystemBill?:  {
      __typename: "AccountingSystemBill",
      id: string,
      deletedAt?: string | null,
      supplierId?: number | null,
      contactPartnerId: number,
      senderBankAccountId: number,
      billingPeriod: BillingPeriod,
      baseCurrency: CurrencyCode,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
      accountingSystemBillAccountingSystemReimbursementId?: string | null,
    } | null,
    bills?:  {
      __typename: "ModelBillConnection",
      nextToken?: string | null,
    } | null,
    transactionReferences?:  {
      __typename: "ModelTransactionReferenceConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    userBookkeepingAccountingSystemBillId?: string | null,
  } | null,
};

export type UpdateUserBookkeepingMutationVariables = {
  input: UpdateUserBookkeepingInput,
  condition?: ModelUserBookkeepingConditionInput | null,
};

export type UpdateUserBookkeepingMutation = {
  updateUserBookkeeping?:  {
    __typename: "UserBookkeeping",
    id: string,
    deletedAt?: string | null,
    userId: string,
    bookkeepingId: string,
    accountingSystemBill?:  {
      __typename: "AccountingSystemBill",
      id: string,
      deletedAt?: string | null,
      supplierId?: number | null,
      contactPartnerId: number,
      senderBankAccountId: number,
      billingPeriod: BillingPeriod,
      baseCurrency: CurrencyCode,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
      accountingSystemBillAccountingSystemReimbursementId?: string | null,
    } | null,
    bills?:  {
      __typename: "ModelBillConnection",
      nextToken?: string | null,
    } | null,
    transactionReferences?:  {
      __typename: "ModelTransactionReferenceConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    userBookkeepingAccountingSystemBillId?: string | null,
  } | null,
};

export type CreateTransactionReferenceMutationVariables = {
  input: CreateTransactionReferenceInput,
  condition?: ModelTransactionReferenceConditionInput | null,
};

export type CreateTransactionReferenceMutation = {
  createTransactionReference?:  {
    __typename: "TransactionReference",
    id: string,
    deletedAt?: string | null,
    name: string,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTransactionReferenceMutationVariables = {
  input: UpdateTransactionReferenceInput,
  condition?: ModelTransactionReferenceConditionInput | null,
};

export type UpdateTransactionReferenceMutation = {
  updateTransactionReference?:  {
    __typename: "TransactionReference",
    id: string,
    deletedAt?: string | null,
    name: string,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAccountingSystemConfigMutationVariables = {
  input: CreateAccountingSystemConfigInput,
  condition?: ModelAccountingSystemConfigConditionInput | null,
};

export type CreateAccountingSystemConfigMutation = {
  createAccountingSystemConfig?:  {
    __typename: "AccountingSystemConfig",
    id: string,
    deletedAt?: string | null,
    baseCurrency: CurrencyCode,
    billingPeriod: BillingPeriod,
    senderBankAccountId: number,
    reimbursement: boolean,
    bookkeepingId: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountingSystemConfigMutationVariables = {
  input: UpdateAccountingSystemConfigInput,
  condition?: ModelAccountingSystemConfigConditionInput | null,
};

export type UpdateAccountingSystemConfigMutation = {
  updateAccountingSystemConfig?:  {
    __typename: "AccountingSystemConfig",
    id: string,
    deletedAt?: string | null,
    baseCurrency: CurrencyCode,
    billingPeriod: BillingPeriod,
    senderBankAccountId: number,
    reimbursement: boolean,
    bookkeepingId: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAccountingSystemBillMutationVariables = {
  input: CreateAccountingSystemBillInput,
  condition?: ModelAccountingSystemBillConditionInput | null,
};

export type CreateAccountingSystemBillMutation = {
  createAccountingSystemBill?:  {
    __typename: "AccountingSystemBill",
    id: string,
    deletedAt?: string | null,
    supplierId?: number | null,
    contactPartnerId: number,
    senderBankAccountId: number,
    billingPeriod: BillingPeriod,
    baseCurrency: CurrencyCode,
    accountingSystemReimbursement?:  {
      __typename: "AccountingSystemReimbursement",
      id: string,
      deletedAt?: string | null,
      receiverIban: string,
      receiverStreet: string,
      receiverHouseNumber: string,
      receiverCity: string,
      receiverPostalCode: string,
      receiverCountry: CountryCode,
      accountingSystemBillId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
    accountingSystemBillAccountingSystemReimbursementId?: string | null,
  } | null,
};

export type UpdateAccountingSystemBillMutationVariables = {
  input: UpdateAccountingSystemBillInput,
  condition?: ModelAccountingSystemBillConditionInput | null,
};

export type UpdateAccountingSystemBillMutation = {
  updateAccountingSystemBill?:  {
    __typename: "AccountingSystemBill",
    id: string,
    deletedAt?: string | null,
    supplierId?: number | null,
    contactPartnerId: number,
    senderBankAccountId: number,
    billingPeriod: BillingPeriod,
    baseCurrency: CurrencyCode,
    accountingSystemReimbursement?:  {
      __typename: "AccountingSystemReimbursement",
      id: string,
      deletedAt?: string | null,
      receiverIban: string,
      receiverStreet: string,
      receiverHouseNumber: string,
      receiverCity: string,
      receiverPostalCode: string,
      receiverCountry: CountryCode,
      accountingSystemBillId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
    accountingSystemBillAccountingSystemReimbursementId?: string | null,
  } | null,
};

export type CreateAccountingSystemReimbursementMutationVariables = {
  input: CreateAccountingSystemReimbursementInput,
  condition?: ModelAccountingSystemReimbursementConditionInput | null,
};

export type CreateAccountingSystemReimbursementMutation = {
  createAccountingSystemReimbursement?:  {
    __typename: "AccountingSystemReimbursement",
    id: string,
    deletedAt?: string | null,
    receiverIban: string,
    receiverStreet: string,
    receiverHouseNumber: string,
    receiverCity: string,
    receiverPostalCode: string,
    receiverCountry: CountryCode,
    accountingSystemBillId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountingSystemReimbursementMutationVariables = {
  input: UpdateAccountingSystemReimbursementInput,
  condition?: ModelAccountingSystemReimbursementConditionInput | null,
};

export type UpdateAccountingSystemReimbursementMutation = {
  updateAccountingSystemReimbursement?:  {
    __typename: "AccountingSystemReimbursement",
    id: string,
    deletedAt?: string | null,
    receiverIban: string,
    receiverStreet: string,
    receiverHouseNumber: string,
    receiverCity: string,
    receiverPostalCode: string,
    receiverCountry: CountryCode,
    accountingSystemBillId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBookingAccountMutationVariables = {
  input: CreateBookingAccountInput,
  condition?: ModelBookingAccountConditionInput | null,
};

export type CreateBookingAccountMutation = {
  createBookingAccount?:  {
    __typename: "BookingAccount",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountNumber: string,
    sortOrder: number,
    taxType: TaxType,
    transactionCategory:  {
      __typename: "TransactionCategory",
      id: string,
      deletedAt?: string | null,
      name: string,
      icon: string,
      createdAt: string,
      updatedAt: string,
    },
    transactionCategoryId: string,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBookingAccountMutationVariables = {
  input: UpdateBookingAccountInput,
  condition?: ModelBookingAccountConditionInput | null,
};

export type UpdateBookingAccountMutation = {
  updateBookingAccount?:  {
    __typename: "BookingAccount",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountNumber: string,
    sortOrder: number,
    taxType: TaxType,
    transactionCategory:  {
      __typename: "TransactionCategory",
      id: string,
      deletedAt?: string | null,
      name: string,
      icon: string,
      createdAt: string,
      updatedAt: string,
    },
    transactionCategoryId: string,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTaxRateMutationVariables = {
  input: CreateTaxRateInput,
  condition?: ModelTaxRateConditionInput | null,
};

export type CreateTaxRateMutation = {
  createTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    deletedAt?: string | null,
    code: string,
    rate: number,
    taxType: TaxType,
    sortOrder: number,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    dateFrom: string,
    dateTo?: string | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTaxRateMutationVariables = {
  input: UpdateTaxRateInput,
  condition?: ModelTaxRateConditionInput | null,
};

export type UpdateTaxRateMutation = {
  updateTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    deletedAt?: string | null,
    code: string,
    rate: number,
    taxType: TaxType,
    sortOrder: number,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    dateFrom: string,
    dateTo?: string | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTransactionCategoryMutationVariables = {
  input: CreateTransactionCategoryInput,
  condition?: ModelTransactionCategoryConditionInput | null,
};

export type CreateTransactionCategoryMutation = {
  createTransactionCategory?:  {
    __typename: "TransactionCategory",
    id: string,
    deletedAt?: string | null,
    name: string,
    icon: string,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTransactionCategoryMutationVariables = {
  input: UpdateTransactionCategoryInput,
  condition?: ModelTransactionCategoryConditionInput | null,
};

export type UpdateTransactionCategoryMutation = {
  updateTransactionCategory?:  {
    __typename: "TransactionCategory",
    id: string,
    deletedAt?: string | null,
    name: string,
    icon: string,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTransactionCategoryMutationVariables = {
  input: DeleteTransactionCategoryInput,
  condition?: ModelTransactionCategoryConditionInput | null,
};

export type DeleteTransactionCategoryMutation = {
  deleteTransactionCategory?:  {
    __typename: "TransactionCategory",
    id: string,
    deletedAt?: string | null,
    name: string,
    icon: string,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBillMutationVariables = {
  input: CreateBillInput,
  condition?: ModelBillConditionInput | null,
};

export type CreateBillMutation = {
  createBill?:  {
    __typename: "Bill",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountingSystemId?: string | null,
    dateFrom: string,
    dateTo?: string | null,
    status: BillStatus,
    amount: number,
    currency: CurrencyCode,
    baseCurrencyAmount?: number | null,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBillMutationVariables = {
  input: UpdateBillInput,
  condition?: ModelBillConditionInput | null,
};

export type UpdateBillMutation = {
  updateBill?:  {
    __typename: "Bill",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountingSystemId?: string | null,
    dateFrom: string,
    dateTo?: string | null,
    status: BillStatus,
    amount: number,
    currency: CurrencyCode,
    baseCurrencyAmount?: number | null,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTransactionMutationVariables = {
  input: CreateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type CreateTransactionMutation = {
  createTransaction?:  {
    __typename: "Transaction",
    id: string,
    deletedAt?: string | null,
    paidAt: string,
    currencyCode: CurrencyCode,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    amount: number,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    title: string,
    exchangeRate?: number | null,
    baseCurrencyAmount?: number | null,
    status: TransactionStatus,
    accountingSystemId?: string | null,
    bookkeepingYear:  {
      __typename: "BookkeepingYear",
      id: string,
      deletedAt?: string | null,
      bookkeepingId: string,
      hasNetTaxRate: boolean,
      year: number,
      balanceStart?: number | null,
      balance?: number | null,
      closedAt?: string | null,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookkeepingYearId: string,
    bill?:  {
      __typename: "Bill",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountingSystemId?: string | null,
      dateFrom: string,
      dateTo?: string | null,
      status: BillStatus,
      amount: number,
      currency: CurrencyCode,
      baseCurrencyAmount?: number | null,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    billId?: string | null,
    bookingAccount:  {
      __typename: "BookingAccount",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountNumber: string,
      sortOrder: number,
      taxType: TaxType,
      transactionCategoryId: string,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookingAccountId: string,
    transactionReference?:  {
      __typename: "TransactionReference",
      id: string,
      deletedAt?: string | null,
      name: string,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionReferenceId?: string | null,
    user:  {
      __typename: "User",
      id: string,
      deletedAt?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      role: UserAppRole,
      isSSO?: boolean | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    replacedById?: string | null,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTransactionMutationVariables = {
  input: UpdateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type UpdateTransactionMutation = {
  updateTransaction?:  {
    __typename: "Transaction",
    id: string,
    deletedAt?: string | null,
    paidAt: string,
    currencyCode: CurrencyCode,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    amount: number,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    title: string,
    exchangeRate?: number | null,
    baseCurrencyAmount?: number | null,
    status: TransactionStatus,
    accountingSystemId?: string | null,
    bookkeepingYear:  {
      __typename: "BookkeepingYear",
      id: string,
      deletedAt?: string | null,
      bookkeepingId: string,
      hasNetTaxRate: boolean,
      year: number,
      balanceStart?: number | null,
      balance?: number | null,
      closedAt?: string | null,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookkeepingYearId: string,
    bill?:  {
      __typename: "Bill",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountingSystemId?: string | null,
      dateFrom: string,
      dateTo?: string | null,
      status: BillStatus,
      amount: number,
      currency: CurrencyCode,
      baseCurrencyAmount?: number | null,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    billId?: string | null,
    bookingAccount:  {
      __typename: "BookingAccount",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountNumber: string,
      sortOrder: number,
      taxType: TaxType,
      transactionCategoryId: string,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookingAccountId: string,
    transactionReference?:  {
      __typename: "TransactionReference",
      id: string,
      deletedAt?: string | null,
      name: string,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionReferenceId?: string | null,
    user:  {
      __typename: "User",
      id: string,
      deletedAt?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      role: UserAppRole,
      isSSO?: boolean | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    replacedById?: string | null,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAttachmentMutationVariables = {
  input: CreateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type CreateAttachmentMutation = {
  createAttachment?:  {
    __typename: "Attachment",
    id: string,
    deletedAt?: string | null,
    key: string,
    accountingSystemId?: string | null,
    accountingSystemName?: string | null,
    transactionId: string,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAttachmentMutationVariables = {
  input: UpdateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type UpdateAttachmentMutation = {
  updateAttachment?:  {
    __typename: "Attachment",
    id: string,
    deletedAt?: string | null,
    key: string,
    accountingSystemId?: string | null,
    accountingSystemName?: string | null,
    transactionId: string,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTaxMutationVariables = {
  input: CreateTaxInput,
  condition?: ModelTaxConditionInput | null,
};

export type CreateTaxMutation = {
  createTax?:  {
    __typename: "Tax",
    id: string,
    deletedAt?: string | null,
    amount?: number | null,
    totalAmount?: number | null,
    transactionId: string,
    taxRate:  {
      __typename: "TaxRate",
      id: string,
      deletedAt?: string | null,
      code: string,
      rate: number,
      taxType: TaxType,
      sortOrder: number,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      dateFrom: string,
      dateTo?: string | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    taxRateId: string,
    accountingSystemId?: string | null,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTaxMutationVariables = {
  input: UpdateTaxInput,
  condition?: ModelTaxConditionInput | null,
};

export type UpdateTaxMutation = {
  updateTax?:  {
    __typename: "Tax",
    id: string,
    deletedAt?: string | null,
    amount?: number | null,
    totalAmount?: number | null,
    transactionId: string,
    taxRate:  {
      __typename: "TaxRate",
      id: string,
      deletedAt?: string | null,
      code: string,
      rate: number,
      taxType: TaxType,
      sortOrder: number,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      dateFrom: string,
      dateTo?: string | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    taxRateId: string,
    accountingSystemId?: string | null,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    deletedAt?: string | null,
    name: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    bookkeepings?:  {
      __typename: "ModelBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    type: OrganizationType,
    logo?: string | null,
    billing?:  {
      __typename: "Billing",
      id: string,
      deletedAt?: string | null,
      userId: string,
      customerId: string,
      subscriptionId: string,
      subscriptionItemId: string,
      quantity: number,
      status: BillingStatus,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    organizationBillingId?: string | null,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      deletedAt?: string | null,
      name: string,
      type: OrganizationType,
      logo?: string | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      organizationBillingId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBillingQueryVariables = {
  id: string,
};

export type GetBillingQuery = {
  getBilling?:  {
    __typename: "Billing",
    id: string,
    deletedAt?: string | null,
    userId: string,
    customerId: string,
    subscriptionId: string,
    subscriptionItemId: string,
    quantity: number,
    status: BillingStatus,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBillingsQueryVariables = {
  filter?: ModelBillingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBillingsQuery = {
  listBillings?:  {
    __typename: "ModelBillingConnection",
    items:  Array< {
      __typename: "Billing",
      id: string,
      deletedAt?: string | null,
      userId: string,
      customerId: string,
      subscriptionId: string,
      subscriptionItemId: string,
      quantity: number,
      status: BillingStatus,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BillingsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBillingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BillingsByUserIdQuery = {
  billingsByUserId?:  {
    __typename: "ModelBillingConnection",
    items:  Array< {
      __typename: "Billing",
      id: string,
      deletedAt?: string | null,
      userId: string,
      customerId: string,
      subscriptionId: string,
      subscriptionItemId: string,
      quantity: number,
      status: BillingStatus,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    deletedAt?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    role: UserAppRole,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    isSSO?: boolean | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      deletedAt?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      role: UserAppRole,
      isSSO?: boolean | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserOrganizationQueryVariables = {
  id: string,
};

export type GetUserOrganizationQuery = {
  getUserOrganization?:  {
    __typename: "UserOrganization",
    id: string,
    deletedAt?: string | null,
    userId: string,
    organizationId: string,
    role: UserOrganizationRole,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserOrganizationsQueryVariables = {
  filter?: ModelUserOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserOrganizationsQuery = {
  listUserOrganizations?:  {
    __typename: "ModelUserOrganizationConnection",
    items:  Array< {
      __typename: "UserOrganization",
      id: string,
      deletedAt?: string | null,
      userId: string,
      organizationId: string,
      role: UserOrganizationRole,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserOrganizationsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserOrganizationsByUserIdQuery = {
  userOrganizationsByUserId?:  {
    __typename: "ModelUserOrganizationConnection",
    items:  Array< {
      __typename: "UserOrganization",
      id: string,
      deletedAt?: string | null,
      userId: string,
      organizationId: string,
      role: UserOrganizationRole,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserOrganizationsByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserOrganizationsByOrganizationIdQuery = {
  userOrganizationsByOrganizationId?:  {
    __typename: "ModelUserOrganizationConnection",
    items:  Array< {
      __typename: "UserOrganization",
      id: string,
      deletedAt?: string | null,
      userId: string,
      organizationId: string,
      role: UserOrganizationRole,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookkeepingQueryVariables = {
  id: string,
};

export type GetBookkeepingQuery = {
  getBookkeeping?:  {
    __typename: "Bookkeeping",
    id: string,
    deletedAt?: string | null,
    name: string,
    organizationId: string,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    accountingSystem: AccountingSystem,
    accountingSystemConfig?:  {
      __typename: "AccountingSystemConfig",
      id: string,
      deletedAt?: string | null,
      baseCurrency: CurrencyCode,
      billingPeriod: BillingPeriod,
      senderBankAccountId: number,
      reimbursement: boolean,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    taxRates?:  {
      __typename: "ModelTaxRateConnection",
      nextToken?: string | null,
    } | null,
    bookkeepingYears?:  {
      __typename: "ModelBookkeepingYearConnection",
      nextToken?: string | null,
    } | null,
    color: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    bookkeepingAccountingSystemConfigId?: string | null,
  } | null,
};

export type ListBookkeepingsQueryVariables = {
  filter?: ModelBookkeepingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookkeepingsQuery = {
  listBookkeepings?:  {
    __typename: "ModelBookkeepingConnection",
    items:  Array< {
      __typename: "Bookkeeping",
      id: string,
      deletedAt?: string | null,
      name: string,
      organizationId: string,
      accountingSystem: AccountingSystem,
      color: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      bookkeepingAccountingSystemConfigId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookkeepingsByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookkeepingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookkeepingsByOrganizationIdQuery = {
  bookkeepingsByOrganizationId?:  {
    __typename: "ModelBookkeepingConnection",
    items:  Array< {
      __typename: "Bookkeeping",
      id: string,
      deletedAt?: string | null,
      name: string,
      organizationId: string,
      accountingSystem: AccountingSystem,
      color: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      bookkeepingAccountingSystemConfigId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookkeepingYearQueryVariables = {
  id: string,
};

export type GetBookkeepingYearQuery = {
  getBookkeepingYear?:  {
    __typename: "BookkeepingYear",
    id: string,
    deletedAt?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    hasNetTaxRate: boolean,
    year: number,
    balanceStart?: number | null,
    balance?: number | null,
    closedAt?: string | null,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBookkeepingYearsQueryVariables = {
  filter?: ModelBookkeepingYearFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookkeepingYearsQuery = {
  listBookkeepingYears?:  {
    __typename: "ModelBookkeepingYearConnection",
    items:  Array< {
      __typename: "BookkeepingYear",
      id: string,
      deletedAt?: string | null,
      bookkeepingId: string,
      hasNetTaxRate: boolean,
      year: number,
      balanceStart?: number | null,
      balance?: number | null,
      closedAt?: string | null,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookkeepingYearsByBookkeepingIdQueryVariables = {
  bookkeepingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookkeepingYearFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookkeepingYearsByBookkeepingIdQuery = {
  bookkeepingYearsByBookkeepingId?:  {
    __typename: "ModelBookkeepingYearConnection",
    items:  Array< {
      __typename: "BookkeepingYear",
      id: string,
      deletedAt?: string | null,
      bookkeepingId: string,
      hasNetTaxRate: boolean,
      year: number,
      balanceStart?: number | null,
      balance?: number | null,
      closedAt?: string | null,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserBookkeepingQueryVariables = {
  id: string,
};

export type GetUserBookkeepingQuery = {
  getUserBookkeeping?:  {
    __typename: "UserBookkeeping",
    id: string,
    deletedAt?: string | null,
    userId: string,
    bookkeepingId: string,
    accountingSystemBill?:  {
      __typename: "AccountingSystemBill",
      id: string,
      deletedAt?: string | null,
      supplierId?: number | null,
      contactPartnerId: number,
      senderBankAccountId: number,
      billingPeriod: BillingPeriod,
      baseCurrency: CurrencyCode,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
      accountingSystemBillAccountingSystemReimbursementId?: string | null,
    } | null,
    bills?:  {
      __typename: "ModelBillConnection",
      nextToken?: string | null,
    } | null,
    transactionReferences?:  {
      __typename: "ModelTransactionReferenceConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    userBookkeepingAccountingSystemBillId?: string | null,
  } | null,
};

export type ListUserBookkeepingsQueryVariables = {
  filter?: ModelUserBookkeepingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserBookkeepingsQuery = {
  listUserBookkeepings?:  {
    __typename: "ModelUserBookkeepingConnection",
    items:  Array< {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserBookkeepingsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserBookkeepingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserBookkeepingsByUserIdQuery = {
  userBookkeepingsByUserId?:  {
    __typename: "ModelUserBookkeepingConnection",
    items:  Array< {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserBookkeepingsByBookkeepingIdQueryVariables = {
  bookkeepingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserBookkeepingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserBookkeepingsByBookkeepingIdQuery = {
  userBookkeepingsByBookkeepingId?:  {
    __typename: "ModelUserBookkeepingConnection",
    items:  Array< {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionReferenceQueryVariables = {
  id: string,
};

export type GetTransactionReferenceQuery = {
  getTransactionReference?:  {
    __typename: "TransactionReference",
    id: string,
    deletedAt?: string | null,
    name: string,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTransactionReferencesQueryVariables = {
  filter?: ModelTransactionReferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionReferencesQuery = {
  listTransactionReferences?:  {
    __typename: "ModelTransactionReferenceConnection",
    items:  Array< {
      __typename: "TransactionReference",
      id: string,
      deletedAt?: string | null,
      name: string,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionReferencesByUserBookkeepingIdQueryVariables = {
  userBookkeepingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionReferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionReferencesByUserBookkeepingIdQuery = {
  transactionReferencesByUserBookkeepingId?:  {
    __typename: "ModelTransactionReferenceConnection",
    items:  Array< {
      __typename: "TransactionReference",
      id: string,
      deletedAt?: string | null,
      name: string,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionReferencesByAuthUserIdQueryVariables = {
  authUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionReferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionReferencesByAuthUserIdQuery = {
  transactionReferencesByAuthUserId?:  {
    __typename: "ModelTransactionReferenceConnection",
    items:  Array< {
      __typename: "TransactionReference",
      id: string,
      deletedAt?: string | null,
      name: string,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountingSystemConfigQueryVariables = {
  id: string,
};

export type GetAccountingSystemConfigQuery = {
  getAccountingSystemConfig?:  {
    __typename: "AccountingSystemConfig",
    id: string,
    deletedAt?: string | null,
    baseCurrency: CurrencyCode,
    billingPeriod: BillingPeriod,
    senderBankAccountId: number,
    reimbursement: boolean,
    bookkeepingId: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountingSystemConfigsQueryVariables = {
  filter?: ModelAccountingSystemConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountingSystemConfigsQuery = {
  listAccountingSystemConfigs?:  {
    __typename: "ModelAccountingSystemConfigConnection",
    items:  Array< {
      __typename: "AccountingSystemConfig",
      id: string,
      deletedAt?: string | null,
      baseCurrency: CurrencyCode,
      billingPeriod: BillingPeriod,
      senderBankAccountId: number,
      reimbursement: boolean,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AccountingSystemConfigsByBookkeepingIdQueryVariables = {
  bookkeepingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountingSystemConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountingSystemConfigsByBookkeepingIdQuery = {
  accountingSystemConfigsByBookkeepingId?:  {
    __typename: "ModelAccountingSystemConfigConnection",
    items:  Array< {
      __typename: "AccountingSystemConfig",
      id: string,
      deletedAt?: string | null,
      baseCurrency: CurrencyCode,
      billingPeriod: BillingPeriod,
      senderBankAccountId: number,
      reimbursement: boolean,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountingSystemBillQueryVariables = {
  id: string,
};

export type GetAccountingSystemBillQuery = {
  getAccountingSystemBill?:  {
    __typename: "AccountingSystemBill",
    id: string,
    deletedAt?: string | null,
    supplierId?: number | null,
    contactPartnerId: number,
    senderBankAccountId: number,
    billingPeriod: BillingPeriod,
    baseCurrency: CurrencyCode,
    accountingSystemReimbursement?:  {
      __typename: "AccountingSystemReimbursement",
      id: string,
      deletedAt?: string | null,
      receiverIban: string,
      receiverStreet: string,
      receiverHouseNumber: string,
      receiverCity: string,
      receiverPostalCode: string,
      receiverCountry: CountryCode,
      accountingSystemBillId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
    accountingSystemBillAccountingSystemReimbursementId?: string | null,
  } | null,
};

export type ListAccountingSystemBillsQueryVariables = {
  filter?: ModelAccountingSystemBillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountingSystemBillsQuery = {
  listAccountingSystemBills?:  {
    __typename: "ModelAccountingSystemBillConnection",
    items:  Array< {
      __typename: "AccountingSystemBill",
      id: string,
      deletedAt?: string | null,
      supplierId?: number | null,
      contactPartnerId: number,
      senderBankAccountId: number,
      billingPeriod: BillingPeriod,
      baseCurrency: CurrencyCode,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
      accountingSystemBillAccountingSystemReimbursementId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AccountingSystemBillsByUserBookkeepingIdQueryVariables = {
  userBookkeepingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountingSystemBillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountingSystemBillsByUserBookkeepingIdQuery = {
  accountingSystemBillsByUserBookkeepingId?:  {
    __typename: "ModelAccountingSystemBillConnection",
    items:  Array< {
      __typename: "AccountingSystemBill",
      id: string,
      deletedAt?: string | null,
      supplierId?: number | null,
      contactPartnerId: number,
      senderBankAccountId: number,
      billingPeriod: BillingPeriod,
      baseCurrency: CurrencyCode,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
      accountingSystemBillAccountingSystemReimbursementId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AccountingSystemBillsByAuthUserIdQueryVariables = {
  authUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountingSystemBillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountingSystemBillsByAuthUserIdQuery = {
  accountingSystemBillsByAuthUserId?:  {
    __typename: "ModelAccountingSystemBillConnection",
    items:  Array< {
      __typename: "AccountingSystemBill",
      id: string,
      deletedAt?: string | null,
      supplierId?: number | null,
      contactPartnerId: number,
      senderBankAccountId: number,
      billingPeriod: BillingPeriod,
      baseCurrency: CurrencyCode,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
      accountingSystemBillAccountingSystemReimbursementId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountingSystemReimbursementQueryVariables = {
  id: string,
};

export type GetAccountingSystemReimbursementQuery = {
  getAccountingSystemReimbursement?:  {
    __typename: "AccountingSystemReimbursement",
    id: string,
    deletedAt?: string | null,
    receiverIban: string,
    receiverStreet: string,
    receiverHouseNumber: string,
    receiverCity: string,
    receiverPostalCode: string,
    receiverCountry: CountryCode,
    accountingSystemBillId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountingSystemReimbursementsQueryVariables = {
  filter?: ModelAccountingSystemReimbursementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountingSystemReimbursementsQuery = {
  listAccountingSystemReimbursements?:  {
    __typename: "ModelAccountingSystemReimbursementConnection",
    items:  Array< {
      __typename: "AccountingSystemReimbursement",
      id: string,
      deletedAt?: string | null,
      receiverIban: string,
      receiverStreet: string,
      receiverHouseNumber: string,
      receiverCity: string,
      receiverPostalCode: string,
      receiverCountry: CountryCode,
      accountingSystemBillId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AccountingSystemReimbursementsByAccountingSystemBillIdQueryVariables = {
  accountingSystemBillId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountingSystemReimbursementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountingSystemReimbursementsByAccountingSystemBillIdQuery = {
  accountingSystemReimbursementsByAccountingSystemBillId?:  {
    __typename: "ModelAccountingSystemReimbursementConnection",
    items:  Array< {
      __typename: "AccountingSystemReimbursement",
      id: string,
      deletedAt?: string | null,
      receiverIban: string,
      receiverStreet: string,
      receiverHouseNumber: string,
      receiverCity: string,
      receiverPostalCode: string,
      receiverCountry: CountryCode,
      accountingSystemBillId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AccountingSystemReimbursementsByAuthUserIdQueryVariables = {
  authUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountingSystemReimbursementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountingSystemReimbursementsByAuthUserIdQuery = {
  accountingSystemReimbursementsByAuthUserId?:  {
    __typename: "ModelAccountingSystemReimbursementConnection",
    items:  Array< {
      __typename: "AccountingSystemReimbursement",
      id: string,
      deletedAt?: string | null,
      receiverIban: string,
      receiverStreet: string,
      receiverHouseNumber: string,
      receiverCity: string,
      receiverPostalCode: string,
      receiverCountry: CountryCode,
      accountingSystemBillId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookingAccountQueryVariables = {
  id: string,
};

export type GetBookingAccountQuery = {
  getBookingAccount?:  {
    __typename: "BookingAccount",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountNumber: string,
    sortOrder: number,
    taxType: TaxType,
    transactionCategory:  {
      __typename: "TransactionCategory",
      id: string,
      deletedAt?: string | null,
      name: string,
      icon: string,
      createdAt: string,
      updatedAt: string,
    },
    transactionCategoryId: string,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBookingAccountsQueryVariables = {
  filter?: ModelBookingAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingAccountsQuery = {
  listBookingAccounts?:  {
    __typename: "ModelBookingAccountConnection",
    items:  Array< {
      __typename: "BookingAccount",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountNumber: string,
      sortOrder: number,
      taxType: TaxType,
      transactionCategoryId: string,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingAccountsByTransactionCategoryIdQueryVariables = {
  transactionCategoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingAccountsByTransactionCategoryIdQuery = {
  bookingAccountsByTransactionCategoryId?:  {
    __typename: "ModelBookingAccountConnection",
    items:  Array< {
      __typename: "BookingAccount",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountNumber: string,
      sortOrder: number,
      taxType: TaxType,
      transactionCategoryId: string,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingAccountsByBookkeepingIdQueryVariables = {
  bookkeepingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingAccountsByBookkeepingIdQuery = {
  bookingAccountsByBookkeepingId?:  {
    __typename: "ModelBookingAccountConnection",
    items:  Array< {
      __typename: "BookingAccount",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountNumber: string,
      sortOrder: number,
      taxType: TaxType,
      transactionCategoryId: string,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaxRateQueryVariables = {
  id: string,
};

export type GetTaxRateQuery = {
  getTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    deletedAt?: string | null,
    code: string,
    rate: number,
    taxType: TaxType,
    sortOrder: number,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    dateFrom: string,
    dateTo?: string | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTaxRatesQueryVariables = {
  filter?: ModelTaxRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaxRatesQuery = {
  listTaxRates?:  {
    __typename: "ModelTaxRateConnection",
    items:  Array< {
      __typename: "TaxRate",
      id: string,
      deletedAt?: string | null,
      code: string,
      rate: number,
      taxType: TaxType,
      sortOrder: number,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      dateFrom: string,
      dateTo?: string | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TaxRatesByBookkeepingIdQueryVariables = {
  bookkeepingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaxRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TaxRatesByBookkeepingIdQuery = {
  taxRatesByBookkeepingId?:  {
    __typename: "ModelTaxRateConnection",
    items:  Array< {
      __typename: "TaxRate",
      id: string,
      deletedAt?: string | null,
      code: string,
      rate: number,
      taxType: TaxType,
      sortOrder: number,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      dateFrom: string,
      dateTo?: string | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionCategoryQueryVariables = {
  id: string,
};

export type GetTransactionCategoryQuery = {
  getTransactionCategory?:  {
    __typename: "TransactionCategory",
    id: string,
    deletedAt?: string | null,
    name: string,
    icon: string,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTransactionCategoriesQueryVariables = {
  filter?: ModelTransactionCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionCategoriesQuery = {
  listTransactionCategories?:  {
    __typename: "ModelTransactionCategoryConnection",
    items:  Array< {
      __typename: "TransactionCategory",
      id: string,
      deletedAt?: string | null,
      name: string,
      icon: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBillQueryVariables = {
  id: string,
};

export type GetBillQuery = {
  getBill?:  {
    __typename: "Bill",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountingSystemId?: string | null,
    dateFrom: string,
    dateTo?: string | null,
    status: BillStatus,
    amount: number,
    currency: CurrencyCode,
    baseCurrencyAmount?: number | null,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBillsQueryVariables = {
  filter?: ModelBillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBillsQuery = {
  listBills?:  {
    __typename: "ModelBillConnection",
    items:  Array< {
      __typename: "Bill",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountingSystemId?: string | null,
      dateFrom: string,
      dateTo?: string | null,
      status: BillStatus,
      amount: number,
      currency: CurrencyCode,
      baseCurrencyAmount?: number | null,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BillsByUserBookkeepingIdQueryVariables = {
  userBookkeepingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BillsByUserBookkeepingIdQuery = {
  billsByUserBookkeepingId?:  {
    __typename: "ModelBillConnection",
    items:  Array< {
      __typename: "Bill",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountingSystemId?: string | null,
      dateFrom: string,
      dateTo?: string | null,
      status: BillStatus,
      amount: number,
      currency: CurrencyCode,
      baseCurrencyAmount?: number | null,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BillsByAuthUserIdQueryVariables = {
  authUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BillsByAuthUserIdQuery = {
  billsByAuthUserId?:  {
    __typename: "ModelBillConnection",
    items:  Array< {
      __typename: "Bill",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountingSystemId?: string | null,
      dateFrom: string,
      dateTo?: string | null,
      status: BillStatus,
      amount: number,
      currency: CurrencyCode,
      baseCurrencyAmount?: number | null,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionQueryVariables = {
  id: string,
};

export type GetTransactionQuery = {
  getTransaction?:  {
    __typename: "Transaction",
    id: string,
    deletedAt?: string | null,
    paidAt: string,
    currencyCode: CurrencyCode,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    amount: number,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    title: string,
    exchangeRate?: number | null,
    baseCurrencyAmount?: number | null,
    status: TransactionStatus,
    accountingSystemId?: string | null,
    bookkeepingYear:  {
      __typename: "BookkeepingYear",
      id: string,
      deletedAt?: string | null,
      bookkeepingId: string,
      hasNetTaxRate: boolean,
      year: number,
      balanceStart?: number | null,
      balance?: number | null,
      closedAt?: string | null,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookkeepingYearId: string,
    bill?:  {
      __typename: "Bill",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountingSystemId?: string | null,
      dateFrom: string,
      dateTo?: string | null,
      status: BillStatus,
      amount: number,
      currency: CurrencyCode,
      baseCurrencyAmount?: number | null,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    billId?: string | null,
    bookingAccount:  {
      __typename: "BookingAccount",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountNumber: string,
      sortOrder: number,
      taxType: TaxType,
      transactionCategoryId: string,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookingAccountId: string,
    transactionReference?:  {
      __typename: "TransactionReference",
      id: string,
      deletedAt?: string | null,
      name: string,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionReferenceId?: string | null,
    user:  {
      __typename: "User",
      id: string,
      deletedAt?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      role: UserAppRole,
      isSSO?: boolean | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    replacedById?: string | null,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionsQuery = {
  listTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      deletedAt?: string | null,
      paidAt: string,
      currencyCode: CurrencyCode,
      amount: number,
      title: string,
      exchangeRate?: number | null,
      baseCurrencyAmount?: number | null,
      status: TransactionStatus,
      accountingSystemId?: string | null,
      bookkeepingYearId: string,
      billId?: string | null,
      bookingAccountId: string,
      transactionReferenceId?: string | null,
      userId: string,
      replacedById?: string | null,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByBookkeepingYearIdQueryVariables = {
  bookkeepingYearId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByBookkeepingYearIdQuery = {
  transactionsByBookkeepingYearId?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      deletedAt?: string | null,
      paidAt: string,
      currencyCode: CurrencyCode,
      amount: number,
      title: string,
      exchangeRate?: number | null,
      baseCurrencyAmount?: number | null,
      status: TransactionStatus,
      accountingSystemId?: string | null,
      bookkeepingYearId: string,
      billId?: string | null,
      bookingAccountId: string,
      transactionReferenceId?: string | null,
      userId: string,
      replacedById?: string | null,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByBillIdQueryVariables = {
  billId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByBillIdQuery = {
  transactionsByBillId?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      deletedAt?: string | null,
      paidAt: string,
      currencyCode: CurrencyCode,
      amount: number,
      title: string,
      exchangeRate?: number | null,
      baseCurrencyAmount?: number | null,
      status: TransactionStatus,
      accountingSystemId?: string | null,
      bookkeepingYearId: string,
      billId?: string | null,
      bookingAccountId: string,
      transactionReferenceId?: string | null,
      userId: string,
      replacedById?: string | null,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByBookingAccountIdQueryVariables = {
  bookingAccountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByBookingAccountIdQuery = {
  transactionsByBookingAccountId?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      deletedAt?: string | null,
      paidAt: string,
      currencyCode: CurrencyCode,
      amount: number,
      title: string,
      exchangeRate?: number | null,
      baseCurrencyAmount?: number | null,
      status: TransactionStatus,
      accountingSystemId?: string | null,
      bookkeepingYearId: string,
      billId?: string | null,
      bookingAccountId: string,
      transactionReferenceId?: string | null,
      userId: string,
      replacedById?: string | null,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByTransactionReferenceIdQueryVariables = {
  transactionReferenceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByTransactionReferenceIdQuery = {
  transactionsByTransactionReferenceId?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      deletedAt?: string | null,
      paidAt: string,
      currencyCode: CurrencyCode,
      amount: number,
      title: string,
      exchangeRate?: number | null,
      baseCurrencyAmount?: number | null,
      status: TransactionStatus,
      accountingSystemId?: string | null,
      bookkeepingYearId: string,
      billId?: string | null,
      bookingAccountId: string,
      transactionReferenceId?: string | null,
      userId: string,
      replacedById?: string | null,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByUserIdQuery = {
  transactionsByUserId?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      deletedAt?: string | null,
      paidAt: string,
      currencyCode: CurrencyCode,
      amount: number,
      title: string,
      exchangeRate?: number | null,
      baseCurrencyAmount?: number | null,
      status: TransactionStatus,
      accountingSystemId?: string | null,
      bookkeepingYearId: string,
      billId?: string | null,
      bookingAccountId: string,
      transactionReferenceId?: string | null,
      userId: string,
      replacedById?: string | null,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransactionsByReplacedByIdQueryVariables = {
  replacedById: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByReplacedByIdQuery = {
  transactionsByReplacedById?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      deletedAt?: string | null,
      paidAt: string,
      currencyCode: CurrencyCode,
      amount: number,
      title: string,
      exchangeRate?: number | null,
      baseCurrencyAmount?: number | null,
      status: TransactionStatus,
      accountingSystemId?: string | null,
      bookkeepingYearId: string,
      billId?: string | null,
      bookingAccountId: string,
      transactionReferenceId?: string | null,
      userId: string,
      replacedById?: string | null,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAttachmentQueryVariables = {
  id: string,
};

export type GetAttachmentQuery = {
  getAttachment?:  {
    __typename: "Attachment",
    id: string,
    deletedAt?: string | null,
    key: string,
    accountingSystemId?: string | null,
    accountingSystemName?: string | null,
    transactionId: string,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsQuery = {
  listAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      deletedAt?: string | null,
      key: string,
      accountingSystemId?: string | null,
      accountingSystemName?: string | null,
      transactionId: string,
      authUserId: string,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AttachmentsByTransactionIdQueryVariables = {
  transactionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttachmentsByTransactionIdQuery = {
  attachmentsByTransactionId?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      deletedAt?: string | null,
      key: string,
      accountingSystemId?: string | null,
      accountingSystemName?: string | null,
      transactionId: string,
      authUserId: string,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AttachmentsByAuthUserIdQueryVariables = {
  authUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttachmentsByAuthUserIdQuery = {
  attachmentsByAuthUserId?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      deletedAt?: string | null,
      key: string,
      accountingSystemId?: string | null,
      accountingSystemName?: string | null,
      transactionId: string,
      authUserId: string,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaxQueryVariables = {
  id: string,
};

export type GetTaxQuery = {
  getTax?:  {
    __typename: "Tax",
    id: string,
    deletedAt?: string | null,
    amount?: number | null,
    totalAmount?: number | null,
    transactionId: string,
    taxRate:  {
      __typename: "TaxRate",
      id: string,
      deletedAt?: string | null,
      code: string,
      rate: number,
      taxType: TaxType,
      sortOrder: number,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      dateFrom: string,
      dateTo?: string | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    taxRateId: string,
    accountingSystemId?: string | null,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTaxesQueryVariables = {
  filter?: ModelTaxFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaxesQuery = {
  listTaxes?:  {
    __typename: "ModelTaxConnection",
    items:  Array< {
      __typename: "Tax",
      id: string,
      deletedAt?: string | null,
      amount?: number | null,
      totalAmount?: number | null,
      transactionId: string,
      taxRateId: string,
      accountingSystemId?: string | null,
      authUserId: string,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TaxesByTransactionIdQueryVariables = {
  transactionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaxFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TaxesByTransactionIdQuery = {
  taxesByTransactionId?:  {
    __typename: "ModelTaxConnection",
    items:  Array< {
      __typename: "Tax",
      id: string,
      deletedAt?: string | null,
      amount?: number | null,
      totalAmount?: number | null,
      transactionId: string,
      taxRateId: string,
      accountingSystemId?: string | null,
      authUserId: string,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TaxesByTaxRateIdQueryVariables = {
  taxRateId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaxFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TaxesByTaxRateIdQuery = {
  taxesByTaxRateId?:  {
    __typename: "ModelTaxConnection",
    items:  Array< {
      __typename: "Tax",
      id: string,
      deletedAt?: string | null,
      amount?: number | null,
      totalAmount?: number | null,
      transactionId: string,
      taxRateId: string,
      accountingSystemId?: string | null,
      authUserId: string,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TaxesByAuthUserIdQueryVariables = {
  authUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaxFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TaxesByAuthUserIdQuery = {
  taxesByAuthUserId?:  {
    __typename: "ModelTaxConnection",
    items:  Array< {
      __typename: "Tax",
      id: string,
      deletedAt?: string | null,
      amount?: number | null,
      totalAmount?: number | null,
      transactionId: string,
      taxRateId: string,
      accountingSystemId?: string | null,
      authUserId: string,
      authGroups: Array< string >,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    deletedAt?: string | null,
    name: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    bookkeepings?:  {
      __typename: "ModelBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    type: OrganizationType,
    logo?: string | null,
    billing?:  {
      __typename: "Billing",
      id: string,
      deletedAt?: string | null,
      userId: string,
      customerId: string,
      subscriptionId: string,
      subscriptionItemId: string,
      quantity: number,
      status: BillingStatus,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    organizationBillingId?: string | null,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    deletedAt?: string | null,
    name: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    bookkeepings?:  {
      __typename: "ModelBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    type: OrganizationType,
    logo?: string | null,
    billing?:  {
      __typename: "Billing",
      id: string,
      deletedAt?: string | null,
      userId: string,
      customerId: string,
      subscriptionId: string,
      subscriptionItemId: string,
      quantity: number,
      status: BillingStatus,
      authGroupsRead: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    organizationBillingId?: string | null,
  } | null,
};

export type OnCreateBillingSubscriptionVariables = {
  filter?: ModelSubscriptionBillingFilterInput | null,
};

export type OnCreateBillingSubscription = {
  onCreateBilling?:  {
    __typename: "Billing",
    id: string,
    deletedAt?: string | null,
    userId: string,
    customerId: string,
    subscriptionId: string,
    subscriptionItemId: string,
    quantity: number,
    status: BillingStatus,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBillingSubscriptionVariables = {
  filter?: ModelSubscriptionBillingFilterInput | null,
};

export type OnUpdateBillingSubscription = {
  onUpdateBilling?:  {
    __typename: "Billing",
    id: string,
    deletedAt?: string | null,
    userId: string,
    customerId: string,
    subscriptionId: string,
    subscriptionItemId: string,
    quantity: number,
    status: BillingStatus,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    deletedAt?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    role: UserAppRole,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    isSSO?: boolean | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    deletedAt?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    userOrganizations?:  {
      __typename: "ModelUserOrganizationConnection",
      nextToken?: string | null,
    } | null,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    role: UserAppRole,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    isSSO?: boolean | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionUserOrganizationFilterInput | null,
  userId?: string | null,
};

export type OnCreateUserOrganizationSubscription = {
  onCreateUserOrganization?:  {
    __typename: "UserOrganization",
    id: string,
    deletedAt?: string | null,
    userId: string,
    organizationId: string,
    role: UserOrganizationRole,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionUserOrganizationFilterInput | null,
  userId?: string | null,
};

export type OnUpdateUserOrganizationSubscription = {
  onUpdateUserOrganization?:  {
    __typename: "UserOrganization",
    id: string,
    deletedAt?: string | null,
    userId: string,
    organizationId: string,
    role: UserOrganizationRole,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBookkeepingSubscriptionVariables = {
  filter?: ModelSubscriptionBookkeepingFilterInput | null,
};

export type OnCreateBookkeepingSubscription = {
  onCreateBookkeeping?:  {
    __typename: "Bookkeeping",
    id: string,
    deletedAt?: string | null,
    name: string,
    organizationId: string,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    accountingSystem: AccountingSystem,
    accountingSystemConfig?:  {
      __typename: "AccountingSystemConfig",
      id: string,
      deletedAt?: string | null,
      baseCurrency: CurrencyCode,
      billingPeriod: BillingPeriod,
      senderBankAccountId: number,
      reimbursement: boolean,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    taxRates?:  {
      __typename: "ModelTaxRateConnection",
      nextToken?: string | null,
    } | null,
    bookkeepingYears?:  {
      __typename: "ModelBookkeepingYearConnection",
      nextToken?: string | null,
    } | null,
    color: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    bookkeepingAccountingSystemConfigId?: string | null,
  } | null,
};

export type OnUpdateBookkeepingSubscriptionVariables = {
  filter?: ModelSubscriptionBookkeepingFilterInput | null,
};

export type OnUpdateBookkeepingSubscription = {
  onUpdateBookkeeping?:  {
    __typename: "Bookkeeping",
    id: string,
    deletedAt?: string | null,
    name: string,
    organizationId: string,
    userBookkeepings?:  {
      __typename: "ModelUserBookkeepingConnection",
      nextToken?: string | null,
    } | null,
    accountingSystem: AccountingSystem,
    accountingSystemConfig?:  {
      __typename: "AccountingSystemConfig",
      id: string,
      deletedAt?: string | null,
      baseCurrency: CurrencyCode,
      billingPeriod: BillingPeriod,
      senderBankAccountId: number,
      reimbursement: boolean,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    taxRates?:  {
      __typename: "ModelTaxRateConnection",
      nextToken?: string | null,
    } | null,
    bookkeepingYears?:  {
      __typename: "ModelBookkeepingYearConnection",
      nextToken?: string | null,
    } | null,
    color: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    bookkeepingAccountingSystemConfigId?: string | null,
  } | null,
};

export type OnCreateBookkeepingYearSubscriptionVariables = {
  filter?: ModelSubscriptionBookkeepingYearFilterInput | null,
};

export type OnCreateBookkeepingYearSubscription = {
  onCreateBookkeepingYear?:  {
    __typename: "BookkeepingYear",
    id: string,
    deletedAt?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    hasNetTaxRate: boolean,
    year: number,
    balanceStart?: number | null,
    balance?: number | null,
    closedAt?: string | null,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBookkeepingYearSubscriptionVariables = {
  filter?: ModelSubscriptionBookkeepingYearFilterInput | null,
};

export type OnUpdateBookkeepingYearSubscription = {
  onUpdateBookkeepingYear?:  {
    __typename: "BookkeepingYear",
    id: string,
    deletedAt?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    hasNetTaxRate: boolean,
    year: number,
    balanceStart?: number | null,
    balance?: number | null,
    closedAt?: string | null,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserBookkeepingSubscriptionVariables = {
  filter?: ModelSubscriptionUserBookkeepingFilterInput | null,
  userId?: string | null,
};

export type OnCreateUserBookkeepingSubscription = {
  onCreateUserBookkeeping?:  {
    __typename: "UserBookkeeping",
    id: string,
    deletedAt?: string | null,
    userId: string,
    bookkeepingId: string,
    accountingSystemBill?:  {
      __typename: "AccountingSystemBill",
      id: string,
      deletedAt?: string | null,
      supplierId?: number | null,
      contactPartnerId: number,
      senderBankAccountId: number,
      billingPeriod: BillingPeriod,
      baseCurrency: CurrencyCode,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
      accountingSystemBillAccountingSystemReimbursementId?: string | null,
    } | null,
    bills?:  {
      __typename: "ModelBillConnection",
      nextToken?: string | null,
    } | null,
    transactionReferences?:  {
      __typename: "ModelTransactionReferenceConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    userBookkeepingAccountingSystemBillId?: string | null,
  } | null,
};

export type OnUpdateUserBookkeepingSubscriptionVariables = {
  filter?: ModelSubscriptionUserBookkeepingFilterInput | null,
  userId?: string | null,
};

export type OnUpdateUserBookkeepingSubscription = {
  onUpdateUserBookkeeping?:  {
    __typename: "UserBookkeeping",
    id: string,
    deletedAt?: string | null,
    userId: string,
    bookkeepingId: string,
    accountingSystemBill?:  {
      __typename: "AccountingSystemBill",
      id: string,
      deletedAt?: string | null,
      supplierId?: number | null,
      contactPartnerId: number,
      senderBankAccountId: number,
      billingPeriod: BillingPeriod,
      baseCurrency: CurrencyCode,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
      accountingSystemBillAccountingSystemReimbursementId?: string | null,
    } | null,
    bills?:  {
      __typename: "ModelBillConnection",
      nextToken?: string | null,
    } | null,
    transactionReferences?:  {
      __typename: "ModelTransactionReferenceConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
    userBookkeepingAccountingSystemBillId?: string | null,
  } | null,
};

export type OnCreateTransactionReferenceSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionReferenceFilterInput | null,
  authUserId?: string | null,
};

export type OnCreateTransactionReferenceSubscription = {
  onCreateTransactionReference?:  {
    __typename: "TransactionReference",
    id: string,
    deletedAt?: string | null,
    name: string,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTransactionReferenceSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionReferenceFilterInput | null,
  authUserId?: string | null,
};

export type OnUpdateTransactionReferenceSubscription = {
  onUpdateTransactionReference?:  {
    __typename: "TransactionReference",
    id: string,
    deletedAt?: string | null,
    name: string,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAccountingSystemConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAccountingSystemConfigFilterInput | null,
};

export type OnCreateAccountingSystemConfigSubscription = {
  onCreateAccountingSystemConfig?:  {
    __typename: "AccountingSystemConfig",
    id: string,
    deletedAt?: string | null,
    baseCurrency: CurrencyCode,
    billingPeriod: BillingPeriod,
    senderBankAccountId: number,
    reimbursement: boolean,
    bookkeepingId: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountingSystemConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAccountingSystemConfigFilterInput | null,
};

export type OnUpdateAccountingSystemConfigSubscription = {
  onUpdateAccountingSystemConfig?:  {
    __typename: "AccountingSystemConfig",
    id: string,
    deletedAt?: string | null,
    baseCurrency: CurrencyCode,
    billingPeriod: BillingPeriod,
    senderBankAccountId: number,
    reimbursement: boolean,
    bookkeepingId: string,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAccountingSystemBillSubscriptionVariables = {
  filter?: ModelSubscriptionAccountingSystemBillFilterInput | null,
  authUserId?: string | null,
};

export type OnCreateAccountingSystemBillSubscription = {
  onCreateAccountingSystemBill?:  {
    __typename: "AccountingSystemBill",
    id: string,
    deletedAt?: string | null,
    supplierId?: number | null,
    contactPartnerId: number,
    senderBankAccountId: number,
    billingPeriod: BillingPeriod,
    baseCurrency: CurrencyCode,
    accountingSystemReimbursement?:  {
      __typename: "AccountingSystemReimbursement",
      id: string,
      deletedAt?: string | null,
      receiverIban: string,
      receiverStreet: string,
      receiverHouseNumber: string,
      receiverCity: string,
      receiverPostalCode: string,
      receiverCountry: CountryCode,
      accountingSystemBillId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
    accountingSystemBillAccountingSystemReimbursementId?: string | null,
  } | null,
};

export type OnUpdateAccountingSystemBillSubscriptionVariables = {
  filter?: ModelSubscriptionAccountingSystemBillFilterInput | null,
  authUserId?: string | null,
};

export type OnUpdateAccountingSystemBillSubscription = {
  onUpdateAccountingSystemBill?:  {
    __typename: "AccountingSystemBill",
    id: string,
    deletedAt?: string | null,
    supplierId?: number | null,
    contactPartnerId: number,
    senderBankAccountId: number,
    billingPeriod: BillingPeriod,
    baseCurrency: CurrencyCode,
    accountingSystemReimbursement?:  {
      __typename: "AccountingSystemReimbursement",
      id: string,
      deletedAt?: string | null,
      receiverIban: string,
      receiverStreet: string,
      receiverHouseNumber: string,
      receiverCity: string,
      receiverPostalCode: string,
      receiverCountry: CountryCode,
      accountingSystemBillId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
    accountingSystemBillAccountingSystemReimbursementId?: string | null,
  } | null,
};

export type OnCreateAccountingSystemReimbursementSubscriptionVariables = {
  filter?: ModelSubscriptionAccountingSystemReimbursementFilterInput | null,
  authUserId?: string | null,
};

export type OnCreateAccountingSystemReimbursementSubscription = {
  onCreateAccountingSystemReimbursement?:  {
    __typename: "AccountingSystemReimbursement",
    id: string,
    deletedAt?: string | null,
    receiverIban: string,
    receiverStreet: string,
    receiverHouseNumber: string,
    receiverCity: string,
    receiverPostalCode: string,
    receiverCountry: CountryCode,
    accountingSystemBillId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountingSystemReimbursementSubscriptionVariables = {
  filter?: ModelSubscriptionAccountingSystemReimbursementFilterInput | null,
  authUserId?: string | null,
};

export type OnUpdateAccountingSystemReimbursementSubscription = {
  onUpdateAccountingSystemReimbursement?:  {
    __typename: "AccountingSystemReimbursement",
    id: string,
    deletedAt?: string | null,
    receiverIban: string,
    receiverStreet: string,
    receiverHouseNumber: string,
    receiverCity: string,
    receiverPostalCode: string,
    receiverCountry: CountryCode,
    accountingSystemBillId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBookingAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBookingAccountFilterInput | null,
};

export type OnCreateBookingAccountSubscription = {
  onCreateBookingAccount?:  {
    __typename: "BookingAccount",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountNumber: string,
    sortOrder: number,
    taxType: TaxType,
    transactionCategory:  {
      __typename: "TransactionCategory",
      id: string,
      deletedAt?: string | null,
      name: string,
      icon: string,
      createdAt: string,
      updatedAt: string,
    },
    transactionCategoryId: string,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBookingAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBookingAccountFilterInput | null,
};

export type OnUpdateBookingAccountSubscription = {
  onUpdateBookingAccount?:  {
    __typename: "BookingAccount",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountNumber: string,
    sortOrder: number,
    taxType: TaxType,
    transactionCategory:  {
      __typename: "TransactionCategory",
      id: string,
      deletedAt?: string | null,
      name: string,
      icon: string,
      createdAt: string,
      updatedAt: string,
    },
    transactionCategoryId: string,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTaxRateSubscriptionVariables = {
  filter?: ModelSubscriptionTaxRateFilterInput | null,
};

export type OnCreateTaxRateSubscription = {
  onCreateTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    deletedAt?: string | null,
    code: string,
    rate: number,
    taxType: TaxType,
    sortOrder: number,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    dateFrom: string,
    dateTo?: string | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTaxRateSubscriptionVariables = {
  filter?: ModelSubscriptionTaxRateFilterInput | null,
};

export type OnUpdateTaxRateSubscription = {
  onUpdateTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    deletedAt?: string | null,
    code: string,
    rate: number,
    taxType: TaxType,
    sortOrder: number,
    accountingSystemId?: string | null,
    bookkeepingId: string,
    dateFrom: string,
    dateTo?: string | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    authGroupsRead: Array< string >,
    authGroupsUpdate: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTransactionCategorySubscriptionVariables = {
  filter?: ModelSubscriptionTransactionCategoryFilterInput | null,
};

export type OnCreateTransactionCategorySubscription = {
  onCreateTransactionCategory?:  {
    __typename: "TransactionCategory",
    id: string,
    deletedAt?: string | null,
    name: string,
    icon: string,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTransactionCategorySubscriptionVariables = {
  filter?: ModelSubscriptionTransactionCategoryFilterInput | null,
};

export type OnUpdateTransactionCategorySubscription = {
  onUpdateTransactionCategory?:  {
    __typename: "TransactionCategory",
    id: string,
    deletedAt?: string | null,
    name: string,
    icon: string,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTransactionCategorySubscriptionVariables = {
  filter?: ModelSubscriptionTransactionCategoryFilterInput | null,
};

export type OnDeleteTransactionCategorySubscription = {
  onDeleteTransactionCategory?:  {
    __typename: "TransactionCategory",
    id: string,
    deletedAt?: string | null,
    name: string,
    icon: string,
    bookingAccounts?:  {
      __typename: "ModelBookingAccountConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBillSubscriptionVariables = {
  filter?: ModelSubscriptionBillFilterInput | null,
  authUserId?: string | null,
};

export type OnCreateBillSubscription = {
  onCreateBill?:  {
    __typename: "Bill",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountingSystemId?: string | null,
    dateFrom: string,
    dateTo?: string | null,
    status: BillStatus,
    amount: number,
    currency: CurrencyCode,
    baseCurrencyAmount?: number | null,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBillSubscriptionVariables = {
  filter?: ModelSubscriptionBillFilterInput | null,
  authUserId?: string | null,
};

export type OnUpdateBillSubscription = {
  onUpdateBill?:  {
    __typename: "Bill",
    id: string,
    deletedAt?: string | null,
    name: string,
    accountingSystemId?: string | null,
    dateFrom: string,
    dateTo?: string | null,
    status: BillStatus,
    amount: number,
    currency: CurrencyCode,
    baseCurrencyAmount?: number | null,
    transactions?:  {
      __typename: "ModelTransactionConnection",
      nextToken?: string | null,
    } | null,
    userBookkeeping:  {
      __typename: "UserBookkeeping",
      id: string,
      deletedAt?: string | null,
      userId: string,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
      userBookkeepingAccountingSystemBillId?: string | null,
    },
    userBookkeepingId: string,
    authUserId: string,
    authGroups: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
  userId?: string | null,
};

export type OnCreateTransactionSubscription = {
  onCreateTransaction?:  {
    __typename: "Transaction",
    id: string,
    deletedAt?: string | null,
    paidAt: string,
    currencyCode: CurrencyCode,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    amount: number,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    title: string,
    exchangeRate?: number | null,
    baseCurrencyAmount?: number | null,
    status: TransactionStatus,
    accountingSystemId?: string | null,
    bookkeepingYear:  {
      __typename: "BookkeepingYear",
      id: string,
      deletedAt?: string | null,
      bookkeepingId: string,
      hasNetTaxRate: boolean,
      year: number,
      balanceStart?: number | null,
      balance?: number | null,
      closedAt?: string | null,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookkeepingYearId: string,
    bill?:  {
      __typename: "Bill",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountingSystemId?: string | null,
      dateFrom: string,
      dateTo?: string | null,
      status: BillStatus,
      amount: number,
      currency: CurrencyCode,
      baseCurrencyAmount?: number | null,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    billId?: string | null,
    bookingAccount:  {
      __typename: "BookingAccount",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountNumber: string,
      sortOrder: number,
      taxType: TaxType,
      transactionCategoryId: string,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookingAccountId: string,
    transactionReference?:  {
      __typename: "TransactionReference",
      id: string,
      deletedAt?: string | null,
      name: string,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionReferenceId?: string | null,
    user:  {
      __typename: "User",
      id: string,
      deletedAt?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      role: UserAppRole,
      isSSO?: boolean | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    replacedById?: string | null,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
  userId?: string | null,
};

export type OnUpdateTransactionSubscription = {
  onUpdateTransaction?:  {
    __typename: "Transaction",
    id: string,
    deletedAt?: string | null,
    paidAt: string,
    currencyCode: CurrencyCode,
    taxes?:  {
      __typename: "ModelTaxConnection",
      nextToken?: string | null,
    } | null,
    amount: number,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      nextToken?: string | null,
    } | null,
    title: string,
    exchangeRate?: number | null,
    baseCurrencyAmount?: number | null,
    status: TransactionStatus,
    accountingSystemId?: string | null,
    bookkeepingYear:  {
      __typename: "BookkeepingYear",
      id: string,
      deletedAt?: string | null,
      bookkeepingId: string,
      hasNetTaxRate: boolean,
      year: number,
      balanceStart?: number | null,
      balance?: number | null,
      closedAt?: string | null,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookkeepingYearId: string,
    bill?:  {
      __typename: "Bill",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountingSystemId?: string | null,
      dateFrom: string,
      dateTo?: string | null,
      status: BillStatus,
      amount: number,
      currency: CurrencyCode,
      baseCurrencyAmount?: number | null,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    billId?: string | null,
    bookingAccount:  {
      __typename: "BookingAccount",
      id: string,
      deletedAt?: string | null,
      name: string,
      accountNumber: string,
      sortOrder: number,
      taxType: TaxType,
      transactionCategoryId: string,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    bookingAccountId: string,
    transactionReference?:  {
      __typename: "TransactionReference",
      id: string,
      deletedAt?: string | null,
      name: string,
      userBookkeepingId: string,
      authUserId: string,
      authGroups: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null,
    transactionReferenceId?: string | null,
    user:  {
      __typename: "User",
      id: string,
      deletedAt?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      role: UserAppRole,
      isSSO?: boolean | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    userId: string,
    replacedById?: string | null,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
  authUserId?: string | null,
};

export type OnCreateAttachmentSubscription = {
  onCreateAttachment?:  {
    __typename: "Attachment",
    id: string,
    deletedAt?: string | null,
    key: string,
    accountingSystemId?: string | null,
    accountingSystemName?: string | null,
    transactionId: string,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
  authUserId?: string | null,
};

export type OnUpdateAttachmentSubscription = {
  onUpdateAttachment?:  {
    __typename: "Attachment",
    id: string,
    deletedAt?: string | null,
    key: string,
    accountingSystemId?: string | null,
    accountingSystemName?: string | null,
    transactionId: string,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTaxSubscriptionVariables = {
  filter?: ModelSubscriptionTaxFilterInput | null,
  authUserId?: string | null,
};

export type OnCreateTaxSubscription = {
  onCreateTax?:  {
    __typename: "Tax",
    id: string,
    deletedAt?: string | null,
    amount?: number | null,
    totalAmount?: number | null,
    transactionId: string,
    taxRate:  {
      __typename: "TaxRate",
      id: string,
      deletedAt?: string | null,
      code: string,
      rate: number,
      taxType: TaxType,
      sortOrder: number,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      dateFrom: string,
      dateTo?: string | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    taxRateId: string,
    accountingSystemId?: string | null,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTaxSubscriptionVariables = {
  filter?: ModelSubscriptionTaxFilterInput | null,
  authUserId?: string | null,
};

export type OnUpdateTaxSubscription = {
  onUpdateTax?:  {
    __typename: "Tax",
    id: string,
    deletedAt?: string | null,
    amount?: number | null,
    totalAmount?: number | null,
    transactionId: string,
    taxRate:  {
      __typename: "TaxRate",
      id: string,
      deletedAt?: string | null,
      code: string,
      rate: number,
      taxType: TaxType,
      sortOrder: number,
      accountingSystemId?: string | null,
      bookkeepingId: string,
      dateFrom: string,
      dateTo?: string | null,
      authGroupsRead: Array< string >,
      authGroupsUpdate: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    taxRateId: string,
    accountingSystemId?: string | null,
    authUserId: string,
    authGroups: Array< string >,
    authGroupsRead: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};
