import {
  ChakraProps,
  FocusLock,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { Ref, forwardRef, useRef, useState } from "react";
import {
  DayPickerSingleProps,
  DayPicker as ReactDayPicker,
} from "react-day-picker";
import "react-day-picker/dist/style.css";
import { formatDate, getLocalDate } from "../utils";

export const selectDate = "Wähle ein Datum";

const DayPicker = ({ ...props }: DayPickerSingleProps) => {
  return <ReactDayPicker {...props} />;
};

type DateSelectorProps = {
  defaultValue: Date | undefined;
  onChange?: (date: Date | undefined) => void;
} & ChakraProps;

const DateSelector = forwardRef(
  (
    { defaultValue, onChange, width, ...props }: DateSelectorProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [date, setDate] = useState<Date | undefined>(defaultValue);
    const inputRefFallback = useRef<HTMLInputElement>(null);
    const inputRef = ref ?? inputRefFallback;

    const handleDateSelect = (selectedDate: Date | undefined) => {
      const localDate = selectedDate ? getLocalDate(selectedDate) : undefined;
      setDate(localDate);
      if (onChange) {
        onChange(localDate);
      }
      onClose();
    };

    return (
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom"
        {...props}
      >
        <PopoverTrigger>
          <Input
            ref={inputRef}
            isReadOnly={true}
            value={date ? formatDate(getLocalDate(new Date(date))) : selectDate}
            width={width}
          />
        </PopoverTrigger>
        <PopoverContent p={5}>
          <FocusLock persistentFocus={false}>
            <PopoverArrow />
            <DayPicker
              mode="single"
              defaultMonth={date}
              selected={date}
              onSelect={handleDateSelect}
              initialFocus={isOpen}
            />
          </FocusLock>
        </PopoverContent>
      </Popover>
    );
  }
);

export default DateSelector;
