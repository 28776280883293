import { Button, Flex, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { FieldArray, Form, Formik, getIn } from "formik";
import { useNavigate } from "react-router-dom";
import {
  AccountingSystem,
  OrganizationType,
  UserAppRole,
  UserOrganizationRole,
} from "../../API";
import * as Yup from "yup";

import OrganizationMemberField from "./organization-member-field";
import { useSetOrganizationMembersValues } from "./create-organization-layout";
import AddButton from "./add-button";
import {
  createOrganizationText,
  currentYear,
  dateToApiString,
  emailValidation,
  lastOfYear,
  selectUserOrganizationRoles,
} from "../../utils";
import {
  routeCreateOrganizationAccountingSystemConfig,
  routeCreateOrganizationBookkeeping,
} from "../routes";
import {
  stringMapUserAppRole,
  stringMapUserOrganizationRole,
  stringMembers,
} from "../../strings";
import { API, Auth } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { State } from "../../types";

type Role = (typeof selectUserOrganizationRoles)[number];

type CalendarYear = {
  is_vat_subject: boolean;
  vat_accounting_method: "effective" | "net_tax";
  vat_accounting_type: "aggreed" | "collected";
};

type AccountingUser = {
  email: string;
  is_superadmin: boolean;
  is_accountant: boolean;
};

export type OrganizationMember = {
  email: string;
  appRole: UserAppRole;
  organizationRole: Role;
};

export type OrganizationMembersValues = {
  organizationAdmin: OrganizationMember;
  organizationMembers: OrganizationMember[];
};

const OrganizationMembers = () => {
  const navigate = useNavigate();

  const {
    state,
    setState,
    user,
    organizationValues,
    organizationMembersValues,
    setOrganizationMembersValues,
    setBookkeepingValues,
  } = useSetOrganizationMembersValues();

  const [stateLocal, setStateLocal] = useState<State>("initial");

  const [fetchingAccountingUsers, setFetchingAccountingUsers] = useState(false);

  const organizationAdmin: OrganizationMember = useMemo(() => {
    return user.role === UserAppRole.accountant
      ? {
          email: "",
          appRole: UserAppRole.organizationAdmin,
          organizationRole: UserOrganizationRole.admin,
        }
      : {
          email: user.email,
          appRole: user.role,
          organizationRole: UserOrganizationRole.admin,
        };
  }, [user.role, user.email]);

  const initialValues: OrganizationMembersValues =
    organizationMembersValues ?? {
      organizationAdmin,
      organizationMembers: [],
    };

  const MembersSchema = Yup.object().shape({
    organizationAdmin: Yup.object().shape({
      email:
        user.role === UserAppRole.accountant
          ? emailValidation()
          : Yup.string().required(),
    }),
    organizationMembers: Yup.array().of(
      Yup.object().shape({
        email: emailValidation(),
      })
    ),
  });

  const isLastStep =
    user?.role === UserAppRole.organizationAdmin &&
    organizationValues?.type === OrganizationType.accountant;

  useEffect(() => {
    if (user.isSSO && !organizationMembersValues && !fetchingAccountingUsers) {
      setFetchingAccountingUsers(true);
      const fetchAccountingUsers = async () => {
        try {
          const response: AccountingUser[] = await API.post(
            "expenslyREST",
            "/auth/bexio/proxy",
            {
              headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
              },
              body: {
                organizationId: organizationValues.id,
                path: "/3.0/users",
                method: "GET",
              },
            }
          );

          // Keep accountants if organization type is accountant
          // else remove accountants
          // Also filter out the current user
          const filtered = response
            .filter((au) => au.email !== user.email)
            .filter(
              (au) =>
                organizationValues.type === OrganizationType.accountant ||
                !au.is_accountant
            );

          // Show admins first
          filtered.sort((a, b) =>
            a.is_superadmin === b.is_superadmin ? 0 : a.is_superadmin ? -1 : 1
          );

          const organizationMembers: OrganizationMember[] = filtered.map(
            (au) => ({
              email: au.email,
              appRole: UserAppRole.user,
              organizationRole: au.is_superadmin
                ? UserOrganizationRole.admin
                : UserOrganizationRole.employee,
            })
          );

          setOrganizationMembersValues({
            organizationAdmin,
            organizationMembers,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setFetchingAccountingUsers(false);
        }
      };
      fetchAccountingUsers();
    }
  }, [
    fetchingAccountingUsers,
    organizationAdmin,
    organizationMembersValues,
    organizationValues.id,
    organizationValues.type,
    setOrganizationMembersValues,
    user.email,
    user.isSSO,
  ]);

  return fetchingAccountingUsers ? (
    <Spinner />
  ) : (
    <VStack
      maxWidth={{ base: "auto", md: "640px" }}
      width="full"
      alignItems="start"
      spacing="12px"
    >
      <Text fontSize={36} fontWeight="medium" color="gray.900">
        Mitglieder anlegen
      </Text>
      <Flex direction="column">
        <Text fontSize={14} fontWeight="semibold" color="gray.700">
          {`${stringMembers} einladen`}
        </Text>
        <Text fontSize={14} color="gray.600">
          {`Lade Deine ${stringMembers} ein, die Expensly App ebenfalls zu nutzen. `}
          <Text fontSize={14} fontWeight="semibold" color="gray.600" as="span">
            {`Alle ${stringMembers} können Transaktionen erfassen.`}
          </Text>
          <Text fontSize={14} color="gray.600">
            Folgende Benutzer haben zusätzliche Funktionen:
          </Text>
        </Text>
        <br />
        <Text fontSize={14} fontWeight="semibold" color="gray.600">
          {`${stringMapUserAppRole.get(UserAppRole.organizationAdmin)}: `}
          <Text fontSize={14} fontWeight="normal" color="gray.600" as="span">
            {`${stringMapUserOrganizationRole.get(
              UserOrganizationRole.admin
            )} und muss `}
          </Text>
          <Text fontSize={14} fontWeight="semibold" color="gray.600" as="span">
            Zahlungsdetails eingeben.
          </Text>
        </Text>
        <Text fontSize={14} fontWeight="semibold" color="gray.600">
          {`${stringMapUserOrganizationRole.get(UserOrganizationRole.admin)}: `}
          <Text fontSize={14} fontWeight="normal" color="gray.600" as="span">
            Kann Organisationseinstellungen anpassen.
          </Text>
        </Text>
        <Text fontSize={14} fontWeight="semibold" color="gray.600">
          {`${stringMapUserOrganizationRole.get(UserOrganizationRole.head)}: `}
          <Text fontSize={14} fontWeight="normal" color="gray.600" as="span">
            Hat den Überblick über die Buchungen der Mitarbeiter.
          </Text>
        </Text>
        <Text fontSize={14} fontWeight="semibold" color="gray.600">
          {`${stringMapUserOrganizationRole.get(
            UserOrganizationRole.employee
          )}: `}
          <Text fontSize={14} fontWeight="normal" color="gray.600" as="span">
            Sieht nur die eigenen Buchungen.
          </Text>
        </Text>
      </Flex>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, helpers) => {
          setOrganizationMembersValues({
            organizationAdmin: {
              email: values.organizationAdmin.email,
              appRole: values.organizationAdmin.appRole,
              organizationRole: values.organizationAdmin.organizationRole,
            },
            organizationMembers: values.organizationMembers.map(
              (organizationMember) => ({
                email: organizationMember.email,
                appRole: organizationMember.appRole,
                organizationRole: organizationMember.organizationRole,
              })
            ),
          });
          if (isLastStep) {
            setState("loading");
          } else if (user.isSSO) {
            // Skip Bookkeeping and Bexio login

            setStateLocal("loading");

            // Get hasNetTaxRate from Bexio
            let hasNetTaxRate: boolean | undefined;
            try {
              const response = await API.post(
                "expenslyREST",
                "/auth/bexio/proxy",
                {
                  headers: {
                    Authorization: `Bearer ${(await Auth.currentSession())
                      .getIdToken()
                      .getJwtToken()}`,
                  },
                  body: {
                    organizationId: organizationValues.id,
                    path: "/3.0/accounting/calendar_years/search",
                    method: "POST",
                    body: JSON.stringify([
                      {
                        field: "end",
                        value: dateToApiString(lastOfYear),
                      },
                    ]),
                  },
                }
              );

              if (
                !response ||
                !Array.isArray(response) ||
                response.length === 0
              ) {
                throw new Error("No calendar years found");
              }

              const calendarYear: CalendarYear = response[0];

              hasNetTaxRate =
                !calendarYear.is_vat_subject ||
                calendarYear.vat_accounting_method === "net_tax";
            } catch (error) {
              console.error(error);
              setStateLocal("error");
            }

            setBookkeepingValues({
              name: organizationValues.name,
              accountingSystem: AccountingSystem.bexio,
              color: organizationValues.accountingColor ?? "#c0bff5",
              year: currentYear,
              balance: 0,
              hasNetTaxRate: hasNetTaxRate ?? true,
            });

            // Navigate to Bookkeeping if hasNetTaxRate couldn't be fetched
            if (hasNetTaxRate === undefined) {
              navigate(routeCreateOrganizationBookkeeping);
            } else {
              navigate(routeCreateOrganizationAccountingSystemConfig);
            }
          } else {
            // If setup of accountant customer or
            // setup of main organization with type company
            navigate(routeCreateOrganizationBookkeeping);
          }
        }}
        validationSchema={MembersSchema}
        validateOnChange={false}
      >
        {(props) => (
          <Form style={{ width: "100%" }}>
            <VStack spacing={{ base: "16px", md: "48px" }}>
              <FieldArray name="organizationMembers">
                {({ remove, push }) => (
                  <VStack alignItems="start" width="full" spacing="16px">
                    <OrganizationMemberField
                      name={UserAppRole.organizationAdmin}
                      email={
                        user.role === UserAppRole.accountant
                          ? {
                              isInvalid: (form) =>
                                (form.errors.organizationAdmin?.email &&
                                  form.touched.organizationAdmin
                                    ?.email) as boolean,
                              errorMessage: (form) =>
                                form.errors.organizationAdmin?.email,
                            }
                          : undefined
                      }
                      props={props}
                    />
                    {props.values.organizationMembers.length > 0 &&
                      props.values.organizationMembers.map(
                        (organizationMember, index) => (
                          <OrganizationMemberField
                            key={`organizationMembers.${index}`}
                            name={`organizationMembers.${index}`}
                            email={{
                              isInvalid: (form) =>
                                (getIn(
                                  form.errors,
                                  `organizationMembers[${index}].email`
                                ) &&
                                  getIn(
                                    form.touched,
                                    `organizationMembers[${index}].email`
                                  )) as boolean,
                              errorMessage: (form) =>
                                getIn(
                                  form.errors,
                                  `organizationMembers[${index}].email`
                                ),
                            }}
                            role={{
                              isInvalid: (form) =>
                                (getIn(
                                  form.errors,
                                  `organizationMembers[${index}].organizationRole`
                                ) &&
                                  getIn(
                                    form.touched,
                                    `organizationMembers[${index}].organizationRole`
                                  )) as boolean,
                              errorMessage: (form) =>
                                getIn(
                                  form.errors,
                                  `organizationMembers[${index}].organizationRole`
                                ),
                            }}
                            onDelete={async () => remove(index)}
                            props={props}
                          />
                        )
                      )}
                    <AddButton<OrganizationMember>
                      push={push}
                      data={{
                        email: "",
                        appRole: UserAppRole.user,
                        organizationRole: UserOrganizationRole.employee,
                      }}
                    />
                  </VStack>
                )}
              </FieldArray>
              <HStack width="full">
                <Button
                  width="full"
                  variant="outline"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Zurück
                </Button>
                <Button
                  width="full"
                  colorScheme="primary"
                  type="submit"
                  isLoading={state === "loading" || stateLocal === "loading"}
                  isDisabled={state === "error" || stateLocal === "error"}
                >
                  {isLastStep ? createOrganizationText : "Weiter"}
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default OrganizationMembers;
