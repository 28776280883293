import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import theme, { themeAuthenticator } from "./theme";

import { Amplify, I18n } from "aws-amplify";
import awsExports from "./aws-exports";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import Routes from "./routes/routes";
import {
  Authenticator,
  ThemeProvider,
  translations,
} from "@aws-amplify/ui-react";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import Gleap from "gleap";
Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.setLanguage("de");

const site = "datadoghq.eu";
const service = "expensly-web";
const clientToken = "pub65f3aa47f8774d3a04d6b6d063a0f140";

datadogLogs.init({
  clientToken,
  site,
  service,
  env: process.env.REACT_APP_ENV,
  sessionSampleRate: 100,
  telemetrySampleRate: 0,
});
datadogLogs.logger.info("Home");
datadogRum.init({
  applicationId: "8d3762a2-c22e-4e5b-b635-12e9206ee45f",
  clientToken,
  site,
  service,
  env: process.env.REACT_APP_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  telemetrySampleRate: 0,
});
datadogRum.startSessionReplayRecording();

Gleap.setLanguage("de");
Gleap.initialize("qMkxV5O0v0dJQ7DUpViY03rUSVPIa9Qu");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ThemeProvider theme={themeAuthenticator}>
        <Authenticator.Provider>
          <Routes />
        </Authenticator.Provider>
      </ThemeProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
