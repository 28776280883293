import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  CurrencyCode,
  UserAppRole,
  UserOrganization,
  UserOrganizationRole,
  UserOrganizationsByOrganizationIdQuery,
  UserOrganizationsByOrganizationIdQueryVariables,
} from "../../API";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  ListItem,
  Spinner,
  Tag,
  Text,
  UnorderedList,
  VStack,
  useRadioGroup,
} from "@chakra-ui/react";
import { useBilling } from "./onboarding-layout";
import {
  aerzteTreuhandLogo,
  formatAmount,
  isBillingActive,
  queryList,
} from "../../utils";
import { API } from "aws-amplify";
import RadioCard from "../../components/radio-card";
import { Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { userOrganizationsByOrganizationId } from "../../graphql/queries";

const priceYearly = 120;
const priceMonthly = 12;

const billingCycles = ["yearly", "monthly"] as const;
type BillingCycles = (typeof billingCycles)[number];

const billingCycleDefault: BillingCycles = "yearly";

type BillingValues = {
  billingCycle: BillingCycles;
};

const Billing = () => {
  const { user: authUser } = useAuthenticator((context) => [context.user]);
  const { user, organizations } = useBilling();
  const [memberQuantity, setMemberQuantity] = useState<number>();

  const inactiveOrganization = organizations?.find(
    (organization) => !isBillingActive(organization)
  );

  const initialValues: BillingValues = {
    billingCycle: billingCycleDefault,
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "billingCycle",
    defaultValue: initialValues.billingCycle,
  });

  const group = getRootProps();

  useEffect(() => {
    if (inactiveOrganization) {
      const fetchMemberQuantity = async () => {
        const members = await queryList<
          UserOrganization,
          UserOrganizationsByOrganizationIdQueryVariables,
          UserOrganizationsByOrganizationIdQuery
        >(userOrganizationsByOrganizationId, {
          organizationId: inactiveOrganization.id,
          filter: { role: { ne: UserOrganizationRole.accountant } },
        });

        setMemberQuantity(members.length);
      };
      fetchMemberQuantity();
    }
  }, [inactiveOrganization]);

  if (user && inactiveOrganization) {
    if (user.role === UserAppRole.organizationAdmin) {
      const isAerzteTreuhandCustomer =
        inactiveOrganization.logo === aerzteTreuhandLogo;
      return (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, helpers) => {
            const response = await API.post(
              "expenslyREST",
              "/billing/create-checkout-session",
              {
                body: {
                  lookupKey: `basic_${values.billingCycle}`,
                  organizationId: inactiveOrganization.id,
                  customerEmail: authUser?.attributes?.email,
                  quantity: memberQuantity,
                  successUrl: `${window.location.origin}/onboarding/confirmation`,
                  cancelUrl: window.location.href,
                  isAerzteTreuhandCustomer,
                },
              }
            );
            if (response) {
              window.location.href = response;
            }
          }}
        >
          {({ values, isSubmitting }) => (
            <Form
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <VStack spacing="20px" width={{ base: "auto", md: "360px" }}>
                <VStack alignItems="start">
                  <Text fontSize={24} fontWeight="semibold" color="gray.900">
                    Basic
                  </Text>
                  <Text fontSize={16} color="gray.600">
                    Für unbegrenzte Anzahl Belege und{" "}
                    {isAerzteTreuhandCustomer
                      ? "Honorarerfassung"
                      : "automatischer Spesenerstattung"}
                    .
                  </Text>
                </VStack>
                <VStack spacing={0}>
                  <HStack>
                    <Text fontSize={36} fontWeight="medium" color="gray.900">
                      {formatAmount(
                        values.billingCycle === "yearly"
                          ? priceYearly
                          : priceMonthly,
                        CurrencyCode.chf,
                        0
                      )}
                    </Text>
                    <VStack
                      spacing={0}
                      fontSize={12}
                      alignItems="start"
                      color="gray.600"
                    >
                      <Text>
                        pro{" "}
                        {values.billingCycle === "yearly" ? "Jahr" : "Monat"}
                      </Text>
                      <Text>
                        {isAerzteTreuhandCustomer
                          ? "und erste 3 Benutzer"
                          : "und Benutzer"}
                      </Text>
                    </VStack>
                  </HStack>
                  {isAerzteTreuhandCustomer && (
                    <Text fontSize={12} color="gray.600">
                      danach {formatAmount(priceYearly, CurrencyCode.chf, 0)}{" "}
                      pro Jahr für jeden weiteren Benutzer
                    </Text>
                  )}
                </VStack>

                {!isAerzteTreuhandCustomer && (
                  <Field name="billingCycle">
                    {({ field }: FieldProps<BillingCycles, BillingValues>) => (
                      <FormControl>
                        <FormLabel fontSize={14} color="gray.700">
                          Abrechnungszeitraum
                        </FormLabel>
                        <VStack {...group} {...field}>
                          {billingCycles.map((billingCycle) => (
                            <Box
                              key={billingCycle}
                              position="relative"
                              width="full"
                            >
                              {billingCycle === "yearly" && (
                                <Tag
                                  colorScheme="success"
                                  position="absolute"
                                  top="0"
                                  right="0"
                                  transform="translate(12.5%, -50%)"
                                  zIndex="1"
                                  variant="solid"
                                >
                                  20% Rabatt
                                </Tag>
                              )}
                              <RadioCard
                                {...getRadioProps({ value: billingCycle })}
                              >
                                <Text
                                  fontSize={14}
                                  color="primary.800"
                                  fontWeight="medium"
                                >
                                  {billingCycle === "yearly"
                                    ? "Jährlich"
                                    : "Monatlich"}
                                </Text>
                                <Text fontSize={14} color="primary.700">
                                  {billingCycle === "yearly"
                                    ? `${formatAmount(
                                        priceYearly,
                                        CurrencyCode.chf,
                                        0
                                      )} pro Jahr und Benutzer`
                                    : `${formatAmount(
                                        priceMonthly,
                                        CurrencyCode.chf,
                                        0
                                      )} pro Monat und Benutzer`}
                                </Text>
                              </RadioCard>
                            </Box>
                          ))}
                        </VStack>
                      </FormControl>
                    )}
                  </Field>
                )}
                <UnorderedList>
                  <ListItem>Unbegrenzte Buchungen</ListItem>
                  {!isAerzteTreuhandCustomer && (
                    <ListItem>Sammelbuchung</ListItem>
                  )}
                  {!isAerzteTreuhandCustomer && (
                    <ListItem>Rückzahlung</ListItem>
                  )}
                  {isAerzteTreuhandCustomer && (
                    <ListItem>Honorar Erfassung</ListItem>
                  )}
                </UnorderedList>
                <Button
                  colorScheme="primary"
                  type="submit"
                  width="full"
                  isLoading={isSubmitting}
                >
                  Testversion starten
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      );
    }
  }
  return <Spinner />;
};

export default Billing;
