import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useSetBookingAccountsValues } from "./create-organization-layout";
import { FieldArray, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { BookingAccount, TaxType } from "../../API";
import { v4 as guid } from "uuid";
import bookingAccountsDefaultMain from "../../assets/data/bookingAccountsDefault.json";
import bookingAccountsDefaultAerzteTreuhand from "../../assets/data/bookingAccountsDefaultAerzteTreuhand.json";

import BookingAccountsTable from "../../components/booking-accounts-table";
import {
  aerzteTreuhandLogo,
  createOrganizationText,
  maxWidthTables,
} from "../../utils";
import { routeCreateOrganizationTaxRates } from "../routes";
import { SortModel } from "../../components/draggable-row";
import { stringBookingAccounts } from "../../strings";
import { useTransactionCategories } from "../../hooks/use-transaction-categories";
import { useAccountingSystemBookingAccounts } from "../../hooks/use-accounting-system-booking-accounts";

const typeNamePlural = stringBookingAccounts;

export type AccountingSystemBookingAccount = {
  id: number;
  account_no: string;
  name: string;
  is_active: boolean;
  is_locked: boolean;
};

export type BookingAccountValue = SortModel &
  Pick<
    BookingAccount,
    | "name"
    | "accountNumber"
    | "taxType"
    | "transactionCategoryId"
    | "accountingSystemId"
  >;

export type BookingAccountsValues = {
  bookingAccounts: BookingAccountValue[];
};

const BookingAccounts = () => {
  const navigate = useNavigate();

  const { transactionCategories } = useTransactionCategories();

  const {
    state,
    setState,
    user,
    mainOrganization,
    organizationValues,
    accountingSystemLoginValues,
    bookkeepingValues,
    bookingAccountsValues,
    setBookingAccountsValues,
  } = useSetBookingAccountsValues();

  const bookingAccountsDefault =
    mainOrganization.logo === aerzteTreuhandLogo
      ? bookingAccountsDefaultAerzteTreuhand
      : bookingAccountsDefaultMain;

  const initialValues: BookingAccountsValues = bookingAccountsValues ?? {
    bookingAccounts: bookingAccountsDefault.map((bookingAccountDefault) => ({
      ...bookingAccountDefault,
      id: guid(),
      accountNumber: bookingAccountDefault.accountNumber as unknown as string,
      taxType: bookingAccountDefault.taxType as TaxType,
    })),
  };

  const isLastStep = bookkeepingValues?.hasNetTaxRate;

  const { accountingSystemBookingAccounts } =
    useAccountingSystemBookingAccounts(
      organizationValues.id,
      (accountingSystemLoginValues?.success || user.isSSO) ?? false
    );

  const isValid = (values: BookingAccountsValues) =>
    (!accountingSystemLoginValues?.success && !user.isSSO) ||
    (accountingSystemBookingAccounts &&
      values.bookingAccounts.every((ba) =>
        accountingSystemBookingAccounts.find(
          (aba) => aba.account_no === ba.accountNumber.toString()
        )
      ));

  return (
    <VStack
      maxWidth={maxWidthTables}
      width="full"
      alignItems="start"
      spacing="12px"
    >
      <Text fontSize={36} fontWeight="medium" color="gray.900">
        {stringBookingAccounts}
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (isValid(values)) {
            setBookingAccountsValues({
              bookingAccounts: values.bookingAccounts.map((ba) => ({
                ...ba,
                accountingSystemId: accountingSystemBookingAccounts
                  ?.find(
                    (aba) => aba.account_no === ba.accountNumber.toString()
                  )
                  ?.id.toString(),
              })),
            });
            if (bookkeepingValues?.hasNetTaxRate) {
              setState("loading");
            } else {
              navigate(routeCreateOrganizationTaxRates);
            }
          }
        }}
      >
        {({ values }) => (
          <Form
            style={{
              width: "100%",
            }}
          >
            <VStack spacing={{ base: "16px", md: "48px" }}>
              <FieldArray name="bookingAccounts">
                {({ remove, replace, push, move }) => (
                  <BookingAccountsTable
                    typeNamePlural={typeNamePlural}
                    transactionCategories={transactionCategories}
                    bookingAccounts={values.bookingAccounts}
                    hasAccountingSystemBookingAccounts={
                      (accountingSystemLoginValues?.success || user.isSSO) ??
                      false
                    }
                    accountingSystemBookingAccounts={
                      accountingSystemBookingAccounts
                    }
                    remove={remove}
                    replace={replace}
                    push={push}
                    move={move}
                  />
                )}
              </FieldArray>
              <HStack width="full">
                <Button
                  width="full"
                  variant="outline"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Zurück
                </Button>
                <Button
                  width="full"
                  colorScheme="primary"
                  type="submit"
                  isLoading={state === "loading"}
                  isDisabled={state === "error" || !isValid(values)}
                >
                  {isLastStep ? createOrganizationText : "Weiter"}
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default BookingAccounts;
