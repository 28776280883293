import { Box, Image, ResponsiveValue } from "@chakra-ui/react";
import {
  ActionMeta,
  chakraComponents,
  Select as ReactSelect,
  SingleValue,
} from "chakra-react-select";

export type Option = {
  value: string | number;
  label: string;
  icon?: string;
};

export type SelectProps = {
  options?: Option[];
  value?: Option;
  onChange?: (
    newValue: SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => void;
  isValid?: boolean;
  placeholder?: string;
  width?: ResponsiveValue<string>;
  menuPortalTarget?: HTMLElement;
  isDisabled?: boolean;
};

const Select = ({
  options,
  value,
  onChange,
  isValid = true,
  placeholder,
  width,
  isDisabled,
}: SelectProps) => {
  return (
    <ReactSelect
      value={value}
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 100 }),
      }}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          width: width ?? "auto",
          bg: "white",
          borderColor: isValid ? provided.borderColor : "error.500",
          _hover: {
            borderColor: isValid ? provided.borderColor : "error.500",
          },
          _focus: {
            borderColor: isValid ? provided.borderColor : "error.500",
            shadow: "none",
          },
          cursor: "pointer",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          bg: "white",
          width: "20px",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        option: (provided, state) => ({
          ...provided,
          bg: state.isSelected ? "primary.50 !important" : "white",
          color: "black !important",
          _hover: {
            bg: "primary.50",
          },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }),
        placeholder: (provided) => ({
          ...provided,
          marginLeft: "6px",
        }),
        singleValue: (provided) => ({
          ...provided,
          marginLeft: "6px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: "0 0 0 2px",
        }),
      }}
      placeholder={placeholder}
      components={{
        Option: ({ children, ...props }) => (
          <chakraComponents.Option {...props}>
            <Box overflow="hidden" textOverflow="ellipsis">
              {children}
            </Box>
            {props.data.icon && (
              <Image src={props.data.icon} boxSize="20px" pl="2px" />
            )}
          </chakraComponents.Option>
        ),
        SingleValue: ({ children, ...props }) => (
          <chakraComponents.SingleValue {...props}>
            <Box overflow="hidden" textOverflow="ellipsis">
              {children}
            </Box>
            {props.data.icon && (
              <Image src={props.data.icon} boxSize="20px" pl="2px" />
            )}
          </chakraComponents.SingleValue>
        ),
      }}
    />
  );
};

export default Select;
