import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ReactElement } from "react";
import { routeOnboarding } from "../routes/routes";

const RequireAuth = ({ children }: { children: ReactElement }) => {
  const location = useLocation();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  if (authStatus !== "authenticated") {
    return authStatus === "unauthenticated" ? (
      <Navigate to={routeOnboarding} state={{ from: location }} replace />
    ) : (
      <></>
    );
  }
  return children;
};

export default RequireAuth;
