import { useEffect, useState } from "react";
import { Auth, API } from "aws-amplify";
import { AccountingSystemBookingAccount } from "../routes/create-organization/booking-accounts";

export const useAccountingSystemBookingAccounts = (
  organizationId?: string,
  accountingSystemLoginSuccess?: boolean
) => {
  const [accountingSystemBookingAccounts, setAccountingSystemBookingAccounts] =
    useState<AccountingSystemBookingAccount[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (
      organizationId &&
      accountingSystemLoginSuccess &&
      !accountingSystemBookingAccounts
    ) {
      const fetchBookingAccounts = async () => {
        setLoading(true);
        try {
          const response: AccountingSystemBookingAccount[] = await API.post(
            "expenslyREST",
            "/auth/bexio/proxy",
            {
              headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
              },
              body: {
                organizationId: organizationId,
                path: "/2.0/accounts",
                method: "GET",
              },
            }
          );

          const filtered = response.filter((account) => account.is_active);
          filtered.sort((a, b) => a.account_no.localeCompare(b.account_no));

          setAccountingSystemBookingAccounts(filtered);
        } catch (error) {
          console.error(error);
          setError(error as Error);
        } finally {
          setLoading(false);
        }
      };
      fetchBookingAccounts();
    }
  }, [
    accountingSystemBookingAccounts,
    accountingSystemLoginSuccess,
    organizationId,
  ]);

  return { accountingSystemBookingAccounts, loading, error };
};
