import { Button, ButtonProps } from "@chakra-ui/react";
import { getAuthUserName } from "../utils";
import { useAuthenticator } from "@aws-amplify/ui-react";

type SignOutLinkButtonProps = ButtonProps & {
  withName?: boolean;
};

const SignOutLinkButton = ({
  withName = true,
  ...props
}: SignOutLinkButtonProps) => {
  const { signOut, user, authStatus } = useAuthenticator((context) => [
    context.signOut,
    context.user,
    context.authStatus,
  ]);

  return authStatus === "authenticated" && user ? (
    <Button
      variant="link"
      color="gray.600"
      fontSize={14}
      fontWeight="normal"
      onClick={signOut}
      {...props}
    >
      {withName ? `${getAuthUserName(user)} abmelden` : "Abmelden"}
    </Button>
  ) : (
    <></>
  );
};

export default SignOutLinkButton;
