import { useState, useEffect } from "react";
import { convertToDateObjects } from "../utils";

const useSessionStorage = <T>(
  key: string,
  initialState?: T
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] => {
  const [value, setValue] = useState<T | undefined>(() => {
    const storedValue = sessionStorage.getItem(key);
    if (!storedValue) return initialState;

    const parsedValue = JSON.parse(storedValue);
    // Convert any fields that look like dates from strings to Date objects
    convertToDateObjects(parsedValue);
    return parsedValue;
  });

  useEffect(() => {
    if (value === undefined) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;
