import {
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import { State } from "../types";
import {
  fetchReceipt,
  formatAmount,
  formatDate,
  getLocalDate,
  getUserName,
  queryList,
  stringWithoutManualLineBreaks,
} from "../utils";
import { Storage } from "aws-amplify";
import { utils, write } from "xlsx";

import IconDownload from "../assets/icons/download.svg";
import { BookkeepingFull, Tax, TransactionFull } from "../models";
import {
  AccountingSystem,
  AccountingSystemConfig,
  AccountingSystemConfigsByBookkeepingIdQuery,
  AccountingSystemConfigsByBookkeepingIdQueryVariables,
  CurrencyCode,
  TaxType,
  TransactionStatus,
} from "../API";
import { useState } from "react";
import Tooltip from "./tooltip";
import { accountingSystemConfigsByBookkeepingId } from "../graphql/queries";
import { format } from "date-fns";

Storage.configure({ level: "public" });

type DownloadButtonProps = {
  bookkeeping?: BookkeepingFull;
  transactions?: TransactionFull[];
  baseCurrency: CurrencyCode;
  state: State;
  dateTo?: Date;
  hasTaxes?: boolean;
};

type AggregatedTransaction = {
  id: string;
  bookingAccountId: string;
  amount: number;
  bookingAccount: {
    accountNumber: string;
    taxType: TaxType;
  };
  paidAt: string;
  title: string;
  taxes: Tax[];
};

type TransactionAccumulator = {
  [key: string]: AggregatedTransaction;
};

const DownloadButton = ({
  bookkeeping,
  transactions,
  baseCurrency,
  state,
  dateTo,
  hasTaxes,
}: DownloadButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const isTopal = bookkeeping?.accountingSystem === AccountingSystem.topal;
  const isBanana = bookkeeping?.accountingSystem === AccountingSystem.banana;
  const useMenu = isTopal || isBanana;

  const downloadExcel = async () => {
    if (transactions) {
      setIsLoading(true);

      var zip = new JSZip();
      const data: (string | number | object)[][] = [
        [
          "Datum",
          "Transaktion",
          "Original Betrag",
          `${baseCurrency.toUpperCase()} Betrag`,
          "MwSt. Betrag",
          "MwSt. Satz",
          "MwSt. Betrag Total",
          "Buchungskategorie",
          "Beleg",
          "Status",
          "Benutzer",
        ],
      ];

      const receiptResults = await Promise.all(
        transactions.map(async (transaction, index) => {
          const key =
            transaction.attachments.length > 0
              ? transaction.attachments[0].key
              : undefined;

          let filepath: string | undefined;
          const date = formatDate(getLocalDate(new Date(transaction.paidAt)));

          const counter = index + 1;

          if (key) {
            const receiptData = await fetchReceipt(key);

            if (receiptData && receiptData.blob) {
              filepath = `Belege/${counter}_${date}_${receiptData.key}`;
              zip = zip.file(filepath, receiptData.blob, { base64: true });
            }
          }

          return {
            index,
            row: [
              date,
              transaction.title,
              formatAmount(transaction.amount, transaction.currencyCode),
              transaction.baseCurrencyAmount
                ? formatAmount(transaction.baseCurrencyAmount, baseCurrency)
                : formatAmount(transaction.amount, baseCurrency),
              transaction.taxes
                .map((tax) =>
                  tax.amount
                    ? formatAmount(tax.amount, transaction.currencyCode)
                    : ""
                )
                .filter((amount) => amount)
                .join(" | "),
              transaction.taxes
                .map((tax) => `${tax.taxRate.rate}%`)
                .filter((rate) => rate)
                .join(" | "),
              transaction.taxes
                .map((tax) =>
                  tax.amount
                    ? formatAmount(
                        tax.totalAmount ?? transaction.amount,
                        transaction.currencyCode
                      )
                    : ""
                )
                .filter((amount) => amount)
                .join(" | "),
              stringWithoutManualLineBreaks(transaction.bookingAccount.name),
              filepath ?? "",
              transaction.status === TransactionStatus.booked
                ? "Abgeschlossen"
                : transaction.status === TransactionStatus.draft
                ? "Entwurf"
                : "Unvollständig",
              getUserName(transaction.user),
            ],
          };
        })
      );

      // Sort the results based on the original order and push into the data array
      receiptResults
        .sort((a, b) => a.index - b.index)
        .forEach((result) => {
          data.push(result.row);
        });

      // Create worksheet and zip file
      const worksheet = utils.aoa_to_sheet(data);

      // Create hyperlinks
      for (let i = 1; i < data.length; i++) {
        const cellRef = `H${i + 1}`;
        const cellValue = worksheet[cellRef].v;
        worksheet[cellRef].l = { Target: cellValue };
      }

      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Transaktionen");

      zip.file(
        "Transaktionen.xlsx",
        write(workbook, { bookType: "xlsx", type: "binary" }),
        { binary: true }
      );

      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, "Transaktionen.zip");
      });

      setIsLoading(false);
    }
  };

  // Example Export CSV
  //
  // BlgNr  Belegnummer
  // Date	  Buchungsdatum
  // AccId	Fibu-Konto
  // Grp	  Beleg-Gruppe (2-stelliger alphanumerischer Code)
  // CAcc	  Gegenkonto (optional)
  // Type	  Soll/Haben (Soll=0, Haben=1)
  // TaxId	MWST-Code
  // BType	Buchungsart (Buchung=0,Kostenstellenbuchung=1, MWST-Buchung=2)
  // ValBt	Buchungsbetrag Brutto, MWST wird anhand des MWST-Codes von Topal berechnet
  // Text	  Buchungstext
  // MkTxB	Technischer Flag für MWST-Behandlung, muss 1 sein
  //
  // BlgNr;Date;AccId;Type;TaxId;BType;ValBt;Text;MkTxB
  // 16054;03.07.2023;1000;0;;0;6526.70;"Honorar";1
  // 16054;03.07.2023;6000;1;;0;6526.70;"Honorar";1
  // 16055;03.07.2023;1000;0;;0;2352.90;"Helsana KK";1
  // 16055;03.07.2023;2487;1;;0;2352.90;"Helsana KK";1
  const downloadTopal = async ({ aggregate }: { aggregate: boolean }) => {
    if (transactions && dateTo) {
      setIsLoading(true);

      // Get AccountingSystemConfig if available
      let accountingSystemConfig: AccountingSystemConfig | undefined;
      if (bookkeeping?.id) {
        try {
          const response = await queryList<
            AccountingSystemConfig,
            AccountingSystemConfigsByBookkeepingIdQueryVariables,
            AccountingSystemConfigsByBookkeepingIdQuery
          >(accountingSystemConfigsByBookkeepingId, {
            bookkeepingId: bookkeeping.id,
          });

          if (response.length > 0) {
            accountingSystemConfig = response[0];
          }
        } catch (error) {
          console.error("Error fetching AccountingSystemConfig", error);
        }
      }

      const accountNumberCash =
        accountingSystemConfig?.senderBankAccountId ?? 1000;

      const data: (string | number | object)[][] = [
        [
          "BlgNr", // Blg
          "Date", // Datum
          "AccId", // Kto
          "Type", // S/H
          "TaxId", // SId
          "BType",
          "ValBt", // Netto
          "Text", // Tx1
          "MkTxB",
        ],
      ];

      const aggregatedTransactions = getAggregatedTransactions(
        transactions,
        aggregate
      );

      for (const [
        index,
        aggregatedTransaction,
      ] of aggregatedTransactions.entries()) {
        const date = formatDate(
          aggregate
            ? dateTo
            : getLocalDate(new Date(aggregatedTransaction.paidAt))
        );

        const taxFirst =
          aggregatedTransaction.taxes.length > 0
            ? aggregatedTransaction.taxes[0]
            : null;
        const taxSecond =
          aggregatedTransaction.taxes.length > 1
            ? aggregatedTransaction.taxes[1]
            : null;

        // Credit account first
        data.push([
          index + 1,
          date,
          accountNumberCash,
          aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
            ? 0
            : 1,
          aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
            ? taxFirst?.taxRate.code ?? ""
            : "",
          0,
          aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
            ? taxFirst?.totalAmount ?? aggregatedTransaction.amount
            : aggregatedTransaction.amount,
          stringWithoutManualLineBreaks(aggregatedTransaction.title),
          1,
        ]);

        data.push([
          index + 1,
          date,
          aggregatedTransaction.bookingAccount.accountNumber,
          aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
            ? 1
            : 0,
          aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
            ? ""
            : taxFirst?.taxRate.code ?? "",
          0,
          aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
            ? aggregatedTransaction.amount
            : taxFirst?.totalAmount ?? aggregatedTransaction.amount,
          stringWithoutManualLineBreaks(aggregatedTransaction.title),
          1,
        ]);

        if (taxSecond) {
          data.push([
            index + 1,
            date,
            aggregatedTransaction.bookingAccount.accountNumber,
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? 1
              : 0,
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? ""
              : taxSecond.taxRate.code,
            0,
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? aggregatedTransaction.amount
              : taxSecond.totalAmount ?? aggregatedTransaction.amount,
            stringWithoutManualLineBreaks(aggregatedTransaction.title),
            1,
          ]);
        }
      }

      const worksheet = utils.aoa_to_sheet(data);

      const workbook = utils.book_new();

      utils.book_append_sheet(workbook, worksheet, "Transaktionen");

      const wbout = write(workbook, { bookType: "csv", type: "binary" });

      // Convert binary string to a buffer to remove BOM
      const buffer = new ArrayBuffer(wbout.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < wbout.length; i++)
        view[i] = wbout.charCodeAt(i) & 0xff;

      // Create blob from the buffer
      const blob = new Blob([buffer], { type: "text/csv;charset=utf-8" });

      saveAs(blob, "Transaktionen.csv");

      setIsLoading(false);
    }
  };

  // Example Export TXT
  // Date;Doc;DocInvoice;Description;AccountDebit;AccountCredit;Amount;VatCode;VatAmountType;VatRate;VatPosted;ExternalReference
  // 05.01.2024;1;;Rechnung 103 an Firma 1;110001;3000;5000.00;V81;;-8.10;-374.65;expensly-2024-01-05-d51e2315
  // 05.01.2024;2;;Bareinzahlung Kasse;1000;1020;2000.00;;;;expensly-2024-01-05-56139481
  // 30.03.2024;3;;Zahlungseingang Rechnung 103;1020;110001;5000.00;;;;expensly-2024-03-30-42b66dd4
  const downloadBanana = async ({ aggregate }: { aggregate: boolean }) => {
    if (transactions && dateTo) {
      setIsLoading(true);

      // Get AccountingSystemConfig if available
      let accountingSystemConfig: AccountingSystemConfig | undefined;
      if (bookkeeping?.id) {
        try {
          const response = await queryList<
            AccountingSystemConfig,
            AccountingSystemConfigsByBookkeepingIdQueryVariables,
            AccountingSystemConfigsByBookkeepingIdQuery
          >(accountingSystemConfigsByBookkeepingId, {
            bookkeepingId: bookkeeping.id,
          });

          if (response.length > 0) {
            accountingSystemConfig = response[0];
          }
        } catch (error) {
          console.error("Error fetching AccountingSystemConfig", error);
        }
      }

      const accountNumberCash =
        accountingSystemConfig?.senderBankAccountId ?? 1000;

      const data: (string | number | object)[][] = [
        [
          "Date",
          ...(aggregate ? [] : ["DocInvoice"]),
          "Description",
          "AccountDebit",
          "AccountCredit",
          "Amount",
          "VatCode",
          ...(aggregate ? [] : ["ExternalReference"]),
        ],
      ];

      const aggregatedTransactions = getAggregatedTransactions(
        transactions,
        aggregate
      );

      for (const aggregatedTransaction of aggregatedTransactions) {
        const date = format(
          aggregate
            ? dateTo
            : getLocalDate(new Date(aggregatedTransaction.paidAt)),
          "yyyy-MM-dd"
        );

        const reference = aggregate
          ? ""
          : `expensly-${aggregatedTransaction.id.split("-")[0]}`;

        const taxFirst =
          aggregatedTransaction.taxes.length > 0
            ? aggregatedTransaction.taxes[0]
            : null;
        const taxSecond =
          aggregatedTransaction.taxes.length > 1
            ? aggregatedTransaction.taxes[1]
            : null;

        if (taxFirst && taxSecond) {
          // Composed booking
          data.push([
            date,
            reference,
            stringWithoutManualLineBreaks(aggregatedTransaction.title),
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? accountNumberCash
              : "",
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? ""
              : accountNumberCash,
            aggregatedTransaction.amount,
            "",
            reference,
          ]);

          data.push([
            date,
            reference,
            stringWithoutManualLineBreaks(aggregatedTransaction.title),
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? ""
              : aggregatedTransaction.bookingAccount.accountNumber,
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? aggregatedTransaction.bookingAccount.accountNumber
              : "",
            taxFirst.totalAmount ?? aggregatedTransaction.amount,
            taxFirst.taxRate.code,
            reference,
          ]);

          data.push([
            date,
            reference,
            stringWithoutManualLineBreaks(aggregatedTransaction.title),
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? ""
              : aggregatedTransaction.bookingAccount.accountNumber,
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? aggregatedTransaction.bookingAccount.accountNumber
              : "",
            taxSecond.totalAmount ?? aggregatedTransaction.amount,
            taxSecond.taxRate.code,
            reference,
          ]);
        } else {
          // Single booking
          data.push([
            date,
            reference,
            stringWithoutManualLineBreaks(aggregatedTransaction.title),
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? accountNumberCash
              : aggregatedTransaction.bookingAccount.accountNumber,
            aggregatedTransaction.bookingAccount.taxType === TaxType.earnings
              ? aggregatedTransaction.bookingAccount.accountNumber
              : accountNumberCash,
            taxFirst?.totalAmount ?? aggregatedTransaction.amount,
            taxFirst?.taxRate.code ?? "",
            reference,
          ]);
        }
      }

      const worksheet = utils.aoa_to_sheet(data);

      const workbook = utils.book_new();

      utils.book_append_sheet(workbook, worksheet, "Transaktionen");

      const wbout = write(workbook, { bookType: "csv", type: "binary" });

      // Convert binary string to a buffer to remove BOM
      const buffer = new ArrayBuffer(wbout.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < wbout.length; i++)
        view[i] = wbout.charCodeAt(i) & 0xff;

      // Create blob from the buffer
      const blob = new Blob([buffer], { type: "text/csv;charset=utf-8" });

      saveAs(blob, "Transaktionen.txt");

      setIsLoading(false);
    }
  };

  const isDisabled = state !== "success" || transactions?.length === 0;

  const iconButton = (
    <IconButton
      variant="outline"
      aria-label="Download receipt"
      icon={<Image boxSize="20px" src={IconDownload} />}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={useMenu ? undefined : downloadExcel}
      as={useMenu ? "span" : undefined}
    />
  );

  const label = "Exportieren";

  return useMenu ? (
    <Menu>
      <Tooltip label={label}>
        <MenuButton disabled={isDisabled}>{iconButton}</MenuButton>
      </Tooltip>
      <MenuList minWidth="auto">
        <MenuItem onClick={downloadExcel}>Excel</MenuItem>
        {isTopal && !hasTaxes && (
          <MenuItem onClick={() => downloadTopal({ aggregate: true })}>
            Topal Sammelbuchungen
          </MenuItem>
        )}
        {isTopal && (
          <MenuItem onClick={() => downloadTopal({ aggregate: false })}>
            Topal Einzelbuchungen
          </MenuItem>
        )}
        {isBanana && (
          <MenuItem onClick={() => downloadBanana({ aggregate: false })}>
            Banana
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  ) : (
    <Tooltip label={label}>{iconButton}</Tooltip>
  );
};

export default DownloadButton;

const getAggregatedTransactions = (
  transactions: TransactionFull[],
  aggregate: boolean
): AggregatedTransaction[] => {
  // Aggregate transactions by bookingAccountId and sum their amounts
  const aggregatedTransactions = aggregate
    ? transactions.reduce((acc: TransactionAccumulator, transaction) => {
        if (!acc[transaction.bookingAccountId]) {
          acc[transaction.bookingAccountId] = {
            ...transaction,
            amount: 0,
            paidAt: transaction.paidAt,
            title: transaction.bookingAccount.name,
          };
        }
        acc[transaction.bookingAccountId].amount +=
          transaction.baseCurrencyAmount
            ? transaction.baseCurrencyAmount
            : transaction.amount;
        return acc;
      }, {})
    : transactions;

  // Convert the aggregated object back to an array
  const aggregatedTransactionsArray = Object.values(aggregatedTransactions);

  return aggregatedTransactionsArray;
};
