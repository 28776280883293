import { useState, useEffect } from "react";
import { queryList } from "../utils";
import { listUserBookkeepings } from "../graphql/queries";
import {
  ListUserBookkeepingsQuery,
  ListUserBookkeepingsQueryVariables,
} from "../API";
import { Bookkeeping, UserBookkeeping } from "../models";

// Custom hook for fetching user bookkeepings
type UseUserBookkeepingsProps = {
  bookkeepings: Bookkeeping[] | undefined;
};

export function useUserBookkeepings({
  bookkeepings,
}: UseUserBookkeepingsProps) {
  const [userBookkeepings, setUserBookkeepings] = useState<UserBookkeeping[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (bookkeepings) {
      const fetchUserBookkeepings = async () => {
        try {
          const userBookkeepings = await queryList<
            UserBookkeeping,
            ListUserBookkeepingsQueryVariables,
            ListUserBookkeepingsQuery
          >(listUserBookkeepings, {
            filter: {
              or: bookkeepings.map((bookkeeping) => ({
                bookkeepingId: {
                  eq: bookkeeping.id,
                },
              })),
            },
          });
          setUserBookkeepings(userBookkeepings);
          setIsLoading(false);
        } catch (err) {
          setError(err as Error);
          setIsLoading(false);
        }
      };
      fetchUserBookkeepings();
    }
  }, [bookkeepings]);

  return {
    userBookkeepings,
    isLoading,
    error,
  };
}
