import { Button, Image } from "@chakra-ui/react";
import IconPlus from "../../assets/icons/plus.svg";

type AddButtonProps<T> = {
  push: <T>(obj: T) => void;
  data: T;
  onComplete?: () => void;
  isDisabled?: boolean;
};

const AddButton = <T,>({
  push,
  data,
  onComplete,
  isDisabled,
}: AddButtonProps<T>) => {
  return (
    <Button
      variant="link"
      size="sm"
      leftIcon={<Image src={IconPlus} alt="Plus Icon" width="16px" />}
      onClick={() => {
        push<T>(data);
        if (onComplete) {
          onComplete();
        }
      }}
      isDisabled={isDisabled}
    >
      Hinzufügen
    </Button>
  );
};

export default AddButton;
