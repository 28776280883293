import { useEffect } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { getUserName } from "../utils";
import { User, UserFull } from "../models";
import Gleap from "gleap";

function useSetDatadogAndGleapUser(
  user: User | UserFull | undefined,
  page: string
) {
  useEffect(() => {
    if (user) {
      const name = getUserName(user, true);
      // Set Datadog Logs User
      datadogLogs.setUser({
        id: user.id,
        name: name,
        email: user.email,
      });
      datadogLogs.logger.info(page);

      // Set Datadog RUM User
      datadogRum.setUser({
        id: user.id,
        name: name,
        email: user.email,
      });

      // Set Gleap User
      if (!Gleap.isUserIdentified()) {
        Gleap.identify(user.email, {
          name: name.split(" ").reverse().join(" "),
          email: user.email,
        });
      }
    }
  }, [page, user]);
}

export default useSetDatadogAndGleapUser;
