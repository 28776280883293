import { useState, useEffect } from "react";
import { queryList } from "../utils";
import { listUsers } from "../graphql/queries";
import {
  ListUsersQuery,
  ListUsersQueryVariables,
  UserOrganization,
} from "../API";
import { User } from "../models";

// Custom hook for fetching users
type UseUsersProps = {
  userOrganizations: UserOrganization[] | undefined;
  includeDeleted?: boolean;
};

export function useUsers({
  userOrganizations,
  includeDeleted = false,
}: UseUsersProps) {
  const [users, setUsers] = useState<User[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (userOrganizations && userOrganizations.length > 0) {
      const fetchUsers = async () => {
        try {
          const users = await queryList<
            User,
            ListUsersQueryVariables,
            ListUsersQuery
          >(
            listUsers,
            {
              filter: {
                or: userOrganizations.map((userOrganization) => ({
                  id: {
                    eq: userOrganization.userId,
                  },
                })),
              },
            },
            includeDeleted
          );
          setUsers(users);
          setIsLoading(false);
        } catch (err) {
          setError(err as Error);
          setIsLoading(false);
        }
      };
      fetchUsers();
    }
  }, [includeDeleted, userOrganizations]);

  return {
    users,
    isLoading,
    error,
  };
}
