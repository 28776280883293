import { Image, Text, Tooltip, VStack } from "@chakra-ui/react";

type TooltipIconProps = {
  header: string;
  body: string;
  icon: string;
};

const TooltipIcon = ({ header, body, icon }: TooltipIconProps) => {
  return (
    <Tooltip
      placement="bottom-start"
      bg="white"
      p="12px"
      label={
        <VStack alignItems="start" spacing="4px">
          <Text color="error.700" fontSize={12} fontWeight={600}>
            {header}
          </Text>
          <Text color="error.500" fontSize={12} fontWeight={500}>
            {body}
          </Text>
        </VStack>
      }
    >
      <Image boxSize="20px" src={icon} />
    </Tooltip>
  );
};

export default TooltipIcon;
