import { useSortable } from "@dnd-kit/sortable";

import IconMenu from "../assets/icons/menu-01.svg";
import { Image } from "@chakra-ui/react";

const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners, isDragging } = useSortable({
    id: rowId,
  });
  return (
    // Alternatively, you could set these attributes on the rows themselves
    <Image
      src={IconMenu}
      {...attributes}
      {...listeners}
      cursor={isDragging ? "grabbing" : "grab"}
    />
  );
};

export default RowDragHandleCell;
