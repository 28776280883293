import { useState, useEffect } from "react";
import { queryList } from "../utils";
import { listUsers } from "../graphql/queries";
import {
  ListUsersQuery,
  ListUsersQueryVariables,
  UserOrganization,
} from "../API";
import { BookkeepingFull, OrganizationFull, User } from "../models";

// Custom hook for fetching users by bookkeeping
type UseUsersByBookkeepingProps = {
  userOrganizations: UserOrganization[] | undefined;
  selectedOrganization: OrganizationFull | undefined;
  selectedBookkeepingFull: BookkeepingFull | undefined;
};

export function useUsersByBookkeeping({
  userOrganizations,
  selectedOrganization,
  selectedBookkeepingFull,
}: UseUsersByBookkeepingProps) {
  const [users, setUsers] = useState<User[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (
      selectedBookkeepingFull &&
      selectedBookkeepingFull.userBookkeepings.length > 0 &&
      selectedBookkeepingFull.organizationId === selectedOrganization?.id &&
      userOrganizations &&
      userOrganizations.length > 0
    ) {
      const fetchUsers = async () => {
        try {
          const users = await queryList<
            User,
            ListUsersQueryVariables,
            ListUsersQuery
          >(listUsers, {
            filter: {
              or: selectedBookkeepingFull?.userBookkeepings
                .filter((userBookkeeping) =>
                  userOrganizations.some(
                    (userOrganization) =>
                      userOrganization.userId === userBookkeeping.userId
                  )
                )
                .map((userBookkeeping) => ({
                  id: {
                    eq: userBookkeeping.userId,
                  },
                })),
            },
          });
          setUsers(users);
          setIsLoading(false);
        } catch (err) {
          setError(err as Error);
          setIsLoading(false);
        }
      };
      fetchUsers();
    }
  }, [selectedBookkeepingFull, selectedOrganization, userOrganizations]);

  return {
    users,
    isLoading,
    error,
  };
}
