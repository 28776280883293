import { Button, Text, VStack } from "@chakra-ui/react";
import { createOrganizationText } from "../utils";
import { useNavigate } from "react-router-dom";
import { routeCreateOrganizationOrganization } from "../routes/routes";

const NoOrganization = () => {
  const navigate = useNavigate();

  return (
    <VStack alignItems="start" spacing="12px">
      <Text fontSize={14} fontWeight={"semibold"} color={"gray.700"}>
        Keine Organisationen gefunden
      </Text>
      <Text fontSize={14} color="gray.600">
        Lege jetzt Deine erste Organisation an.
      </Text>
      <Button
        width="full"
        colorScheme="primary"
        onClick={() => {
          navigate(routeCreateOrganizationOrganization);
        }}
      >
        {createOrganizationText}
      </Button>
    </VStack>
  );
};

export default NoOrganization;
