import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import {
  Box,
  Button,
  CloseButton,
  HStack,
  Image,
  Link,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import LogoBexio from "../../assets/logos/bexio.svg";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { API } from "aws-amplify";
import { v4 as guid } from "uuid";
import { OnboardingStep } from "./onboarding-layout";
import useSessionStorage from "../../hooks/use-session-storage";
import useLocalStorage from "../../hooks/use-local-storage";
import { AxiosError, isAxiosError } from "axios";
import { ExternalLinkIcon, WarningIcon } from "@chakra-ui/icons";

import PermissionAccountingReports from "../../assets/accounting_permissions/permissionAccountingReports.png";
import PermissionAdmin from "../../assets/accounting_permissions/permissionAdmin.png";
import PermissionBanking from "../../assets/accounting_permissions/permissionBanking.png";
import PermissionContact from "../../assets/accounting_permissions/permissionContact.png";
import PermissionFm from "../../assets/accounting_permissions/permissionFm.png";
import PermissionKbBill from "../../assets/accounting_permissions/permissionKbBill.png";

const commonToastStyles = {
  p: 4,
  bg: "red.500",
  borderRadius: "md",
  boxShadow: "lg",
  color: "white",
  display: "flex",
};

type AuthState = "authorize" | "sso" | "complete" | "error";

type UserInfo = {
  email: string;
  given_name: string;
  family_name: string;
};

type AuthValues = {
  authState: AuthState;
  state?: string;
  userInfo?: UserInfo;
};
const keyAuthValues: OnboardingStep = "auth";

export type AccountingSystemValues = {
  provider: Provider;
};
export const keyAccountingSystemValues = "accounting-system";

type Provider = "bexio";

type Permissions = {
  userId: number;
  hasFmPermission: boolean;
  hasAccountingReportsPermission: boolean;
  hasAdminPermission: boolean;
  hasBankingPermission: boolean;
  hasKbBillPermission: boolean;
  hasContactPermission: boolean;
};

type Permission = keyof Omit<Permissions, "userId">;

type AdminAndPermissions = "NoAdmin" | Permissions;

const bexioClientId = "46c01f33-46db-4f0d-a003-c5977d06bce5";
const bexioRedirectUriDev =
  "https://cqtpwosabc.execute-api.eu-central-1.amazonaws.com/dev/auth/bexio/callback";
const bexioRedirectUriStaging =
  "https://bbx692x960.execute-api.eu-central-1.amazonaws.com/staging/auth/bexio/callback";
const bexioRedirectUriProd =
  "https://g2zypbu8b6.execute-api.eu-central-1.amazonaws.com/prod/auth/bexio/callback";

const nameFields = {
  given_name: {
    label: "Vorname",
    placeholder: "Vorname eingeben",
    isRequired: true,
    order: 1,
  },
  family_name: {
    label: "Nachname",
    placeholder: "Nachname eingeben",
    isRequired: true,
    order: 2,
  },
};

const Auth = () => {
  const { route, toSignIn, toSignUp, authStatus } = useAuthenticator(
    (context) => [context.route, context.authStatus]
  );

  const toast = useToast();

  const [authValues, setAuthValues] =
    useSessionStorage<AuthValues>(keyAuthValues);
  const [, setAccountingValues] = useLocalStorage<AccountingSystemValues>(
    keyAccountingSystemValues
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const provider = searchParams.get("provider") as Provider;
  const secret = searchParams.get("secret");
  const state = searchParams.get("state");
  const error = searchParams.get("error");

  const initiateBexioAuth = useCallback(() => {
    setAuthValues({
      authState: "authorize",
      state: guid(),
    });
  }, [setAuthValues]);

  useEffect(() => {
    if (provider === "bexio") {
      setSearchParams();
      toSignUp();
      initiateBexioAuth();
    }
  }, [initiateBexioAuth, provider, setSearchParams, toSignUp]);

  useEffect(() => {
    if (
      authStatus === "unauthenticated" &&
      secret &&
      state &&
      authValues?.authState === "sso"
    ) {
      setSearchParams();
      const finalizeAuthFlow = async () => {
        try {
          // Check if state matches
          if (state !== authValues.state) {
            throw new Error("State mismatch");
          }

          const response = await API.post("expenslyREST", "/auth/bexio/sso", {
            body: {
              secret,
            },
          });

          setAuthValues({
            authState: "complete",
            userInfo: response,
          });

          setAccountingValues({
            provider: "bexio",
          });

          toSignUp();
        } catch (error) {
          setAuthValues({ authState: "error" });
          if (isAxiosError(error)) {
            const axiosError = error as AxiosError<{
              error: AdminAndPermissions;
            }>;
            if (axiosError.response?.data.error === "NoAdmin") {
              toast({
                title: "Bitte melde Dich mit dem Adminbenutzer an",
                status: "error",
              });
            } else if (axiosError.response?.data.error) {
              const permissionsUrl = `https://office.bexio.com/user_manager/editRights/id/${axiosError.response.data.error.userId}`;
              const permissionsMissing: Permission[] = Object.entries(
                axiosError.response.data.error
              )
                .filter(([, value]) => value === false)
                .map(([key]) => key as Permission);
              toast({
                duration: 99999,
                render: ({ onClose }) => (
                  <Box {...commonToastStyles} position="relative">
                    <WarningIcon boxSize={5} mr={3} />
                    <VStack alignItems="start">
                      <Text fontWeight="bold" mb={1}>
                        {`Fehlende Berechtigung${
                          permissionsMissing.length > 1 ? "en" : ""
                        }`}
                      </Text>
                      <Link
                        href={permissionsUrl}
                        isExternal
                        color="white"
                        textDecoration="underline"
                      >
                        bexio Benutzerrechte bearbeiten{" "}
                        <ExternalLinkIcon mx="2px" />
                      </Link>
                      <Text>{`Bitte erteile folgende Berechtigung${
                        permissionsMissing.length > 1 ? "en" : ""
                      }:`}</Text>
                      {permissionsMissing.map((permission) => {
                        return (
                          <Image
                            src={
                              permission === "hasAccountingReportsPermission"
                                ? PermissionAccountingReports
                                : permission === "hasAdminPermission"
                                ? PermissionAdmin
                                : permission === "hasBankingPermission"
                                ? PermissionBanking
                                : permission === "hasContactPermission"
                                ? PermissionContact
                                : permission === "hasFmPermission"
                                ? PermissionFm
                                : PermissionKbBill
                            }
                          />
                        );
                      })}
                      <Button
                        variant="outline"
                        background={"white"}
                        leftIcon={<Image src={LogoBexio} width="24px" />}
                        onClick={() => {
                          initiateBexioAuth();
                          onClose();
                        }}
                      >
                        Erneut mit bexio anmelden
                      </Button>
                    </VStack>
                    <CloseButton
                      position="absolute"
                      right="4px"
                      top="4px"
                      color="white"
                      size="sm"
                      onClick={onClose}
                    />
                  </Box>
                ),
              });
            }
          } else {
            toast({
              title: "Fehler beim Anmelden",
              status: "error",
            });
          }
        }
      };

      finalizeAuthFlow();
    } else if (error) {
      setAuthValues({ authState: "error" });
      setSearchParams();
      toast({
        title: "Fehler beim Anmelden",
        status: "error",
      });
    }
  }, [
    authStatus,
    authValues,
    error,
    initiateBexioAuth,
    secret,
    setAccountingValues,
    setAuthValues,
    setSearchParams,
    state,
    toSignUp,
    toast,
  ]);

  useEffect(() => {
    if (
      authStatus === "unauthenticated" &&
      authValues?.authState === "authorize"
    ) {
      setAuthValues({ ...authValues, authState: "sso" });
      window.location.replace(
        `https://idp.bexio.com/authorize?client_id=${bexioClientId}&response_type=code&redirect_uri=${
          process.env.REACT_APP_ENV === "prod"
            ? bexioRedirectUriProd
            : process.env.REACT_APP_ENV === "staging"
            ? bexioRedirectUriStaging
            : bexioRedirectUriDev
        }&scope=${encodeURIComponent(
          [
            "openid",
            "offline_access",
            "file",
            "contact_edit",
            "kb_bill_show",
          ].join(" ")
        )}&state=${authValues.state}`
      );
    }
  }, [authStatus, authValues, setAuthValues]);

  useEffect(() => {
    if (
      authStatus === "authenticated" &&
      authValues?.authState === "complete"
    ) {
      sessionStorage.removeItem(keyAuthValues);
    }
  }, [authStatus, authValues?.authState]);

  return authStatus === "configuring" ||
    route === "transition" ||
    authValues?.authState === "authorize" ||
    authValues?.authState === "sso" ? (
    <Spinner />
  ) : (
    <Authenticator
      initialState={
        authValues && authValues.authState === "complete" ? "signUp" : "signIn"
      }
      formFields={{
        forceNewPassword: nameFields,
        signUp: authValues?.userInfo
          ? {
              email: {
                defaultValue: authValues.userInfo.email,
                isReadOnly: true,
              },
              given_name: {
                ...nameFields.given_name,
                defaultValue: authValues.userInfo.given_name,
              },
              family_name: {
                ...nameFields.family_name,
                defaultValue: authValues.userInfo.family_name,
              },
            }
          : nameFields,
      }}
      components={{
        Header: () => (
          <VStack alignItems="start" spacing="12px">
            <Text fontSize={36} fontWeight="medium" color="gray.900">
              {route === "signUp" ? "Registrierung" : "Anmeldung"}
            </Text>
            <Text>
              {route === "forceNewPassword"
                ? "Bitte wähle ein neues Passwort"
                : `E-Mail-Adresse und Passwort eingeben, um sich ${
                    route === "signUp" ? "zu registrieren" : "anzumelden"
                  }`}
            </Text>
            {route === "signUp" && authValues?.authState !== "complete" && (
              <Button
                width="full"
                variant="outline"
                leftIcon={<Image src={LogoBexio} width="24px" />}
                onClick={initiateBexioAuth}
              >
                Mit bexio anmelden
              </Button>
            )}
          </VStack>
        ),
        Footer: () => (
          <VStack width="full" spacing="32px">
            <HStack width="full" justify="center">
              <Text color="gray.600" fontSize={14}>
                {route === "signIn"
                  ? "Noch keinen Account?"
                  : "Bereits ein Konto?"}
              </Text>
              <Button
                variant="link"
                colorScheme="primary"
                fontSize={14}
                fontWeight="semibold"
                onClick={() => {
                  if (route === "signIn") {
                    toSignUp();
                  } else {
                    toSignIn();
                  }
                }}
              >
                {route === "signIn" ? "Registrieren" : "Anmelden"}
              </Button>
            </HStack>
          </VStack>
        ),
      }}
    />
  );
};

export default Auth;
