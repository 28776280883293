import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate, useLocation } from "react-router-dom";
import { routeOnboarding, routeTransactions } from "./routes";

const Home = () => {
  const location = useLocation();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  return authStatus === "authenticated" ? (
    <Navigate to={routeTransactions} state={{ from: location }} replace />
  ) : authStatus === "unauthenticated" ? (
    <Navigate to={routeOnboarding} state={{ from: location }} replace />
  ) : (
    <></>
  );
};

export default Home;
