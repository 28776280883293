/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    deletedAt
    name
    userOrganizations {
      nextToken
      __typename
    }
    bookkeepings {
      nextToken
      __typename
    }
    type
    logo
    billing {
      id
      deletedAt
      userId
      customerId
      subscriptionId
      subscriptionItemId
      quantity
      status
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    organizationBillingId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      name
      type
      logo
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      organizationBillingId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getBilling = /* GraphQL */ `query GetBilling($id: ID!) {
  getBilling(id: $id) {
    id
    deletedAt
    userId
    customerId
    subscriptionId
    subscriptionItemId
    quantity
    status
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBillingQueryVariables,
  APITypes.GetBillingQuery
>;
export const listBillings = /* GraphQL */ `query ListBillings(
  $filter: ModelBillingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBillings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      userId
      customerId
      subscriptionId
      subscriptionItemId
      quantity
      status
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBillingsQueryVariables,
  APITypes.ListBillingsQuery
>;
export const billingsByUserId = /* GraphQL */ `query BillingsByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBillingFilterInput
  $limit: Int
  $nextToken: String
) {
  billingsByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      userId
      customerId
      subscriptionId
      subscriptionItemId
      quantity
      status
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BillingsByUserIdQueryVariables,
  APITypes.BillingsByUserIdQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    deletedAt
    firstName
    lastName
    email
    userOrganizations {
      nextToken
      __typename
    }
    userBookkeepings {
      nextToken
      __typename
    }
    role
    transactions {
      nextToken
      __typename
    }
    isSSO
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      firstName
      lastName
      email
      role
      isSSO
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getUserOrganization = /* GraphQL */ `query GetUserOrganization($id: ID!) {
  getUserOrganization(id: $id) {
    id
    deletedAt
    userId
    organizationId
    role
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserOrganizationQueryVariables,
  APITypes.GetUserOrganizationQuery
>;
export const listUserOrganizations = /* GraphQL */ `query ListUserOrganizations(
  $filter: ModelUserOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      userId
      organizationId
      role
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserOrganizationsQueryVariables,
  APITypes.ListUserOrganizationsQuery
>;
export const userOrganizationsByUserId = /* GraphQL */ `query UserOrganizationsByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  userOrganizationsByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      userId
      organizationId
      role
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserOrganizationsByUserIdQueryVariables,
  APITypes.UserOrganizationsByUserIdQuery
>;
export const userOrganizationsByOrganizationId = /* GraphQL */ `query UserOrganizationsByOrganizationId(
  $organizationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  userOrganizationsByOrganizationId(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      userId
      organizationId
      role
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserOrganizationsByOrganizationIdQueryVariables,
  APITypes.UserOrganizationsByOrganizationIdQuery
>;
export const getBookkeeping = /* GraphQL */ `query GetBookkeeping($id: ID!) {
  getBookkeeping(id: $id) {
    id
    deletedAt
    name
    organizationId
    userBookkeepings {
      nextToken
      __typename
    }
    accountingSystem
    accountingSystemConfig {
      id
      deletedAt
      baseCurrency
      billingPeriod
      senderBankAccountId
      reimbursement
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    bookingAccounts {
      nextToken
      __typename
    }
    taxRates {
      nextToken
      __typename
    }
    bookkeepingYears {
      nextToken
      __typename
    }
    color
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    bookkeepingAccountingSystemConfigId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookkeepingQueryVariables,
  APITypes.GetBookkeepingQuery
>;
export const listBookkeepings = /* GraphQL */ `query ListBookkeepings(
  $filter: ModelBookkeepingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookkeepings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      name
      organizationId
      accountingSystem
      color
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      bookkeepingAccountingSystemConfigId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookkeepingsQueryVariables,
  APITypes.ListBookkeepingsQuery
>;
export const bookkeepingsByOrganizationId = /* GraphQL */ `query BookkeepingsByOrganizationId(
  $organizationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookkeepingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookkeepingsByOrganizationId(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      organizationId
      accountingSystem
      color
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      bookkeepingAccountingSystemConfigId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookkeepingsByOrganizationIdQueryVariables,
  APITypes.BookkeepingsByOrganizationIdQuery
>;
export const getBookkeepingYear = /* GraphQL */ `query GetBookkeepingYear($id: ID!) {
  getBookkeepingYear(id: $id) {
    id
    deletedAt
    bookkeepingId
    transactions {
      nextToken
      __typename
    }
    hasNetTaxRate
    year
    balanceStart
    balance
    closedAt
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookkeepingYearQueryVariables,
  APITypes.GetBookkeepingYearQuery
>;
export const listBookkeepingYears = /* GraphQL */ `query ListBookkeepingYears(
  $filter: ModelBookkeepingYearFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookkeepingYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      bookkeepingId
      hasNetTaxRate
      year
      balanceStart
      balance
      closedAt
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookkeepingYearsQueryVariables,
  APITypes.ListBookkeepingYearsQuery
>;
export const bookkeepingYearsByBookkeepingId = /* GraphQL */ `query BookkeepingYearsByBookkeepingId(
  $bookkeepingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookkeepingYearFilterInput
  $limit: Int
  $nextToken: String
) {
  bookkeepingYearsByBookkeepingId(
    bookkeepingId: $bookkeepingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      bookkeepingId
      hasNetTaxRate
      year
      balanceStart
      balance
      closedAt
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookkeepingYearsByBookkeepingIdQueryVariables,
  APITypes.BookkeepingYearsByBookkeepingIdQuery
>;
export const getUserBookkeeping = /* GraphQL */ `query GetUserBookkeeping($id: ID!) {
  getUserBookkeeping(id: $id) {
    id
    deletedAt
    userId
    bookkeepingId
    accountingSystemBill {
      id
      deletedAt
      supplierId
      contactPartnerId
      senderBankAccountId
      billingPeriod
      baseCurrency
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      accountingSystemBillAccountingSystemReimbursementId
      __typename
    }
    bills {
      nextToken
      __typename
    }
    transactionReferences {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    userBookkeepingAccountingSystemBillId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserBookkeepingQueryVariables,
  APITypes.GetUserBookkeepingQuery
>;
export const listUserBookkeepings = /* GraphQL */ `query ListUserBookkeepings(
  $filter: ModelUserBookkeepingFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserBookkeepings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserBookkeepingsQueryVariables,
  APITypes.ListUserBookkeepingsQuery
>;
export const userBookkeepingsByUserId = /* GraphQL */ `query UserBookkeepingsByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserBookkeepingFilterInput
  $limit: Int
  $nextToken: String
) {
  userBookkeepingsByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserBookkeepingsByUserIdQueryVariables,
  APITypes.UserBookkeepingsByUserIdQuery
>;
export const userBookkeepingsByBookkeepingId = /* GraphQL */ `query UserBookkeepingsByBookkeepingId(
  $bookkeepingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserBookkeepingFilterInput
  $limit: Int
  $nextToken: String
) {
  userBookkeepingsByBookkeepingId(
    bookkeepingId: $bookkeepingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserBookkeepingsByBookkeepingIdQueryVariables,
  APITypes.UserBookkeepingsByBookkeepingIdQuery
>;
export const getTransactionReference = /* GraphQL */ `query GetTransactionReference($id: ID!) {
  getTransactionReference(id: $id) {
    id
    deletedAt
    name
    userBookkeeping {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    userBookkeepingId
    transactions {
      nextToken
      __typename
    }
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionReferenceQueryVariables,
  APITypes.GetTransactionReferenceQuery
>;
export const listTransactionReferences = /* GraphQL */ `query ListTransactionReferences(
  $filter: ModelTransactionReferenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactionReferences(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionReferencesQueryVariables,
  APITypes.ListTransactionReferencesQuery
>;
export const transactionReferencesByUserBookkeepingId = /* GraphQL */ `query TransactionReferencesByUserBookkeepingId(
  $userBookkeepingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionReferenceFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionReferencesByUserBookkeepingId(
    userBookkeepingId: $userBookkeepingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionReferencesByUserBookkeepingIdQueryVariables,
  APITypes.TransactionReferencesByUserBookkeepingIdQuery
>;
export const transactionReferencesByAuthUserId = /* GraphQL */ `query TransactionReferencesByAuthUserId(
  $authUserId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionReferenceFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionReferencesByAuthUserId(
    authUserId: $authUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionReferencesByAuthUserIdQueryVariables,
  APITypes.TransactionReferencesByAuthUserIdQuery
>;
export const getAccountingSystemConfig = /* GraphQL */ `query GetAccountingSystemConfig($id: ID!) {
  getAccountingSystemConfig(id: $id) {
    id
    deletedAt
    baseCurrency
    billingPeriod
    senderBankAccountId
    reimbursement
    bookkeepingId
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountingSystemConfigQueryVariables,
  APITypes.GetAccountingSystemConfigQuery
>;
export const listAccountingSystemConfigs = /* GraphQL */ `query ListAccountingSystemConfigs(
  $filter: ModelAccountingSystemConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountingSystemConfigs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      baseCurrency
      billingPeriod
      senderBankAccountId
      reimbursement
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountingSystemConfigsQueryVariables,
  APITypes.ListAccountingSystemConfigsQuery
>;
export const accountingSystemConfigsByBookkeepingId = /* GraphQL */ `query AccountingSystemConfigsByBookkeepingId(
  $bookkeepingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountingSystemConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  accountingSystemConfigsByBookkeepingId(
    bookkeepingId: $bookkeepingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      baseCurrency
      billingPeriod
      senderBankAccountId
      reimbursement
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccountingSystemConfigsByBookkeepingIdQueryVariables,
  APITypes.AccountingSystemConfigsByBookkeepingIdQuery
>;
export const getAccountingSystemBill = /* GraphQL */ `query GetAccountingSystemBill($id: ID!) {
  getAccountingSystemBill(id: $id) {
    id
    deletedAt
    supplierId
    contactPartnerId
    senderBankAccountId
    billingPeriod
    baseCurrency
    accountingSystemReimbursement {
      id
      deletedAt
      receiverIban
      receiverStreet
      receiverHouseNumber
      receiverCity
      receiverPostalCode
      receiverCountry
      accountingSystemBillId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    userBookkeepingId
    authUserId
    authGroups
    createdAt
    updatedAt
    accountingSystemBillAccountingSystemReimbursementId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountingSystemBillQueryVariables,
  APITypes.GetAccountingSystemBillQuery
>;
export const listAccountingSystemBills = /* GraphQL */ `query ListAccountingSystemBills(
  $filter: ModelAccountingSystemBillFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountingSystemBills(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      supplierId
      contactPartnerId
      senderBankAccountId
      billingPeriod
      baseCurrency
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      accountingSystemBillAccountingSystemReimbursementId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountingSystemBillsQueryVariables,
  APITypes.ListAccountingSystemBillsQuery
>;
export const accountingSystemBillsByUserBookkeepingId = /* GraphQL */ `query AccountingSystemBillsByUserBookkeepingId(
  $userBookkeepingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountingSystemBillFilterInput
  $limit: Int
  $nextToken: String
) {
  accountingSystemBillsByUserBookkeepingId(
    userBookkeepingId: $userBookkeepingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      supplierId
      contactPartnerId
      senderBankAccountId
      billingPeriod
      baseCurrency
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      accountingSystemBillAccountingSystemReimbursementId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccountingSystemBillsByUserBookkeepingIdQueryVariables,
  APITypes.AccountingSystemBillsByUserBookkeepingIdQuery
>;
export const accountingSystemBillsByAuthUserId = /* GraphQL */ `query AccountingSystemBillsByAuthUserId(
  $authUserId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountingSystemBillFilterInput
  $limit: Int
  $nextToken: String
) {
  accountingSystemBillsByAuthUserId(
    authUserId: $authUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      supplierId
      contactPartnerId
      senderBankAccountId
      billingPeriod
      baseCurrency
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      accountingSystemBillAccountingSystemReimbursementId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccountingSystemBillsByAuthUserIdQueryVariables,
  APITypes.AccountingSystemBillsByAuthUserIdQuery
>;
export const getAccountingSystemReimbursement = /* GraphQL */ `query GetAccountingSystemReimbursement($id: ID!) {
  getAccountingSystemReimbursement(id: $id) {
    id
    deletedAt
    receiverIban
    receiverStreet
    receiverHouseNumber
    receiverCity
    receiverPostalCode
    receiverCountry
    accountingSystemBillId
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountingSystemReimbursementQueryVariables,
  APITypes.GetAccountingSystemReimbursementQuery
>;
export const listAccountingSystemReimbursements = /* GraphQL */ `query ListAccountingSystemReimbursements(
  $filter: ModelAccountingSystemReimbursementFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountingSystemReimbursements(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      receiverIban
      receiverStreet
      receiverHouseNumber
      receiverCity
      receiverPostalCode
      receiverCountry
      accountingSystemBillId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountingSystemReimbursementsQueryVariables,
  APITypes.ListAccountingSystemReimbursementsQuery
>;
export const accountingSystemReimbursementsByAccountingSystemBillId = /* GraphQL */ `query AccountingSystemReimbursementsByAccountingSystemBillId(
  $accountingSystemBillId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountingSystemReimbursementFilterInput
  $limit: Int
  $nextToken: String
) {
  accountingSystemReimbursementsByAccountingSystemBillId(
    accountingSystemBillId: $accountingSystemBillId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      receiverIban
      receiverStreet
      receiverHouseNumber
      receiverCity
      receiverPostalCode
      receiverCountry
      accountingSystemBillId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccountingSystemReimbursementsByAccountingSystemBillIdQueryVariables,
  APITypes.AccountingSystemReimbursementsByAccountingSystemBillIdQuery
>;
export const accountingSystemReimbursementsByAuthUserId = /* GraphQL */ `query AccountingSystemReimbursementsByAuthUserId(
  $authUserId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountingSystemReimbursementFilterInput
  $limit: Int
  $nextToken: String
) {
  accountingSystemReimbursementsByAuthUserId(
    authUserId: $authUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      receiverIban
      receiverStreet
      receiverHouseNumber
      receiverCity
      receiverPostalCode
      receiverCountry
      accountingSystemBillId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccountingSystemReimbursementsByAuthUserIdQueryVariables,
  APITypes.AccountingSystemReimbursementsByAuthUserIdQuery
>;
export const getBookingAccount = /* GraphQL */ `query GetBookingAccount($id: ID!) {
  getBookingAccount(id: $id) {
    id
    deletedAt
    name
    accountNumber
    sortOrder
    taxType
    transactionCategory {
      id
      deletedAt
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    transactionCategoryId
    accountingSystemId
    bookkeepingId
    transactions {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingAccountQueryVariables,
  APITypes.GetBookingAccountQuery
>;
export const listBookingAccounts = /* GraphQL */ `query ListBookingAccounts(
  $filter: ModelBookingAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookingAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      name
      accountNumber
      sortOrder
      taxType
      transactionCategoryId
      accountingSystemId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingAccountsQueryVariables,
  APITypes.ListBookingAccountsQuery
>;
export const bookingAccountsByTransactionCategoryId = /* GraphQL */ `query BookingAccountsByTransactionCategoryId(
  $transactionCategoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingAccountsByTransactionCategoryId(
    transactionCategoryId: $transactionCategoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      accountNumber
      sortOrder
      taxType
      transactionCategoryId
      accountingSystemId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingAccountsByTransactionCategoryIdQueryVariables,
  APITypes.BookingAccountsByTransactionCategoryIdQuery
>;
export const bookingAccountsByBookkeepingId = /* GraphQL */ `query BookingAccountsByBookkeepingId(
  $bookkeepingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingAccountsByBookkeepingId(
    bookkeepingId: $bookkeepingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      accountNumber
      sortOrder
      taxType
      transactionCategoryId
      accountingSystemId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingAccountsByBookkeepingIdQueryVariables,
  APITypes.BookingAccountsByBookkeepingIdQuery
>;
export const getTaxRate = /* GraphQL */ `query GetTaxRate($id: ID!) {
  getTaxRate(id: $id) {
    id
    deletedAt
    code
    rate
    taxType
    sortOrder
    accountingSystemId
    bookkeepingId
    dateFrom
    dateTo
    taxes {
      nextToken
      __typename
    }
    authGroupsRead
    authGroupsUpdate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTaxRateQueryVariables,
  APITypes.GetTaxRateQuery
>;
export const listTaxRates = /* GraphQL */ `query ListTaxRates(
  $filter: ModelTaxRateFilterInput
  $limit: Int
  $nextToken: String
) {
  listTaxRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      code
      rate
      taxType
      sortOrder
      accountingSystemId
      bookkeepingId
      dateFrom
      dateTo
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTaxRatesQueryVariables,
  APITypes.ListTaxRatesQuery
>;
export const taxRatesByBookkeepingId = /* GraphQL */ `query TaxRatesByBookkeepingId(
  $bookkeepingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTaxRateFilterInput
  $limit: Int
  $nextToken: String
) {
  taxRatesByBookkeepingId(
    bookkeepingId: $bookkeepingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      code
      rate
      taxType
      sortOrder
      accountingSystemId
      bookkeepingId
      dateFrom
      dateTo
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaxRatesByBookkeepingIdQueryVariables,
  APITypes.TaxRatesByBookkeepingIdQuery
>;
export const getTransactionCategory = /* GraphQL */ `query GetTransactionCategory($id: ID!) {
  getTransactionCategory(id: $id) {
    id
    deletedAt
    name
    icon
    bookingAccounts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionCategoryQueryVariables,
  APITypes.GetTransactionCategoryQuery
>;
export const listTransactionCategories = /* GraphQL */ `query ListTransactionCategories(
  $filter: ModelTransactionCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactionCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionCategoriesQueryVariables,
  APITypes.ListTransactionCategoriesQuery
>;
export const getBill = /* GraphQL */ `query GetBill($id: ID!) {
  getBill(id: $id) {
    id
    deletedAt
    name
    accountingSystemId
    dateFrom
    dateTo
    status
    amount
    currency
    baseCurrencyAmount
    transactions {
      nextToken
      __typename
    }
    userBookkeeping {
      id
      deletedAt
      userId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      userBookkeepingAccountingSystemBillId
      __typename
    }
    userBookkeepingId
    authUserId
    authGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBillQueryVariables, APITypes.GetBillQuery>;
export const listBills = /* GraphQL */ `query ListBills(
  $filter: ModelBillFilterInput
  $limit: Int
  $nextToken: String
) {
  listBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      name
      accountingSystemId
      dateFrom
      dateTo
      status
      amount
      currency
      baseCurrencyAmount
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBillsQueryVariables, APITypes.ListBillsQuery>;
export const billsByUserBookkeepingId = /* GraphQL */ `query BillsByUserBookkeepingId(
  $userBookkeepingId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBillFilterInput
  $limit: Int
  $nextToken: String
) {
  billsByUserBookkeepingId(
    userBookkeepingId: $userBookkeepingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      accountingSystemId
      dateFrom
      dateTo
      status
      amount
      currency
      baseCurrencyAmount
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BillsByUserBookkeepingIdQueryVariables,
  APITypes.BillsByUserBookkeepingIdQuery
>;
export const billsByAuthUserId = /* GraphQL */ `query BillsByAuthUserId(
  $authUserId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBillFilterInput
  $limit: Int
  $nextToken: String
) {
  billsByAuthUserId(
    authUserId: $authUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      name
      accountingSystemId
      dateFrom
      dateTo
      status
      amount
      currency
      baseCurrencyAmount
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BillsByAuthUserIdQueryVariables,
  APITypes.BillsByAuthUserIdQuery
>;
export const getTransaction = /* GraphQL */ `query GetTransaction($id: ID!) {
  getTransaction(id: $id) {
    id
    deletedAt
    paidAt
    currencyCode
    taxes {
      nextToken
      __typename
    }
    amount
    attachments {
      nextToken
      __typename
    }
    title
    exchangeRate
    baseCurrencyAmount
    status
    accountingSystemId
    bookkeepingYear {
      id
      deletedAt
      bookkeepingId
      hasNetTaxRate
      year
      balanceStart
      balance
      closedAt
      authGroups
      createdAt
      updatedAt
      __typename
    }
    bookkeepingYearId
    bill {
      id
      deletedAt
      name
      accountingSystemId
      dateFrom
      dateTo
      status
      amount
      currency
      baseCurrencyAmount
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    billId
    bookingAccount {
      id
      deletedAt
      name
      accountNumber
      sortOrder
      taxType
      transactionCategoryId
      accountingSystemId
      bookkeepingId
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    bookingAccountId
    transactionReference {
      id
      deletedAt
      name
      userBookkeepingId
      authUserId
      authGroups
      createdAt
      updatedAt
      __typename
    }
    transactionReferenceId
    user {
      id
      deletedAt
      firstName
      lastName
      email
      role
      isSSO
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    userId
    replacedById
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionQueryVariables,
  APITypes.GetTransactionQuery
>;
export const listTransactions = /* GraphQL */ `query ListTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      paidAt
      currencyCode
      amount
      title
      exchangeRate
      baseCurrencyAmount
      status
      accountingSystemId
      bookkeepingYearId
      billId
      bookingAccountId
      transactionReferenceId
      userId
      replacedById
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsQueryVariables,
  APITypes.ListTransactionsQuery
>;
export const transactionsByBookkeepingYearId = /* GraphQL */ `query TransactionsByBookkeepingYearId(
  $bookkeepingYearId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByBookkeepingYearId(
    bookkeepingYearId: $bookkeepingYearId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      paidAt
      currencyCode
      amount
      title
      exchangeRate
      baseCurrencyAmount
      status
      accountingSystemId
      bookkeepingYearId
      billId
      bookingAccountId
      transactionReferenceId
      userId
      replacedById
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByBookkeepingYearIdQueryVariables,
  APITypes.TransactionsByBookkeepingYearIdQuery
>;
export const transactionsByBillId = /* GraphQL */ `query TransactionsByBillId(
  $billId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByBillId(
    billId: $billId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      paidAt
      currencyCode
      amount
      title
      exchangeRate
      baseCurrencyAmount
      status
      accountingSystemId
      bookkeepingYearId
      billId
      bookingAccountId
      transactionReferenceId
      userId
      replacedById
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByBillIdQueryVariables,
  APITypes.TransactionsByBillIdQuery
>;
export const transactionsByBookingAccountId = /* GraphQL */ `query TransactionsByBookingAccountId(
  $bookingAccountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByBookingAccountId(
    bookingAccountId: $bookingAccountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      paidAt
      currencyCode
      amount
      title
      exchangeRate
      baseCurrencyAmount
      status
      accountingSystemId
      bookkeepingYearId
      billId
      bookingAccountId
      transactionReferenceId
      userId
      replacedById
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByBookingAccountIdQueryVariables,
  APITypes.TransactionsByBookingAccountIdQuery
>;
export const transactionsByTransactionReferenceId = /* GraphQL */ `query TransactionsByTransactionReferenceId(
  $transactionReferenceId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByTransactionReferenceId(
    transactionReferenceId: $transactionReferenceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      paidAt
      currencyCode
      amount
      title
      exchangeRate
      baseCurrencyAmount
      status
      accountingSystemId
      bookkeepingYearId
      billId
      bookingAccountId
      transactionReferenceId
      userId
      replacedById
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByTransactionReferenceIdQueryVariables,
  APITypes.TransactionsByTransactionReferenceIdQuery
>;
export const transactionsByUserId = /* GraphQL */ `query TransactionsByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      paidAt
      currencyCode
      amount
      title
      exchangeRate
      baseCurrencyAmount
      status
      accountingSystemId
      bookkeepingYearId
      billId
      bookingAccountId
      transactionReferenceId
      userId
      replacedById
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByUserIdQueryVariables,
  APITypes.TransactionsByUserIdQuery
>;
export const transactionsByReplacedById = /* GraphQL */ `query TransactionsByReplacedById(
  $replacedById: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByReplacedById(
    replacedById: $replacedById
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      paidAt
      currencyCode
      amount
      title
      exchangeRate
      baseCurrencyAmount
      status
      accountingSystemId
      bookkeepingYearId
      billId
      bookingAccountId
      transactionReferenceId
      userId
      replacedById
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByReplacedByIdQueryVariables,
  APITypes.TransactionsByReplacedByIdQuery
>;
export const getAttachment = /* GraphQL */ `query GetAttachment($id: ID!) {
  getAttachment(id: $id) {
    id
    deletedAt
    key
    accountingSystemId
    accountingSystemName
    transactionId
    authUserId
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAttachmentQueryVariables,
  APITypes.GetAttachmentQuery
>;
export const listAttachments = /* GraphQL */ `query ListAttachments(
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      key
      accountingSystemId
      accountingSystemName
      transactionId
      authUserId
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAttachmentsQueryVariables,
  APITypes.ListAttachmentsQuery
>;
export const attachmentsByTransactionId = /* GraphQL */ `query AttachmentsByTransactionId(
  $transactionId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
) {
  attachmentsByTransactionId(
    transactionId: $transactionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      key
      accountingSystemId
      accountingSystemName
      transactionId
      authUserId
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AttachmentsByTransactionIdQueryVariables,
  APITypes.AttachmentsByTransactionIdQuery
>;
export const attachmentsByAuthUserId = /* GraphQL */ `query AttachmentsByAuthUserId(
  $authUserId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
) {
  attachmentsByAuthUserId(
    authUserId: $authUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      key
      accountingSystemId
      accountingSystemName
      transactionId
      authUserId
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AttachmentsByAuthUserIdQueryVariables,
  APITypes.AttachmentsByAuthUserIdQuery
>;
export const getTax = /* GraphQL */ `query GetTax($id: ID!) {
  getTax(id: $id) {
    id
    deletedAt
    amount
    totalAmount
    transactionId
    taxRate {
      id
      deletedAt
      code
      rate
      taxType
      sortOrder
      accountingSystemId
      bookkeepingId
      dateFrom
      dateTo
      authGroupsRead
      authGroupsUpdate
      createdAt
      updatedAt
      __typename
    }
    taxRateId
    accountingSystemId
    authUserId
    authGroups
    authGroupsRead
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTaxQueryVariables, APITypes.GetTaxQuery>;
export const listTaxes = /* GraphQL */ `query ListTaxes($filter: ModelTaxFilterInput, $limit: Int, $nextToken: String) {
  listTaxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deletedAt
      amount
      totalAmount
      transactionId
      taxRateId
      accountingSystemId
      authUserId
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTaxesQueryVariables, APITypes.ListTaxesQuery>;
export const taxesByTransactionId = /* GraphQL */ `query TaxesByTransactionId(
  $transactionId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTaxFilterInput
  $limit: Int
  $nextToken: String
) {
  taxesByTransactionId(
    transactionId: $transactionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      amount
      totalAmount
      transactionId
      taxRateId
      accountingSystemId
      authUserId
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaxesByTransactionIdQueryVariables,
  APITypes.TaxesByTransactionIdQuery
>;
export const taxesByTaxRateId = /* GraphQL */ `query TaxesByTaxRateId(
  $taxRateId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTaxFilterInput
  $limit: Int
  $nextToken: String
) {
  taxesByTaxRateId(
    taxRateId: $taxRateId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      amount
      totalAmount
      transactionId
      taxRateId
      accountingSystemId
      authUserId
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaxesByTaxRateIdQueryVariables,
  APITypes.TaxesByTaxRateIdQuery
>;
export const taxesByAuthUserId = /* GraphQL */ `query TaxesByAuthUserId(
  $authUserId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTaxFilterInput
  $limit: Int
  $nextToken: String
) {
  taxesByAuthUserId(
    authUserId: $authUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deletedAt
      amount
      totalAmount
      transactionId
      taxRateId
      accountingSystemId
      authUserId
      authGroups
      authGroupsRead
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaxesByAuthUserIdQueryVariables,
  APITypes.TaxesByAuthUserIdQuery
>;
