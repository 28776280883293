import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Select,
  Text,
  useToken,
  VStack,
} from "@chakra-ui/react";
import { DataTable } from "../../components/data-table";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { AccountingSystem, TaxType } from "../../API";
import DeleteButton from "../../components/delete-button";

import { ReactComponent as IconEdit } from "../../assets/icons/pencil02.svg";
import { ReactComponent as IconCancel } from "../../assets/icons/xClose.svg";
import IconSave from "../../assets/icons/save01.svg";
import { AccountingSystemTax, TaxRateValue } from "./tax-rates";
import DateSelector, { selectDate } from "../../components/date-selector";
import {
  dateStringToDate,
  firstOfYear,
  formatDate,
  getLocalDate,
} from "../../utils";
import { AccountingSystemLoginValues } from "./accounting-system-login";
import IconAlertCircle from "../../assets/icons/alert-circle.svg";
import TooltipIcon from "../../components/tooltip-icon";
import RowDragHandleCell from "../../components/row-drag-handle-cell";
import Tooltip from "../../components/tooltip";
import AddButton from "./add-button";
import { v4 as guid } from "uuid";

const columnHelper = createColumnHelper<TaxRateValue>();

const taxTypes: TaxType[] = [
  TaxType.investments,
  TaxType.materials,
  TaxType.earnings,
];

const getTaxTypeName = (
  accountingSystem: AccountingSystem | undefined,
  taxType: TaxType
) => {
  switch (taxType) {
    case TaxType.investments:
      return accountingSystem === AccountingSystem.banana
        ? "Vorsteuer (Guthaben) Investitionen und Betriebsaufwand"
        : "Vorsteuer MWST Investitionen, übriger Betriebsaufwand";
    case TaxType.materials:
      return accountingSystem === AccountingSystem.banana
        ? "Vorsteuer (Guthaben) Material- und Dienstleistungsaufwand"
        : "Vorsteuer MWST Material, Waren, Dienstleistungen, Energie";
    case TaxType.earnings:
      return accountingSystem === AccountingSystem.banana
        ? "Umsatzsteuer (Schuld) Verkauf und Dienstleistungen"
        : "Geschuldete MWST (Umsatzsteuer)";
    default:
      return "";
  }
};

type TaxRatesTableProps = {
  accountingSystem: AccountingSystem | undefined;
  accountingSystemLoginValues: AccountingSystemLoginValues | undefined;
  typeNameSingular: string;
  typeNamePlural: string;
  taxRates: TaxRateValue[];
  accountingSystemTaxes?: AccountingSystemTax[];
  remove: <TaxRateValue>(index: number) => TaxRateValue | undefined;
  replace: <TaxRateValue>(index: number, value: TaxRateValue) => void;
  push: <X = any>(obj: X) => void;
  move: (from: number, to: number) => void;
};

const TaxRatesTable = ({
  accountingSystem,
  accountingSystemLoginValues,
  typeNameSingular,
  typeNamePlural,
  taxRates,
  accountingSystemTaxes,
  remove,
  replace,
  push,
  move,
}: TaxRatesTableProps) => {
  const [gray700] = useToken("colors", ["gray.700"]);

  const [taxRate, setTaxRate] = useState<TaxRateValue>();

  const refCodeInput = useRef<HTMLInputElement>(null);
  const refCodeSelect = useRef<HTMLSelectElement>(null);
  const refRate = useRef<HTMLInputElement>(null);
  const refDateFrom = useRef<HTMLInputElement>(null);
  const refDateTo = useRef<HTMLInputElement>(null);
  const refTaxType = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (!taxRate) {
      const taxRateNew = taxRates.find((b) => b.code.length === 0);
      if (taxRateNew) {
        setTaxRate(taxRateNew);
      }
    }
  }, [taxRate, taxRates]);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "drag-handle",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      }),
      columnHelper.accessor("code", {
        cell: (info) =>
          taxRate && taxRate.id === info.row.original.id ? (
            accountingSystemTaxes ? (
              <Select
                ref={refCodeSelect}
                defaultValue={taxRate.code}
                onChange={() => {
                  const tax = accountingSystemTaxes?.find(
                    (ast) => ast.code === refCodeSelect.current?.value
                  );
                  if (tax) {
                    refRate.current!.value = tax.value.toString();
                    if (tax.start_month && tax.start_year) {
                      const date = new Date(
                        tax.start_year,
                        tax.start_month - 1
                      );
                      refDateFrom.current!.value = formatDate(date);
                    } else {
                      refDateFrom.current!.value = formatDate(firstOfYear);
                    }
                    if (tax.end_month && tax.end_year) {
                      const date = new Date(tax.end_year, tax.end_month - 1);
                      refDateTo.current!.value = formatDate(date);
                    } else {
                      refDateTo.current!.value = "";
                    }
                    refTaxType.current!.value =
                      tax.type === "pre_tax_investment"
                        ? TaxType.investments
                        : tax.type === "pre_tax_material"
                        ? TaxType.materials
                        : TaxType.earnings;
                  }
                }}
              >
                {accountingSystemTaxes.map((ast) => (
                  <option key={ast.code} value={ast.code}>
                    {`${ast.display_name}`}
                  </option>
                ))}
              </Select>
            ) : (
              <Input ref={refCodeInput} defaultValue={taxRate.code} autoFocus />
            )
          ) : accountingSystemTaxes &&
            !accountingSystemTaxes.find(
              (ast) => ast.code === info.getValue()
            ) ? (
            <HStack>
              <Text color={"error.500"}>{info.getValue()}</Text>
              <TooltipIcon
                header="Dieser MwSt-Satz ist in bexio nicht vorhanden."
                body="Der gewählte Mehrwertsteuersatz ist in bexio nicht vorhanden. Bitte MwSt-Satz ändern oder löschen."
                icon={IconAlertCircle}
              />
            </HStack>
          ) : (
            info.getValue()
          ),
        header: "MwSt. Code",
      }),
      columnHelper.accessor("rate", {
        cell: (info) =>
          taxRate && taxRate.id === info.row.original.id ? (
            <Input ref={refRate} defaultValue={taxRate.rate} />
          ) : (
            info.getValue()
          ),
        header: typeNameSingular,
      }),
      columnHelper.accessor("dateFrom", {
        cell: (info) =>
          taxRate && taxRate.id === info.row.original.id ? (
            <DateSelector
              ref={refDateFrom}
              defaultValue={getLocalDate(info.getValue())}
            />
          ) : (
            formatDate(getLocalDate(info.getValue()))
          ),
        header: "Gültig von",
      }),
      columnHelper.accessor("dateTo", {
        cell: (info) =>
          taxRate && taxRate.id === info.row.original.id ? (
            <DateSelector
              ref={refDateTo}
              defaultValue={
                info.getValue()
                  ? getLocalDate(info.getValue() as Date)
                  : undefined
              }
            />
          ) : info.getValue() ? (
            formatDate(getLocalDate(info.getValue() as Date))
          ) : (
            ""
          ),
        header: "Gültig bis",
      }),
      columnHelper.accessor("taxType", {
        cell: (info) =>
          taxRate && taxRate.id === info.row.original.id ? (
            <Select
              ref={refTaxType}
              defaultValue={taxRate.taxType}
              width="auto"
            >
              {taxTypes.map((taxType) => (
                <option key={taxType} value={taxType}>
                  {getTaxTypeName(accountingSystem, taxType)}
                </option>
              ))}
            </Select>
          ) : (
            getTaxTypeName(accountingSystem, info.getValue())
          ),
        header: "Steuertyp",
      }),
      columnHelper.display({
        id: "edit",
        cell: (info) => {
          if (taxRate && taxRate.id === info.row.original.id) {
            return (
              <HStack>
                <Tooltip label="Speichern">
                  <IconButton
                    aria-label="Save"
                    variant="outline"
                    icon={<Image boxSize="20px" src={IconSave} />}
                    onClick={() => {
                      replace(info.row.index, {
                        ...taxRate,
                        code: accountingSystemTaxes
                          ? refCodeSelect.current?.value ?? taxRate.code
                          : refCodeInput.current?.value ?? taxRate.code,
                        rate: refRate.current?.value ?? taxRate.rate,
                        dateFrom:
                          refDateFrom.current?.value &&
                          refDateFrom.current.value !== selectDate
                            ? new Date(
                                dateStringToDate(refDateFrom.current.value)
                              )
                            : taxRate.dateFrom,
                        dateTo:
                          refDateTo.current?.value &&
                          refDateTo.current.value !== selectDate
                            ? new Date(
                                dateStringToDate(refDateTo.current.value)
                              )
                            : null,
                        taxType: refTaxType.current?.value,
                      });
                      setTaxRate(undefined);
                    }}
                  />
                </Tooltip>
                <DeleteButton onDelete={async () => remove(info.row.index)} />
                <Tooltip label="Abbrechen">
                  <IconButton
                    variant="outline"
                    aria-label="Cancel"
                    icon={
                      <Flex boxSize="20px" alignItems="center">
                        <IconCancel stroke={gray700} />
                      </Flex>
                    }
                    onClick={() => setTaxRate(undefined)}
                  />
                </Tooltip>
              </HStack>
            );
          }
          return (
            <Tooltip label="Bearbeiten">
              <IconButton
                variant="outline"
                aria-label="Edit"
                icon={
                  <Flex boxSize="20px" alignItems="center">
                    <IconEdit stroke={gray700} />
                  </Flex>
                }
                onClick={() => {
                  setTaxRate(info.row.original);
                }}
              />
            </Tooltip>
          );
        },
      }),
    ],
    [
      accountingSystem,
      accountingSystemTaxes,
      gray700,
      remove,
      replace,
      taxRate,
      typeNameSingular,
    ]
  );

  return (
    <VStack alignItems="start" width="full" spacing="16px">
      <Box
        width="full"
        minHeight={{ base: "auto", md: "418px" }}
        maxHeight="51vh"
        overflowY="auto"
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
      >
        <DataTable
          typeNamePlural={typeNamePlural}
          columns={columns}
          data={taxRates}
          move={move}
          state={
            accountingSystemLoginValues?.success && !accountingSystemTaxes
              ? "loading"
              : "success"
          }
        />
      </Box>
      <AddButton<TaxRateValue>
        push={push}
        data={{
          id: guid(),
          code: "",
          rate: 0,
          dateFrom: firstOfYear,
          dateTo: null,
          taxType: TaxType.investments,
        }}
        isDisabled={!!taxRates.find((tr) => tr.code.length === 0)}
      />
    </VStack>
  );
};

export default TaxRatesTable;
