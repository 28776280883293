import {
  ChakraProps,
  Circle,
  FocusLock,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Ref, forwardRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

const colors = ["#c0bff5", "#e1f5ea", "#fcf4cf", "#ffc4c8"];

type ColorPickerProps = {
  value: string;
} & ChakraProps;

const ColorPicker = forwardRef(
  ({ value }: ColorPickerProps, ref: Ref<HTMLInputElement>) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [color, setColor] = useState<string>(value);

    return (
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Circle size="16px" bgColor={color} />
            </InputLeftElement>
            <Input ref={ref} isReadOnly={true} value={color} />
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent p={2} width="auto">
          <FocusLock persistentFocus={false}>
            <HStack>
              <HexColorPicker color={color} onChange={setColor} />
              <VStack>
                {colors.map((c) => (
                  <Circle
                    key={c}
                    size="28px"
                    bgColor="white"
                    boxShadow="0 2px 4px rgba(0,0,0,.2)"
                  >
                    <Circle
                      size="26px"
                      bgColor={c}
                      onClick={() => setColor(c)}
                    />
                  </Circle>
                ))}
              </VStack>
            </HStack>
          </FocusLock>
        </PopoverContent>
      </Popover>
    );
  }
);

export default ColorPicker;
