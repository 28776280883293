import { Circle, Flex, Image, useToken } from "@chakra-ui/react";
import { TransactionStatus } from "../API";
import { ReactComponent as IconPencil } from "../assets/icons/pencil02.svg";
import IconCheck from "../assets/icons/check.svg";
import IconX from "../assets/icons/x.svg";
import IconRefresh from "../assets/icons/refresh-cw-02.svg";

const circleSize = "28px";
const circleBorder = "1px";
const iconBoxSize = "12px";

const TransactionStatusBadge = ({ status }: { status: TransactionStatus }) => {
  const [warning600] = useToken("colors", ["warning.600"]);

  return status === TransactionStatus.booked ? (
    <Circle size={circleSize} border={circleBorder} borderColor="success.600">
      <Image boxSize={iconBoxSize} src={IconCheck} />
    </Circle>
  ) : status === TransactionStatus.draft ? (
    <Circle size={circleSize} border={circleBorder} borderColor="grayCool.600">
      <Image boxSize={iconBoxSize} src={IconRefresh} />
    </Circle>
  ) : status === TransactionStatus.extracted ? (
    <Circle size={circleSize} border={circleBorder} borderColor="warning.600">
      <Flex boxSize={iconBoxSize} alignItems="center">
        <IconPencil stroke={warning600} />
      </Flex>
    </Circle>
  ) : (
    <Circle size={circleSize} border={circleBorder} borderColor="error.600">
      <Image boxSize={iconBoxSize} src={IconX} />
    </Circle>
  );
};

export default TransactionStatusBadge;
