import {
  Box,
  Circle,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Progress,
  Spacer,
  Step,
  StepIndicator,
  StepStatus,
  Stepper,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

import LogoExpensly from "../../assets/logos/expensly.svg";
import IconEmail from "../../assets/icons/mail-01.svg";
import IconStepComplete from "../../assets/icons/stepComplete.svg";
import IllustrationWelcome from "../../assets/illustrations/welcome.svg";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  GetBillingQuery,
  GetUserQuery,
  ListBookkeepingsQuery,
  ListBookkeepingsQueryVariables,
  ListOrganizationsQuery,
  ListOrganizationsQueryVariables,
  UserAppRole,
} from "../../API";
import {
  getBilling,
  getUser,
  listBookkeepings,
  listOrganizations,
} from "../../graphql/queries";
import {
  Billing,
  Bookkeeping,
  Organization,
  OrganizationFull,
  User,
} from "../../models";
import {
  routeCreateOrganizationAccountingSystemLogin,
  routeCreateOrganizationOrganization,
  routeOnboardingBilling,
  routeOnboardingConfirmation,
} from "../routes";
import { isBillingActive, queryList } from "../../utils";
import SignOutLinkButton from "../../components/sign-out-link-button";
import Tooltip from "../../components/tooltip";
import useSetDatadogAndGleapUser from "../../hooks/use-set-datadog-and-gleap-user";
import Gleap from "gleap";

const email = "help@expensly.ch";

const onboardingSteps = [
  "onboarding",
  "auth",
  "billing",
  "confirmation",
] as const;
const onboardingStepsFiltered = onboardingSteps.filter(
  (s) => s !== "onboarding"
);
export type OnboardingStep = (typeof onboardingSteps)[number];

type ContextTypeBilling = {
  user?: User;
  organizations?: OrganizationFull[];
};

const OnboardingLayout = () => {
  const [organizations, setOrganizations] = useState<OrganizationFull[]>();
  const [bookkeepings, setBookkeepings] = useState<Bookkeeping[]>();
  const [user, setUser] = useState<User>();

  useSetDatadogAndGleapUser(user, "Onboarding");
  Gleap.showFeedbackButton(true);

  const { user: authUser, authStatus } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  const step = location.pathname.split("/").pop() as OnboardingStep;
  const stepIndex = onboardingSteps.findIndex((s) => s === step);

  const [searchParams] = useSearchParams();
  const secret = searchParams.get("secret");
  const state = searchParams.get("state");
  const error = searchParams.get("error");

  useEffect(() => {
    if (authStatus === "authenticated" && !user && authUser.attributes?.sub) {
      const fetchUser = async () => {
        const user = await API.graphql<GraphQLQuery<GetUserQuery>>(
          graphqlOperation(getUser, { id: authUser.attributes?.sub })
        );
        if (user.data?.getUser) {
          setUser(user.data?.getUser as unknown as User);
        }
      };
      fetchUser();
    } else if (authStatus === "unauthenticated" && user) {
      setUser(undefined);
      setOrganizations(undefined);
      setBookkeepings(undefined);
    }
  }, [authStatus, authUser, user]);

  useEffect(() => {
    if (user) {
      const fetchOrganizations = async () => {
        try {
          const organizations = await queryList<
            Organization,
            ListOrganizationsQueryVariables,
            ListOrganizationsQuery
          >(listOrganizations);

          const organizationsFull: OrganizationFull[] = [];

          for (const organization of organizations) {
            let billing: Billing | undefined;

            if (organization.organizationBillingId) {
              const response = await API.graphql<GraphQLQuery<GetBillingQuery>>(
                graphqlOperation(getBilling, {
                  id: organization.organizationBillingId,
                })
              );

              billing = response.data?.getBilling as Billing;
            }

            organizationsFull.push({
              ...organization,
              billing,
            });
          }

          setOrganizations(organizationsFull);
        } catch {
          setOrganizations([]);
        }
      };
      fetchOrganizations();
    }
  }, [user]);

  useEffect(() => {
    if (organizations) {
      if (organizations.length > 0) {
        const fetchBookkeepings = async () => {
          const bookkeepings = await queryList<
            Bookkeeping,
            ListBookkeepingsQueryVariables,
            ListBookkeepingsQuery
          >(listBookkeepings, {
            filter: {
              or: organizations.map((organization) => ({
                organizationId: { eq: organization.id },
              })),
            },
          });

          setBookkeepings(bookkeepings);
        };
        fetchBookkeepings();
      } else {
        setBookkeepings([]);
      }
    }
  }, [organizations]);

  useEffect(() => {
    if (
      organizations &&
      user &&
      bookkeepings &&
      location.pathname !== routeOnboardingConfirmation
    ) {
      // If there's at least one Organization with a valid Billing
      // navigate to from route
      // else to billing route
      if (
        user.role === UserAppRole.organizationAdmin &&
        !organizations.some((organization) => isBillingActive(organization))
      ) {
        if (bookkeepings.length > 0) {
          navigate(routeOnboardingBilling);
        } else if (secret || state || error) {
          navigate({
            pathname: routeCreateOrganizationAccountingSystemLogin,
            search: createSearchParams({
              secret: secret || "",
              state: state || "",
              error: error || "",
            }).toString(),
          });
        } else {
          navigate(routeCreateOrganizationOrganization);
        }
      } else {
        navigate(from, { replace: true });
      }
    }
  }, [
    bookkeepings,
    error,
    from,
    location.pathname,
    navigate,
    organizations,
    secret,
    state,
    user,
  ]);

  const showIllustration = useBreakpointValue({ base: false, md: true });

  return (
    <Flex width="full" height="full" justify="space-between">
      <Flex
        flex={{ base: 1, md: "0 50%" }}
        direction="column"
        justify="center"
        p="32px"
      >
        <Flex justify="space-between">
          <Tooltip label="Zurück zur Startseite">
            <IconButton
              variant="unstyled"
              icon={<Image src={LogoExpensly} width={142} />}
              aria-label={"Expensly Logo"}
              onClick={() => navigate("/")}
            />
          </Tooltip>
          <SignOutLinkButton />
        </Flex>
        <VStack
          height="full"
          width="full"
          px={{ base: 0, md: "148px" }}
          py={{ base: "24px", md: 0 }}
          justify="center"
        >
          {step !== "onboarding" && (
            <Box position="relative" width="full" px="24px" pt="24px">
              <Stepper colorScheme="transparent" index={stepIndex} gap={0}>
                {onboardingStepsFiltered.map((s, index) => (
                  <Step key={index}>
                    <StepIndicator bg="white" border="none">
                      <StepStatus
                        active={
                          <Circle size="32px" bg="primary.50">
                            <Circle size="8px" bg="primary.600" />
                          </Circle>
                        }
                        complete={
                          <Circle size="32px" bg="white">
                            <Circle size="24px" bg="primary.50">
                              <Image src={IconStepComplete} />
                            </Circle>
                          </Circle>
                        }
                        incomplete={
                          <Circle size="32px" bg="white">
                            <Circle size="24px" bg="gray.50">
                              <Circle size="8px" bg="gray.200" />
                            </Circle>
                          </Circle>
                        }
                      />
                    </StepIndicator>
                  </Step>
                ))}
              </Stepper>
              <Progress
                value={(stepIndex / (onboardingStepsFiltered.length - 1)) * 100}
                colorScheme="primary"
                position="relative"
                height="2px"
                width="full"
                top="-16px"
                zIndex={-1}
              />
            </Box>
          )}
          <Flex
            width="full"
            justify="center"
            minHeight="418px"
            overflowY="auto"
            alignItems="center"
          >
            <Outlet
              context={
                step === "billing"
                  ? ({ user, organizations } satisfies ContextTypeBilling)
                  : undefined
              }
            />
          </Flex>
        </VStack>
        <Flex>
          <Text color="gray.600" fontSize={14}>
            © Expensly {new Date().getFullYear()}
          </Text>
          <Spacer />
          <Link href={`mailto:${email}`} isExternal>
            <HStack>
              <Image src={IconEmail} alt="Email Icon" width="16px" />
              <Text color="gray.600" fontSize={14}>
                {email}
              </Text>
            </HStack>
          </Link>
        </Flex>
      </Flex>
      {showIllustration && (
        <Flex
          flex="0 50%"
          direction="column"
          justify="center"
          alignItems="center"
          bgGradient="linear(to-b, #C0BFF5, #EEEDFA)"
          px="108px"
        >
          <Image src={LogoExpensly} alt="Expensly Logo" width={250} />
          <Image
            src={IllustrationWelcome}
            alt="Welcome Illustration"
            maxWidth={504}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default OnboardingLayout;

export const useBilling = () => {
  return useOutletContext<ContextTypeBilling>();
};
