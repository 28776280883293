import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
  useDisclosure,
  useToast,
  useToken,
} from "@chakra-ui/react";
import { stringMembers } from "../../../strings";
import { useContextTypeSettingsOrganization } from "../dashboard-layout";
import {
  CreateUserBookkeepingInput,
  CreateUserInput,
  CreateUserOrganizationInput,
  CurrencyCode,
  ListOrganizationsQuery,
  ListOrganizationsQueryVariables,
  OrganizationType,
  UpdateUserBookkeepingInput,
  UpdateUserInput,
  UpdateUserOrganizationInput,
  UserAppRole,
  UserOrganization,
  UserOrganizationRole,
} from "../../../API";
import { FieldArray, Form, Formik } from "formik";
import { DeletedAt, Organization, User } from "../../../models";
import { useNavigate } from "react-router-dom";
import {
  createUser,
  createUserOrganization,
  createUserBookkeeping,
  updateUser,
  updateUserOrganization,
  updateUserBookkeeping,
} from "../../../graphql/mutations";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  aerzteTreuhandLogo,
  deleteItem,
  emailAlreadyExists,
  emailValidation,
  endOfMonthUnix,
  formatDate,
  queryList,
} from "../../../utils";
import { useUserOrganizations } from "../../../hooks/use-user-organizations";
import TeamTable from "./team-table";
import { useUsers } from "../../../hooks/use-users";
import { useUserBookkeepings } from "../../../hooks/use-user-bookkeepings";
import { useEffect, useRef, useState } from "react";
import { listOrganizations } from "../../../graphql/queries";
import * as Yup from "yup";
import { getUnixTime } from "date-fns";
import { ReactComponent as IconCancel } from "../../../assets/icons/xClose.svg";

export type TeamValue = Pick<User, "id" | "lastName" | "firstName" | "email"> &
  DeletedAt & {
    appRole: User["role"];
    organizationRole: UserOrganization["role"];
    bookkeepings: {
      id: string;
      name: string;
    }[];
  };

type TeamValueFlat = Omit<TeamValue, "bookkeepings"> & {
  bookkeepingId?: string;
};

export type TeamValues = {
  members?: TeamValue[];
};

const typeNamePlural = stringMembers;

const Team = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [gray700] = useToken("colors", ["gray.700"]);

  const { user, selectedOrganization, bookkeepings } =
    useContextTypeSettingsOrganization();
  const { userOrganizations } = useUserOrganizations({
    selectedOrganization,
    withAccountants:
      user?.role === UserAppRole.accountant &&
      selectedOrganization?.type !== OrganizationType.accountant,
    includeDeleted: true,
  });
  const { users } = useUsers({
    userOrganizations,
    includeDeleted: true,
  });
  const { userBookkeepings } = useUserBookkeepings({ bookkeepings });

  // Fetch accountants if user is an accountant
  // and selectedOrganization is not an accountant organization
  const [accountantOrganizations, setAccountantOrganizations] =
    useState<Organization[]>();
  const {
    userOrganizations: accountantUserOrganizations,
    setUserOrganizations: setAccountantUserOrganizations,
  } = useUserOrganizations({
    selectedOrganization:
      accountantOrganizations && accountantOrganizations.length > 0
        ? accountantOrganizations[0]
        : undefined,
  });
  const { users: accountantUsers } = useUsers({
    userOrganizations: accountantUserOrganizations,
  });

  useEffect(() => {
    if (user && user.role === UserAppRole.accountant) {
      if (selectedOrganization?.type !== OrganizationType.accountant) {
        const fetchAccountantOrganizations = async () => {
          const accountantOrganizations = await queryList<
            Organization,
            ListOrganizationsQueryVariables,
            ListOrganizationsQuery
          >(listOrganizations, {
            filter: {
              type: {
                eq: OrganizationType.accountant,
              },
            },
          });
          setAccountantOrganizations(accountantOrganizations);
        };
        fetchAccountantOrganizations();
      } else {
        setAccountantOrganizations(undefined);
        setAccountantUserOrganizations(undefined);
      }
    }
  }, [selectedOrganization, setAccountantUserOrganizations, user]);

  const initialValues: TeamValues = {
    members: [
      ...(userOrganizations ? [...userOrganizations] : []),
      ...(accountantUserOrganizations
        ? [
            ...accountantUserOrganizations.filter(
              (auo) =>
                !userOrganizations?.some((uo) => uo.userId === auo.userId)
            ),
          ]
        : []),
    ]
      ?.map((uo) => {
        const user =
          users?.find((u) => u.id === uo.userId) ??
          accountantUsers?.find((u) => u.id === uo.userId);
        return {
          id: user?.id,
          lastName: user?.lastName,
          firstName: user?.firstName,
          email: user?.email,
          appRole: user?.role,
          organizationRole:
            // Show Accountant role if user is an accountant
            accountantUserOrganizations?.find((auo) => auo.id === uo.id)
              ? UserOrganizationRole.accountant
              : uo.role,
          bookkeepings:
            userBookkeepings
              ?.filter((ub) => ub.userId === user?.id)
              .map((ub) => ({
                id: ub.bookkeepingId,
                name:
                  bookkeepings?.find((b) => b.id === ub.bookkeepingId)?.name ??
                  "",
              })) ?? [],
          deletedAt: user?.deletedAt,
        } as TeamValue;
      })
      .filter((m) => m.id)
      // Show Organization Admin first
      // and Accountants last
      .toSorted((a, b) => {
        if (a.appRole === UserAppRole.organizationAdmin) {
          return -1; // 'a' should come before 'b'
        } else if (b.appRole === UserAppRole.organizationAdmin) {
          return 1; // 'b' should come before 'a'
        } else if (a.appRole === UserAppRole.accountant) {
          return 1; // 'a' should come after 'b'
        } else if (b.appRole === UserAppRole.accountant) {
          return -1; // 'b' should come after 'a'
        }
        return 0; // 'a' and 'b' are considered equal
      }),
  };

  const TeamSchema = Yup.object().shape({
    members: Yup.array()
      .of(
        Yup.object().shape({
          lastName: Yup.string().required("Nachname ist erforderlich"),
          firstName: Yup.string().required("Vorname ist erforderlich"),
          email: emailValidation([
            ...(users ? [...users] : []),
            ...(accountantUsers ? [...accountantUsers] : []),
          ]),
        })
      )
      .test("unique-emails", emailAlreadyExists, function (members, context) {
        const emails = context.parent.members.map((m: TeamValue) => m.email);
        return new Set(emails).size === emails.length;
      }),
  });

  type UpcomingInvoice = {
    currency: CurrencyCode;
    lines: {
      data: {
        amount: number;
        currency: CurrencyCode;
        description: string;
        quantity: number;
      }[];
    };
    subscription_proration_date: number;
    subtotal: number;
    tax: number;
    total: number;
  };

  type UpcomingInvoiceModalProps = {
    upcomingInvoice?: UpcomingInvoice;
    userFirst?: User;
    membersFlat?: TeamValueFlat[];
    toAddUsersCognito?:
      | Pick<
          User,
          | "id"
          | "lastName"
          | "firstName"
          | "email"
          | "role"
          | "authGroupsRead"
          | "authGroupsUpdate"
        >[];
    toRemoveUsers?: UpdateUserInput[];
    closed?: boolean;
    confirmed?: boolean;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef(null);
  const [upcomingInvoiceModalProps, setUpcomingInvoiceModalProps] =
    useState<UpcomingInvoiceModalProps>({});

  useEffect(() => {
    if (upcomingInvoiceModalProps.closed !== undefined) {
      if (upcomingInvoiceModalProps.closed) {
        onClose();
      } else {
        onOpen();
      }
    }
  }, [upcomingInvoiceModalProps.closed, onClose, onOpen]);

  const onModalClose = () => {
    setUpcomingInvoiceModalProps({});
    onClose();
  };

  // Execute changes if user confirms upcoming invoice
  useEffect(() => {
    if (
      selectedOrganization &&
      upcomingInvoiceModalProps.userFirst &&
      upcomingInvoiceModalProps.membersFlat &&
      upcomingInvoiceModalProps.toAddUsersCognito &&
      upcomingInvoiceModalProps.toRemoveUsers &&
      ((!upcomingInvoiceModalProps.closed &&
        upcomingInvoiceModalProps.confirmed) ||
        (upcomingInvoiceModalProps.closed &&
          !upcomingInvoiceModalProps.confirmed))
    ) {
      const updateTeam = async () => {
        const userOrganizationFirst = userOrganizations![0];
        const userBookkeepingFirst = userBookkeepings
          ? userBookkeepings[0]
          : undefined;

        // First create Cognito users so that we can get the user id
        const headers = {
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
          "Content-Type": "application/json",
        };

        const createUsersCognitoPromises =
          upcomingInvoiceModalProps.toAddUsersCognito?.map(async (u) => {
            const response = await API.post("AdminQueries", "/createUser", {
              headers: headers,
              body: {
                email: u.email,
                lastName: u.lastName,
                firstName: u.firstName,
              },
            });
            const userId = response.User.Username;
            const member = upcomingInvoiceModalProps.membersFlat?.find(
              (m) => m.id === u.id
            );
            return { ...member, id: userId } as TeamValueFlat;
          });

        const updatedMembers = await Promise.all(createUsersCognitoPromises!);

        const membersUpdated = upcomingInvoiceModalProps.membersFlat?.map(
          (member) => {
            const updatedMember = updatedMembers.find(
              (u) => u.email === member.email
            );
            return updatedMember ?? member;
          }
        );

        // Create
        const toAddUsers: CreateUserInput[] | undefined = getToAddUsers(
          upcomingInvoiceModalProps.userFirst!,
          membersUpdated,
          users
        );

        const toAddUserOrganizations:
          | CreateUserOrganizationInput[]
          | undefined = membersUpdated
          ?.filter((m) => !userOrganizations?.find((uo) => uo.userId === m.id))
          .map((m) => {
            return {
              userId: m.id,
              organizationId: selectedOrganization.id,
              role: m.organizationRole,
              authGroupsRead: userOrganizationFirst.authGroupsRead,
              authGroupsUpdate: userOrganizationFirst.authGroupsUpdate,
            };
          });

        // Add a user bookkeeping for each bookkeeping in member
        const toAddUserBookkeepings: CreateUserBookkeepingInput[] | undefined =
          userBookkeepingFirst
            ? membersUpdated
                ?.filter(
                  (m) =>
                    !userBookkeepings?.find((ub) => ub.userId === m.id) &&
                    m.bookkeepingId
                )
                .flat()
                .map((m) => {
                  return {
                    bookkeepingId: m.bookkeepingId!,
                    userId: m.id,
                    authGroupsRead: userBookkeepingFirst.authGroupsRead,
                    authGroupsUpdate: userBookkeepingFirst.authGroupsUpdate,
                  };
                })
            : undefined;

        // Update
        const toUpdateUsers: UpdateUserInput[] | undefined = membersUpdated
          ?.filter((m) => users?.find((u) => u.id === m.id))
          ?.map((m) => {
            const user = users?.find((u) => u.id === m.id);
            return {
              id: m.id,
              ...(m.lastName !== user?.lastName
                ? { lastName: m.lastName }
                : {}),
              ...(m.firstName !== user?.firstName
                ? { firstName: m.firstName }
                : {}),
              ...(m.email !== user?.email ? { email: m.email } : {}),
            };
          })
          .filter((u) => Object.keys(u).length > 1);

        const toUpdateUserOrganizations:
          | UpdateUserOrganizationInput[]
          | undefined = membersUpdated
          ?.map((m) => {
            const userOrganization = userOrganizations?.find(
              (uo) => uo.userId === m.id
            );
            if (userOrganization) {
              return {
                id: userOrganization?.id,
                ...(m.organizationRole !== userOrganization?.role
                  ? { role: m.organizationRole }
                  : {}),
              };
            } else {
              // Use dummy id
              return { id: "" };
            }
          })
          .filter((uo) => Object.keys(uo).length > 1);

        // Remove
        // Remove users that have been removed from the team
        // If the user is an accountant, only remove if the selected organization is an accountant organization

        const toRemoveUserOrganizations:
          | UpdateUserOrganizationInput[]
          | undefined = userOrganizations
          ?.filter(
            (uo) =>
              !uo.deletedAt && !membersUpdated?.find((m) => m.id === uo.userId)
          )
          ?.map((uo) => {
            return {
              id: uo.id,
            };
          });

        const toRemoveUserBookkeepings:
          | UpdateUserBookkeepingInput[]
          | undefined = userBookkeepings
          ?.filter((ub) => {
            const member = membersUpdated?.find((m) => m.id === ub.userId);
            return !member || member.bookkeepingId !== ub.bookkeepingId;
          })
          ?.map((ub) => {
            return {
              id: ub.id,
            };
          });

        // Execute the mutations in parallel
        const createUsersPromises = toAddUsers?.map((u) =>
          API.graphql<GraphQLQuery<CreateUserInput>>(
            graphqlOperation(createUser, {
              input: u,
            })
          )
        );

        const createUserOrganizationPromises = toAddUserOrganizations?.map(
          (uo) =>
            API.graphql<GraphQLQuery<CreateUserOrganizationInput>>(
              graphqlOperation(createUserOrganization, {
                input: uo,
              })
            )
        );

        const createUserBookkeepingPromises = toAddUserBookkeepings?.map((ub) =>
          API.graphql<GraphQLQuery<CreateUserBookkeepingInput>>(
            graphqlOperation(createUserBookkeeping, {
              input: ub,
            })
          )
        );

        const updateUsersCognitoPromises = toUpdateUsers?.map(async (u) => {
          API.post("AdminQueries", "/updateUser", {
            headers: headers,
            body: {
              userId: u.id,
              email: u.email,
              lastName: u.lastName,
              firstName: u.firstName,
            },
          });
        });

        const updateUserPromises = toUpdateUsers?.map((u) =>
          API.graphql<GraphQLQuery<UpdateUserInput>>(
            graphqlOperation(updateUser, {
              input: u,
            })
          )
        );

        const updateUserOrganizationPromises = toUpdateUserOrganizations?.map(
          (uo) =>
            API.graphql<GraphQLQuery<UpdateUserOrganizationInput>>(
              graphqlOperation(updateUserOrganization, {
                input: uo,
              })
            )
        );

        const removeUserPromises = upcomingInvoiceModalProps.toRemoveUsers?.map(
          (u) => deleteItem(updateUser, u.id)
        );

        const removeUserOrganizationPromises = toRemoveUserOrganizations?.map(
          (uo) => deleteItem(updateUserOrganization, uo.id)
        );

        const removeUserBookkeepingPromises = toRemoveUserBookkeepings?.map(
          (ub) => deleteItem(updateUserBookkeeping, ub.id)
        );

        try {
          const results = await Promise.allSettled([
            ...(createUsersPromises ?? []),
            ...(createUserOrganizationPromises ?? []),
            ...(createUserBookkeepingPromises ?? []),
            ...(updateUsersCognitoPromises ?? []),
            ...(updateUserPromises ?? []),
            ...(updateUserOrganizationPromises ?? []),
            ...(removeUserPromises ?? []),
            ...(removeUserOrganizationPromises ?? []),
            ...(removeUserBookkeepingPromises ?? []),
          ]);

          // Check for any rejections (errors) in the results
          const errors = results.filter((r) => r.status === "rejected");

          if (errors.length > 0) {
            // Handle errors - display error toast
            toast({
              title: "Fehler beim Speichern",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else {
            // No errors, display success/info toast accordingly
            if (
              (toAddUsers && toAddUsers.length > 0) ||
              (toAddUserOrganizations && toAddUserOrganizations.length > 0) ||
              (toAddUserBookkeepings && toAddUserBookkeepings.length > 0) ||
              (toUpdateUsers && toUpdateUsers.length > 0) ||
              (toUpdateUserOrganizations &&
                toUpdateUserOrganizations.length > 0) ||
              (upcomingInvoiceModalProps.toRemoveUsers &&
                upcomingInvoiceModalProps.toRemoveUsers.length > 0) ||
              (toRemoveUserOrganizations &&
                toRemoveUserOrganizations.length > 0) ||
              (toRemoveUserBookkeepings && toRemoveUserBookkeepings.length > 0)
            ) {
              // Update subscription if there are changes
              if (upcomingInvoiceModalProps.upcomingInvoice) {
                await API.post("expenslyREST", "/billing/update-subscription", {
                  body: {
                    subscriptionId:
                      selectedOrganization.billing?.subscriptionId,
                    subscriptionItemId:
                      selectedOrganization.billing?.subscriptionItemId,
                    // Use quantity of the last item in lines.data
                    quantity:
                      upcomingInvoiceModalProps.upcomingInvoice.lines.data[
                        upcomingInvoiceModalProps.upcomingInvoice.lines.data
                          .length - 1
                      ].quantity,
                    prorationDate:
                      upcomingInvoiceModalProps.upcomingInvoice
                        ?.subscription_proration_date,
                  },
                });
              }

              toast({
                title: "Team gespeichert",
                status: "success",
              });
            } else {
              toast({
                title: "Keine Änderungen",
                status: "info",
              });
            }
          }
        } catch (error) {
          // Catch any other errors that may occur
          toast({
            title: "Fehler beim Speichern",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setUpcomingInvoiceModalProps({
            closed: true,
          });
        }
      };
      updateTeam();
    }
  }, [
    selectedOrganization,
    toast,
    upcomingInvoiceModalProps,
    upcomingInvoiceModalProps.closed,
    upcomingInvoiceModalProps.confirmed,
    upcomingInvoiceModalProps.membersFlat,
    upcomingInvoiceModalProps.toAddUsersCognito,
    upcomingInvoiceModalProps.toRemoveUsers,
    upcomingInvoiceModalProps.userFirst,
    userBookkeepings,
    userOrganizations,
    users,
  ]);

  return (
    <VStack width="full" height="full" alignItems="start" spacing="20px">
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={buttonRef}
        onClose={onModalClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent m={4}>
            <AlertDialogHeader
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pb={0}
            >
              <Text fontSize="lg" fontWeight="semibold">
                Abonnementänderung
              </Text>

              <IconButton
                variant="ghost"
                aria-label="Close"
                icon={
                  <Flex
                    boxSize="20px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconCancel stroke={gray700} />
                  </Flex>
                }
                onClick={onModalClose}
              />
            </AlertDialogHeader>
            {upcomingInvoiceModalProps.upcomingInvoice && (
              <AlertDialogBody color="gray.600">
                <Text fontSize={14} color="gray.600">
                  {`Die Abrechnung erfolgt pro rata per ${formatDate(
                    new Date(
                      upcomingInvoiceModalProps.upcomingInvoice
                        .subscription_proration_date * 1000
                    )
                  )}.`}
                </Text>
                <br />
                <Text fontSize={14} fontWeight="semibold" color="gray.700">
                  {
                    upcomingInvoiceModalProps.upcomingInvoice.lines.data[0]
                      .description
                  }
                </Text>
                <Text fontSize={14} color="gray.600">
                  {`${(
                    upcomingInvoiceModalProps.upcomingInvoice.lines.data[0]
                      .amount / 100
                  ).toFixed(
                    2
                  )} ${upcomingInvoiceModalProps.upcomingInvoice.lines.data[0].currency.toUpperCase()} für ${
                    upcomingInvoiceModalProps.upcomingInvoice.lines.data[0]
                      .quantity
                  } Benutzer`}
                </Text>
                {upcomingInvoiceModalProps.upcomingInvoice.lines.data.length >
                  1 && (
                  <Flex direction="column">
                    <br />
                    <Text fontSize={14} fontWeight="semibold" color="gray.700">
                      {
                        upcomingInvoiceModalProps.upcomingInvoice.lines.data[1]
                          .description
                      }
                    </Text>
                    <Text fontSize={14} color="gray.600">
                      {`${(
                        upcomingInvoiceModalProps.upcomingInvoice.lines.data[1]
                          .amount / 100
                      ).toFixed(
                        2
                      )} ${upcomingInvoiceModalProps.upcomingInvoice.lines.data[1].currency.toUpperCase()} für ${
                        upcomingInvoiceModalProps.upcomingInvoice.lines.data[1]
                          .quantity
                      } Benutzer`}
                    </Text>
                  </Flex>
                )}
                <br />
                <Text fontSize={14} fontWeight="semibold" color="gray.700">
                  Abrechnung
                </Text>
                <Text fontSize={14} color="gray.600">
                  {`Zwischensumme ${(
                    upcomingInvoiceModalProps.upcomingInvoice.subtotal / 100
                  ).toFixed(
                    2
                  )} ${upcomingInvoiceModalProps.upcomingInvoice.currency.toUpperCase()}`}
                </Text>
                <Text fontSize={14} color="gray.600">
                  {`MwSt. ${(
                    upcomingInvoiceModalProps.upcomingInvoice.tax / 100
                  ).toFixed(
                    2
                  )} ${upcomingInvoiceModalProps.upcomingInvoice.currency.toUpperCase()}`}
                </Text>
                <Text fontSize={14} fontWeight="semibold" color="gray.700">
                  {`Gesamt ${(
                    upcomingInvoiceModalProps.upcomingInvoice.total / 100
                  ).toFixed(
                    2
                  )} ${upcomingInvoiceModalProps.upcomingInvoice.currency.toUpperCase()}`}
                </Text>
                {upcomingInvoiceModalProps.upcomingInvoice.total < 0 && (
                  <Flex direction="column">
                    <br />
                    <Text fontSize={14} color="gray.600">
                      Der Betrag wird in der nächsten Abrechnungsperiode
                      abgezogen.
                    </Text>
                  </Flex>
                )}
              </AlertDialogBody>
            )}
            <AlertDialogFooter flexDirection="column">
              <Button
                ref={buttonRef}
                colorScheme="primary"
                onClick={() => {
                  setUpcomingInvoiceModalProps({
                    ...upcomingInvoiceModalProps,
                    confirmed: true,
                  });
                }}
                isLoading={upcomingInvoiceModalProps.confirmed}
                width="full"
              >
                Bestätigen
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <VStack alignItems="start" spacing="4px">
        <Text fontSize={18} fontWeight="semibold" color="gray.900">
          {typeNamePlural}
        </Text>
        <Text fontSize={14} color="gray.600">
          {`Hier kannst du deine ${typeNamePlural} verwalten`}
        </Text>
      </VStack>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={TeamSchema}
        validateOnChange={false}
        onSubmit={async (values) => {
          if (selectedOrganization) {
            // get first real user to get authGroups
            const userFirst = users?.find(
              (u) =>
                selectedOrganization.type === OrganizationType.accountant ||
                u.role !== UserAppRole.accountant
            )!;

            const membersFlat: TeamValueFlat[] | undefined = values.members
              ?.filter((m) => !m.deletedAt)
              .flatMap((m) =>
                m.bookkeepings.length > 0
                  ? m.bookkeepings.map((b) => ({
                      ...m,
                      bookkeepingId: b.id,
                    }))
                  : m
              );

            // Prepare the mutations needed to calculate the new invoice seats count
            const toAddUsersCognito = getToAddUsers(
              userFirst,
              membersFlat,
              users
            );

            const toRemoveUsers: UpdateUserInput[] | undefined = users
              ?.filter(
                (u) =>
                  !u.deletedAt &&
                  !membersFlat
                    ?.filter((m) => !m.deletedAt)
                    .find((m) => m.id === u.id) &&
                  (u.role !== UserAppRole.accountant ||
                    selectedOrganization.type === OrganizationType.accountant)
              )
              ?.map((u) => {
                return {
                  id: u.id,
                };
              });

            // Update seats count in billing
            const seatsCount =
              (userOrganizations?.filter(
                (uo) =>
                  !uo.deletedAt && uo.role !== UserOrganizationRole.accountant
              ).length ?? 0) +
              (toAddUsersCognito?.length ?? 0) -
              (toRemoveUsers?.length ?? 0);
            const billingQuantity =
              selectedOrganization?.billing?.quantity ?? 0;

            if (
              // Make sure to only apply if seatsCount could be calculated
              seatsCount > 0 &&
              // Make sure to only apply if billingQuantity is set
              billingQuantity > 0 &&
              // Make sure to only apply if there are changes in seatsCount
              seatsCount !== billingQuantity
            ) {
              // Prorate the invoice at end of month if users are removed else prorate now
              const prorationDate =
                seatsCount < billingQuantity
                  ? endOfMonthUnix
                  : getUnixTime(new Date());
              const response = await API.get(
                "expenslyREST",
                "/billing/retrieve-upcoming-invoice",
                {
                  queryStringParameters: {
                    subscriptionId:
                      selectedOrganization.billing?.subscriptionId,
                    subscriptionItemId:
                      selectedOrganization.billing?.subscriptionItemId,
                    quantity:
                      selectedOrganization.logo === aerzteTreuhandLogo
                        ? // ÄT has a minimum of 3 seats
                          Math.max(seatsCount, 3)
                        : seatsCount,
                    prorationDate,
                  },
                }
              );

              if (response) {
                const upcomingInvoice = response as UpcomingInvoice;
                setUpcomingInvoiceModalProps({
                  upcomingInvoice,
                  closed: false,
                  userFirst,
                  membersFlat,
                  toAddUsersCognito,
                  toRemoveUsers,
                });
              }
            } else {
              setUpcomingInvoiceModalProps({
                closed: true,
                userFirst,
                membersFlat,
                toAddUsersCognito,
                toRemoveUsers,
              });
            }
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <Form
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <VStack width="full" height="full" justify="space-between">
              <FieldArray name="members">
                {({ remove, replace, push }) => (
                  <TeamTable
                    typeNamePlural={typeNamePlural}
                    selectedOrganization={selectedOrganization}
                    bookkeepings={bookkeepings}
                    users={users}
                    team={values}
                    remove={remove}
                    replace={replace}
                    push={push}
                  />
                )}
              </FieldArray>
              <Divider />
              <HStack width="full" justify="end">
                <Button
                  variant="outline"
                  onClick={() => {
                    navigate("/");
                  }}
                  isDisabled={isSubmitting}
                >
                  Abbrechen
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Speichern
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default Team;

const getToAddUsers = (
  userFirst: User,
  members?: TeamValueFlat[],
  users?: User[]
) =>
  members
    ?.filter(
      (m) =>
        m.appRole !== UserAppRole.accountant &&
        !users?.find((u) => u.id === m.id)
    )
    .map((m) => {
      return {
        id: m.id,
        lastName: m.lastName,
        firstName: m.firstName,
        email: m.email,
        role: m.appRole,
        authGroupsRead: userFirst.authGroupsRead,
        authGroupsUpdate: userFirst.authGroupsUpdate,
      };
    });
