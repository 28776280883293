import { CSSProperties } from "react";

import { Row, flexRender } from "@tanstack/react-table";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Td, Tr } from "@chakra-ui/react";
import { DeletedAt } from "../models";

export type SortModel = DeletedAt & {
  id: string;
};

type DraggableRowProps<T extends SortModel> = {
  draggingEnabled?: boolean;
  row: Row<T>;
  selectedIndex?: number;
  setSelectedIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const DraggableRow = <T extends SortModel>({
  draggingEnabled = false,
  row,
  selectedIndex,
  setSelectedIndex,
}: DraggableRowProps<T>) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    // connect row ref to dnd-kit, apply important styles
    <Tr
      ref={setNodeRef}
      style={draggingEnabled ? style : undefined}
      bgColor={
        row.original.deletedAt
          ? "error.50"
          : selectedIndex === row.index
          ? "primary.50"
          : undefined
      }
      _hover={
        setSelectedIndex
          ? {
              bgColor: "primary.50",
              cursor: row.original.deletedAt ? undefined : "pointer",
            }
          : undefined
      }
      onClick={
        setSelectedIndex
          ? () =>
              setSelectedIndex(
                row.index === selectedIndex ? undefined : row.index
              )
          : undefined
      }
    >
      {row.getVisibleCells().map((cell) => {
        // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
        const meta: any = cell.column.columnDef.meta;
        return (
          <Td key={cell.id} isNumeric={meta?.isNumeric}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </Tr>
  );
};

export default DraggableRow;
