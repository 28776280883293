// theme.ts

// 1. import `extendTheme` function
import { Theme } from "@aws-amplify/ui-react";
import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { MultiSelectTheme } from "chakra-multiselect";

// 2. Add your color mode config
const config: ThemeConfig = { initialColorMode: "light" };

// 3. extend the theme
const theme = extendTheme({
  config,
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    gray25: "#FCFCFD",
    gray: {
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#344054",
      800: "#1D2939",
      900: "#101828",
    },
    grayCool25: "#FCFCFD",
    grayCool: {
      50: "#F9F9FB",
      100: "#EFF1F5",
      200: "#DCDFEA",
      300: "#B9C0D4",
      400: "#7D89B0",
      500: "#5D6B98",
      600: "#4A5578",
      700: "#404968",
      800: "#30374F",
      900: "#111322",
    },
    primary25: "#F5F5FA",
    primary: {
      50: "#EEEDFA",
      100: "#D5D4FA",
      200: "#C0BFF5",
      300: "#9B99E8",
      400: "#6D6ACC",
      500: "#4541CC",
      600: "#4440CF",
      700: "#3A36C2",
      800: "#2C2A91",
      900: "#1D1B63",
    },
    error25: "#FFFBFA",
    error: {
      50: "#FEF3F2",
      100: "#FEE4E2",
      200: "#FECDCB",
      300: "#FDA29B",
      400: "#F97066",
      500: "#F04438",
      600: "#D92D20",
      700: "#B42318",
      800: "#912018",
      900: "#7A271A",
    },
    warning25: "#FFFDF5",
    warning: {
      50: "#FFFAEB",
      100: "#FEF0C7",
      200: "#FEDE89",
      300: "#FEC84B",
      400: "#FDB022",
      500: "#F79009",
      600: "#DC6803",
      700: "#B54708",
      800: "#93370D",
      900: "#7A2E0E",
    },
    success25: "#F6FEF9",
    success: {
      50: "#ECFDF3",
      100: "#D1FADF",
      200: "#A6F4C5",
      300: "#6CE9A6",
      400: "#32D583",
      500: "#12B76A",
      600: "#039855",
      700: "#027A48",
      800: "#05603A",
      900: "#054F31",
    },
  },
  fonts: {
    body: `'Inter', sans-serif`,
  },
  components: {
    MultiSelect: {
      ...MultiSelectTheme,
      baseStyle: (props: any) => {
        const baseStyle = MultiSelectTheme.baseStyle(props) as any;
        return {
          ...baseStyle,
          control: {
            ...baseStyle.control,
            backgroundColor: "white",
          },
        };
      },
    },
  },
  styles: {
    global: {
      // Target all input types including 'input', 'select', etc.
      "input, select, textarea": {
        backgroundColor: "white !important", // Use !important to ensure override
      },
    },
  },
});

export default theme;

const themeAuthenticator: Theme = {
  name: "Auth Expensly Theme",
  tokens: {
    colors: {
      brand: {
        primary: {
          "10": theme.colors.primary[50],
          "20": theme.colors.primary[100],
          "40": theme.colors.primary[300],
          "60": theme.colors.primary[500],
          "80": theme.colors.primary[700],
          "90": theme.colors.primary[800],
          "100": theme.colors.primary[900],
        },
      },
    },
  },
};

export { themeAuthenticator };
