import {
  Box,
  Circle,
  Flex,
  HStack,
  RadioProps,
  useRadio,
} from "@chakra-ui/react";

const RadioCard = (props: RadioProps) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" width="full">
      <input {...input} />
      <HStack
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        borderRadius={12}
        borderColor="gray.200"
        _checked={{
          bg: "primary.50",
          color: "primary.800",
          borderColor: "primary.600",
        }}
        p="16px"
      >
        <Circle
          {...checkbox}
          size="16px"
          borderWidth="1px"
          borderColor="gray.300"
          _checked={{
            borderColor: "primary.600",
          }}
        >
          <Circle
            {...checkbox}
            size="6px"
            bg="transparent"
            _checked={{
              bg: "primary.600",
            }}
          />
        </Circle>
        <Flex width="full" direction="column" alignItems="start">
          {props.children}
        </Flex>
      </HStack>
    </Box>
  );
};

export default RadioCard;
