import {
  Box,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import { DataTable } from "../../../components/data-table";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";

import {
  OrganizationType,
  UserAppRole,
  UserOrganizationRole,
} from "../../../API";
import AddButton from "../../create-organization/add-button";
import { v4 as guid } from "uuid";
import RemoveButton from "../../create-organization/remove-button";
import { TeamValue, TeamValues } from "./team";
import {
  stringIsDisabled,
  stringMapUserAppRole,
  stringMapUserOrganizationRole,
} from "../../../strings";
import { MultiSelect, Option } from "chakra-multiselect";
import { Bookkeeping, OrganizationFull, User } from "../../../models";
import Tooltip from "../../../components/tooltip";
import { Field, FieldProps, getIn } from "formik";
import {
  emailAlreadyExists,
  selectUserOrganizationRoles,
} from "../../../utils";
import Select, { Option as SelectOption } from "../../../components/select";

const columnHelper = createColumnHelper<TeamValue>();

type TeamTableProps = {
  typeNamePlural: string;
  selectedOrganization: OrganizationFull | undefined;
  bookkeepings: Bookkeeping[] | undefined;
  users: User[] | undefined;
  team: TeamValues;
  remove: <TeamValue>(index: number) => TeamValue | undefined;
  replace: <TeamValue>(index: number, value: TeamValue) => void;
  push: <X = any>(obj: X) => void;
};

const TeamTable = ({
  typeNamePlural,
  selectedOrganization,
  bookkeepings,
  users,
  team,
  remove,
  replace,
  push,
}: TeamTableProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    const emailsNew = team.members?.map((m) => m.email) ?? [];
    // Only update if emails have changed
    if (
      emails.length !== emailsNew.length ||
      !emails.every((email, index) => email === emailsNew[index])
    ) {
      setEmails(emails);
    }
  }, [emails, team.members]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("lastName", {
        cell: (info) => {
          const isDisabled =
            !!info.row.original.deletedAt ||
            info.row.original.organizationRole ===
              UserOrganizationRole.accountant ||
            (selectedOrganization?.type === OrganizationType.company &&
              info.row.original.appRole === UserAppRole.accountant);
          const fieldName = `members.${info.row.index}.lastName`;
          return (
            <Field name={fieldName}>
              {({ field, form }: FieldProps<number, TeamValues>) => (
                <FormControl
                  isInvalid={
                    (getIn(form.errors, fieldName) &&
                      getIn(form.touched, fieldName)) as boolean
                  }
                >
                  <Tooltip label={isDisabled ? stringIsDisabled : undefined}>
                    <Input
                      defaultValue={info.getValue()}
                      onBlur={(event) => {
                        replace(info.row.index, {
                          ...info.row.original,
                          lastName: event.target.value,
                        });
                      }}
                      isDisabled={isDisabled}
                    />
                  </Tooltip>
                  <FormErrorMessage>
                    {getIn(form.errors, fieldName)}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          );
        },
        header: "Nachname",
      }),
      columnHelper.accessor("firstName", {
        cell: (info) => {
          const isDisabled =
            !!info.row.original.deletedAt ||
            info.row.original.organizationRole ===
              UserOrganizationRole.accountant ||
            (selectedOrganization?.type === OrganizationType.company &&
              info.row.original.appRole === UserAppRole.accountant);
          const fieldName = `members.${info.row.index}.firstName`;
          return (
            <Field name={fieldName}>
              {({ field, form }: FieldProps<number, TeamValues>) => (
                <FormControl
                  isInvalid={
                    (getIn(form.errors, fieldName) &&
                      getIn(form.touched, fieldName)) as boolean
                  }
                >
                  <Tooltip label={isDisabled ? stringIsDisabled : undefined}>
                    <Input
                      defaultValue={info.getValue()}
                      onBlur={(event) => {
                        replace(info.row.index, {
                          ...info.row.original,
                          firstName: event.target.value,
                        });
                      }}
                      isDisabled={isDisabled}
                    />
                  </Tooltip>
                  <FormErrorMessage>
                    {getIn(form.errors, fieldName)}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          );
        },
        header: "Vorname",
      }),
      columnHelper.accessor("email", {
        cell: (info) => {
          // Disable if user already exists or is an accountant or organization admin
          const isDisabled =
            !!info.row.original.deletedAt ||
            info.row.original.organizationRole ===
              UserOrganizationRole.accountant ||
            (selectedOrganization?.type === OrganizationType.company &&
              info.row.original.appRole === UserAppRole.accountant) ||
            info.row.original.appRole === UserAppRole.organizationAdmin ||
            users?.find((u) => u.id === info.row.original.id) !== undefined;
          const fieldName = `members.${info.row.index}.email`;
          const hasDuplicates =
            emails &&
            emails.filter((email) => email === info.getValue()).length > 1;
          return (
            <Field name={fieldName}>
              {({ field, form }: FieldProps<number, TeamValues>) => (
                <FormControl
                  isInvalid={
                    hasDuplicates ||
                    ((getIn(form.errors, fieldName) &&
                      getIn(form.touched, fieldName)) as boolean)
                  }
                >
                  <Tooltip label={isDisabled ? stringIsDisabled : undefined}>
                    <Input
                      defaultValue={info.getValue()}
                      onBlur={(event) => {
                        replace(info.row.index, {
                          ...info.row.original,
                          email: event.target.value,
                        });
                      }}
                      isDisabled={isDisabled}
                    />
                  </Tooltip>
                  <FormErrorMessage>
                    {hasDuplicates
                      ? emailAlreadyExists
                      : getIn(form.errors, fieldName)}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          );
        },
        header: "E-Mail",
      }),
      columnHelper.accessor("organizationRole", {
        cell: (info) => {
          const isDisabled =
            !!info.row.original.deletedAt ||
            info.row.original.organizationRole ===
              UserOrganizationRole.accountant ||
            info.row.original.appRole === UserAppRole.organizationAdmin;
          const optionsUserOrganizationRole: SelectOption[] = (
            isDisabled
              ? Object.values(UserOrganizationRole)
              : selectUserOrganizationRoles
          ).map((role) => ({
            value: role,
            label:
              info.row.original.appRole === UserAppRole.organizationAdmin &&
              role === UserOrganizationRole.admin
                ? stringMapUserAppRole.get(UserAppRole.organizationAdmin) ?? ""
                : stringMapUserOrganizationRole.get(role) ?? "",
          }));
          return (
            <Tooltip label={isDisabled ? stringIsDisabled : undefined}>
              <Select
                value={optionsUserOrganizationRole.find(
                  (option) => option.value === info.getValue()
                )}
                options={optionsUserOrganizationRole}
                onChange={(option) => {
                  replace(info.row.index, {
                    ...info.row.original,
                    organizationRole: option?.value as UserOrganizationRole,
                  });
                }}
                isDisabled={isDisabled}
              />
            </Tooltip>
          );
        },
        header: "Rolle",
      }),
      columnHelper.accessor("bookkeepings", {
        cell: (info) => {
          const disabled =
            !!info.row.original.deletedAt ||
            info.row.original.appRole === UserAppRole.organizationAdmin;
          return (
            <Tooltip label={disabled ? stringIsDisabled : undefined}>
              <Box>
                <MultiSelect
                  options={bookkeepings?.map((b) => ({
                    label: b.name,
                    value: b.id,
                  }))}
                  value={info
                    .getValue()
                    .map((b) => ({ label: b.name, value: b.id }))}
                  onChange={(value) => {
                    // TODO: Workaround for https://github.com/bmartel/chakra-multiselect/issues/44
                    if (
                      info.row.original.appRole !==
                      UserAppRole.organizationAdmin
                    ) {
                      replace(info.row.index, {
                        ...info.row.original,
                        bookkeepings: (value as unknown as Option[]).map(
                          (v) => ({
                            id: v.value,
                            name:
                              bookkeepings?.find((b) => b.id === v.value)
                                ?.name ?? "",
                          })
                        ),
                      });
                    }
                  }}
                  disabled={disabled}
                />
              </Box>
            </Tooltip>
          );
        },
        header: "Buchhaltungen",
      }),
    ],
    [bookkeepings, emails, replace, selectedOrganization?.type, users]
  );

  return (
    <VStack alignItems="start" width="full" spacing="16px">
      <Box
        width="full"
        minHeight="418px"
        maxHeight="51vh"
        overflowY="auto"
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
      >
        <DataTable
          typeNamePlural={typeNamePlural}
          columns={columns}
          data={team.members}
          state={team.members ? "success" : "loading"}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      </Box>
      <HStack>
        <AddButton<TeamValue>
          push={push}
          data={{
            id: guid(),
            lastName: "",
            firstName: "",
            email: "",
            appRole: UserAppRole.user,
            organizationRole: UserOrganizationRole.employee,
            bookkeepings: [],
          }}
          isDisabled={
            !!team?.members?.find(
              (m) =>
                m.lastName?.length === 0 ||
                m.firstName?.length === 0 ||
                m.email?.length === 0
            )
          }
        />
        <RemoveButton
          remove={remove}
          index={selectedIndex}
          onComplete={() =>
            setSelectedIndex(
              selectedIndex && selectedIndex > 0 ? selectedIndex - 1 : 0
            )
          }
          isDisabled={selectedIndex === undefined}
        />
      </HStack>
    </VStack>
  );
};

export default TeamTable;
