import { Button, Image } from "@chakra-ui/react";
import IconMinus from "../../assets/icons/minus.svg";

type RemoveButtonProps = {
  remove: <T>(index: number) => T | undefined;
  index?: number;
  onComplete?: () => void;
  isDisabled?: boolean;
};

const RemoveButton = ({
  remove,
  index,
  onComplete,
  isDisabled,
}: RemoveButtonProps) => {
  return (
    <Button
      variant="link"
      size="sm"
      color="error.700"
      _active={{ color: "error.800" }}
      leftIcon={<Image src={IconMinus} alt="Minus Icon" width="16px" />}
      onClick={() => {
        if (index !== undefined) {
          remove(index);
          if (onComplete) {
            onComplete();
          }
        }
      }}
      isDisabled={isDisabled}
    >
      Entfernen
    </Button>
  );
};

export default RemoveButton;
