import {
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  useToken,
} from "@chakra-ui/react";
import { Field, FieldProps, FormikProps } from "formik";
import { OrganizationMembersValues } from "./organization-members";

import IconEmail from "../../assets/icons/mail-01.svg";
import { ReactComponent as IconDelete } from "../../assets/icons/xClose.svg";
import {
  stringMapUserAppRole,
  stringMapUserOrganizationRole,
} from "../../strings";
import Tooltip from "../../components/tooltip";
import { selectUserOrganizationRoles } from "../../utils";
import { UserAppRole, UserOrganizationRole } from "../../API";
import FormSelect from "../../components/form-select";
import { Option } from "../../components/select";
import { useMemo } from "react";

type OrganizationMemberFieldProps = {
  name: string;
  email?: {
    isInvalid: (form: FormikProps<OrganizationMembersValues>) => boolean;
    errorMessage: (
      form: FormikProps<OrganizationMembersValues>
    ) => string | undefined;
  };
  role?: {
    isInvalid: (form: FormikProps<OrganizationMembersValues>) => boolean;
    errorMessage: (
      form: FormikProps<OrganizationMembersValues>
    ) => string | undefined;
  };
  onDelete?: () => Promise<void>;
  props: FormikProps<OrganizationMembersValues>;
};

const OrganizationMemberField = ({
  name,
  email,
  role,
  onDelete,
  props,
}: OrganizationMemberFieldProps) => {
  const [gray700] = useToken("colors", ["gray.700"]);

  const optionsUserOrganizationRole: Option[] = useMemo(
    () =>
      selectUserOrganizationRoles.map((role) => ({
        value: role,
        label:
          name === UserAppRole.organizationAdmin &&
          role === UserOrganizationRole.admin
            ? stringMapUserAppRole.get(UserAppRole.organizationAdmin) ?? ""
            : stringMapUserOrganizationRole.get(role) ?? "",
      })),
    [name]
  );

  return (
    <HStack width="full" alignItems="start">
      <Field name={`${name}.email`}>
        {({ field, form }: FieldProps<number, OrganizationMembersValues>) => (
          <FormControl isInvalid={email?.isInvalid(form)}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Image src={IconEmail} alt="Email Icon" width="16px" />
              </InputLeftElement>
              <Input
                {...field}
                placeholder="name@beispiel.com"
                isDisabled={!email}
              ></Input>
            </InputGroup>
            <FormErrorMessage>{email?.errorMessage(form)}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name={`${name}.organizationRole`}>
        {({ field }: FieldProps<number, OrganizationMembersValues>) => (
          <FormControl>
            <FormSelect
              field={field}
              props={props}
              options={optionsUserOrganizationRole}
              isDisabled={!role}
            />
          </FormControl>
        )}
      </Field>
      {onDelete ? (
        <Tooltip label="Mitglied löschen">
          <IconButton
            aria-label="Delete Member"
            variant="unstyled"
            display="flex"
            icon={
              <Flex boxSize="20px" alignItems="center">
                <IconDelete stroke={gray700} />
              </Flex>
            }
            onClick={onDelete}
          />
        </Tooltip>
      ) : (
        <IconButton aria-label="Delete Member Hidden" visibility="hidden" />
      )}
    </HStack>
  );
};

export default OrganizationMemberField;
