import {
  endOfMonth,
  format,
  formatISO,
  getUnixTime,
  lastDayOfYear,
  startOfDay,
  startOfYear,
} from "date-fns";
import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  BookkeepingFull,
  BookkeepingYear,
  OrganizationFull,
  Tax,
  TransactionFull,
  User,
  UserFull,
} from "./models";
import {
  BillingStatus,
  CurrencyCode,
  UserAppRole,
  UserOrganizationRole,
} from "./API";
import * as Yup from "yup";
import { AmplifyUser } from "@aws-amplify/ui";

import IconBankNote01 from "./assets/icons/categories/bankNote01.svg";
import BookOpen01 from "./assets/icons/categories/bookOpen01.svg";
import Car02 from "./assets/icons/categories/car02.svg";
import CoinsStacked01 from "./assets/icons/categories/coinsStacked01.svg";
import CoinsStacked02 from "./assets/icons/categories/coinsStacked02.svg";
import Droplets03 from "./assets/icons/categories/droplets03.svg";
import File06 from "./assets/icons/categories/file06.svg";
import Home03 from "./assets/icons/categories/home03.svg";
import MedicalCircle from "./assets/icons/categories/medicalCircle.svg";
import MedicalCross from "./assets/icons/categories/medicalCross.svg";
import Package from "./assets/icons/categories/package.svg";
import Pencil01 from "./assets/icons/categories/pencil01.svg";
import Tool02 from "./assets/icons/categories/tool02.svg";
import Users01 from "./assets/icons/categories/users01.svg";
import Restaurant from "./assets/icons/categories/restaurant.svg";
import Receipt from "./assets/icons/categories/receipt.svg";
import Train from "./assets/icons/categories/train.svg";
import Hotel from "./assets/icons/categories/hotel.svg";
import Trophy01 from "./assets/icons/categories/trophy01.svg";
import { keyAccountingSystemValues } from "./routes/onboarding/auth";
import { ResponsiveValue } from "@chakra-ui/react";

export const formatAmount: (
  amount: number,
  currency: CurrencyCode,
  maximumFractionDigits?: number
) => string = (amount, currency, maximumFractionDigits) =>
  amount.toLocaleString("de-CH", {
    style: "currency",
    currency: currency.toUpperCase(),
    maximumFractionDigits: maximumFractionDigits,
  });

// Formats date to "dd.MM.yyyy" string
export const formatDate = (date: Date) => format(date, "dd.MM.yyyy");

// Converts a date string in "dd.MM.yyyy" format to a Date object
export const dateStringToDate = (dateString: string) => {
  const [day, month, year] = dateString.split(".").map(Number);
  return new Date(year, month - 1, day);
};

// Returns the first day of the current year as a Date object
export const firstOfYear = startOfYear(new Date());

// Returns the last day of the current year as a Date object
export const lastOfYear = lastDayOfYear(new Date());

// Returns the current year
export const currentYear = new Date().getFullYear();

export const endOfMonthUnix = getUnixTime(endOfMonth(new Date()));

// Converts a Date object to a YYYY-MM-DD formatted string for API usage
export const dateToApiString = (date: Date) =>
  formatISO(date, { representation: "date" });

// Adjusts a given date to the start of the day in local time
export const getLocalDate = (date: Date) => startOfDay(date);

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getCategory = (
  bookingAccounts: Object,
  bookingAccountId: number,
  title: string
) => {
  let bookingAccount = "";
  for (const [key, value] of Object.entries(bookingAccounts)) {
    if (value === bookingAccountId) {
      bookingAccount = key;
      if (title.includes(bookingAccount)) {
        break;
      }
    }
  }
  return bookingAccount;
};

// TODO: Workaround before upgrading to Amplify v6
interface StorageGetResponse {
  Body?: Blob;
}

export const fetchReceipt = async (
  key: string
): Promise<{ blob: Blob; key: string } | null> => {
  const receipts = await Storage.list(key, { pageSize: 1 });

  if (receipts.results.length > 0 && receipts.results[0].key) {
    const receipt = (await Storage.get(receipts.results[0].key, {
      download: true,
    })) as StorageGetResponse;
    if (receipt.Body) {
      return {
        blob: receipt.Body as Blob,
        key: receipts.results[0].key,
      };
    }
  }

  return null;
};

export const getBookkeepingYear = (
  bookkeeping: BookkeepingFull,
  date?: Date
): BookkeepingYear | undefined =>
  bookkeeping.bookkeepingYears.find(
    (bookkeepingYear: BookkeepingYear) =>
      bookkeepingYear.year ===
      (date?.getUTCFullYear() ?? new Date().getFullYear())
  );

export const compareStrings = (a: string, b: string) =>
  a.localeCompare(b, undefined, { sensitivity: "accent" });

export const getTaxAmount = (transaction: TransactionFull, tax: Tax) =>
  tax.amount ??
  transaction.amount - transaction.amount / (1 + tax.taxRate.rate / 100);

export const getTransactionCategoryIcon = (icon: string) => {
  switch (icon) {
    case "bankNote01":
      return IconBankNote01;
    case "bookOpen01":
      return BookOpen01;
    case "car02":
      return Car02;
    case "coinsStacked01":
      return CoinsStacked01;
    case "coinsStacked02":
      return CoinsStacked02;
    case "droplets03":
      return Droplets03;
    case "file06":
      return File06;
    case "home03":
      return Home03;
    case "medicalCircle":
      return MedicalCircle;
    case "medicalCross":
      return MedicalCross;
    case "package":
      return Package;
    case "pencil01":
      return Pencil01;
    case "tool02":
      return Tool02;
    case "users01":
      return Users01;
    case "restaurant":
      return Restaurant;
    case "receipt":
      return Receipt;
    case "train":
      return Train;
    case "hotel":
      return Hotel;
    case "trophy01":
      return Trophy01;
    default:
      return undefined;
  }
};

// https://github.com/jquense/yup/issues/851#issuecomment-931295671
export const yupSequentialStringSchema = (schemas: Yup.StringSchema[]) => {
  return Yup.string().test(async (value, context) => {
    try {
      for (const schema of schemas) {
        // eslint-disable-next-line no-await-in-loop
        await schema.validate(value);
      }
    } catch (error: unknown) {
      const message = (error as Yup.ValidationError).message;
      return context.createError({ message });
    }

    return true;
  });
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const pretrialDays = 14;

// Pretrial is pretrial_days (Firebase Remote Config - default 14) days from created date
export const isPretrialActive = (organization: OrganizationFull) => {
  const now = new Date();
  const createdAt = new Date(organization.createdAt);
  const diff = now.getTime() - createdAt.getTime();
  const diffDays = diff / (1000 * 60 * 60 * 24);
  return diffDays < pretrialDays;
};

export const isBillingActive = (organization: OrganizationFull) =>
  organization.billing?.status === BillingStatus.active ||
  organization.billing?.status === BillingStatus.trialing ||
  organization.billing?.status === BillingStatus.past_due;

export const showBillingModal = (
  organization: OrganizationFull,
  user: UserFull
) => {
  const userOrganization = user.userOrganizations?.find(
    (uo) => uo.organizationId === organization.id
  );

  return (
    !isBillingActive(organization) &&
    (user.role === UserAppRole.organizationAdmin ||
      (!isPretrialActive(organization) &&
        userOrganization?.role !== UserOrganizationRole.accountant))
  );
};

export const getUserName = (user: User | UserFull, full = false) =>
  user.lastName.length > 0 && user.firstName.length > 0
    ? `${user.lastName} ${
        full ? user.firstName : `${user.firstName.substring(0, 1)}.`
      }`
    : user.email;

export const getAuthUserName = (user?: AmplifyUser) =>
  user?.attributes &&
  user.attributes.family_name.length > 0 &&
  user.attributes.given_name.length > 0
    ? `${
        user?.attributes?.family_name
      } ${user?.attributes?.given_name.substring(0, 1)}.`
    : user?.attributes?.email;

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

const keyFilter = "filter";
const filterDeleted = {
  not: {
    deletedAt: {
      contains: "",
    },
  },
};

export const queryList: <Type, InputType, OutputType>(
  query: GeneratedQuery<InputType, OutputType>,
  variables?: any,
  includeDeleted?: boolean,
  nextToken?: string,
  paginatedItems?: Type[] | undefined
) => Promise<Type[]> = async <Type, InputType, OutputType>(
  query: GeneratedQuery<InputType, OutputType>,
  variables?: any,
  includeDeleted: boolean = false,
  nextToken?: string,
  paginatedItems?: Type[]
) => {
  paginatedItems = paginatedItems ?? [];

  // Extend filter to exclude deleted items
  if (variables && !includeDeleted && !nextToken) {
    variables[keyFilter] =
      keyFilter in variables && variables[keyFilter]
        ? {
            and: [variables[keyFilter], filterDeleted],
          }
        : filterDeleted;
  }

  // Add nextToken to variables
  if (nextToken) {
    variables = {
      ...variables,
      nextToken,
    };
  }

  const response = await API.graphql<GraphQLQuery<OutputType>>(
    graphqlOperation(query, variables)
  );

  let queryName = query.split(" ")[1].replace("(", "").replace("\n", "");
  queryName = queryName.charAt(0).toLowerCase() + queryName.slice(1);

  if ("data" in response) {
    const data = (response.data as any)[queryName];
    const items: Type[] = data.items;
    paginatedItems.push(...items);

    if (data.nextToken) {
      return await queryList<Type, InputType, OutputType>(
        query,
        variables,
        includeDeleted,
        data.nextToken,
        paginatedItems
      );
    }
  }

  return paginatedItems;
};

export const deleteItem: <InputType, OutputType>(
  query: GeneratedMutation<InputType, OutputType>,
  id: string,
  additionalVariables?: any
) => Promise<void> = async <InputType, OutputType>(
  query: GeneratedMutation<InputType, OutputType>,
  id: string,
  additionalVariables?: any
) => {
  await API.graphql<GraphQLQuery<OutputType>>(
    graphqlOperation(query, {
      input: {
        id: id,
        deletedAt: getLocalDate(new Date()).toISOString(),
        ...(additionalVariables ?? {}),
      },
    })
  );
};

export const aerzteTreuhandLogo = "aerzte_treuhand.png";

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const createOrganizationText = "Organisation anlegen";

export const createOrganizationSteps = [
  "organization",
  "organization-members",
  "bookkeeping",
  "accounting-system-login",
  "accounting-system-config",
  "booking-accounts",
  "tax-rates",
] as const;

export const clearCreateOrganizationSessionStorage = () => {
  createOrganizationSteps.forEach((key) => sessionStorage.removeItem(key));
  localStorage.removeItem(keyAccountingSystemValues);
};

export const stringWithoutManualLineBreaks = (s: string) =>
  s
    .replaceAll("-\\n", "")
    .replaceAll("-\n", "")
    .replaceAll("\\n", " ")
    .replaceAll("\n", " ");

export const showSettings = (
  user: UserFull,
  organization: OrganizationFull
) => {
  const userOrganization = user.userOrganizations?.find(
    (uo) => uo.organizationId === organization.id
  );
  return (
    // Accountants can see settings of their clients
    userOrganization?.role === UserOrganizationRole.accountant ||
    // Admins can see settings of their organization if not ÄT
    (userOrganization?.role === UserOrganizationRole.admin &&
      organization.logo !== aerzteTreuhandLogo) ||
    // Accountant admins can see settings of their organization
    (user.role === UserAppRole.accountant &&
      userOrganization?.role === UserOrganizationRole.admin)
  );
};

export const emailAlreadyExists = "E-Mail-Adresse bereits vorhanden";

export const emailValidation = (users?: User[]) =>
  yupSequentialStringSchema([
    Yup.string()
      .email("Ungültige E-Mail-Adresse")
      .matches(
        /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i,
        "Ungültige E-Mail-Adresse"
      )
      .required("E-Mail-Adresse ist erforderlich"),
    Yup.string().test("unique", emailAlreadyExists, async (value) => {
      // Check first if the email is already in the list of users
      if (users?.find((user) => user.email === value)) {
        return true;
      }

      const response = await API.get("AdminQueries", "/getUser", {
        headers: {
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
          "Content-Type": "application/json",
        },
        queryStringParameters: {
          username: value,
        },
      });
      return !("Username" in response);
    }),
  ]);

export const selectUserOrganizationRoles = [
  UserOrganizationRole.employee,
  UserOrganizationRole.head,
  UserOrganizationRole.admin,
] as const;

// Function to check if a value is a string that looks like an ISO 8601 date
export const looksLikeDate = (value: any) => {
  return (
    typeof value === "string" &&
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[+-]\d{2}:\d{2})$/.test(
      value
    )
  );
};

// Function to recursively convert date strings to Date objects within an object
export const convertToDateObjects = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recurse into objects
      convertToDateObjects(obj[key]);
    } else if (looksLikeDate(obj[key])) {
      // Convert string to Date
      obj[key] = new Date(obj[key]);
    }
  }
};

export const maxWidth: ResponsiveValue<string> = { base: "auto", md: "560px" };
export const maxWidthTables: ResponsiveValue<string> = {
  base: "auto",
  md: "1024px",
};

export const openInNewTab = (url: string) => {
  let windowReference = window.open();
  if (windowReference) {
    windowReference.location = url;
  }
};
