import { useEffect, useMemo, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  Spacer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  VStack,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../components/data-table";
import {
  aerzteTreuhandLogo,
  compareStrings,
  dateToApiString,
  deleteItem,
  fetchReceipt,
  firstOfYear,
  formatAmount,
  formatDate,
  getBookkeepingYear,
  getLocalDate,
  getTaxAmount,
  getTransactionCategoryIcon,
  getUserName,
  isPretrialActive,
  openInNewTab,
  queryList,
  showBillingModal,
  showSettings,
  stringWithoutManualLineBreaks,
} from "../../utils";
import { State } from "../../types";

import IconFilePdf from "../../assets/icons/file-pdf.svg";
import { ReactComponent as IconEdit } from "../../assets/icons/pencil.svg";
import { ReactComponent as IconCancel } from "../../assets/icons/xClose.svg";
import IconSettings from "../../assets/icons/settings-01.svg";

import DownloadButton from "../../components/download-button";
import DateSelector from "../../components/date-selector";
import DeleteButton from "../../components/delete-button";
import {
  AccountingSystem,
  AttachmentsByTransactionIdQuery,
  AttachmentsByTransactionIdQueryVariables,
  CurrencyCode,
  ListUsersQuery,
  ListUsersQueryVariables,
  OrganizationType,
  TaxRatesByBookkeepingIdQuery,
  TaxRatesByBookkeepingIdQueryVariables,
  TaxType,
  TaxesByTransactionIdQuery,
  TaxesByTransactionIdQueryVariables,
  TransactionStatus,
  TransactionsByBookkeepingYearIdQuery,
  TransactionsByBookkeepingYearIdQueryVariables,
  UpdateBookkeepingYearMutation,
  UpdateTransactionMutation,
  UserAppRole,
} from "../../API";
import {
  Attachment,
  BookingAccount,
  BookkeepingFull,
  BookkeepingYear,
  Tax,
  TaxRate,
  Transaction,
  TransactionCategory,
  TransactionFull,
  User,
} from "../../models";
import {
  attachmentsByTransactionId,
  listUsers,
  taxRatesByBookkeepingId,
  taxesByTransactionId,
  transactionsByBookkeepingYearId,
} from "../../graphql/queries";
import TransactionStatusBadge from "../../components/transaction-status-badge";
import DateRangeSelector from "../../components/date-range-selector";
import { DateRange } from "react-day-picker";
import { MultiSelect, Option } from "chakra-multiselect";
import NoOrganization from "../../components/no-organization";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  updateBookkeepingYear,
  updateTransaction,
} from "../../graphql/mutations";
import { useContextTypeTransactions } from "./dashboard-layout";
import { useNavigate } from "react-router-dom";
import { routeOnboardingBilling, routeSettingsBookkeeping } from "../routes";
import { useTransactionCategories } from "../../hooks/use-transaction-categories";
import { useUserOrganizations } from "../../hooks/use-user-organizations";
import { useUsersByBookkeeping } from "../../hooks/use-users-by-bookkeeping";
import Tooltip from "../../components/tooltip";
import SignOutLinkButton from "../../components/sign-out-link-button";
import { useTransactionReferencesByUsers } from "../../hooks/use-transaction-references-by-users";
import Select, { Option as SelectOption } from "../../components/select";

const emptyFunction = () => {};
const reloadWindow = () => window.location.reload();

const typeNameSingular = "Transaktion";
const typeNamePlural = "Transaktionen";

const baseCurrency = CurrencyCode.chf;

const columnHelper = createColumnHelper<TransactionFull>();

const currencyCodesDefault = [CurrencyCode.chf, CurrencyCode.eur];
const currencyCodesExtended = [
  ...currencyCodesDefault,
  CurrencyCode.usd,
  CurrencyCode.gbp,
  CurrencyCode.brl,
  CurrencyCode.cny,
  CurrencyCode.jpy,
  CurrencyCode.mxn,
  CurrencyCode.cop,
];

const optionsCurrencyCodesExtended: SelectOption[] = currencyCodesExtended.map(
  (currency) => ({
    value: currency,
    label: currency.toUpperCase(),
  })
);

const Transactions = () => {
  const navigate = useNavigate();

  const [gray700, success700, error700] = useToken("colors", [
    "gray.700",
    "success.700",
    "error.700",
  ]);

  const {
    user,
    organizations,
    selectedOrganization,
    selectedBookkeepingFull,
    setSelectedBookkeepingFull,
  } = useContextTypeTransactions();

  const optionsBookingAccounts: SelectOption[] | undefined = useMemo(
    () =>
      selectedBookkeepingFull?.bookingAccounts.map((bookingAccount) => ({
        value: bookingAccount.id,
        label: stringWithoutManualLineBreaks(bookingAccount.name),
      })),
    [selectedBookkeepingFull]
  );

  const { transactionCategories } = useTransactionCategories();
  const [taxRates, setTaxRates] = useState<TaxRate[]>();
  const [selectedUsers, setSelectedUsers] = useState<Option[]>();
  const { userOrganizations } = useUserOrganizations({ selectedOrganization });
  const { users } = useUsersByBookkeeping({
    userOrganizations,
    selectedOrganization,
    selectedBookkeepingFull,
  });

  const optionsUsers: SelectOption[] | undefined = useMemo(
    () =>
      users?.map((user) => ({
        value: user.id,
        label: getUserName(user),
      })),
    [users]
  );

  const { transactionReferences } = useTransactionReferencesByUsers(users);
  const [transactions, setTransactions] = useState<TransactionFull[]>();
  const [state, setState] = useState<State>("initial");
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: firstOfYear,
    to: getLocalDate(new Date()),
  });
  const [hasEarnings, setHasEarnings] = useState<boolean>();
  const [hasCurrencies, setHasCurrencies] = useState<boolean>();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [transactionIdsSelected, setTransactionIdsSelected] = useState<
    string[]
  >([]);
  const [transactionsUpdated, setTransactionsUpdated] = useState<
    TransactionFull[]
  >([]);

  const transactionAmountSign = useMemo(
    () => (transaction: TransactionFull) =>
      hasEarnings &&
      transaction.bookingAccount.taxType !== TaxType.earnings &&
      // Accountants will always see the amount as positive
      user?.role !== UserAppRole.accountant
        ? -1
        : 1,
    [hasEarnings, user]
  );

  const columns = useMemo(
    () => [
      ...(editing
        ? [
            columnHelper.display({
              id: "select",
              cell: (info) => (
                <Checkbox
                  colorScheme="primary"
                  isChecked={
                    !!transactionIdsSelected.find(
                      (tid) => info.row.original.id === tid
                    )
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      setTransactionIdsSelected([
                        ...transactionIdsSelected,
                        info.row.original.id,
                      ]);
                    } else {
                      setTransactionIdsSelected(
                        transactionIdsSelected.filter(
                          (tid) => tid !== info.row.original.id
                        )
                      );
                    }
                  }}
                />
              ),
              header: () => (
                <Checkbox
                  colorScheme="primary"
                  onChange={(event) => {
                    if (event.target.checked) {
                      setTransactionIdsSelected(
                        transactions?.map((transaction) => transaction.id) ?? []
                      );
                    } else {
                      setTransactionIdsSelected([]);
                    }
                  }}
                  isChecked={
                    transactionIdsSelected.length === transactions?.length
                  }
                />
              ),
            }),
          ]
        : []),
      columnHelper.accessor("paidAt", {
        cell: (info) => {
          if (editing) {
            const transactionUpdated = transactionsUpdated.find(
              (t) => t.id === info.row.original.id
            );

            return (
              <DateSelector
                defaultValue={getLocalDate(
                  new Date(transactionUpdated?.paidAt ?? info.getValue())
                )}
                onChange={(date) => {
                  if (date) {
                    if (transactionUpdated) {
                      setTransactionsUpdated(
                        transactionsUpdated.map((t) => ({
                          ...t,
                          paidAt:
                            t.id === info.row.original.id
                              ? dateToApiString(date)
                              : t.paidAt,
                        }))
                      );
                    } else {
                      setTransactionsUpdated([
                        ...transactionsUpdated,
                        {
                          ...info.row.original,
                          paidAt: dateToApiString(date),
                        },
                      ]);
                    }
                  }
                }}
                width="120px"
              />
            );
          } else {
            return formatDate(getLocalDate(new Date(info.getValue())));
          }
        },
        header: "Datum",
      }),
      columnHelper.accessor("title", {
        cell: (info) => {
          if (editing) {
            const transactionUpdated = transactionsUpdated.find(
              (t) => t.id === info.row.original.id
            );

            return (
              <Input
                defaultValue={transactionUpdated?.title ?? info.getValue()}
                onBlur={(event) => {
                  if (transactionUpdated) {
                    setTransactionsUpdated(
                      transactionsUpdated.map((t) => ({
                        ...t,
                        title: event.target.value,
                      }))
                    );
                  } else {
                    setTransactionsUpdated([
                      ...transactionsUpdated,
                      {
                        ...info.row.original,
                        title: event.target.value,
                      },
                    ]);
                  }
                }}
                width="200px"
              />
            );
          } else {
            return info.getValue();
          }
        },
        header: typeNameSingular,
      }),
      columnHelper.accessor("amount", {
        cell: (info) => {
          if (editing) {
            const transactionUpdated = transactionsUpdated.find(
              (t) => t.id === info.row.original.id
            );

            const inputAmount = (
              <NumberInput
                defaultValue={transactionUpdated?.amount ?? info.getValue()}
                onBlur={(event) => {
                  if (transactionUpdated) {
                    setTransactionsUpdated(
                      transactionsUpdated.map((t) => ({
                        ...t,
                        amount: parseFloat(event.target.value),
                      }))
                    );
                  } else {
                    setTransactionsUpdated([
                      ...transactionsUpdated,
                      {
                        ...info.row.original,
                        amount: parseFloat(event.target.value),
                      },
                    ]);
                  }
                }}
                precision={2}
                width="100px"
              >
                <NumberInputField />
              </NumberInput>
            );

            const input =
              hasEarnings &&
              ((transactionUpdated &&
                selectedBookkeepingFull?.bookingAccounts.find(
                  (ba) => ba.id === transactionUpdated.bookingAccountId
                )?.taxType !== TaxType.earnings) ||
                (!transactionUpdated &&
                  info.row.original.bookingAccount.taxType !==
                    TaxType.earnings)) ? (
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Box width="20px">-</Box>
                  </InputLeftElement>
                  {inputAmount}
                </InputGroup>
              ) : (
                inputAmount
              );

            return hasCurrencies ? (
              <HStack>
                <Select
                  value={optionsCurrencyCodesExtended.find(
                    (option) =>
                      option.value ===
                      (transactionUpdated?.currencyCode ??
                        info.row.original.currencyCode)
                  )}
                  options={optionsCurrencyCodesExtended}
                  onChange={(option) => {
                    if (transactionUpdated) {
                      setTransactionsUpdated(
                        transactionsUpdated.map((t) => ({
                          ...t,
                          currencyCode: option?.value as CurrencyCode,
                        }))
                      );
                    } else {
                      setTransactionsUpdated([
                        ...transactionsUpdated,
                        {
                          ...info.row.original,
                          currencyCode: option?.value as CurrencyCode,
                        },
                      ]);
                    }
                  }}
                  width="80px"
                />
                {input}
              </HStack>
            ) : (
              input
            );
          } else {
            return formatAmount(
              transactionAmountSign(info.row.original) * info.getValue(),
              info.row.original.currencyCode
            );
          }
        },
        header: hasCurrencies ? "Original Betrag" : "Betrag",
      }),
      ...(hasCurrencies
        ? [
            columnHelper.accessor("baseCurrencyAmount", {
              cell: (info) => {
                const transactionUpdated = transactionsUpdated.find(
                  (t) => t.id === info.row.original.id
                );

                if (editing && transactionUpdated) {
                  return transactionUpdated.baseCurrencyAmount
                    ? formatAmount(
                        transactionAmountSign(transactionUpdated) *
                          (transactionUpdated.baseCurrencyAmount as number),
                        baseCurrency
                      )
                    : formatAmount(
                        transactionAmountSign(transactionUpdated) *
                          transactionUpdated.amount,
                        baseCurrency
                      );
                } else {
                  return info.getValue()
                    ? formatAmount(
                        transactionAmountSign(info.row.original) *
                          (info.getValue() as number),
                        baseCurrency
                      )
                    : formatAmount(
                        transactionAmountSign(info.row.original) *
                          info.row.original.amount,
                        baseCurrency
                      );
                }
              },
              header: `${baseCurrency.toUpperCase()} Betrag`,
            }),
          ]
        : []),
      ...(taxRates && taxRates.length > 0
        ? [
            columnHelper.accessor("taxes", {
              cell: (info) => {
                const transactionUpdated = transactionsUpdated.find(
                  (t) => t.id === info.row.original.id
                );
                const taxesActive = (
                  editing && transactionUpdated
                    ? transactionUpdated.taxes
                    : info.getValue()
                ).filter((tax) => !tax.deletedAt);

                if (editing) {
                  return (
                    <HStack>
                      {taxesActive.length > 0 && (
                        <NumberInput
                          defaultValue={getTaxAmount(
                            transactionUpdated ?? info.row.original,
                            taxesActive[0] as Tax
                          )}
                          precision={2}
                          width="28"
                          isDisabled
                        >
                          <NumberInputField />
                        </NumberInput>
                      )}
                      {taxesActive.length > 1 && (
                        <NumberInput
                          defaultValue={getTaxAmount(
                            transactionUpdated ?? info.row.original,
                            taxesActive[1] as Tax
                          )}
                          precision={2}
                          width="28"
                          isDisabled
                        >
                          <NumberInputField />
                        </NumberInput>
                      )}
                    </HStack>
                  );
                } else {
                  return taxesActive
                    .map((tax) =>
                      formatAmount(
                        getTaxAmount(info.row.original, tax as Tax),
                        baseCurrency
                      )
                    )
                    .join(" | ");
                }
              },
              header: "MwSt. Betrag",
              id: "taxes_amount",
            }),
            columnHelper.accessor("taxes", {
              cell: (info) => {
                const taxesActive = info
                  .getValue()
                  .filter((tax) => !tax.deletedAt);

                if (editing) {
                  const taxRatesActive = taxRates.filter(
                    (taxRate) =>
                      new Date(taxRate.dateFrom) <=
                        new Date(info.row.original.paidAt) &&
                      (!taxRate.dateTo ||
                        new Date(taxRate.dateTo) >=
                          new Date(info.row.original.paidAt))
                  );
                  const optionsTaxRatesActive: SelectOption[] =
                    taxRatesActive.map((taxRate) => ({
                      value: taxRate.id,
                      label: `${taxRate.rate}%`,
                    }));

                  return (
                    <HStack>
                      {taxesActive.length > 0 && taxRatesActive.length > 0 && (
                        <Select
                          value={optionsTaxRatesActive.find(
                            (option) =>
                              option.value === taxesActive[0].taxRate.id
                          )}
                          options={optionsTaxRatesActive}
                          width="84px"
                          isDisabled
                        />
                      )}
                      {taxesActive.length > 1 && taxRatesActive.length > 0 && (
                        <Select
                          value={optionsTaxRatesActive.find(
                            (option) =>
                              option.value === taxesActive[1].taxRate.id
                          )}
                          options={optionsTaxRatesActive}
                          width="84px"
                          isDisabled
                        />
                      )}
                    </HStack>
                  );
                } else {
                  return taxesActive
                    .map((tax) => `${tax.taxRate?.rate}%`)
                    .join(" | ");
                }
              },
              header: "MwSt. Satz",
              id: "taxes_rate",
            }),
            columnHelper.accessor("taxes", {
              cell: (info) => {
                const taxesActive = info
                  .getValue()
                  .filter((tax) => !tax.deletedAt);

                return taxesActive
                  .map((tax) =>
                    formatAmount(
                      tax.totalAmount ?? info.row.original.amount,
                      baseCurrency
                    )
                  )
                  .join(" | ");
              },
              header: "MwSt. Betrag Total",
              id: "taxes_total_amount",
            }),
          ]
        : []),
      columnHelper.accessor("bookingAccount", {
        cell: (info) => {
          if (editing) {
            const transactionUpdated = transactionsUpdated.find(
              (t) => t.id === info.row.original.id
            );

            return (
              <Select
                value={optionsBookingAccounts?.find(
                  (option) =>
                    option.value ===
                    (transactionUpdated?.bookingAccountId ?? info.getValue().id)
                )}
                options={optionsBookingAccounts}
                onChange={(option) => {
                  if (transactionUpdated) {
                    setTransactionsUpdated(
                      transactionsUpdated.map((t) => ({
                        ...t,
                        bookingAccountId: option?.value as string,
                      }))
                    );
                  } else {
                    setTransactionsUpdated([
                      ...transactionsUpdated,
                      {
                        ...info.row.original,
                        bookingAccountId: option?.value as string,
                      },
                    ]);
                  }
                }}
                width="160px"
              />
            );
          } else {
            return (
              <Tag variant="outline">
                <TagLeftIcon
                  as={Image}
                  src={getTransactionCategoryIcon(
                    info.getValue().transactionCategory.icon
                  )}
                />
                <TagLabel>
                  {stringWithoutManualLineBreaks(info.getValue().name)}
                </TagLabel>
              </Tag>
            );
          }
        },
        header: "Buchungskategorie",
      }),
      ...(transactionReferences && transactionReferences.length > 0
        ? [
            columnHelper.accessor("transactionReference", {
              cell: (info) => {
                if (editing) {
                  const transactionUpdated = transactionsUpdated.find(
                    (t) => t.id === info.row.original.id
                  );
                  const optionsTransactionReferences: SelectOption[] =
                    transactionReferences
                      .filter(
                        (transactionReference) =>
                          transactionReference.authUserId ===
                          info.row.original.userId
                      )
                      .map((transactionReference) => ({
                        value: transactionReference.id,
                        label: stringWithoutManualLineBreaks(
                          transactionReference.name
                        ),
                      }));

                  return (
                    <Select
                      value={optionsTransactionReferences.find(
                        (option) =>
                          option.value ===
                          (transactionUpdated?.transactionReferenceId ??
                            info.getValue()?.id)
                      )}
                      options={optionsTransactionReferences}
                      onChange={(option) => {
                        if (transactionUpdated) {
                          setTransactionsUpdated(
                            transactionsUpdated.map((t) => ({
                              ...t,
                              transactionReferenceId: option?.value as string,
                            }))
                          );
                        } else {
                          setTransactionsUpdated([
                            ...transactionsUpdated,
                            {
                              ...info.row.original,
                              transactionReferenceId: option?.value as string,
                            },
                          ]);
                        }
                      }}
                      width="160px"
                    />
                  );
                } else {
                  return info.getValue()?.name;
                }
              },
              header: "Referenz",
            }),
          ]
        : []),
      columnHelper.accessor("attachments", {
        cell: (info) => (
          <Tooltip label="Beleg herunterladen">
            <IconButton
              variant="outline"
              aria-label="Download receipt"
              icon={<Image boxSize="20px" src={IconFilePdf} />}
              isDisabled={info.getValue().length === 0}
              onClick={async () => {
                const key =
                  info.getValue().length > 0
                    ? info.getValue()[0].key
                    : undefined;

                if (key) {
                  const receiptData = await fetchReceipt(key);

                  if (receiptData && receiptData.blob) {
                    const url = URL.createObjectURL(receiptData.blob);
                    openInNewTab(url);
                  }
                }
              }}
            />
          </Tooltip>
        ),
        header: "Beleg",
      }),
      columnHelper.accessor("status", {
        cell: (info) => <TransactionStatusBadge status={info.getValue()} />,
        header: "Status",
      }),
      columnHelper.accessor("user", {
        cell: (info) => {
          if (editing) {
            const transactionUpdated = transactionsUpdated.find(
              (t) => t.id === info.row.original.id
            );

            return (
              <Select
                value={optionsUsers?.find(
                  (option) =>
                    option.value ===
                    (transactionUpdated?.userId ?? info.getValue().id)
                )}
                options={optionsUsers}
                onChange={(option) => {
                  if (transactionUpdated) {
                    setTransactionsUpdated(
                      transactionsUpdated.map((t) => ({
                        ...t,
                        userId: option?.value as string,
                      }))
                    );
                  } else {
                    setTransactionsUpdated([
                      ...transactionsUpdated,
                      {
                        ...info.row.original,
                        userId: option?.value as string,
                      },
                    ]);
                  }
                }}
                width="140px"
              />
            );
          } else {
            return getUserName(info.getValue());
          }
        },
        header: "Benutzer",
      }),
    ],
    [
      editing,
      hasCurrencies,
      hasEarnings,
      optionsBookingAccounts,
      optionsUsers,
      selectedBookkeepingFull?.bookingAccounts,
      taxRates,
      transactionAmountSign,
      transactionIdsSelected,
      transactionReferences,
      transactions,
      transactionsUpdated,
    ]
  );

  const updateBalances = async (
    bookkeepingYearsAmount: Map<BookkeepingYear, number>
  ) => {
    // Update bookkeepingYear balance
    const bookkeepingYearsAmountPromises = Array.from(
      bookkeepingYearsAmount.entries()
    ).map(async ([bookkeepingYear, amount]) => {
      if (typeof bookkeepingYear.balance === "number") {
        await API.graphql<GraphQLQuery<UpdateBookkeepingYearMutation>>({
          query: updateBookkeepingYear,
          variables: {
            input: {
              id: bookkeepingYear.id,
              balance: bookkeepingYear.balance + amount,
            },
          },
        });
      }
    });

    await Promise.all(bookkeepingYearsAmountPromises);

    // Trigger Bookkeeping update
    setSelectedBookkeepingFull(undefined);
  };

  useEffect(() => {
    if (selectedBookkeepingFull) {
      setHasEarnings(
        selectedBookkeepingFull.bookingAccounts.some(
          (bookingAccount) => bookingAccount.taxType === TaxType.earnings
        )
      );
    }
  }, [selectedBookkeepingFull]);

  useEffect(() => {
    if (selectedOrganization) {
      setHasCurrencies(selectedOrganization.logo !== aerzteTreuhandLogo);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedBookkeepingFull) {
      const fetchTaxRates = async () => {
        const taxRates = await queryList<
          TaxRate,
          TaxRatesByBookkeepingIdQueryVariables,
          TaxRatesByBookkeepingIdQuery
        >(taxRatesByBookkeepingId, {
          bookkeepingId: selectedBookkeepingFull.id,
        });

        const taxRatesSorted = taxRates.sort((a, b) =>
          a.sortOrder < b.sortOrder ? -1 : 1
        );

        setTaxRates(taxRatesSorted);
      };
      fetchTaxRates();
    }
  }, [selectedBookkeepingFull]);

  useEffect(() => {
    if (state === "loading") {
      if (
        selectedBookkeepingFull &&
        selectedBookkeepingFull.organizationId === selectedOrganization?.id
      ) {
        async function fetchTransactions() {
          if (dateRange?.from && dateRange?.to) {
            const years = [];

            const dateRangeTo = dateRange.to;
            dateRangeTo.setHours(23);
            dateRangeTo.setMinutes(59);

            for (
              let i = dateRange.from.getFullYear();
              i <= dateRangeTo.getFullYear();
              i++
            ) {
              years.push(i);
            }

            const bookkeepingYears = years
              .map((year) =>
                getBookkeepingYear(
                  selectedBookkeepingFull as BookkeepingFull,
                  new Date(year, 2, 2)
                )
              )
              .filter((bookkeepingYear) => bookkeepingYear);

            if (
              bookkeepingYears.length > 0 &&
              selectedUsers &&
              selectedUsers.length > 0
            ) {
              const transactionPromises = bookkeepingYears.map(
                async (bookkeepingYear) =>
                  await queryList<
                    Transaction,
                    TransactionsByBookkeepingYearIdQueryVariables,
                    TransactionsByBookkeepingYearIdQuery
                  >(transactionsByBookkeepingYearId, {
                    bookkeepingYearId: bookkeepingYear!.id,
                    filter: {
                      and: [
                        {
                          paidAt: {
                            between: [
                              dateToApiString(dateRange.from!),
                              dateToApiString(dateRangeTo),
                            ],
                          },
                        },
                        selectedUsers?.length > 1
                          ? {
                              or: selectedUsers.map((user) => ({
                                userId: {
                                  eq: user.value,
                                },
                              })),
                            }
                          : {
                              userId: {
                                eq: selectedUsers[0].value,
                              },
                            },
                      ].filter((filter) => filter),
                    },
                  })
              );

              const transactionsByYear = await Promise.all(transactionPromises);

              const transactionFullPromises = transactionsByYear
                .flat()
                .map(async (transaction) => {
                  const bookingAccount =
                    selectedBookkeepingFull?.bookingAccounts.find(
                      (bookingAccount) =>
                        bookingAccount.id === transaction?.bookingAccountId
                    ) as BookingAccount;
                  const transactionCategory = transactionCategories?.find(
                    (transactionCategory) =>
                      transactionCategory.id ===
                      bookingAccount.transactionCategoryId
                  ) as TransactionCategory;
                  const transactionReference = transactionReferences?.find(
                    (transactionReference) =>
                      transactionReference.id ===
                      transaction?.transactionReferenceId
                  );
                  const bookingAccountFull = {
                    ...bookingAccount,
                    transactionCategory: transactionCategory,
                  };
                  const user = users?.find(
                    (user) => user.id === transaction.userId
                  ) as User;
                  const taxes = await queryList<
                    Tax,
                    TaxesByTransactionIdQueryVariables,
                    TaxesByTransactionIdQuery
                  >(taxesByTransactionId, {
                    transactionId: transaction.id,
                  });
                  const attachments = await queryList<
                    Attachment,
                    AttachmentsByTransactionIdQueryVariables,
                    AttachmentsByTransactionIdQuery
                  >(attachmentsByTransactionId, {
                    transactionId: transaction.id,
                  });

                  return {
                    ...(transaction as unknown as Transaction),
                    bookingAccount: bookingAccountFull,
                    taxes: taxes.map((tax) => ({
                      ...tax,
                      taxRate: taxRates?.find(
                        (taxRate) => taxRate.id === tax.taxRateId
                      ) as TaxRate,
                    })),
                    user: user,
                    attachments: attachments,
                    transactionReference: transactionReference,
                  };
                });

              const transactionsAll: TransactionFull[] = await Promise.all(
                transactionFullPromises
              );

              setTransactions(
                transactionsAll.sort((a, b) =>
                  compareStrings(b.paidAt, a.paidAt)
                )
              );

              // Reset editing states
              setTransactionIdsSelected(
                transactionIdsSelected.filter((tid) =>
                  transactionsAll.find((t) => t.id === tid)
                )
              );
              setTransactionsUpdated(
                transactionsUpdated.filter((t) =>
                  transactionsAll.find((transaction) => transaction.id === t.id)
                )
              );
            } else {
              setTransactions([]);
            }
          }
        }

        fetchTransactions();
      } else {
        setTransactions([]);
      }

      setState("success");
    }
  }, [
    dateRange,
    selectedBookkeepingFull,
    selectedOrganization,
    selectedUsers,
    state,
    taxRates,
    transactionCategories,
    transactionIdsSelected,
    transactionReferences,
    transactionsUpdated,
    users,
  ]);

  useEffect(() => {
    setState("loading");
  }, [
    dateRange,
    selectedBookkeepingFull,
    selectedOrganization,
    selectedUsers,
    transactionReferences,
  ]);

  useEffect(() => {
    if (users) {
      setSelectedUsers(
        users.map((user) => ({ label: getUserName(user), value: user.id }))
      );
    }
  }, [users]);

  let balance: number | undefined;

  if (
    selectedOrganization?.logo === aerzteTreuhandLogo &&
    selectedBookkeepingFull
  ) {
    balance = getBookkeepingYear(selectedBookkeepingFull)?.balance ?? undefined;
  }

  type BillingModalProps = {
    pretrialActive: boolean;
    organizationAdminUser: User | undefined;
    onClose?: () => void;
    closed?: boolean;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef(null);
  const [billingModalProps, setBillingModalProps] = useState<BillingModalProps>(
    {
      pretrialActive: true,
      organizationAdminUser: undefined,
    }
  );

  useEffect(() => {
    if (
      selectedOrganization &&
      user &&
      showBillingModal(selectedOrganization, user) &&
      !isOpen &&
      !billingModalProps.closed
    ) {
      const showBillingModal = async () => {
        const _pretrialActive = isPretrialActive(selectedOrganization);
        const usersResponse = await queryList<
          User,
          ListUsersQueryVariables,
          ListUsersQuery
        >(listUsers, {
          filter: {
            role: { eq: UserAppRole.organizationAdmin },
          },
        });

        setBillingModalProps({
          pretrialActive: _pretrialActive,
          organizationAdminUser:
            usersResponse.length > 0 ? usersResponse[0] : undefined,
          onClose: _pretrialActive
            ? () => {
                setBillingModalProps({ ...billingModalProps, closed: true });
              }
            : undefined,
          closed: false,
        });
      };
      showBillingModal();
    }
  }, [billingModalProps, isOpen, selectedOrganization, user]);

  useEffect(() => {
    if (billingModalProps.closed !== undefined) {
      if (billingModalProps.closed) {
        onClose();
      } else {
        onOpen();
      }
    }
  }, [billingModalProps.closed, onClose, onOpen]);

  return (
    <VStack p="4" alignItems="start" width="full">
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={buttonRef}
        onClose={billingModalProps.onClose ?? emptyFunction}
        isCentered
        closeOnOverlayClick={billingModalProps.pretrialActive}
      >
        <AlertDialogOverlay>
          <AlertDialogContent m={4}>
            <AlertDialogHeader
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pb={0}
            >
              <Text fontSize="lg" fontWeight="semibold">
                Zahlungsdetails aktualisieren
              </Text>
              {billingModalProps.onClose && (
                <IconButton
                  variant="ghost"
                  aria-label="Close"
                  icon={
                    <Flex
                      boxSize="20px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconCancel stroke={gray700} />
                    </Flex>
                  }
                  onClick={billingModalProps.onClose}
                  isDisabled={billingModalProps.onClose === undefined}
                />
              )}
            </AlertDialogHeader>
            <AlertDialogBody color="gray.600">
              {user?.role === UserAppRole.organizationAdmin
                ? `Um Expensly${
                    billingModalProps.pretrialActive ? " in Zukunft" : ""
                  } weiterhin nutzen zu können, musst du die Zahlungsdetails aktualisieren.`
                : "Um Expensly weiterhin nutzen zu können, muss dein Organisationsleiter die Zahlungsdetails auf web.expensly.ch aktualisieren."}
              {user?.role !== UserAppRole.organizationAdmin &&
                billingModalProps.organizationAdminUser && (
                  <>
                    <br />
                    {`Kontaktiere dazu ${billingModalProps.organizationAdminUser.email}`}
                  </>
                )}
              {user?.role !== UserAppRole.organizationAdmin && (
                <>
                  <br />
                  <br />
                  Klicke auf Bestätigen, wenn die Zahlungsdetails aktualisiert
                  wurden.
                </>
              )}
            </AlertDialogBody>
            <AlertDialogFooter flexDirection="column">
              <Button
                ref={buttonRef}
                colorScheme="primary"
                onClick={
                  user?.role === UserAppRole.organizationAdmin
                    ? () => navigate(routeOnboardingBilling)
                    : billingModalProps.onClose ?? reloadWindow
                }
                width="full"
              >
                {user?.role === UserAppRole.organizationAdmin
                  ? "Zahlungsdetails aktualisieren"
                  : "Bestätigen"}
              </Button>
              {!billingModalProps.onClose && (
                <SignOutLinkButton mt="12px" withName={false} />
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex
        mb="4"
        alignItems={{ base: "stretch", md: "center" }}
        gap={{ base: "2", md: "4" }}
        width="full"
        zIndex={2}
        direction={{ base: "column", md: "row" }}
      >
        <Heading size="md">{typeNamePlural}</Heading>
        <Spacer />
        <DateRangeSelector
          range={dateRange}
          setRange={setDateRange}
          state={state}
        />
        <MultiSelect
          options={users?.map((user) => ({
            label: getUserName(user),
            value: user.id,
          }))}
          value={selectedUsers}
          onChange={(value) => {
            setSelectedUsers(value as unknown as Option[]);
          }}
        />
        <Flex gap={{ base: "2", md: "4" }}>
          <DownloadButton
            bookkeeping={selectedBookkeepingFull}
            transactions={transactions?.filter((t) =>
              transactionIdsSelected.length > 0
                ? transactionIdsSelected.includes(t.id)
                : true
            )}
            baseCurrency={baseCurrency}
            state={state}
            dateTo={dateRange?.to}
            hasTaxes={taxRates && taxRates.length > 0}
          />
          {transactionIdsSelected.length > 0 && (
            <DeleteButton
              typeName={
                transactionIdsSelected.length === 1
                  ? typeNameSingular
                  : typeNamePlural
              }
              onDelete={async () => {
                setDeleting(true);

                const transactionDeletePromises = transactionIdsSelected.map(
                  async (transactionId) => {
                    await deleteItem(
                      updateTransaction,
                      transactionId,
                      selectedBookkeepingFull?.accountingSystem ===
                        AccountingSystem.bexio
                        ? {
                            status: TransactionStatus.draft,
                          }
                        : undefined
                    );
                  }
                );

                await Promise.all(transactionDeletePromises);

                // Update balance if ÄT
                if (
                  selectedOrganization?.logo === aerzteTreuhandLogo &&
                  selectedBookkeepingFull
                ) {
                  const bookkeepingYearsAmount = new Map<
                    BookkeepingYear,
                    number
                  >();
                  for (const transactionId of transactionIdsSelected) {
                    const transaction = transactions?.find(
                      (t) => t.id === transactionId
                    );
                    if (transaction) {
                      const bookkeepingYear = getBookkeepingYear(
                        selectedBookkeepingFull,
                        getLocalDate(new Date(transaction.paidAt))
                      );
                      if (bookkeepingYear) {
                        const amount =
                          transaction.bookingAccount.taxType ===
                          TaxType.earnings
                            ? -transaction.amount
                            : transaction.amount;

                        if (bookkeepingYearsAmount.has(bookkeepingYear)) {
                          bookkeepingYearsAmount.set(
                            bookkeepingYear,
                            bookkeepingYearsAmount.get(bookkeepingYear)! +
                              amount
                          );
                        } else {
                          bookkeepingYearsAmount.set(bookkeepingYear, amount);
                        }

                        // Also update next bookkeepingYear if transaction was from a previous year
                        const nextBookkeepingYear = getBookkeepingYear(
                          selectedBookkeepingFull,
                          getLocalDate(new Date())
                        );
                        if (
                          nextBookkeepingYear &&
                          nextBookkeepingYear.year > bookkeepingYear?.year
                        ) {
                          if (bookkeepingYearsAmount.has(nextBookkeepingYear)) {
                            bookkeepingYearsAmount.set(
                              nextBookkeepingYear,
                              bookkeepingYearsAmount.get(nextBookkeepingYear)! +
                                amount
                            );
                          } else {
                            bookkeepingYearsAmount.set(
                              nextBookkeepingYear,
                              amount
                            );
                          }
                        }
                      }
                    }
                  }

                  await updateBalances(bookkeepingYearsAmount);
                }

                setDeleting(false);
                setEditing(false);
                setState("loading");
                setTransactions([]);
              }}
              isLoading={deleting}
            />
          )}
          {editing ? (
            <Button
              variant="outline"
              colorScheme="error"
              leftIcon={
                <Flex boxSize="20px" alignItems="center">
                  <IconCancel stroke={error700} />
                </Flex>
              }
              onClick={() => {
                setEditing(false);
              }}
            >
              Abbrechen
            </Button>
          ) : (
            <Tooltip label="Bearbeiten">
              <IconButton
                variant="outline"
                aria-label="Edit"
                icon={<IconEdit stroke={gray700} />}
                onClick={() => setEditing(true)}
                isDisabled={state !== "success" || transactions?.length === 0}
              />
            </Tooltip>
          )}
          {editing && (
            <Button
              variant="outline"
              colorScheme="success"
              leftIcon={
                <Flex boxSize="20px" alignItems="center">
                  <IconEdit stroke={success700} />
                </Flex>
              }
              isLoading={updating}
              onClick={async () => {
                setUpdating(true);

                const transactionUpdatePromises = transactionsUpdated.map(
                  async (transactionUpdated) => {
                    const transactionOriginal = transactions?.find(
                      (t) => t.id === transactionUpdated.id
                    );
                    await API.graphql<GraphQLQuery<UpdateTransactionMutation>>({
                      query: updateTransaction,
                      variables: {
                        input: {
                          id: transactionUpdated.id,
                          ...(transactionOriginal?.paidAt !==
                          transactionUpdated.paidAt
                            ? {
                                paidAt: transactionUpdated.paidAt,
                                // Also update bookkeepingYearId
                                // if paidAt date has changed
                                bookkeepingYearId: getBookkeepingYear(
                                  selectedBookkeepingFull!,
                                  getLocalDate(
                                    new Date(transactionUpdated.paidAt)
                                  )
                                )?.id,
                              }
                            : {}),
                          ...(transactionOriginal?.title !==
                          transactionUpdated.title
                            ? {
                                title: transactionUpdated.title,
                              }
                            : {}),
                          ...(transactionOriginal?.amount !==
                          transactionUpdated.amount
                            ? {
                                amount: transactionUpdated.amount,
                              }
                            : {}),
                          ...(transactionOriginal?.currencyCode !==
                          transactionUpdated.currencyCode
                            ? {
                                currencyCode: transactionUpdated.currencyCode,
                              }
                            : {}),
                          ...(transactionOriginal?.bookingAccountId !==
                          transactionUpdated.bookingAccountId
                            ? {
                                bookingAccountId:
                                  transactionUpdated.bookingAccountId,
                              }
                            : {}),
                          ...(transactionOriginal?.transactionReferenceId !==
                          transactionUpdated.transactionReferenceId
                            ? {
                                transactionReferenceId:
                                  transactionUpdated.transactionReferenceId,
                              }
                            : {}),
                          ...(transactionOriginal?.userId !==
                          transactionUpdated.userId
                            ? {
                                userId: transactionUpdated.userId,
                              }
                            : {}),
                          ...(selectedBookkeepingFull?.accountingSystem ===
                          AccountingSystem.bexio
                            ? {
                                status: TransactionStatus.draft,
                              }
                            : {}),
                          // TODO Update taxes
                        },
                      },
                    });
                  }
                );

                await Promise.all(transactionUpdatePromises);

                // Update balance if ÄT
                if (
                  selectedOrganization?.logo === aerzteTreuhandLogo &&
                  selectedBookkeepingFull
                ) {
                  const bookkeepingYearsAmount = new Map<
                    BookkeepingYear,
                    number
                  >();
                  for (const transactionUpdated of transactionsUpdated) {
                    const transactionOriginal = transactions?.find(
                      (t) => t.id === transactionUpdated.id
                    );
                    const bookkeepingYear = getBookkeepingYear(
                      selectedBookkeepingFull,
                      getLocalDate(new Date(transactionUpdated.paidAt))
                    );
                    if (bookkeepingYear && transactionOriginal) {
                      const amountOld =
                        transactionOriginal.bookingAccount.taxType ===
                        TaxType.earnings
                          ? transactionOriginal.amount
                          : -transactionOriginal.amount;
                      const amountNew =
                        transactionUpdated.bookingAccount.taxType ===
                        TaxType.earnings
                          ? transactionUpdated.amount
                          : -transactionUpdated.amount;
                      const amount = amountNew - amountOld;

                      if (bookkeepingYearsAmount.has(bookkeepingYear)) {
                        bookkeepingYearsAmount.set(
                          bookkeepingYear,
                          bookkeepingYearsAmount.get(bookkeepingYear)! + amount
                        );
                      } else {
                        bookkeepingYearsAmount.set(bookkeepingYear, amount);
                      }

                      // Also update next bookkeepingYear if transaction was from a previous year
                      const nextBookkeepingYear = getBookkeepingYear(
                        selectedBookkeepingFull,
                        getLocalDate(new Date())
                      );
                      if (
                        nextBookkeepingYear &&
                        nextBookkeepingYear.year > bookkeepingYear?.year
                      ) {
                        if (bookkeepingYearsAmount.has(nextBookkeepingYear)) {
                          bookkeepingYearsAmount.set(
                            nextBookkeepingYear,
                            bookkeepingYearsAmount.get(nextBookkeepingYear)! +
                              amount
                          );
                        } else {
                          bookkeepingYearsAmount.set(
                            nextBookkeepingYear,
                            amount
                          );
                        }
                      }

                      await updateBalances(bookkeepingYearsAmount);
                    }
                  }
                }

                setUpdating(false);
                setEditing(false);
                setState("loading");
                setTransactions([]);
              }}
            >
              Speichern
            </Button>
          )}
          {user &&
            selectedOrganization &&
            showSettings(user, selectedOrganization) && (
              <Tooltip
                label={`Einstellungen ${
                  selectedOrganization?.logo === aerzteTreuhandLogo
                    ? "Kassenbuch"
                    : "Buchhaltung"
                }`}
              >
                <IconButton
                  variant="outline"
                  aria-label="Bookkeeping settings"
                  icon={<Image boxSize="20px" src={IconSettings} />}
                  onClick={() => {
                    navigate(routeSettingsBookkeeping);
                  }}
                />
              </Tooltip>
            )}
        </Flex>
      </Flex>
      {typeof balance === "number" && (
        <Card width="full" borderRadius={12}>
          <CardBody color="gray.900" fontWeight="semibold" fontSize={36}>
            <Text color="gray.600" fontWeight="medium" fontSize={14}>
              Saldo
            </Text>
            <Text>{formatAmount(balance, CurrencyCode.chf)}</Text>
          </CardBody>
        </Card>
      )}
      <DataTable
        typeNamePlural={typeNamePlural}
        columns={columns}
        data={transactions}
        state={state}
        error={
          selectedOrganization?.type === OrganizationType.accountant &&
          organizations &&
          organizations.length === 1 ? (
            <NoOrganization />
          ) : undefined
        }
      />
    </VStack>
  );
};

export default Transactions;
