import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Skeleton,
  Text,
  Center,
  Box,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { State } from "../types";
import { randomIntFromInterval } from "../utils";

// needed for table body level scope DnD setup
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import AppStore from "../assets/logos/app-store.svg";
import GooglePlay from "../assets/logos/google-play.svg";
import AppStoreButton from "./app-store-button";
import DraggableRow, { SortModel } from "./draggable-row";
import { useMemo, useState } from "react";

export const typeNamePluralTransactions = "Transaktionen";

export type DataTableProps<T extends SortModel> = {
  typeNamePlural: string;
  data?: T[];
  columns: ColumnDef<T, any>[];
  state: State;
  error?: React.ReactElement;
  move?: (from: number, to: number) => void;
  selectedIndex?: number;
  setSelectedIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export function DataTable<T extends SortModel>({
  typeNamePlural,
  data,
  columns,
  state,
  error,
  move,
  selectedIndex,
  setSelectedIndex,
}: DataTableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data: data || [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id, //required because row indexes will change
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data?.map(({ id }) => id) ?? [],
    [data]
  );

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id && move) {
      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);
      move(oldIndex, newIndex);
    }
  }

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <Table
        style={{
          borderCollapse:
            state === "success" && data && data.length > 0
              ? undefined
              : "separate",
        }}
      >
        <Thead>
          {table.getHeaderGroups().map((headerGroup, indexHeaderGroup) => (
            <Tr key={indexHeaderGroup}>
              {headerGroup.headers.map((header, indexHeader) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={indexHeader}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    textTransform="none"
                    position="sticky"
                    top="0px"
                    bgColor="white"
                    zIndex={1}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {state === "success" && data && data.length > 0 ? (
            <SortableContext
              items={dataIds}
              strategy={verticalListSortingStrategy}
            >
              {table.getRowModel().rows.map((row) => (
                <DraggableRow
                  draggingEnabled={move !== undefined}
                  key={row.id}
                  row={row}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                />
              ))}
            </SortableContext>
          ) : state === "initial" || state === "loading" ? (
            table.getHeaderGroups().map((headerGroup) =>
              Array.from({ length: 10 }, (_, index) => index).map((index) => (
                <Tr key={index}>
                  {headerGroup.headers
                    .slice(0, headerGroup.headers.length - 1)
                    .map((_, indexHeader) => {
                      return (
                        <Td key={indexHeader}>
                          <Skeleton
                            height={5}
                            width={`${randomIntFromInterval(80, 160)}px`}
                          />
                        </Td>
                      );
                    })}
                </Tr>
              ))
            )
          ) : (
            <Tr>
              <Td borderBottom="none">
                <Box left={0} position="absolute" width="full" height="48">
                  <Center height="full">
                    {(state === "error" || (data && data.length === 0)) &&
                      (state === "error" ? (
                        error
                      ) : typeNamePlural === typeNamePluralTransactions ? (
                        <VStack
                          alignItems="start"
                          spacing="12px"
                          px={{ base: "16px", md: 0 }}
                        >
                          <Text
                            fontSize={14}
                            fontWeight={"semibold"}
                            color={"gray.700"}
                          >
                            {`Keine ${typeNamePlural} vorhanden`}
                          </Text>
                          <Text fontSize={14} color="gray.600">
                            Lade jetzt die App herunter und erfasse Deine erste
                            Transaktion.
                          </Text>
                          <HStack>
                            <AppStoreButton
                              icon={AppStore}
                              url="https://apps.apple.com/ch/app/expensly-ch/id6443880888?l=de"
                            />
                            <AppStoreButton
                              icon={GooglePlay}
                              url="https://play.google.com/store/apps/details?id=ai.nutr.expensly"
                            />
                          </HStack>
                        </VStack>
                      ) : (
                        <Text
                          fontSize={14}
                          fontWeight={"semibold"}
                          color={"gray.700"}
                        >
                          {`Keine ${typeNamePlural} vorhanden`}
                        </Text>
                      ))}
                  </Center>
                </Box>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </DndContext>
  );
}
