import {
  CircularProgress,
  CircularProgressLabel,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { sleep } from "../../utils";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import IconCheck from "../../assets/icons/check.svg";

const Confirmation = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress >= 100) {
      const forceAccessTokenRefresh = async () => {
        await Auth.currentAuthenticatedUser({ bypassCache: true });
        navigate("/");
      };
      forceAccessTokenRefresh();
    }
    if (progress < 100) {
      const timer = async () => {
        await sleep(10);
        setProgress(progress + 0.2);
      };
      timer();
    }
  }, [navigate, progress]);

  return (
    <VStack alignItems="start" spacing="12px">
      <Text fontSize={36} fontWeight="medium" color="gray.900">
        Vielen Dank für Dein Abonnement
      </Text>
      <Text fontSize={14} color="gray.600">
        Eine Zahlung an Stripe wird auf Deiner Abrechnung ausgewiesen.
      </Text>
      <CircularProgress
        size="80px"
        thickness="3px"
        color="success.600"
        value={progress}
        m="auto"
      >
        <CircularProgressLabel>
          <Image boxSize="24px" src={IconCheck} m="auto" />
        </CircularProgressLabel>
      </CircularProgress>
    </VStack>
  );
};

export default Confirmation;
