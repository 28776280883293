import {
  Box,
  Circle,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Progress,
  Spacer,
  Spinner,
  Step,
  StepIndicator,
  StepStatus,
  Stepper,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import LogoExpensly from "../../assets/logos/expensly.svg";
import IconEmail from "../../assets/icons/mail-01.svg";
import IconStepComplete from "../../assets/icons/stepComplete.svg";

import { useEffect, useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  AccountingSystem,
  CreateAccountingSystemBillInput,
  CreateAccountingSystemBillMutation,
  CreateAccountingSystemConfigInput,
  CreateAccountingSystemConfigMutation,
  CreateBookingAccountInput,
  CreateBookingAccountMutation,
  CreateBookkeepingInput,
  CreateBookkeepingMutation,
  CreateBookkeepingYearInput,
  CreateBookkeepingYearMutation,
  CreateOrganizationInput,
  CreateOrganizationMutation,
  CreateTaxRateInput,
  CreateTaxRateMutation,
  CreateUserBookkeepingInput,
  CreateUserBookkeepingMutation,
  CreateUserInput,
  CreateUserMutation,
  CreateUserOrganizationInput,
  CreateUserOrganizationMutation,
  GetOrganizationQuery,
  GetUserQuery,
  ListUserOrganizationsQuery,
  ListUserOrganizationsQueryVariables,
  OrganizationType,
  UpdateOrganizationInput,
  UpdateOrganizationMutation,
  UpdateUserInput,
  UpdateUserMutation,
  UserAppRole,
  UserOrganization,
  UserOrganizationRole,
} from "../../API";
import {
  getOrganization,
  getUser,
  listUserOrganizations,
} from "../../graphql/queries";
import { Organization, User } from "../../models";
import { v4 as guid } from "uuid";
import { OrganizationValues } from "./organization";
import { OrganizationMembersValues } from "./organization-members";
import { BookingAccountsValues } from "./booking-accounts";
import {
  routeCreateOrganization,
  routeCreateOrganizationAccountingSystemConfig,
  routeCreateOrganizationAccountingSystemLogin,
  routeCreateOrganizationBookingAccounts,
  routeCreateOrganizationBookkeeping,
  routeCreateOrganizationMembers,
  routeCreateOrganizationOrganization,
  routeCreateOrganizationTaxRates,
  routeOnboardingBilling,
} from "../routes";
import {
  createAccountingSystemBill,
  createAccountingSystemConfig,
  createBookingAccount,
  createBookkeeping,
  createBookkeepingYear,
  createOrganization,
  createTaxRate,
  createUser,
  createUserBookkeeping,
  createUserOrganization,
  updateOrganization,
  updateUser,
} from "../../graphql/mutations";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  aerzteTreuhandLogo,
  clearCreateOrganizationSessionStorage,
  createOrganizationSteps,
  dateToApiString,
  queryList,
} from "../../utils";
import { State } from "../../types";
import { BookkeepingValues } from "./bookkeeping";
import { TaxRatesValues } from "./tax-rates";
import { AccountingSystemLoginValues } from "./accounting-system-login";
import SignOutLinkButton from "../../components/sign-out-link-button";
import { AccountingSystemConfigValues } from "./accounting-system-config";
import useSessionStorage from "../../hooks/use-session-storage";
import Tooltip from "../../components/tooltip";
import useLocalStorage from "../../hooks/use-local-storage";
import {
  AccountingSystemValues,
  keyAccountingSystemValues,
} from "../onboarding/auth";
import useSetDatadogAndGleapUser from "../../hooks/use-set-datadog-and-gleap-user";
import Gleap from "gleap";

type ContextTypeOrganization = {
  user: User;
  organizationValues: OrganizationValues;
  setOrganizationValues: React.Dispatch<
    React.SetStateAction<OrganizationValues | undefined>
  >;
};

type ContextTypeOrganizationMembers = {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  user: User;
  organizationValues: OrganizationValues;
  organizationMembersValues: OrganizationMembersValues | undefined;
  setOrganizationMembersValues: React.Dispatch<
    React.SetStateAction<OrganizationMembersValues | undefined>
  >;
  setBookkeepingValues: React.Dispatch<
    React.SetStateAction<BookkeepingValues | undefined>
  >;
};

type ContextTypeBookkeeping = {
  user: User;
  mainOrganization: Organization;
  organizationValues: OrganizationValues;
  bookkeepingValues: BookkeepingValues | undefined;
  setBookkeepingValues: React.Dispatch<
    React.SetStateAction<BookkeepingValues | undefined>
  >;
};

type ContextTypeAccountingSystemLogin = {
  organizationValues: OrganizationValues;
  accountingSystemLoginValues: AccountingSystemLoginValues | undefined;
  setAccountingSystemLoginValues: React.Dispatch<
    React.SetStateAction<AccountingSystemLoginValues | undefined>
  >;
};

type ContextTypeAccountingSystemConfig = {
  user: User;
  organizationValues: OrganizationValues;
  bookkeepingValues: BookkeepingValues | undefined;
  accountingSystemLoginValues: AccountingSystemLoginValues | undefined;
  accountingSystemConfigValues: AccountingSystemConfigValues | undefined;
  setAccountingSystemConfigValues: React.Dispatch<
    React.SetStateAction<AccountingSystemConfigValues | undefined>
  >;
};

type ContextTypeBookingAccounts = {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  user: User;
  mainOrganization: Organization;
  organizationValues: OrganizationValues;
  accountingSystemLoginValues: AccountingSystemLoginValues | undefined;
  bookkeepingValues: BookkeepingValues | undefined;
  bookingAccountsValues: BookingAccountsValues | undefined;
  setBookingAccountsValues: React.Dispatch<
    React.SetStateAction<BookingAccountsValues | undefined>
  >;
};

type ContextTypeTaxRates = {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  user: User;
  organizationValues: OrganizationValues;
  bookkeepingValues: BookkeepingValues | undefined;
  accountingSystemLoginValues: AccountingSystemLoginValues | undefined;
  taxRatesValues: TaxRatesValues | undefined;
  setTaxRatesValues: React.Dispatch<
    React.SetStateAction<TaxRatesValues | undefined>
  >;
};

const email = "help@expensly.ch";

export type CreateOrganizationStep = (typeof createOrganizationSteps)[number];

const CreateOrganizationLayout = () => {
  const { user: authUser } = useAuthenticator((context) => [context.user]);

  const location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();

  const step = location.pathname.split("/").pop() as CreateOrganizationStep;
  const stepIndex = createOrganizationSteps.findIndex((s) => s === step);

  const toast = useToast();

  const [user, setUser] = useState<User>();

  useSetDatadogAndGleapUser(user, "CreateOrganization");
  Gleap.showFeedbackButton(true);

  const [mainOrganization, setMainOrganization] = useState<Organization>();
  const [accountants, setAccountants] = useState<UserOrganization[]>();

  const keyOrganizationValues: CreateOrganizationStep = "organization";
  const [organizationValues, setOrganizationValues] =
    useSessionStorage<OrganizationValues>(keyOrganizationValues);

  const keyOrganizationMembersValues: CreateOrganizationStep =
    "organization-members";
  const [organizationMembersValues, setOrganizationMembersValues] =
    useSessionStorage<OrganizationMembersValues>(keyOrganizationMembersValues);

  const keyBookkeepingValues: CreateOrganizationStep = "bookkeeping";
  const [bookkeepingValues, setBookkeepingValues] =
    useSessionStorage<BookkeepingValues>(keyBookkeepingValues);

  const [accountingSystemValues] = useLocalStorage<AccountingSystemValues>(
    keyAccountingSystemValues
  );

  const keyAccountingSystemLoginValues: CreateOrganizationStep =
    "accounting-system-login";
  const [accountingSystemLoginValues, setAccountingSystemLoginValues] =
    useSessionStorage<AccountingSystemLoginValues>(
      keyAccountingSystemLoginValues
    );

  const keyAccountingSystemConfigValues: CreateOrganizationStep =
    "accounting-system-config";
  const [accountingSystemConfigValues, setAccountingSystemConfigValues] =
    useSessionStorage<AccountingSystemConfigValues>(
      keyAccountingSystemConfigValues
    );

  const keyBookingAccountsValues: CreateOrganizationStep = "booking-accounts";
  const [bookingAccountsValues, setBookingAccountsValues] =
    useSessionStorage<BookingAccountsValues>(keyBookingAccountsValues);

  const keyTaxRatesValues: CreateOrganizationStep = "tax-rates";
  const [taxRatesValues, setTaxRatesValues] =
    useSessionStorage<TaxRatesValues>(keyTaxRatesValues);

  const [state, setState] = useState<State>("initial");

  useEffect(() => {
    if (!user && authUser) {
      const fetchUser = async () => {
        const user = await API.graphql<GraphQLQuery<GetUserQuery>>(
          graphqlOperation(getUser, { id: authUser.attributes?.sub })
        );
        if (user.data?.getUser) {
          setUser(user.data?.getUser as unknown as User);
        }
      };
      fetchUser();
    }
  }, [authUser, user]);

  useEffect(() => {
    if (authUser && !mainOrganization) {
      const fetchOrganizationMain = async () => {
        const userOrganizations = await queryList<
          UserOrganization,
          ListUserOrganizationsQueryVariables,
          ListUserOrganizationsQuery
        >(listUserOrganizations, {
          filter: {
            userId: { eq: authUser?.attributes?.sub },
            role: { ne: UserOrganizationRole.accountant },
          },
        });

        userOrganizations.sort((a, b) =>
          a.createdAt.localeCompare(b.createdAt)
        );

        if (userOrganizations.length > 0) {
          const organization = await API.graphql<
            GraphQLQuery<GetOrganizationQuery>
          >(
            graphqlOperation(getOrganization, {
              id: userOrganizations[0].organizationId,
            })
          );

          if (organization.data?.getOrganization) {
            setMainOrganization(
              organization.data.getOrganization as Organization
            );
          }
        }
      };
      fetchOrganizationMain();
    }
  }, [mainOrganization, authUser]);

  useEffect(() => {
    if (user && mainOrganization && !organizationValues) {
      if (user.role === UserAppRole.accountant) {
        setOrganizationValues({
          id: guid(),
          name: "",
          type: OrganizationType.company,
          submitted: false,
        });
      } else {
        setOrganizationValues({
          id: mainOrganization.id,
          name: mainOrganization.name,
          type: mainOrganization.type,
          submitted: false,
        });
      }
    }
  }, [mainOrganization, organizationValues, setOrganizationValues, user]);

  useEffect(() => {
    if (
      user &&
      mainOrganization &&
      !accountants &&
      user.role === UserAppRole.accountant
    ) {
      const fetchAccountants = async () => {
        const accountants = await queryList<
          UserOrganization,
          ListUserOrganizationsQueryVariables,
          ListUserOrganizationsQuery
        >(listUserOrganizations, {
          filter: { organizationId: { eq: mainOrganization.id } },
        });

        if (accountants.length > 0) {
          setAccountants(accountants);
        }
      };
      fetchAccountants();
    }
  }, [accountants, mainOrganization, user]);

  useEffect(() => {
    // If setup of main organization with type accountant or
    // bookingAccounts have been set up and hasNetTaxRate
    // or taxRates have been set up
    if (state === "loading" && mainOrganization) {
      // Go back through the process if anything is missing
      if (!!!organizationValues) {
        setState("initial");
        navigate(routeCreateOrganizationOrganization);
        return;
      }

      if (!!!organizationMembersValues) {
        setState("initial");
        navigate(routeCreateOrganizationMembers);
        return;
      }

      if (organizationValues.type !== OrganizationType.accountant) {
        if (!!!bookkeepingValues) {
          setState("initial");
          navigate(routeCreateOrganizationBookkeeping);
          return;
        }

        if (
          bookkeepingValues.accountingSystem === AccountingSystem.bexio &&
          !!!accountingSystemLoginValues &&
          !user?.isSSO
        ) {
          setState("initial");
          navigate(routeCreateOrganizationAccountingSystemLogin);
          return;
        }

        if (
          bookkeepingValues.accountingSystem === AccountingSystem.bexio &&
          !!!accountingSystemConfigValues &&
          !user?.isSSO
        ) {
          setState("initial");
          navigate(routeCreateOrganizationAccountingSystemConfig);
          return;
        }

        if (!!!bookingAccountsValues) {
          setState("initial");
          navigate(routeCreateOrganizationBookingAccounts);
          return;
        }

        if (!bookkeepingValues.hasNetTaxRate && !!!taxRatesValues) {
          setState("initial");
          navigate(routeCreateOrganizationTaxRates);
          return;
        }
      }

      const persistData = async () => {
        // Persist data and return to last page

        const organizationId = organizationValues.id;

        const updateRoles = [
          UserOrganizationRole.accountant,
          UserOrganizationRole.admin,
        ].map((r) => `${organizationId}-${r}`);

        const readRolesRestricted = [
          UserOrganizationRole.accountant,
          UserOrganizationRole.admin,
          UserOrganizationRole.head,
        ].map((r) => `${organizationId}-${r}`);

        const readRolesAll = Object.values(UserOrganizationRole).map(
          (r) => `${organizationId}-${r}`
        );

        let accountingSystemContactPartnerId: number | undefined;

        // Fetch contact partner id first before creating organization
        if (bookkeepingValues?.accountingSystem === AccountingSystem.bexio) {
          try {
            const response = await API.post(
              "expenslyREST",
              "/auth/bexio/proxy",
              {
                headers: {
                  Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
                },
                body: {
                  organizationId: organizationValues.id,
                  path: "/3.0/users/me",
                  method: "GET",
                },
              }
            );

            if (response) {
              accountingSystemContactPartnerId = response.id;
            }
          } catch (error) {
            toast({
              title: "Fehler beim Abrufen der bexio User ID",
              status: "error",
            });
            setState("error");
            return;
          }
        }

        // Create or Update Organization
        // Create new Organization only if it is an accountant customer
        if (user?.role === UserAppRole.accountant) {
          const organizationInput: CreateOrganizationInput = {
            id: organizationId,
            name: organizationValues!.name,
            type: OrganizationType.company,
            logo: mainOrganization.logo,
            authGroupsRead: readRolesAll,
            authGroupsUpdate: updateRoles,
          };

          try {
            await API.graphql<GraphQLQuery<CreateOrganizationMutation>>(
              graphqlOperation(createOrganization, { input: organizationInput })
            );
          } catch (error) {
            toast({
              title: "Fehler beim Erstellen der Organisation",
              status: "error",
            });
            setState("error");
            return;
          }
        } else if (
          organizationValues.name !== mainOrganization.name ||
          organizationValues.type !== mainOrganization.type
        ) {
          const organizationInput: UpdateOrganizationInput = {
            id: mainOrganization!.id,
            name: organizationValues.name,
            type: organizationValues.type,
          };

          try {
            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
              graphqlOperation(updateOrganization, { input: organizationInput })
            );
          } catch (error) {
            toast({
              title: "Fehler beim Aktualisieren der Organisation",
              status: "error",
            });
            setState("error");
            return;
          }
        }

        const bookkeepingId = guid();

        const readRolesBookkeepingAll = [
          ...[UserOrganizationRole.accountant, UserOrganizationRole.admin].map(
            (r) => `${organizationId}-${r}`
          ),
          ...[UserOrganizationRole.head, UserOrganizationRole.employee].map(
            (r) => `${organizationId}-${bookkeepingId}-${r}`
          ),
        ];

        // Create Bookkeeping
        if (bookkeepingValues) {
          const bookkeepingInput: CreateBookkeepingInput = {
            id: bookkeepingId,
            name: bookkeepingValues.name,
            organizationId: organizationId,
            accountingSystem: bookkeepingValues.accountingSystem,
            color: bookkeepingValues.color,
            authGroupsRead: readRolesBookkeepingAll,
            authGroupsUpdate: updateRoles,
          };

          try {
            await API.graphql<GraphQLQuery<CreateBookkeepingMutation>>(
              graphqlOperation(createBookkeeping, {
                input: bookkeepingInput,
              })
            );
          } catch (error) {
            toast({
              title: `Fehler beim Erstellen ${
                mainOrganization.logo === aerzteTreuhandLogo
                  ? "des Kassenbuchs"
                  : "der Buchhaltung"
              }`,
              status: "error",
            });
            setState("error");
            return;
          }

          const endYear = bookkeepingValues.year;
          const years = [endYear - 1, endYear];

          try {
            const bookkeepingYearPromises = years.map(async (year) => {
              const balanceStart =
                mainOrganization.logo === aerzteTreuhandLogo
                  ? year === endYear
                    ? bookkeepingValues.balance
                    : 0
                  : undefined;

              const bookkeepingYearInput: CreateBookkeepingYearInput = {
                bookkeepingId: bookkeepingId,
                hasNetTaxRate: bookkeepingValues.hasNetTaxRate,
                year: year,
                ...(typeof balanceStart === "number"
                  ? { balanceStart, balance: balanceStart }
                  : {}),
                authGroups: readRolesBookkeepingAll,
              };

              await API.graphql<GraphQLQuery<CreateBookkeepingYearMutation>>(
                graphqlOperation(createBookkeepingYear, {
                  input: bookkeepingYearInput,
                })
              );
            });

            await Promise.all(bookkeepingYearPromises);
          } catch (error) {
            toast({
              title: "Fehler beim Erstellen eines Buchhaltungsjahres",
              status: "error",
            });
            setState("error");
            return;
          }

          // Create BookingAccounts
          if (bookingAccountsValues) {
            try {
              const bookingAccountPromises =
                bookingAccountsValues.bookingAccounts.map(
                  async (bookingAccount) => {
                    const bookingAccountInput: CreateBookingAccountInput = {
                      name: bookingAccount.name,
                      accountNumber: bookingAccount.accountNumber,
                      sortOrder:
                        bookingAccountsValues.bookingAccounts.findIndex(
                          (b) => b.id === bookingAccount.id
                        ) + 1,
                      taxType: bookingAccount.taxType,
                      transactionCategoryId:
                        bookingAccount.transactionCategoryId,
                      bookkeepingId: bookkeepingId,
                      accountingSystemId: bookingAccount.accountingSystemId,
                      authGroupsRead: [
                        ...updateRoles,
                        ...readRolesBookkeepingAll.filter(
                          (r) =>
                            r.includes(UserOrganizationRole.head) ||
                            r.includes(UserOrganizationRole.employee)
                        ),
                      ],
                      authGroupsUpdate: updateRoles,
                    };

                    await API.graphql<
                      GraphQLQuery<CreateBookingAccountMutation>
                    >(
                      graphqlOperation(createBookingAccount, {
                        input: bookingAccountInput,
                      })
                    );
                  }
                );

              await Promise.all(bookingAccountPromises);
            } catch (error) {
              toast({
                title: "Fehler beim Erstellen eines Buchhaltungskontos",
                status: "error",
              });
              setState("error");
              return;
            }
          }
        }

        // Create TaxRates
        if (taxRatesValues) {
          try {
            const taxRatePromises = taxRatesValues.taxRates.map(
              async (taxRate) => {
                const taxRateInput: CreateTaxRateInput = {
                  code: taxRate.code,
                  rate: taxRate.rate,
                  dateFrom: dateToApiString(taxRate.dateFrom),
                  dateTo: taxRate.dateTo
                    ? dateToApiString(taxRate.dateTo)
                    : null,
                  taxType: taxRate.taxType,
                  sortOrder:
                    taxRatesValues.taxRates.findIndex(
                      (t) => t.id === taxRate.id
                    ) + 1,
                  bookkeepingId: bookkeepingId,
                  accountingSystemId: taxRate.accountingSystemId,
                  authGroupsRead: [
                    ...updateRoles,
                    ...readRolesBookkeepingAll.filter(
                      (r) =>
                        r.includes(UserOrganizationRole.head) ||
                        r.includes(UserOrganizationRole.employee)
                    ),
                  ],
                  authGroupsUpdate: updateRoles,
                };

                await API.graphql<GraphQLQuery<CreateTaxRateMutation>>(
                  graphqlOperation(createTaxRate, {
                    input: taxRateInput,
                  })
                );
              }
            );

            await Promise.all(taxRatePromises);
          } catch (error) {
            toast({
              title: "Fehler beim Erstellen eines Steuersatzes",
              status: "error",
            });
            setState("error");
            return;
          }
        }

        // Create AccountingSystemConfig
        if (accountingSystemConfigValues) {
          const accountingSystemConfigInput: CreateAccountingSystemConfigInput =
            {
              baseCurrency: accountingSystemConfigValues.baseCurrency,
              billingPeriod: accountingSystemConfigValues.billingPeriod,
              senderBankAccountId:
                accountingSystemConfigValues.senderBankAccountId!,
              reimbursement: accountingSystemConfigValues.reimbursement,
              bookkeepingId: bookkeepingId,
              authGroupsRead: readRolesBookkeepingAll,
              authGroupsUpdate: updateRoles,
            };

          try {
            await API.graphql<
              GraphQLQuery<CreateAccountingSystemConfigMutation>
            >(
              graphqlOperation(createAccountingSystemConfig, {
                input: accountingSystemConfigInput,
              })
            );
          } catch (error) {
            toast({
              title: "Fehler beim Erstellen der bexio Einstellungen",
              status: "error",
            });
            setState("error");
            return;
          }
        }

        // Add Accountants to Organization and Bookkeeping
        if (user?.role === UserAppRole.accountant) {
          try {
            const userOrganizationPromises = accountants!.map(
              async (accountant) => {
                const userOrganizationInput: CreateUserOrganizationInput = {
                  userId: accountant.userId,
                  organizationId: organizationId,
                  role: UserOrganizationRole.accountant,
                  authGroupsRead: readRolesAll,
                  authGroupsUpdate: updateRoles,
                };

                await API.graphql<GraphQLQuery<CreateUserOrganizationMutation>>(
                  graphqlOperation(createUserOrganization, {
                    input: userOrganizationInput,
                  })
                );
              }
            );

            await Promise.all(userOrganizationPromises);
          } catch (error) {
            toast({
              title:
                "Fehler bei der Zuweisung eines Treuhänders zur Organisation",
              status: "error",
            });
            setState("error");
            return;
          }

          try {
            const userBookkeepingPromises = accountants!.map(
              async (accountant) => {
                const userBookkeepingInput: CreateUserBookkeepingInput = {
                  userId: accountant.userId,
                  bookkeepingId: bookkeepingId,
                  authGroupsRead: [
                    ...updateRoles,
                    ...readRolesBookkeepingAll.filter((r) =>
                      r.includes(UserOrganizationRole.head)
                    ),
                  ],
                  authGroupsUpdate: updateRoles,
                };

                await API.graphql<GraphQLQuery<CreateUserBookkeepingMutation>>(
                  graphqlOperation(createUserBookkeeping, {
                    input: userBookkeepingInput,
                  })
                );
              }
            );

            await Promise.all(userBookkeepingPromises);
          } catch (error) {
            toast({
              title: `Fehler bei der Zuweisung eines Treuhänders ${
                mainOrganization.logo === aerzteTreuhandLogo
                  ? "zum Kassenbuch"
                  : "zur Buchhaltung"
              }`,
              status: "error",
            });
            setState("error");
            return;
          }
        }

        const headers = {
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
          "Content-Type": "application/json",
        };

        // Create Users
        const members = [
          organizationMembersValues.organizationAdmin,
          ...organizationMembersValues.organizationMembers,
        ];

        // Update user if admin is main user and OrganizationType has changed to accountant
        const usersUpdateToAccountant = members.filter(
          (member) =>
            member.email === user?.email &&
            organizationValues.type === OrganizationType.accountant
        );

        // Else create only UserBookkeeping for admin main user
        const usersAddUserBookkeepingToOrganizationAdmin = members.filter(
          (member) =>
            member.email === user?.email &&
            organizationValues.type !== OrganizationType.accountant
        );

        // Else create user if admin is not main user
        const usersCreate = members.filter(
          (member) => member.email !== user?.email
        );

        try {
          const userPromises = usersUpdateToAccountant.map(async (member) => {
            const userInput: UpdateUserInput = {
              id: user!.id,
              role: UserAppRole.accountant,
            };

            await API.graphql<GraphQLQuery<UpdateUserMutation>>(
              graphqlOperation(updateUser, {
                input: userInput,
              })
            );
          });

          await Promise.all(userPromises);
        } catch (error) {
          toast({
            title: "Fehler beim Aktualisieren des Benutzers",
            status: "error",
          });
          setState("error");
          return;
        }

        try {
          const userPromises = usersAddUserBookkeepingToOrganizationAdmin.map(
            async (member) => {
              const userBookkeepingId = guid();
              const userId = user!.id;

              // Create UserBookkeeping
              const userBookkeepingInput: CreateUserBookkeepingInput = {
                id: userBookkeepingId,
                userId: userId,
                bookkeepingId: bookkeepingId,
                authGroupsRead: [
                  ...updateRoles,
                  ...readRolesBookkeepingAll.filter((r) =>
                    r.includes(UserOrganizationRole.head)
                  ),
                ],
                authGroupsUpdate: updateRoles,
              };

              await API.graphql<GraphQLQuery<CreateUserBookkeepingMutation>>(
                graphqlOperation(createUserBookkeeping, {
                  input: userBookkeepingInput,
                })
              );

              // Create AccountingSystemBill
              if (
                accountingSystemConfigValues &&
                accountingSystemContactPartnerId
              ) {
                const accountingSystemBillInput: CreateAccountingSystemBillInput =
                  {
                    contactPartnerId: accountingSystemContactPartnerId,
                    senderBankAccountId:
                      accountingSystemConfigValues.senderBankAccountId!,
                    billingPeriod: accountingSystemConfigValues.billingPeriod,
                    baseCurrency: accountingSystemConfigValues.baseCurrency,
                    userBookkeepingId: userBookkeepingId,
                    authUserId: userId,
                    authGroups: updateRoles,
                  };

                await API.graphql<
                  GraphQLQuery<CreateAccountingSystemBillMutation>
                >(
                  graphqlOperation(createAccountingSystemBill, {
                    input: accountingSystemBillInput,
                  })
                );
              }
            }
          );

          await Promise.all(userPromises);
        } catch (error) {
          toast({
            title: `Fehler bei der Zuweisung eines Mitglieds ${
              mainOrganization.logo === aerzteTreuhandLogo
                ? "zum Kassenbuch"
                : "zur Buchhaltung"
            }`,
            status: "error",
          });
          setState("error");
          return;
        }

        try {
          const userPromises = usersCreate.map(async (member) => {
            // Create Cognito User
            const response = await API.post("AdminQueries", "/createUser", {
              headers: headers,
              body: {
                email: member.email,
              },
            });

            const userId = response.User.Username;

            // Create DB User
            const userInput: CreateUserInput = {
              id: userId,
              firstName: "",
              lastName: "",
              email: member.email,
              role:
                organizationValues.type === OrganizationType.accountant
                  ? UserAppRole.accountant
                  : member.appRole,
              authGroupsRead: readRolesAll,
              authGroupsUpdate: updateRoles,
            };

            await API.graphql<GraphQLQuery<CreateUserMutation>>(
              graphqlOperation(createUser, {
                input: userInput,
              })
            );

            // Create UserOrganization
            const userOrganizationInput: CreateUserOrganizationInput = {
              userId: userId,
              organizationId: organizationId,
              role: member.organizationRole,
              authGroupsRead: readRolesAll,
              authGroupsUpdate: updateRoles,
            };

            await API.graphql<GraphQLQuery<CreateUserOrganizationMutation>>(
              graphqlOperation(createUserOrganization, {
                input: userOrganizationInput,
              })
            );

            // Create UserBookkeeping
            const userBookkeepingId = guid();
            const userBookkeepingInput: CreateUserBookkeepingInput = {
              id: userBookkeepingId,
              userId: userId,
              bookkeepingId: bookkeepingId,
              authGroupsRead: [
                ...updateRoles,
                ...readRolesBookkeepingAll.filter((r) =>
                  r.includes(UserOrganizationRole.head)
                ),
              ],
              authGroupsUpdate: updateRoles,
            };

            await API.graphql<GraphQLQuery<CreateUserBookkeepingMutation>>(
              graphqlOperation(createUserBookkeeping, {
                input: userBookkeepingInput,
              })
            );

            // Create AccountingSystemBill
            if (
              accountingSystemConfigValues &&
              accountingSystemContactPartnerId
            ) {
              const accountingSystemBillInput: CreateAccountingSystemBillInput =
                {
                  contactPartnerId: accountingSystemContactPartnerId,
                  senderBankAccountId:
                    accountingSystemConfigValues.senderBankAccountId!,
                  billingPeriod: accountingSystemConfigValues.billingPeriod,
                  baseCurrency: accountingSystemConfigValues.baseCurrency,
                  userBookkeepingId: userBookkeepingId,
                  authUserId: userId,
                  authGroups: updateRoles,
                };

              await API.graphql<
                GraphQLQuery<CreateAccountingSystemBillMutation>
              >(
                graphqlOperation(createAccountingSystemBill, {
                  input: accountingSystemBillInput,
                })
              );
            }
          });

          await Promise.all(userPromises);
        } catch (error) {
          toast({
            title: "Fehler beim Erstellen eines Mitglieds",
            status: "error",
          });
          setState("error");
          return;
        }

        // Clear session storage
        clearCreateOrganizationSessionStorage();

        await Auth.currentAuthenticatedUser({ bypassCache: true });

        // Navigate to billing if organization type is company
        if (organizationValues.type === OrganizationType.company) {
          navigate(routeOnboardingBilling);
        } else {
          navigate(from, { replace: true });
        }
      };

      persistData();
    }
  }, [
    accountants,
    accountingSystemConfigValues,
    accountingSystemLoginValues,
    bookingAccountsValues,
    bookkeepingValues,
    from,
    mainOrganization,
    navigate,
    organizationMembersValues,
    organizationValues,
    state,
    taxRatesValues,
    toast,
    user,
  ]);

  // Update User if isSSO is not set but provider bexio is detected
  // Make sure to execute only once
  useEffect(() => {
    if (accountingSystemValues?.provider === "bexio" && user && !user.isSSO) {
      const updateUserSSO = async () => {
        const userInput: UpdateUserInput = {
          id: user.id,
          isSSO: true,
        };

        try {
          await API.graphql<GraphQLQuery<UpdateUserMutation>>(
            graphqlOperation(updateUser, {
              input: userInput,
            })
          );

          // remove local storage
          localStorage.removeItem(keyAccountingSystemValues);

          // Refresh user
          setUser(undefined);
        } catch (error) {
          toast({
            title: "Fehler beim Aktualisieren des Benutzers",
            status: "error",
          });
          setState("error");
          return;
        }
      };
      updateUserSSO();
    }
  }, [accountingSystemValues?.provider, toast, user]);

  return (
    <Flex
      height="full"
      direction="column"
      justify="center"
      p={{ base: "16px", md: "32px" }}
    >
      <Flex justify="space-between">
        <Tooltip label="Zurück zur Startseite">
          <IconButton
            variant="unstyled"
            icon={<Image src={LogoExpensly} width={{ base: 100, md: 142 }} />}
            aria-label={"Expensly Logo"}
            onClick={() => navigate("/")}
          />
        </Tooltip>
        <SignOutLinkButton />
      </Flex>
      <VStack height="full" spacing={{ base: "16px", md: "48px" }}>
        <Box width="full" maxWidth="720px" px="24px" pt="24px">
          <Stepper colorScheme="transparent" index={stepIndex} gap={0}>
            {createOrganizationSteps.map((s, index) => (
              <Step
                key={index}
                onClick={() => navigate(`${routeCreateOrganization}/${s}`)}
              >
                <StepIndicator bg="white" border="none" cursor="pointer">
                  <StepStatus
                    active={
                      <Circle size="32px" bg="primary.50">
                        <Circle size="8px" bg="primary.600" />
                      </Circle>
                    }
                    complete={
                      <Circle size="32px" bg="white">
                        <Circle size="24px" bg="primary.50">
                          {(s === "organization" && organizationValues) ||
                          (s === "organization-members" &&
                            organizationMembersValues) ||
                          (s === "bookkeeping" && bookkeepingValues) ||
                          (s === "accounting-system-login" &&
                            accountingSystemLoginValues) ||
                          (s === "accounting-system-config" &&
                            accountingSystemConfigValues) ||
                          (s === "booking-accounts" && bookingAccountsValues) ||
                          (s === "tax-rates" && taxRatesValues) ? (
                            <Image src={IconStepComplete} />
                          ) : (
                            <Circle size="8px" bg="primary.200" />
                          )}
                        </Circle>
                      </Circle>
                    }
                    incomplete={
                      <Circle size="32px" bg="white">
                        {(s === "organization" && organizationValues) ||
                        (s === "organization-members" &&
                          organizationMembersValues) ||
                        (s === "bookkeeping" && bookkeepingValues) ||
                        (s === "accounting-system-login" &&
                          accountingSystemLoginValues) ||
                        (s === "accounting-system-config" &&
                          accountingSystemConfigValues) ||
                        (s === "booking-accounts" && bookingAccountsValues) ||
                        (s === "tax-rates" && taxRatesValues) ? (
                          <Circle size="24px" bg="primary.50">
                            <Image src={IconStepComplete} />
                          </Circle>
                        ) : (
                          <Circle size="24px" bg="gray.50">
                            <Circle size="8px" bg="gray.200" />
                          </Circle>
                        )}
                      </Circle>
                    }
                  />
                </StepIndicator>
              </Step>
            ))}
          </Stepper>
          <Progress
            value={(stepIndex / (createOrganizationSteps.length - 1)) * 100}
            colorScheme="primary"
            position="relative"
            height="2px"
            width="full"
            top="-16px"
            zIndex={-1}
          />
        </Box>
        <VStack
          height="full"
          minHeight={{ base: "auto", md: "628px" }}
          width="full"
          flexGrow={1}
          overflow="auto"
        >
          {user && organizationValues && mainOrganization ? (
            <Outlet
              context={
                step === "organization"
                  ? ({
                      user,
                      organizationValues,
                      setOrganizationValues,
                    } satisfies ContextTypeOrganization)
                  : step === "organization-members"
                  ? ({
                      state,
                      setState,
                      user,
                      organizationValues,
                      organizationMembersValues,
                      setOrganizationMembersValues,
                      setBookkeepingValues,
                    } satisfies ContextTypeOrganizationMembers)
                  : step === "bookkeeping"
                  ? ({
                      user,
                      mainOrganization,
                      organizationValues,
                      bookkeepingValues,
                      setBookkeepingValues,
                    } satisfies ContextTypeBookkeeping)
                  : step === "accounting-system-login"
                  ? ({
                      organizationValues,
                      accountingSystemLoginValues,
                      setAccountingSystemLoginValues:
                        setAccountingSystemLoginValues,
                    } satisfies ContextTypeAccountingSystemLogin)
                  : step === "accounting-system-config"
                  ? ({
                      user,
                      organizationValues,
                      bookkeepingValues,
                      accountingSystemLoginValues,
                      accountingSystemConfigValues,
                      setAccountingSystemConfigValues,
                    } satisfies ContextTypeAccountingSystemConfig)
                  : step === "booking-accounts"
                  ? ({
                      state,
                      setState,
                      user,
                      mainOrganization,
                      organizationValues,
                      accountingSystemLoginValues,
                      bookkeepingValues,
                      bookingAccountsValues,
                      setBookingAccountsValues,
                    } satisfies ContextTypeBookingAccounts)
                  : ({
                      state,
                      setState,
                      user,
                      organizationValues,
                      bookkeepingValues,
                      accountingSystemLoginValues,
                      taxRatesValues,
                      setTaxRatesValues,
                    } satisfies ContextTypeTaxRates)
              }
            />
          ) : (
            <Spinner />
          )}
        </VStack>
      </VStack>
      <Flex>
        <Text color="gray.600" fontSize={14}>
          © Expensly {new Date().getFullYear()}
        </Text>
        <Spacer />
        <Link href={`mailto:${email}`} isExternal>
          <HStack>
            <Image src={IconEmail} alt="Email Icon" width="16px" />
            <Text color="gray.600" fontSize={14}>
              {email}
            </Text>
          </HStack>
        </Link>
      </Flex>
    </Flex>
  );
};

export default CreateOrganizationLayout;

export const useSetOrganizationValues = () => {
  return useOutletContext<ContextTypeOrganization>();
};

export const useSetOrganizationMembersValues = () => {
  return useOutletContext<ContextTypeOrganizationMembers>();
};

export const useSetBookkeepingValues = () => {
  return useOutletContext<ContextTypeBookkeeping>();
};

export const useSetAccountingSystemLoginValues = () => {
  return useOutletContext<ContextTypeAccountingSystemLogin>();
};

export const useSetAccountingSystemConfigValues = () => {
  return useOutletContext<ContextTypeAccountingSystemConfig>();
};

export const useSetBookingAccountsValues = () => {
  return useOutletContext<ContextTypeBookingAccounts>();
};

export const useSetTaxRatesValues = () => {
  return useOutletContext<ContextTypeTaxRates>();
};
