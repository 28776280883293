import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useSetTaxRatesValues } from "./create-organization-layout";
import { FieldArray, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { AccountingSystem, TaxType } from "../../API";
import { v4 as guid } from "uuid";
import taxRatesDefault from "../../assets/data/taxRatesDefault.json";
import taxRatesDefaultTopal from "../../assets/data/taxRatesDefaultTopal.json";
import taxRatesDefaultBanana from "../../assets/data/taxRatesDefaultBanana.json";

import TaxRatesTable from "./tax-rates-table";
import { createOrganizationText, maxWidthTables } from "../../utils";
import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { SortModel } from "../../components/draggable-row";

const typeNameSingular = "Steuersatz";
const typeNamePlural = "Steuersätze";

type AccountingTaxType =
  | "net_tax"
  | "non_consideration_sales_tax"
  | "pre_customs_tax_investment"
  | "pre_customs_tax_material"
  | "pre_regards_tax_investment"
  | "pre_regards_tax_material"
  | "pre_tax_investment"
  | "pre_tax_material"
  | "sales_tax"
  | "not_taxable_turnover"
  | "opted_net_tax"
  | "opted_sales_tax";

export type AccountingSystemTax = {
  id: number;
  code: string;
  display_name: string;
  value: number;
  start_month: number;
  start_year: number;
  end_month: number;
  end_year: number;
  is_active: boolean;
  type: AccountingTaxType;
};

export type TaxRateValue = SortModel & {
  code: string;
  dateFrom: Date;
  dateTo?: Date | null;
  rate: number;
  taxType: TaxType;
  accountingSystemId?: string;
};

export type TaxRatesValues = {
  taxRates: TaxRateValue[];
};

const TaxRates = () => {
  const navigate = useNavigate();
  const [accountingSystemTaxes, setAccountingSystemTaxes] =
    useState<AccountingSystemTax[]>();

  const {
    state,
    setState,
    user,
    organizationValues,
    bookkeepingValues,
    accountingSystemLoginValues,
    taxRatesValues,
    setTaxRatesValues,
  } = useSetTaxRatesValues();

  const initialValues: TaxRatesValues = taxRatesValues ?? {
    taxRates: (bookkeepingValues?.accountingSystem === AccountingSystem.topal
      ? taxRatesDefaultTopal
      : bookkeepingValues?.accountingSystem === AccountingSystem.banana
      ? taxRatesDefaultBanana
      : taxRatesDefault
    ).map((taxRateDefault) => ({
      ...taxRateDefault,
      id: guid(),
      dateFrom: new Date(taxRateDefault.dateFrom),
      dateTo: taxRateDefault.dateTo ? new Date(taxRateDefault.dateTo) : null,
      taxType: taxRateDefault.taxType as TaxType,
    })),
  };

  useEffect(() => {
    if (
      (accountingSystemLoginValues?.success || user.isSSO) &&
      !accountingSystemTaxes
    ) {
      const fetchTaxRates = async () => {
        try {
          const response: AccountingSystemTax[] = await API.post(
            "expenslyREST",
            "/auth/bexio/proxy",
            {
              headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
              },
              body: {
                organizationId: organizationValues.id,
                path: "/3.0/taxes",
                method: "GET",
              },
            }
          );

          const filtered = response.filter((account) => account.is_active);
          filtered.sort((a, b) => a.code.localeCompare(b.code));

          setAccountingSystemTaxes(filtered);
        } catch (error) {
          console.error(error);
        }
      };
      fetchTaxRates();
    }
  }, [
    accountingSystemTaxes,
    accountingSystemLoginValues?.success,
    organizationValues.id,
    navigate,
    user.isSSO,
  ]);

  const isValid = (values: TaxRatesValues) =>
    (!accountingSystemLoginValues?.success && !user.isSSO) ||
    (accountingSystemTaxes &&
      values.taxRates.every((tr) =>
        accountingSystemTaxes.find((ast) => ast.code === tr.code)
      ));

  return (
    <VStack
      maxWidth={maxWidthTables}
      width="full"
      alignItems="start"
      spacing="12px"
    >
      <Text fontSize={36} fontWeight="medium" color="gray.900">
        {typeNamePlural}
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (isValid(values)) {
            setTaxRatesValues({
              taxRates: values.taxRates.map((tr) => ({
                ...tr,
                accountingSystemId: accountingSystemTaxes
                  ?.find((ast) => ast.code === tr.code)
                  ?.id.toString(),
              })),
            });
            setState("loading");
          }
        }}
      >
        {({ values }) => (
          <Form
            style={{
              width: "100%",
            }}
          >
            <VStack spacing={{ base: "16px", md: "48px" }}>
              <FieldArray name="taxRates">
                {({ remove, replace, push, move }) => (
                  <TaxRatesTable
                    accountingSystem={bookkeepingValues?.accountingSystem}
                    accountingSystemLoginValues={accountingSystemLoginValues}
                    typeNameSingular={typeNameSingular}
                    typeNamePlural={typeNamePlural}
                    taxRates={values.taxRates}
                    accountingSystemTaxes={accountingSystemTaxes}
                    remove={remove}
                    replace={replace}
                    push={push}
                    move={move}
                  />
                )}
              </FieldArray>
              <HStack width="full">
                <Button
                  width="full"
                  variant="outline"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Zurück
                </Button>
                <Button
                  width="full"
                  colorScheme="primary"
                  type="submit"
                  isLoading={state === "loading"}
                  isDisabled={state === "error" || !isValid(values)}
                >
                  {createOrganizationText}
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default TaxRates;
